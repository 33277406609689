import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
   Actions
} from 'firestar'
import ReactJson from 'react-json-view'
import {
   useLocation
} from 'react-router-dom'
import { Alert } from './Alert';
import { ActivityIndicator } from 'react-native';
import { Helmet } from "react-helmet";

export default function Playground() {

   const location = useLocation()
   const [path, setPath] = useState('')
   const [result, setResult] = useState<any>()
   const [showLoader, setShowLoader] = useState(false)

   useEffect(() => {
      const matches = location.pathname.match(/playground\/(.*)/g) ?? []
      if(matches?.length > 0){
         const sendingPath = matches[0].replace('playground','')
         setPath(sendingPath)
         onClickRun(sendingPath)
      }
   }, [])

   return (
      <div style={{
         padding: 20
      }}>
         <Helmet>
            <title>GINferno - Playground</title>
         </Helmet>
         <Typography variant='h6'>Playground</Typography>
         <Paper>
            <div style={{
               padding: 20,
               marginTop: 10,
               display: 'flex',
               alignItems: 'center',
               flex: 1,
               justifyContent: 'space-between',
               maxWidth: 1400
            }}>
               <TextField 
                  value={path}
                  onChange={(event) => setPath(event.target.value)}
                  style={{ display: 'flex', flex: 1, marginRight: 20 }} 
                  variant='outlined'/>
               <Button 
                  onClick={() => onClickRun()}
                  disableElevation 
                  variant={'contained'}>Run</Button>
               <Button 
                  style={{
                     marginLeft: 10
                  }}
                  disabled={result == null}
                  onClick={() => onClickOpenOnFirestore()}
                  disableElevation 
                  variant={'contained'}>Firestore ↗</Button>
            </div>
            {showLoader == true && <ActivityIndicator/>}
            <div style={{
               padding: 20
            }}>
               <ReactJson
                  collapsed={path.length == 0}
                  sortKeys
                  style={{
                     fontSize: 18,
                     flexWrap: 'wrap',
                     wordWrap: "break-word",
                     textAlign: 'left'
                  }} 
                  src={result} />
            </div>
         </Paper>
      </div>
   )

   async function onClickRun(sendingPath?:string){
      setShowLoader(true)
      try {
         const response = await Actions.readDocument(sendingPath ?? path)
         setResult(response)  
      } catch (error) {
         console.log('Error Running Playground',error)
         Alert.alert(
            'Error Running Playground',
            String(error)
         )
      }
      setShowLoader(false)
   }

   function onClickOpenOnFirestore(){
      const url = `https://console.firebase.google.com/project/ginfernov2/firestore/data${path}`
      window.open(url)
   }
}
