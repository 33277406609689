import { Rooms } from 'gf-connect'
import MaterialTable, { Column } from 'material-table'
import React, { useEffect, useMemo, useState } from 'react'
import { Alert } from './Alert'
import { CollectionDescriptor, LastDocType, OrderType, WhereType } from 'firestar'
import lodash from 'lodash'
import moment from 'moment'
import firebase from 'firebase/compat/app'
import Materials from 'gf-connect/lib/Materials'
import { Icon, LinearProgress } from 'react-native-elements'
import { Rating, UserRating } from 'gf-connect/lib/Ratings'
import { Recipe } from 'gf-connect/lib/Recipe'
import { Review } from 'gf-connect/lib/Review'
import { RoomTypeOptions } from 'gf-connect/lib/Rooms'

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

function SortOptions() {
    return {
        'updatedAt': 'Updated At',
        'createdAt': 'Created At',
        'totalMembers': 'Total Members',
        'startDate': 'Start Date',
        'endDate': 'End Date',
    }
}

export default function RoomsList() {

    const [rooms, setRooms] = useState<Rooms.Room[]>([])
    const [showLoader, setShowLoader] = useState(false)
    const [lastDoc, setLastDoc] = useState<any>()
    const [sortFilter, setSortFilter] = useState<OrderType>({
        fieldPath: 'updatedAt',
        directionStr: 'desc'
    })

    const columns = useMemo<Column<Rooms.Room>[]>(() => {
        return [
            {
                title: 'Id',
                field: 'id',
                sorting: false,
            },
            {
                title: 'Name',
                field: 'name',
                sorting: false,
            },
            {
                title: 'Total Members',
                field: 'totalMembers',
                sorting: false,
            },
            {
                field: 'selectedGins',
                title: 'Selected Gins',
                emptyValue: 'false'
            },
            {
                title: 'Type',
                field: 'type',
                sorting: false,
                cellStyle: {
                    fontSize: 12
                },
                emptyValue: 'Tasting',
                lookup: RoomTypeOptions
            },
            {
                title: 'Status',
                sorting: false,
                render: (room) => Rooms.getRoomStatus(room).toUpperCase(),
                cellStyle: {
                    fontSize: 12
                }
            },
            {
                title: SortOptions()[sortFilter.fieldPath as string],
                sorting: true,
                render: (m) => {
                    const field = sortFilter.fieldPath as string
                    if (['createdAt', 'updatedAt', 'startDate', 'endDate'].includes(field)) {
                        const timeStamp = m[field] as firebase.firestore.Timestamp
                        const date = timeStamp.toDate()
                        return (
                            <div style={{
                                textAlign: 'center'
                            }}>
                                <div>{moment(date).format('hh:mm A • DD-MMM-YYYY')}</div>
                                <div>{moment(date).fromNow()}</div>
                            </div>
                        )
                    } else {
                        return (
                            <div>{lodash.get(m, field)}</div>
                        )
                    }
                },
                defaultSort: sortFilter.directionStr,
                align: 'center'
            },
            {
                title: '',
                sorting: false,
                render: (room) => (
                    <div style={{
                       flexDirection: 'row',
                       alignItems: 'center'
                    }}>
                       <Icon
                          onPress={() => window.open(`https://admin.ginferno.app/playground/rooms/${room.id}`)}
                          name='games' />
                    </div>
                 ),
            }
        ]
    }, [sortFilter])

    useEffect(() => {
        fetchRooms(false)
    }, [sortFilter])

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <div></div>
                <select style={{
                    padding: 15,
                    fontSize: 16,
                    borderWidth: 0,
                    marginRight: 15,
                    textAlign: 'right',
                    background: 'transparent'
                }} value={sortFilter.fieldPath as string} onChange={onChangeSortFilter}>
                    {Object.keys(SortOptions()).map((key) => {
                        return (
                            <option value={key}>Sort By: {SortOptions()[key]}</option>
                        )
                    })}
                </select>
            </div>
            <MaterialTable
                title='Rooms'
                isLoading={showLoader}
                onOrderChange={onOrderChange}
                onChangePage={onChangePage}
                options={{
                    search: false,
                    pageSizeOptions: [PAGESIZE],
                    pageSize: PAGESIZE,
                    sorting: true,
                    filtering: false,
                }}
                detailPanel={
                    (room) => <RoomDetail room={room}/>
                }
                data={rooms}
                columns={columns} />
        </div>
    )

    function onChangePage(page: number, pageSize: number) {
        if (rooms.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE)) {
           console.log('Paginating', lastDoc)
           if (lastDoc != null) fetchRooms(true)
        }
     }

    function onOrderChange(_: number, orderDirection: "asc" | "desc") {
        if (orderDirection.length > 0) {
            setSortFilter({
                ...sortFilter,
                directionStr: orderDirection
            })
        } else {
            setSortFilter({
                ...sortFilter,
                directionStr: 'asc'
            })
        }
    }

    function onChangeSortFilter(event) {
        setSortFilter({
            fieldPath: event.target.value,
            directionStr: 'desc'
        })
    }

    async function fetchRooms(paginating: boolean) {
        setShowLoader(true)
        try {
            const response = await Rooms.getRooms(paginating == true ? lastDoc : undefined, {
                orderBy: sortFilter,
                limit: INITIAL_PAGESIZE
            })
            if(paginating == true){
                setRooms([...rooms, ...response.data])
            }else{
                setRooms(response.data)
            }
            setLastDoc(response.lastDoc)
        } catch (error) {
            Alert.alert('Error Fetching Rooms', String(error))
            console.log('Error Fetching Rooms', error)
        }
        setShowLoader(false)
    }
}

function RoomDetail(props:{
    room: Rooms.Room
}){
    const { room } = props
    return (
        <div style={{
            padding: '1em',
            background: '#decceb'
        }}>
            <h3>{room.name}</h3>
            <br/>
            <RoomGins  roomId={room.id} />
            <br/>
        </div>
    )
}

function RoomGins(props:{
    roomId: string
}){
    const { roomId } = props
    const [materials, setMaterials] = useState<Materials.Material[]>([])
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        fetchMaterials()
    }, [])
    
    if(showLoader == true){
        return <LinearProgress/>
    }

    return (
        <div style={{
            background: '#ebe1c5',
            padding: '1em'
        }}>
            <MaterialTable
                data={materials}
                options={{
                    pageSize: materials.length,
                    pageSizeOptions: [],
                }}
                title='Gins'
                detailPanel={(material) => <GinRatings roomId={roomId} material={material} />}
                columns={[
                    {
                        field: 'id',
                        title: 'Id'
                    },
                    {
                        field: 'name',
                        title: 'Name'
                    },
                    {
                        field: 'ratings.numberOfRatings',
                        title: 'Number Of Ratings'
                    },
                    {
                        field: 'ratings.absoluteAverage',
                        title: 'Absolute Average'
                    },
                    {
                        field: 'ratings.absoluteTotal',
                        title: 'Absolute Total'
                    },
                    {
                        title: '',
                        render: (material) => (
                            <div style={{
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <Icon
                                    onPress={() => window.open(`https://admin.ginferno.app/playground/rooms/${roomId}/materials/${material.id}`)}
                                    name='games' />
                            </div>
                        ),
                    }
                ]} />
            {materials.length > 0 && <RoomRecipes roomId={roomId} materials={materials} />}
        </div>
    )

    async function fetchMaterials() {
        setShowLoader(true)
        try {
            const response = await Rooms.getGins(roomId, undefined, {
                limit: 10000
            })
            setMaterials(response.data)
        } catch (error) {
            Alert.alert('Error Getting Gins', String(error))
            console.log('Error Getting Gins', error)
        }
        setShowLoader(false)
    }
}

function GinRatings(props:{
    roomId: string,
    material: Materials.Material
}){
    const { roomId } = props
    const [material, setMaterial] = useState(props.material)
    const [ratings, setRatings] = useState<UserRating[]>([])
    const [showLoader, setShowLoader] = useState(false)
    const rating = useMemo(() => {
        const total = ratings.reduce((previous, current) => previous + (current.absoluteHighest ?? 0) ,0)
        const average = total / ratings.length
        return {
            numberOfRatings: ratings.length,
            absoluteTotal: total,
            absoluteAverage: isNaN(average) ? 0 : average
        } as Rating
    }, [ratings])
    const absoluteTotal = material.ratings?.absoluteTotal
    const numberOfRatings = material.ratings?.numberOfRatings
    const absoluteAverage = material.ratings?.absoluteAverage

    useEffect(() => {
        fetchMaterials()
    }, [])
    
    if(showLoader == true){
        return <LinearProgress/>
    }

    return (
        <div style={{
            background: '#b7e3e8',
            padding: '1em'
        }}>
            <div>
                <p>
                    <b>Calculated</b>
                    <div>Absolute Total: {rating.absoluteTotal?.toFixed(2)}</div>
                    <div>Number of Ratings: {rating.numberOfRatings?.toFixed(2)}</div>
                    <div>Absolute Average: {rating.absoluteAverage?.toFixed(2)}</div>
                </p>
                <p>
                    <b>Stored</b>
                    <div>Absolute Total: {absoluteTotal?.toFixed(2)}: {absoluteTotal?.toFixed(2) != rating.absoluteTotal?.toFixed(2) ? '❌' : '✅'}</div>
                    <div>Number of Ratings: {numberOfRatings?.toFixed(2)}: {numberOfRatings?.toFixed(2) != rating.numberOfRatings?.toFixed(2) ? '❌' : '✅'}</div>
                    <div>Absolute Average: {absoluteAverage?.toFixed(2)}: {absoluteAverage?.toFixed(2) != rating.absoluteAverage?.toFixed(2) ? '❌' : '✅'}</div>
                </p>
                <p>{absoluteAverage?.toFixed(2) != rating.absoluteAverage?.toFixed(2) && <button onClick={onClickFix}>Fix</button>}</p>
            </div>
            <MaterialTable 
                data={ratings}
                options={{
                    pageSize: ratings.length,
                    pageSizeOptions: [],
                    search: false
                }}
                title='Gins'
                columns={[
                    {
                        field: 'id',
                        title: 'Id'
                    },
                    {
                        field: 'absoluteHighest',
                        title: 'Absolute Highest'
                    },
                    {
                        title: 'Updated At',
                        render: (userRating) => {
                            const date = userRating.updatedAt.toDate()
                            return (
                                <div style={{
                                    textAlign: 'center'
                                }}>
                                    <div>{moment(date).format('hh:mm A • DD-MMM-YYYY')}</div>
                                    <div>{moment(date).fromNow()}</div>
                                </div>
                            )
                        }
                    },
                    {
                        title: '',
                        render: (userRating) => (
                            <div style={{
                               flexDirection: 'row',
                               alignItems: 'center'
                            }}>
                               <Icon
                                  onPress={() => window.open(`https://admin.ginferno.app/playground/rooms/${roomId}/materials/${material.id}/ratings/${userRating.id}`)}
                                  name='games' />
                            </div>
                         ),
                    }
                ]}/>
        </div>
    )

    async function fetchMaterials() {
        setShowLoader(true)
        try {
            const response = await Rooms.getRatings(roomId, material.id, {
                limit: 10000
            })
            setRatings(response.data)
        } catch (error) {
            Alert.alert('Error Getting Gins', String(error))
            console.log('Error Getting Gins', error)
        }
        setShowLoader(false)
    }

    async function onClickFix() {
        setShowLoader(true)
        try {
            const firestore = firebase.firestore()
            const docRef = firestore.doc(`rooms/${roomId}/materials/${material.id}`)
            await firestore.runTransaction((transaction) => {
                return transaction.get(docRef)
                    .then(() => {
                        Rooms.updateMaterial(roomId, material.id, {
                            ratings: rating
                        }, transaction)
                    }
                    )
            })
            setMaterial({
                ...material,
              ratings: rating  
            })

        } catch (error) {
            Alert.alert('Error fixing', String(error))
            console.log('Error Fixing', error)
        }
        setShowLoader(false)
    }
}

function RoomRecipes(props: {
    roomId: string,
    materials: Materials.Material[]
}) {
    const { roomId, materials } = props
    const [recipes, setRecipes] = useState<Recipe[]>([])
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        fetchRecipes()
    }, [])

    if (showLoader == true) {
        return <LinearProgress />
    }

    return (
        <div style={{
            background: '#b7e3e8',
            padding: '1em'
        }}>
            <MaterialTable
                data={recipes}
                options={{
                    pageSize: recipes.length,
                    pageSizeOptions: [],
                    search: false
                }}
                title='Recipes'
                detailPanel={(recipe) => <RecipeReviews recipe={recipe} roomId={roomId}/>}
                columns={[
                    {
                        field: 'id',
                        title: 'Id'
                    },
                    {
                        field: 'Name',
                        render: (recipe) => recipe.combinations?.map((m) => m.name).join(' + ')
                    },
                    {
                        field: 'ratings.numberOfRatings',
                        title: 'Number Of Ratings'
                    },
                    {
                        field: 'ratings.absoluteAverage',
                        title: 'Absolute Average'
                    },
                    {
                        field: 'ratings.absoluteTotal',
                        title: 'Absolute Total'
                    },
                    {
                        title: 'Updated At',
                        render: (userRating) => {
                            const date = userRating.updatedAt.toDate()
                            return (
                                <div style={{
                                    textAlign: 'center'
                                }}>
                                    <div>{moment(date).format('hh:mm A • DD-MMM-YYYY')}</div>
                                    <div>{moment(date).fromNow()}</div>
                                </div>
                            )
                        }
                    },
                    {
                        title: '',
                        render: (recipe) => (
                            <div style={{
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <Icon
                                    onPress={() => window.open(`https://admin.ginferno.app/playground/rooms/${roomId}/recipes/${recipe.id}`)}
                                    name='games' />
                            </div>
                        ),
                    }
                ]} />
        </div>
    )

    async function fetchRecipes() {
        setShowLoader(true)
        try {
            const response = await Rooms.getRecipes(roomId,{
                limit: 10000
            })
            const materialIds = materials.map((m) => m.id)
            const filtered = response.data.filter((recipe) => {
                return materialIds.includes(recipe.materialId ?? '')
            })
            setRecipes(filtered)
        } catch (error) {
            Alert.alert('Error Getting Recipes', String(error))
            console.log('Error Getting Recipes', error)
        }
        setShowLoader(false)
    }
}

function RecipeReviews(props:{
    roomId: string,
    recipe: Recipe
}){
    const { roomId, recipe } = props
    const [reviews, setReviews] = useState<Review[]>([])
    const [showLoader, setShowLoader] = useState(false)
    const rating = useMemo(() => {
        const total = reviews.reduce((previous, current) => previous + (current.absoluteRating ?? 0) ,0)
        const average = total / reviews.length
        return {
            numberOfRatings: reviews.length,
            absoluteTotal: total,
            absoluteAverage: isNaN(average) ? 0 : average
        } as Rating
    }, [reviews])
    const absoluteTotal = recipe.ratings?.absoluteTotal
    const numberOfRatings = recipe.ratings?.numberOfRatings
    const absoluteAverage = recipe.ratings?.absoluteAverage

    useEffect(() => {
        fetchReviews()
    }, [])
    
    if(showLoader == true){
        return <LinearProgress/>
    }

    return (
        <div style={{
            background: '#ccdba0',
            padding: '1em'
        }}>
            <div>
                <p>
                    <b>Calculated</b>
                    <div>Absolute Total: {rating.absoluteTotal?.toFixed(2)}</div>
                    <div>Number of Ratings: {rating.numberOfRatings?.toFixed(2)}</div>
                    <div>Absolute Average: {rating.absoluteAverage?.toFixed(2)}</div>
                </p>
                <p>
                    <b>Stored</b>
                    <div>Absolute Total: {absoluteTotal?.toFixed(2)}: {absoluteTotal?.toFixed(2) != rating.absoluteTotal?.toFixed(2) ? '❌' : '✅'}</div>
                    <div>Number of Ratings: {numberOfRatings?.toFixed(2)}: {numberOfRatings?.toFixed(2) != rating.numberOfRatings?.toFixed(2) ? '❌' : '✅'}</div>
                    <div>Absolute Average: {absoluteAverage?.toFixed(2)}: {absoluteAverage?.toFixed(2) != rating.absoluteAverage?.toFixed(2) ? '❌' : '✅'}</div>
                </p>
            </div>
            <MaterialTable 
                data={reviews}
                options={{
                    pageSize: reviews.length,
                    pageSizeOptions: [],
                    search: false
                }}
                title='Reviews'
                columns={[
                    {
                        field: 'id',
                        title: 'Id'
                    },
                    {
                        field: 'absoluteRating',
                        title: 'Absolute Rating'
                    },
                    {
                        title: 'Updated At',
                        render: (userRating) => {
                            const date = userRating.updatedAt.toDate()
                            return (
                                <div style={{
                                    textAlign: 'center'
                                }}>
                                    <div>{moment(date).format('hh:mm A • DD-MMM-YYYY')}</div>
                                    <div>{moment(date).fromNow()}</div>
                                </div>
                            )
                        }
                    },
                    {
                        title: '',
                        render: (review) => (
                            <div style={{
                               flexDirection: 'row',
                               alignItems: 'center'
                            }}>
                               <Icon
                                  onPress={() => window.open(`https://admin.ginferno.app/playground/rooms/${roomId}/recipes/${recipe.id}/reviews/${review.id}`)}
                                  name='games' />
                            </div>
                         ),
                    }
                ]}/>
        </div>
    )

    async function fetchReviews() {
        setShowLoader(true)
        try {
            const response = await Rooms.getReviewForRecipeId(roomId, recipe.id, {
                limit: 10000
            })
            setReviews(response.data)
        } catch (error) {
            Alert.alert('Error Getting Gins', String(error))
            console.log('Error Getting Gins', error)
        }
        setShowLoader(false)
    }
}

