import { 
   Company as CompanyType,
   getAllCompanies,
   uploadCompanies,
   updateCompany,
   createCompany,
   getCompany
} from 'gf-connect/lib/Company'
import { Change, AppState } from '.'

export type Company = CompanyType

const initialState : Company[] = []

export default function (state = initialState, action: Change) {
   switch (action.type) {
      case 'SYNC_COMPANIES' : {
         let currentList = [...state]            
         const newCompanies = [...action.payload as CompanyType[]]
         newCompanies.forEach( (p:CompanyType) => {
               const findIndex = currentList.findIndex( (c) => c.id ===  p.id)                  
               if(findIndex >= 0){
                  currentList[findIndex] = p
               }else{
                  currentList.push(p)
               }
            }
         )   
         return currentList
      }
      default:
         return state
   }
}

export function getCompanies (limit?:number) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Company[]>((resolve, reject) => {
         getAllCompanies({
            limit : limit ?? 10000,
            orderBy : {
               fieldPath : 'updatedAt',
               directionStr : 'desc'
            }
         })
         .then(
            (companies) => {
               resolve(companies.data as Company[])
               dispatch({
                  payload : companies.data,
                  type : 'SYNC_COMPANIES'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function uploadCompaniesFromCSV(data:any[],onProgress:any) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Company[]>((resolve, reject) => {         
         uploadCompanies(data,onProgress)
         .then(
            (companies) => {
               resolve(companies)
               dispatch({
                  payload : companies,
                  type : 'SYNC_COMPANIES'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function addCompany(company:Company) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Company>((resolve, reject) => {
         createCompany(company)
         .then(
            (response) => {
               resolve(response)
               dispatch({
                  payload : [response],
                  type : 'SYNC_COMPANIES'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function updateCompanyInfo(company:Company) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Company>((resolve, reject) => {
         updateCompany(company)
         .then(
            (response) => {
               resolve(response)
               dispatch({
                  payload : response,
                  type : 'SYNC_COMPANIES'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function deleteCompany(company:Company) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Company>((resolve, reject) => {
         updateCompany({...company,status : 'deleted'})
         .then(
            (response) => {
               resolve(response)
               dispatch({
                  payload : [response],
                  type : 'SYNC_COMPANIES'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function getCompanyDetails(companyId:string) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Company>((resolve, reject) => {
         getCompany(companyId)
         .then(
            (response) => {
               resolve(response)
               dispatch({
                  payload : [response],
                  type : 'SYNC_COMPANIES'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}