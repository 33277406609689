import { Change, AppState } from '.'
import { Promotion, getActivePromotions } from 'gf-connect/lib/Promotion'
import { incrementBannerIndex } from './Settings'

const initialState : Promotion[] = []

export default function (state = initialState, action: Change) : Promotion[] {
   switch (action.type) {
      case 'SYNC_PROMOTIONS' : {
         let currentList = [...state]            
         const newPromotions = [...action.payload as Promotion[]]
         newPromotions.forEach( (p:Promotion) => {
               const findIndex = currentList.findIndex( (c) => c.id ===  p.id)                  
               if(findIndex >= 0){
                  currentList[findIndex] = p
               }else{
                  currentList.push(p)
               }
            }
         )   
         return currentList
      }
      case 'CLEAR_PROMOTIONS' :{
         return []
      }
      default:
         return state
   }
}

export function fetchPromotions() {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         getActivePromotions()
            .then(
               (promotions) => {
                  dispatch({
                     type: 'SYNC_PROMOTIONS',
                     payload: promotions
                  })
                  const random = Math.random() * (promotions.length - 0) + 0
                  dispatch(incrementBannerIndex(random))
                  resolve(promotions)
               }
            )
            .catch((error) => {
               reject(error)
            })
      })
   }
}
