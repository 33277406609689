import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import firebase from 'firebase/compat/app'
import CircularProgress from '@material-ui/core/CircularProgress';

export class Login extends Component {

   state = {
      email : '',
      password : '',
      showLoader : false
   }

   render() {

      const { email, password, showLoader } = this.state
      
      return (
         <div>
            <Backdrop style={{
               zIndex: 1000,
               color: '#fff',
            }} open={showLoader}>
               <CircularProgress color="inherit" />
            </Backdrop>
            <Paper style={{
               padding : 20,
            }}>
               <Typography variant='h5'>Login to GINferno Admin</Typography>
               <TextField
                  style={{
                     marginRight : 5
                  }}
                  type='email'
                  onChange ={(event) => this.setState({ email : event.target.value }) }
                  id="outlined-helperText"
                  label="Email"
                  defaultValue=""
                  value={email}
                  variant="outlined"
               />
               <TextField
                  style={{
                     marginLeft: 5
                  }}
                  type='password'
                  onChange={(event) => this.setState({ password : event.target.value })}
                  id="outlined-helperText"
                  label="Password"
                  defaultValue=""
                  variant="outlined"
                  value={password}
               />
               <Button 
                  onClick={this.onClickLogin}
                  style={{
                     marginLeft : 10,
                  }}
                  variant='contained'>Login</Button>
            </Paper>
         </div>
      )
   }

   onClickLogin = () => {

      const { email, password } = this.state
      this.setState({ showLoader: true })

      if (email.toLowerCase().endsWith('@ginferno.app') || email.toLowerCase().endsWith('@logisian.in')) {

         firebase.auth().signInWithEmailAndPassword(email.trim(), password)
            .then(
               () => {
                  this.setState({ showLoader: false })
               }
            )
            .catch(
               (error) => {
                  alert('Error' + String(error))
                  this.setState({ showLoader: false })
               }
            )

      } else {

         

      }
   }
}

export default Login
