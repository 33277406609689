import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table';
import { getAllMaterials, Material } from 'gf-connect/lib/Material'
import Ratings, { Rating, UserRating } from 'gf-connect/lib/Ratings';
import { Alert } from './Alert';
import {
   View,
   Text,
   ActivityIndicator
} from 'react-native'
import { Link } from 'react-router-dom';
import { Button, Icon, SearchBar } from 'react-native-elements';
import moment from 'moment';

const INITIAL_PAGESIZE = 150
const PAGESIZE = 100

export default function RatingsHealth() {

   const [materials, setMaterials] = useState<Material[]>([])
   const [showLoader, setShowLoader] = useState(true)
   const [searchText, setSearchText] = useState('')
	const debouncedSearchTerm = useDebounce(searchText, 500);
   const [lastDoc, setLastDoc] = useState<any>()

   useEffect(() => {
      fetchMaterialsWithRatings()
   }, [])

   useEffect(
      () => {
         // Make sure we have a value (user has entered something in input)
         if (debouncedSearchTerm) {
            // Set isSearching state
            //  setIsSearching(true);
            //  // Fire off our API call
            //  searchCharacters(debouncedSearchTerm).then(results => {
            // 	// Set back to false since request finished
            // 	setIsSearching(false);
            // 	// Set results state
            // 	setResults(results);
            //  });
            fetchMaterialsWithRatings(undefined, debouncedSearchTerm)
         } else {
            fetchMaterialsWithRatings()
            //  setResults([]);
         }
      },
      // This is the useEffect input array
      // Our useEffect function will only execute if this value changes ...
      // ... and thanks to our hook it will only change if the original ...
      // value (searchTerm) hasn't changed for more than 500ms.
      [debouncedSearchTerm]
   );

   async function fetchMaterialsWithRatings(pagination?: boolean, searchTerm?: string) {
      setShowLoader(true)
      try {

         const searchTags = (searchTerm ?? '').split(' ')
            .map((text: string) => text.toLowerCase().trim())
            .filter((text: string) => text.length > 0)


         const response = await getAllMaterials({
            limit: pagination === true ? PAGESIZE : INITIAL_PAGESIZE,
            lastDoc: pagination === true ? lastDoc : undefined,
            where: searchTags.length > 0 ?
               [
                  {
                     fieldPath: 'tags',
                     opStr: 'array-contains-any',
                     value: searchTags
                  }
               ] : [],
            orderBy: searchTags.length == 0 ? {
               fieldPath: 'ratings.numberOfRatings',
               directionStr: 'desc'
            } : undefined,
         })
         console.log('Materials Count : ', response.data.length)

         if ((pagination ?? false) == true) {
            setMaterials([...materials, ...response.data])
         } else {
            setMaterials(response.data as Material[])
         }
         setLastDoc(response.lastDoc)
      } catch (error) {
         console.log('Error Fetching Materials : ', error)
         Alert.alert('Error Fetching Materials', String(error))
      }
      setShowLoader(false)
   }

   return (
      <View>
         <View style={{
            paddingVertical: 15,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
         }}>
            <Button
               containerStyle={{
                  width: 150
               }}
               disabled={showLoader == true}
               onPress={onPressRefresh}
               type='outline'
               title='Refresh' />
            <SearchBar
               containerStyle={{
                  backgroundColor: 'white',
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  padding: 0
               }}
               inputContainerStyle={{
                  backgroundColor: 'white'
               }}
               onClear={() => {
                  setSearchText('')
               }}
               placeholder='Search'
               onChangeText={(text) => setSearchText(text)}
               value={searchText} />
         </View>
         <MaterialTable
            options={{
               pageSize: PAGESIZE,
               pageSizeOptions: [PAGESIZE],
               rowStyle: {
                  background: '#b5d8f7'
               },
               search: false
            }}
            isLoading={showLoader}
            onChangePage={onChangePage}
            title={`Rated Materials (${materials.length})`}
            data={materials}
            columns={[
               {
                  title: 'Id',
                  field: 'id'
               },
               {
                  title: 'Name',
                  field: 'name'
               },
               {
                  title: 'Total Reviews',
                  field: 'totalReviews'
               },
               {
                  title: 'Absolute Total',
                  field: 'ratings.absoluteTotal',
                  render: (material) => material.ratings?.absoluteTotal?.toFixed(1)
               },
               {
                  title: 'Number Of Ratings',
                  field: 'ratings.numberOfRatings'
               },
               {
                  title: 'Absolute Average',
                  field: 'ratings.absoluteAverage',
                  render: (material) => material.ratings?.absoluteAverage?.toFixed(2)
               },
               {
                  title: 'Average Check',
                  render: (material) => {

                     const average = (material.ratings?.absoluteTotal ?? 0) / (material.ratings?.numberOfRatings ?? 0)
                     let fixedCalculatedAverage = parseFloat(average.toFixed(2))
                     fixedCalculatedAverage = isNaN(fixedCalculatedAverage) ? 0 : fixedCalculatedAverage

                     let absoluteAverage = parseFloat((material.ratings?.absoluteAverage ?? 0).toFixed(2))

                     if (fixedCalculatedAverage == absoluteAverage) {
                        return '✅'
                     } else {
                        return (
                           `${absoluteAverage} != ${fixedCalculatedAverage}`
                        )
                     }
                  }
               },
               {
                  field: 'id',
                  editable: 'never',
                  render: (m) => (
                     <Icon
                        onPress={() => window.open(`https://admin.ginferno.app/playground/materials/${m.id}`)}
                        name='games' />
                  ),
               },
               {
                  title: '',
                  render: (material) => (
                     <View>
                        {material.type == 'gin' && (
                           <Link to={`/settings/ratings-health/${material.id}`}>→</Link>
                        )}
                     </View>
                  )
               }
            ]}
            detailPanel={(rowData) => {
               return (
                  <View style={{
                     padding: 15
                  }}>
                     <RatingsList
                        ratings={rowData.ratings}
                        materialId={rowData.id} />
                  </View>
               )
            }}
         />
      </View>
   )

   function onChangePage(page: number, pageSize: number) {
      if (materials.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE)) {
         if(lastDoc != null) fetchMaterialsWithRatings(true,searchText)
      }
   }

   function onPressRefresh() {
      if(searchText.length > 0){
         setSearchText('')
      }else{
         fetchMaterialsWithRatings()
      }
   }
}

function RatingsList(props: {
   materialId: string,
   ratings?: Rating
}) {

   const { materialId, ratings } = props
   const [allRatings, setAllRatings] = useState<UserRating[]>([])
   const [showLoader, setShowLoader] = useState(false)

   const [numberOfRatings, setNumberOfRatings] = useState(0)
   const [absoluteTotal, setAbsoluteTotal] = useState(0)
   const [absoluteAverage, setAbsoluteAverage] = useState<number>(0)

   useEffect(() => {
      fetchRatings()
   }, [])

   useEffect(() => {

      if(allRatings.length > 0){
         const __totalNumberOfRatings = allRatings.reduce(
            (previous, current) => previous + ((current.absoluteHighest ?? 0) > 0 ? 1 : 0)
         ,0)
         setNumberOfRatings(__totalNumberOfRatings)
   
         const __absoluteTotal = allRatings.reduce(
            (previous, current) => previous + ((current.absoluteHighest ?? 0) > 0 ? (current.absoluteHighest ?? 0) : 0)
         , 0)
         setAbsoluteTotal(__absoluteTotal)      
      }
   }, [allRatings])

   useEffect(() => {
     
      if(absoluteTotal > 0 && numberOfRatings > 0){
         const average = (absoluteTotal ?? 0) / (numberOfRatings ?? 0)
         const __absoluteAverage = parseFloat(average.toFixed(2))
         setAbsoluteAverage(__absoluteAverage)
      }
   }, [absoluteTotal, numberOfRatings])
   
   async function fetchRatings(){
      setShowLoader(true)
      try {
         const response = await Ratings.get(materialId,{
            orderBy: {
               fieldPath: 'absoluteHighest',
               directionStr: 'desc'
            },
            limit: 10000
         })
         setAllRatings(response.data)


      } catch (error) {
         console.log('Error Getting Ratings',error)
      }
      setShowLoader(false)
   }

   if(showLoader == true) return <ActivityIndicator/>

   return (
      <View>
         {allRatings.length > 0 && <View style={{
            marginBottom: 10
         }}>
            <Text>Number Of Ratings:
               {parseFloat(numberOfRatings.toFixed(2)) === parseFloat((ratings?.numberOfRatings ?? 0).toFixed(2)) ? '✅'
                  : `❌ => ${ratings?.numberOfRatings} != ${numberOfRatings}`}
            </Text>
            <Text>Absolute Total:
               {parseFloat(absoluteTotal.toFixed(2)) === parseFloat((ratings?.absoluteTotal ?? 0).toFixed(2)) ? '✅'
                  : `❌ => ${ratings?.absoluteTotal} != ${absoluteTotal}`}
            </Text>
            <Text>Absolute Average:
               {parseFloat(absoluteAverage.toFixed(2)) === parseFloat((ratings?.absoluteAverage ?? 0).toFixed(2)) ? '✅'
                  : `❌ => ${ratings?.absoluteAverage} != ${absoluteAverage}`}
            </Text>
         </View>}
         <MaterialTable
            title={`User Ratings ${absoluteTotal}/${numberOfRatings}=${absoluteAverage}`}
            isLoading={showLoader}
            options={{
               pageSize: allRatings.length,
               search: false,
               rowStyle: {
                  background: '#e6e485'
               }
            }}
            data={allRatings}
            columns={[
               {
                  title: 'User Id',
                  field: 'id'
               },
               {
                  title: 'Absolute Highest',
                  field: 'absoluteHighest',
                  render: (userRating) => userRating.absoluteHighest?.toFixed(2)
               },
               {
                  title: 'Updated At',
                  render: (recipe) => moment(recipe.updatedAt.toDate()).format('hh:mm A, DD/MM/YYYY')
               },
               {
                  field: 'id',
                  editable: 'never',
                  render: (r) => (
                     <Icon
                        onPress={() => window.open(`https://admin.ginferno.app/playground/materials/${materialId}/ratings/${r.id}`)}
                        name='games'/>
                  ),
               },
            ]} />
      </View>
   )
}

// Our hook
export function useDebounce(value, delay) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);
 
	useEffect(
	  () => {
		 // Set debouncedValue to value (passed in) after the specified delay
		 const handler = setTimeout(() => {
			setDebouncedValue(value);
		 }, delay);
 
		 // Return a cleanup function that will be called every time ...
		 // ... useEffect is re-called. useEffect will only be re-called ...
		 // ... if value changes (see the inputs array below). 
		 // This is how we prevent debouncedValue from changing if value is ...
		 // ... changed within the delay period. Timeout gets cleared and restarted.
		 // To put it in context, if the user is typing within our app's ...
		 // ... search box, we don't want the debouncedValue to update until ...
		 // ... they've stopped typing for more than 500ms.
		 return () => {
			clearTimeout(handler);
		 };
	  },
	  // Only re-call effect if value changes
	  // You could also add the "delay" var to inputs array if you ...
	  // ... need to be able to change that dynamically.
	  [value] 
	);
 
	return debouncedValue;
 }