import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import {
   Actions,
   Storage
} from 'firestar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { createFlatEmptyLanguageKeys, createTimestampContext, exportListToCSV } from 'gf-connect/lib/Utils';
import { useHistory } from 'react-router-dom';
import { Analytics, Materials, Rooms, Users } from 'gf-connect';
import firebase from 'firebase/compat/app';
import { ActivityIndicator, View } from 'react-native';
import { User } from 'gf-connect/lib/User';
import InternalUsers from './InternalUsers';
import moment from 'moment';
import { Alert } from './Alert';
import { isDev } from './utils';
import { getSearchObject } from 'gf-connect/lib/Material';
// import { getSearchObject, updateMaterialTags } from 'gf-connect/lib/Material';


export default function Settings() {

   const history = useHistory()
   const [tabIndex, setTabIndex] = useState(0)
   const [showLoader, setShowLoader] = useState(false)

   if (showLoader == true) {
      return <ActivityIndicator />
   }

   return (
      <div style={{
         display: 'flex',
         flexDirection: 'column',
      }}>
         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabIndex} onChange={onChangeTab} aria-label="basic tabs example">
               <Tab label="Users" value={0} />
               <Tab label="App Settings" value={1} />
               <Tab label="Functions" value={2} />
            </Tabs>
         </Box>
         <View>
            {tabIndex == 0 && (
               <InternalUsers />
            )}
            {tabIndex == 1 && (
               <AppSettings />
            )}
            {tabIndex == 2 && (
               <View>
                  <Button
                     color='primary'
                     style={{
                        width: 300,
                        marginTop: 10
                     }}
                     onClick={onPressDownloadRatings}
                     variant='contained'>Download Ratings</Button>
                  <Button
                     style={{
                        width: 300,
                        marginTop: 10
                     }}
                     color='primary'
                     onClick={onPressRemoveSpamUsers}
                     variant='contained'>Remove Spam Users</Button>
                  <Button
                     style={{
                        width: 300,
                        marginTop: 10
                     }}
                     color='primary'
                     onClick={onPressDownloadEANData}
                     variant='contained'>Download Barcode/EAN Data</Button>
                  <Button
                     style={{
                        width: 300,
                        marginTop: 10
                     }}
                     color='primary'
                     onClick={onPressCheckRatingsHealth}
                     variant='contained'>Check Ratings Health</Button>
                  <Button
                     variant='contained'
                     style={{
                        width: 300,
                        marginTop: 10
                     }}
                     color='primary'
                     onClick={onPressDownloadUsers}>Download Users</Button>
                  <Button
                     variant='contained'
                     style={{
                        width: 300,
                        marginTop: 10
                     }}
                     color='primary'
                     onClick={onClickDownloadCompanies}>Download Companies</Button>
                  <Button
                     variant='contained'
                     style={{
                        width: 300,
                        marginTop: 10
                     }}
                     color='primary'
                     onClick={onClickDownloadBannerEvents}>Download Banner Clicks</Button>
                  <Button
                     style={{
                        width: 300,
                        marginTop: 10
                     }}
                     color='primary'
                     onClick={printSitemap}
                     variant='contained'>Update Sitemap</Button>
                  {isDev() && <Button
                     style={{
                        width: 300,
                        marginTop: 10
                     }}
                     color='primary'
                     onClick={runQuery}
                     variant='contained'>Run Query</Button>}
               </View>
            )}
         </View>
      </div>
   )

   function onChangeTab(_: any, value: number) {
      setTabIndex(value)
   }

   async function printSitemap() {

      console.log('Printing Site Map')
      setShowLoader(true)
      try {
         const ginResponse = await Actions.readFullCollection('materials', {
            where: [
               {
                  fieldPath: 'type',
                  opStr: '==',
                  value: 'gin'
               }
            ],
            limit: 10000
         })

         const mixerResponse = await Actions.readFullCollection('materials', {
            where: [
               {
                  fieldPath: 'type',
                  opStr: '==',
                  value: 'mixer'
               }
            ],
            limit: 10000
         })

         const gins = ginResponse.data.filter(
            (m) => (m.uri ?? '').trim().length > 0).map(
               m => `https://web.ginferno.app/${m.type}/${m.uri}`
            )
         const uniqueGinsUrls = [...new Set(gins)];
         console.log('Unique Gin Urls: ', uniqueGinsUrls)
         console.log('Duplicate Gin Urls: ', gins.length - uniqueGinsUrls.length)

         const mixers = mixerResponse.data.filter(
            (m) => (m.uri ?? '').trim().length > 0).map(
               m => `https://web.ginferno.app/${m.type}/${m.uri}`
            )
         const uniqueMixerUrls = [...new Set(mixers)];
         console.log('Unique Mixer Urls: ', uniqueMixerUrls)
         console.log('Duplicate Mixer Urls: ', mixers.length - uniqueMixerUrls.length)

         console.log('Total Gins:', gins)

         console.log('Mixers Gins:', mixers)

         const sitemap = {
            gins: uniqueGinsUrls,
            mixers: uniqueMixerUrls,
            lastmod: new Date().toISOString(),
            changefreq: 'weekly',
            priority: 0.8
         }

         const jsonString = JSON.stringify(sitemap)
         console.log('Sitemap', jsonString)

         const uploadResponse = await Storage.uploadFileDataToPath('sitemap.json', jsonString, 'raw', {
            contentType: 'application/json',
            cacheControl: 'public,max-age=10000000',
            customMetadata: {
               access: 'public'
            }
         })

         Alert.alert('Sitemap Updated', 'Open the link?', [
            {
               text: 'Open',
               onPress: () => window.open(uploadResponse.downloadUrl, '_blank')
            },
            {
               text: 'Cancel',
            }
         ])

         console.log('UploadResponse: ', uploadResponse)

      } catch (error) {
         console.log('Error Print Site Map Url: ', error)
      }

      setShowLoader(false)
   }

   async function runQuery() {

      console.log('Running Query...')
      try {


		// const response = await Materials.getMaterials({
		// 	orderBy: {
		// 		fieldPath: 'thumbnail',
		// 		directionStr: 'desc'
		// 	},
		// 	limit: 10000
		// })
		// const materials = response.data.filter(({ thumbnail }) => {
		// 	return thumbnail?.startsWith('https://firebasestorage.googleapis.com')
		// })
		
		// exportListToCSV(materials.map(({ name, thumbnail, id }) => {
		// 	return {
		// 		name, thumbnail, id
		// 	}
		// }),'material_to_optimise_images_' + materials.length)

		//   const roomsResponse = await Rooms.getRooms(undefined, {
		// 	  limit: 1000
		//   })
		//   const rooms = roomsResponse.data.filter((room) => room.type == null)
		//   console.log('Total Rooms', rooms.length)
		//   await Actions.batchUpdate(rooms.map(({ id }) => {
		// 	  return {
		// 		  path: `rooms/${id}`,
		// 		  document: {
		// 			  type: 'tasting'
		// 		  }
		// 	  }
		//   }))


		//   await rooms.reduce((previous, current) => previous.then(() => {
		// 	  return new Promise(async (resolve) => {
		// 		  try {
		// 			  const roomId = current.id
		// 			  const response = await Rooms.getMaterials(roomId, {
		// 				  limit: 10000,
		// 				  orderBy: {
		// 					  fieldPath: 'ratings.absoluteTotal',
		// 					  directionStr: 'desc'
		// 				  }
		// 			  })
		// 			  const materials = response.data
		// 			  console.log('Updating room', roomId, materials.length)
		// 			  await Actions.batchUpdate(materials.map((material) => {
		// 				  const searchObject = getSearchObject(material)
		// 				  return {
		// 					  path: `rooms/${roomId}/materials/${material.id}`,
		// 					  document: searchObject
		// 				  }
		// 			  }))
		// 			  resolve()
		// 		  } catch (error) {
		// 			  console.log('Error Updating Room Material', current.id, error)
		// 			  resolve()
		// 		  }
		// 	  })
		//   }), Promise.resolve())

         // const response = await Users.getAll({
         //    orderBy: {
         //       fieldPath: 'region',
         //       directionStr: 'asc'
         //    },
         //    limit: 66
         // })

         // console.log('response', response.data.map((u) => u.id + ' ' + u.region))

         // await Actions.batchUpdate<User>(response.data.map((user) => {
         //    return {
         //       path: 'users/' +  user.id,
         //       document: {
         //          region: user.region?.toLowerCase()
         //       }
         //    }
         // }))


         // const roomId = '3J7dJYP7LN7FHGfilrZg'
         // const response = await Rooms.getMembers(roomId, {
         //    limit: 10000
         // })

         // console.log('response', response.data.length)

         // Rooms.update(roomId, {
         //    totalMembers: response.data.length
         // })

      //    await response.data.reduce((previous, current) => previous.then(() => {
      //       return new Promise(async (resolve) => {
      //          try {
      //             console.log('Old Material', current)
      //             const response = await Materials.getMaterial(current.id)
      //             console.log('Material', response)
      //             const roomMaterial = Rooms.getRoomMaterial(response)
      //             await Rooms.updateMaterial(roomId, current.id, roomMaterial)
      //             console.log('New Material', roomMaterial)
      //             resolve()
      //          } catch (error) {
      //             console.log('Error Updating Room Material', current.id, error)
      //             resolve()
      //          }
      //       })
      //    }), Promise.resolve())

      //    let list = metalist.map((m) => {
      //       return {
      //          title: m['Title'],
      //          description: m['Meta Description Text'],
      //          url: m['URL']
      //       }
      //    }) as {
      //       title: string,
      //       description: string,
      //       url: string
      //    }[]
   
      //    // console.log('metalist', list)
   
      //    let totalCounter = 0
      //    let successCounter = 0
      //    let failedCounter = 0
      //    let skippedCounter = 0

      //    await list.reduce((previous, current) => previous.then(() => {

      //       return new Promise<void>(async (resolve) => {
      //          try {
      //             const comps = current.url.split('/')
      //             const uri = comps[comps.length - 1]
      //             const response = await Materials.getMaterials({
      //                where: [
      //                   {
      //                      fieldPath: 'uri',
      //                      opStr: '==',
      //                      value: uri
      //                   }
      //                ]
      //             })
      //             if(response.data.length > 0){
      //                if(response.data.length > 1){
      //                   console.log('🚫 Duplicate Material URLs found', current.url, response.data.map((m) => m.id))
      //                   skippedCounter = skippedCounter + 1
      //                }else{
      //                   const material = response.data[0]
      //                   if(material.meta == null){
      //                      await Materials.updateMaterial(material.id, {
      //                         meta: {
      //                            title: current.title,
      //                            description: current.description
      //                         }
      //                      })

      //                      successCounter = successCounter + 1
      //                      console.log('✅ Material Updated', current.url, material.id)
   
      //                   }else{
      //                      console.log('⚠️ Meta Already added', current.url, material.id)
      //                      skippedCounter = skippedCounter + 1
                           
      //                   }
      //                }
      //             }else{
      //                throw new Error(`❌ Material with Url not found ${current.url}`);
      //             }
      //             console.log('Completed', totalCounter)
      //             totalCounter = totalCounter + 1
      //             resolve()
      //          } catch (error) {
      //             failedCounter = failedCounter + 1
      //             console.log('Error Fetching Material With Url', error, current.url)
      //             resolve()
      //          }
      //       })

      //    }), Promise.resolve())

      //    console.log('*******************************')

      //    console.log('Success', successCounter)
      //    console.log('Failed', failedCounter)
      //    console.log('Skipped', skippedCounter)

      //    // const response = await Actions.readCollection('materials',{
      //    //    limit: 10000,
      //    //    where: [
      //    //       {
      //    //          fieldPath: 'meta',
      //    //          opStr: '!=',
      //    //          value: null
      //    //       }
      //    //    ]
      //    // })
      //    // console.log('Total', response.data.length)  
      } catch (error) {
         console.log('Error Running Query', error)
      }
   }

   function formatDate(timestamp: firebase.firestore.Timestamp) {
      if (timestamp?.toDate != null) {
         const date = timestamp?.toDate()
         return moment(date).format('YYYY-MM-DD') as any
      } else {
         return formatDate(createTimestampContext(timestamp))
      }
   }

   async function onPressDownloadUsers() {

      setShowLoader(true)

      try {

         const response = await Actions.readFullCollection<User>('users', {
            limit: 10000
         })

         const formattedList = response.data.map(
            (user) => {
               const ___user = { ...user } as User
               if (___user.lastSignInAt) {
                  ___user.lastSignInAt = formatDate(___user.lastSignInAt)
               }
               if (___user.createdAt) {
                  ___user.createdAt = formatDate(___user.createdAt)
               }
               if (___user.updatedAt) {
                  ___user.updatedAt = formatDate(___user.updatedAt)
               }
               if (___user.notificationPermissionLastAsked) {
                  ___user.notificationPermissionLastAsked = formatDate(___user.notificationPermissionLastAsked)
               }
               return ___user
            }
         )

         exportListToCSV(formattedList, 'Users', [
            'id',
            'photoURL',
            'email',
            'displayName',
            'isAnonymous',
            'uid',
            'addresses',
            'gender',
            'ratings',
            'recentSearches',
            'username',
            'token',
            'tags',
            'shippingCountry_code',
            'language',
            'region',
            'currentDevice_metadata_OS_name',
            'currentDevice_metadata_OS_version',
            'lastSignInAt',
            'allowNotifications',
            'notificationPermission',
            'notificationPermissionLastAsked',
            'createdAt',
            'updatedAt',
            'createdBy_email',
            'createdBy_displayName',
            'createdBy_uid',
            'updatedBy_email',
            'updatedBy_displayName',
            'updatedBy_uid',
         ])
      } catch (error) {
         Alert.alert('Error Downloading', String(error))
         console.log('Error Downloading Users : ', error)
      }

      setShowLoader(false)
   }

   async function onClickDownloadCompanies() {

      setShowLoader(true)
      try {

         const response = await Actions.readFullCollection<User>('companies', {
            limit: 10000
         })

         const formattedList = response.data.map(
            (user) => {
               const ___user = { ...user } as User
               if (___user.lastSignInAt) {
                  ___user.lastSignInAt = formatDate(___user.lastSignInAt)
               }
               if (___user.createdAt) {
                  ___user.createdAt = formatDate(___user.createdAt)
               }
               if (___user.updatedAt) {
                  ___user.updatedAt = formatDate(___user.updatedAt)
               }
               if (___user.notificationPermissionLastAsked) {
                  ___user.notificationPermissionLastAsked = formatDate(___user.notificationPermissionLastAsked)
               }
               return ___user
            }
         )

         exportListToCSV(formattedList, 'companies', [
            'id',
            'name',
            'address_street',
            'address_postalCode',
            'address_city',
            ...createFlatEmptyLanguageKeys('address.translatedCity'),
            'address_state',
            'address_country',
            'email',
            'social_homepage',
            'social_facebookUrl',
            'social_instagramUrl',
            'description',
            ...createFlatEmptyLanguageKeys('translatedDescription'),
            'parent_name',
            'parent_id',
            'comments',
            'status'
         ])
      } catch (error) {
         console.log('Error Downloading Companies')
         Alert.alert('Error Downloading Companies', String(error))
      }
      setShowLoader(false)
   }

   async function onClickDownloadBannerEvents() {
      try {
         const response = await Analytics.getAllEventsById('BANNER_CLICK', {
            limit: 10000
         })
         const dateString = moment(new Date()).format('DD-MM-YYYY')
         exportListToCSV(response.data, 'BANNER_CLICK_EVENTS_' + dateString, [
            'title',
            'promotionId',
            'type',
            'region',
            'uid',
            'displayName',
            'email',
            'phoneNumber',
            'photoURL',
            'providerId',
            'updatedAt_seconds',
            'updatedAt_nanoseconds',
            'createdAt_seconds',
            'createdAt_nanoseconds',
         ])
      } catch (error) {
         console.log('Error Downloading Analytics')
         Alert.alert('Error Downloading Analytics', String(error))
      }
   }

   function onPressCheckRatingsHealth() {
      history.push('/settings/ratings-health')
   }

   function onPressRemoveSpamUsers() {
      window.open('https://us-central1-ginfernov2.cloudfunctions.net/removeSpamUsers')
   }

   async function onPressDownloadRatings() {

      setShowLoader(true)

      try {
         const recipesResponse = await Actions.readFullCollection('recipes',{
            limit: 10000
         })
         const recipes = await recipesResponse.data.map(r => r)
   
         const reviewsResponse = await Actions.readFullCollectionGroup('reviews',{
            limit: 10000
         })
         const reviews = await reviewsResponse.data.map(r => r)
   
         exportListToCSV(recipes, 'Recipes_' + recipes.length, [
            'id',
            'ratings_numberOfRatings',
            'ratings_relativeTotal',
            'ratings_relativeAverage',
         ])
   
         exportListToCSV(reviews, 'Reviews_' + reviews.length, [
            'id',
            'recipe_id',
            'createdBy_email',
            'createdAt_seconds',
            'createdAt_nanoseconds',
            'createdBy_displayName',
            'createdBy_id',
            'absoluteRating',
            'relativeRating',
            'recipe_combinations_0_translatedName_de',
            'recipe_combinations_1_translatedName_de',
            'recipe_combinations_2_translatedName_de',
            'recipe_combinations_3_translatedName_de',
            'recipe_combinations_4_translatedName_de',
            'scope'
         ])
      } catch (error) {
         Alert.alert('Error Downloading Ratings', String(error))
         console.log('Error Downloading Ratings', error)
      }

      setShowLoader(false)
   }
   

   async function onPressDownloadEANData() {
      setShowLoader(true)
      const response = await Actions.readFullCollection('materials',{
         limit: 10000
      })
      const materials = response.data
      const exportData: any[] = []
      materials.forEach((material) => {
         const barcodes = material.barcodes ?? []
         if (barcodes.length > 0) {
            barcodes.forEach((barcode) => {
               exportData.push({
                  id: `${material.id}_${barcode ?? ''}`,
                  materialId: material.id,
                  name: material.name,
                  barcode: barcode
               })
            })
         } else {
            exportData.push({
               id: `${material.id}_`,
               materialId: material.id,
               name: material.name,
               barcode: ''
            })
         }
      })
      exportListToCSV(exportData, 'materials_barcode_data', [
         'id',
         'materialId',
         'name',
         'barcode'
      ])
      setShowLoader(false)
   }


}

function AppSettings() {
   return (
      <View>
         App Settings
      </View>
   )
}