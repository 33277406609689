import React ,{ useEffect, useState } from 'react'
import Materials from 'gf-connect/lib/Materials';
import {
   searchMaterial
} from 'gf-connect/lib/Material';
import {
   useDebounce
} from './MaterialsBeta';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

function SearchMaterial(props:{
   onSelectMaterial: (material: Materials.Material | null) => void
}) {
   const { onSelectMaterial } = props
   const [searchText, setSearchText] = useState('')
   const debounceSearchText = useDebounce(searchText, 100)
   const [searchResults, setSearchResults] = useState<Materials.Material[]>([])
   const [showLoader, setShowLoader] = useState(false)
   const [open, setOpen] = useState(false)

   useEffect(
      () => {
         if (debounceSearchText) {
            getMaterial(debounceSearchText)
         }
      },
      [debounceSearchText]
   );

   async function getMaterial(text: string) {
      setShowLoader(true)
      try {

         let searchText = text
         let type : undefined | any
         const comps = text.split(':')
         if(comps.length > 1){
            type = (comps[0] ?? '').toLowerCase()
            searchText = (comps[comps.length - 1] ?? '').toLowerCase()
         }
         const response = await searchMaterial(searchText, type, undefined, {
            limit: 50
         })
         setSearchResults(response.data)
      } catch (error) {
         console.log('Error Searching Material', error)
      }
      setShowLoader(false)
   }

   return (
      <Autocomplete
         open={open}
         onOpen={() => {
            setOpen(true);
         }}
         onClose={() => {
            setOpen(false);
         }}
         getOptionSelected={(option, value) => option.name === value.name}
         getOptionLabel={(option) => option.name}
         filterOptions={(options, state) => options}
         options={searchResults}
         loading={showLoader}
         onChange={(e,value) => onSelectMaterial(value)}
         renderInput={(params) => (
            <TextField
               {...params}
               placeholder='Search Material'
               value={searchText}
               onChange={(e) => setSearchText(e.target.value)}
               InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                     <React.Fragment>
                        {showLoader ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                     </React.Fragment>
                  ),
               }}
            />
         )}
      />
   )
}

export default SearchMaterial