// import { FilterType } from 'src/consumer/RoomsFilter'
import { Change, AppState } from '.'

interface SettingsType {
   didConfirmLegalAge : boolean,
   language?: string,
   bannerIndex?: number,
   roomFilter?: any[],
   appProfile?:any
}

export type Settings = SettingsType

const initialState : Settings = {
   didConfirmLegalAge : false,
   // language: 'en'//Platform.OS == 'ios' ? Strings.getInterfaceLanguage() : 'en'
}

export default function (state = initialState, action: Change) {
   switch (action.type) {
      case 'UPDATE_SETTINGS' : {
         const payload : Settings = action.payload
         return {
            ...state,
            ...payload
         }
      }
      default:
         return state
   }
}

export function updateSettings (settings:Settings) : Change {
   return {
      payload : settings,
      type : 'UPDATE_SETTINGS'
   }
}

export function incrementBannerIndex(value?:number) : any{
   return (dispatch: (change: Change) => any, getState: () => AppState) => {
      const promotions = getState().promotions ?? []
      let currentBannerIndex = getState().settings.bannerIndex ?? value
      currentBannerIndex = (currentBannerIndex ?? 0) + 1
      if(currentBannerIndex > promotions.length-1){
         currentBannerIndex = 0
      }
      dispatch({
         type: 'UPDATE_SETTINGS',
         payload: {
            bannerIndex: currentBannerIndex
         } as Settings
      })
      return currentBannerIndex
   }
}
