import React,{ useState, useEffect } from 'react'
import { View, Text, FlatList, ActivityIndicator } from 'react-native'
import Shops, { BillingDayType } from 'gf-connect/lib/Shops'
import { GFSelect } from './FormUtils'
import RatedMaterials from 'gf-connect/lib/RatedMaterials'
import moment from 'moment'
import { Button, Divider } from 'react-native-elements'
import { exportListToCSV } from 'gf-connect/lib/Utils'
import { Alert } from './Alert'
import lodash from 'lodash';

const BillingPeriodTypes = {
   7 : 'Last 7 Days',
   30: 'Last 30 Days'   
}

export default function ShopBilling(props:{
   shopId: string,
   shopName: string
}) {
   const shopId = props.shopId
   const [selectedPeriod, setSelectedPeriod] = useState(7)
   const [responseDays, setResponseDays] = useState<BillingDayType[]>([])
   const [showLoader, setShowLoader] = useState(true)

   const [calculatedBilling, setCalculatedBilling] = useState({
      totalItems: 0,
      inStockItems : 0,
      viewedItems: 0
   })

   useEffect(() => {
      getBillingForPeriod(7)
   }, [])

   return (
      <View style={{
         flex: 1,
      }}>
         <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: 20,
            alignItems: 'center'
         }}>
            <GFSelect
               editing={true}
               label='Billing Period'
               value={selectedPeriod}
               onSelectValue={onSelectPeriod}
               types={BillingPeriodTypes} />
            <Button onPress={onPressExport} title='Export CSV'/>
         </View>
         {showLoader == false ? (
            <FlatList
               ListHeaderComponent={renderHeader}
               ItemSeparatorComponent={() => <Divider />}
               renderItem={renderItem}
               data={responseDays}
               ListEmptyComponent={() => <Text style={{
                  marginVertical: 15
               }}>No Records Available</Text>}
               ListFooterComponent={renderFooter}
            />
         ) : (
            <ActivityIndicator style={{
               marginVertical: 15,
               width: 900
            }}/>
         )}
      </View>
   )

   function onSelectPeriod(value){
      setSelectedPeriod(value)
      getBillingForPeriod(value)
   }

   function renderItem({item}){

      const billingDay = item as BillingDayType
      const { inStockItems, totalItems, viewedItems } = calculateBillingDay(billingDay)
      return (
         <View style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'space-between'
         }}>
            <Text style={{
               width: 300
            }}>{moment(billingDay.id,'DDMMYYYY').format('DD MMM YYYY')}</Text>
            <Text style={{
               marginLeft: 10,
               width: 300
            }}>{inStockItems}</Text>
            <Text style={{
               marginLeft: 10,
               width: 300
            }}>{totalItems}</Text>
            <Text style={{
               marginLeft: 10,
               width: 300
            }}>{viewedItems}</Text>
         </View>
      )
   }

   function renderHeader(){
      return (
         <View style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderTopWidth: 1,
            borderTopColor: '#dddddd',
            paddingVertical: 10,
            borderBottomWidth: 1,
            borderBottomColor: '#dddddd',
         }}>
            <Text style={{
               fontWeight: 'bold',
               width: 300
            }}>Day</Text>
            <Text style={{
               marginLeft: 10,
               fontWeight: 'bold',
               width: 300
            }}>In Stock Items</Text>
            <Text style={{
               marginLeft: 10,
               fontWeight: 'bold',
               width: 300
            }}>Total Items</Text>
            <Text style={{
               marginLeft: 10,
               fontWeight: 'bold',
               width: 300
            }}>Viewed Items</Text>
         </View>
      )
   }

   function renderFooter(){
      return (
         <View style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottomWidth: 1,
            borderBottomColor: '#dddddd',
            paddingVertical: 10,
            borderTopWidth: 1,
            borderTopColor: '#dddddd',
         }}>
            <Text style={{
               fontWeight: 'bold',
               width: 300
            }}>Total</Text>
            <Text style={{
               marginLeft: 10,
               fontWeight: 'bold',
               width: 300
            }}>{calculatedBilling.inStockItems}</Text>
            <Text style={{
               marginLeft: 10,
               fontWeight: 'bold',
               width: 300
            }}>{calculatedBilling.totalItems}</Text>
            <Text style={{
               marginLeft: 10,
               fontWeight: 'bold',
               width: 300
            }}>{calculatedBilling.viewedItems}</Text>

         </View>
      )
   }

   function getDayIdFromPast(count: number) {
      const today = new Date()
      var currentDay = today.getDate();
      today.setDate(currentDay - count);
      const dayId = moment(today).format('DDMMYYYY')
      return dayId
   }

   async function getBillingForPeriod(numberOfDays:number){
      setShowLoader(true)
      const dayIds : string[] = []
      for (let i = numberOfDays - 1; i >= 0; i--) {
         const dayId = getDayIdFromPast(i)
         dayIds.push(dayId)
      }
      try {
         const chunks = lodash.chunk(dayIds,10)
         let responses = [] as BillingDayType[]
         await chunks.reduce((previous,chunk) => previous.then(
            () => {
               return new Promise(async (resolve,reject) => {
                  try {
                     const response = await Shops.getBillingForDays(shopId,chunk)  
                     responses = responses.concat(response.data)
                     resolve()
                  } catch (error) {
                     reject(error)
                  }
               })
            }
         ),Promise.resolve())
      
         setResponseDays(responses)
         const totalCalculation : typeof calculatedBilling = {
            inStockItems: 0,
            totalItems: 0,
            viewedItems: 0
         }
         responses.forEach((billingDay) => {
            const { inStockItems, totalItems, viewedItems } = calculateBillingDay(billingDay)
            totalCalculation.inStockItems = totalCalculation.inStockItems + inStockItems
            totalCalculation.totalItems = totalCalculation.totalItems + totalItems
            totalCalculation.viewedItems = totalCalculation.viewedItems + viewedItems
         })
         setCalculatedBilling(totalCalculation)

      } catch (error) {
         console.log('Error getBillingForPeriod : ',error)
         Alert.alert('Error Getting Data',String(error))
      }
      setShowLoader(false)
   }

   function onPressExport(){
      exportListToCSV(responseDays,`${props.shopName}_Billing`)
   }
}

function calculateBillingDay(billingDay:BillingDayType){
   let totalItems = 0
   let inStockItems = 0
   let viewedItems = 0
   Object.keys(billingDay.availability).forEach((key) => {
      const stats = billingDay.availability[key]
      totalItems = (stats.totalItems ?? 0) + totalItems
      inStockItems = (stats.inStockItems ?? 0) + inStockItems
      viewedItems = (stats.viewedItems ?? 0) + viewedItems
   })
   return {
      totalItems,
      inStockItems,
      viewedItems
   }
}
