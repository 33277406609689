import React, { useState, useEffect } from 'react'
import { View, Text, ActivityIndicator, Image } from 'react-native'
import MaterialTable, {
   Column, Filter} from 'material-table'
import Materials from 'gf-connect/lib/Materials'
import { CollectionDescriptor, LastDocType, OrderType, WhereType } from 'firestar'
import { Alert } from './Alert'
import { checkMaterialNameChange, FlavourOptions, getSearchObject, MaterialStatusTypes, MaterialType, MaterialTypes, RelatedLinkOptions } from 'gf-connect/lib/Material'
import { Category } from 'gf-connect/lib/Category'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import {
   Button,
   CheckBox,
   Icon,
   SearchBar
} from 'react-native-elements'
import lodash from 'lodash'
import moment from 'moment'
import TextField from './utils/TextField'
import firebase from 'firebase/compat/app'
import { Helmet } from "react-helmet";
import ImageUploading, { ImageListType } from 'react-images-uploading';
import Resizer from 'react-image-file-resizer';
import {
   exportListToCSV,
   getSupportedLanguages,
   createEmptyLanguageObject,
   createFlatEmptyLanguageKeys
} from 'gf-connect/lib/Utils'
import { GFAutoComplete, validateUrl } from './FormUtils'
import { getCompanySummary } from 'gf-connect/lib/Company'
import {
   Companies
} from 'gf-connect'
import User from 'gf-connect/lib/User';
import {
   primaryGinKinds,
   secondaryGinKinds,
   tertiaryGinKinds,
   primaryMixerKinds,
   secondaryMixerKinds,
   tertiaryMixerKinds,
   primaryGarnishKinds,
   secondaryGarnishKinds,
   tertiaryGarnishKinds
} from 'gf-connect/lib/Category';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
	createTimeStamp,
   getDiff,
   flattenObject
} from 'firestar/lib/Utils'
import { getCountryWithCode, getSearchableCountries, isDev, getSearchableCountryObject } from './utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MTextField from '@material-ui/core/TextField'
import SearchCompany from './SearchCompany'
import TextEditor from './components/TextEditor'

const supportedLanguages = getSupportedLanguages()

const countries = getSearchableCountries('en')
const countriesOptions = getSearchableCountryObject('code','name')
const countriesList = countries.map((c) => {
	return { label: c.name, value: c }
})

const translatedDefaultDescription = () => {
   return {
      en: 'At the moment there is no information available.\n\nWe are already in contact with the producer in order to be able to present you details and pictures shortly.',
      de: 'Momentan stehen uns noch keine Infos zur Verfügung.\n\nWir sind bereits mit dem Hersteller in Kontakt, um dir in kürze Details und Bilder präsentieren zu können.',
      es: 'Actualmente no hay información disponible. \n\nYa estamos en contacto con el fabricante para poder ofrecerte detalles y fotos en breve.',
      fr: 'Pour l\'instant, aucune information n\'est disponible.\n\nNous sommes déjà en contact avec le producteur afin de pouvoir vous présenter des détails et des photos prochainement.',
      it: 'Al momento non ci sono informazioni disponibili.\n\nSiamo già in contatto con il produttore per potervi presentare a breve dettagli e immagini.'
   }
}

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

const __MaterialStatusTypes = lodash.cloneDeep(MaterialStatusTypes) as any
delete __MaterialStatusTypes.deleted

const Columns = (barcodeMode?: boolean): Column<Materials.Material>[] => {

   const list: Column<Materials.Material>[] = [
      {
         headerStyle: { fontWeight: 'bold' },
         editable: 'never',
         title: 'Image',
         filtering: false,
         sorting: false,
         render: (material) => (
            <div style={{
               textAlign: 'center'
            }}>
               {material.thumbnail?.startsWith('https://storage.googleapis.com') ? (
                  <img alt={material.name} style={{
                     width: 60,
                     height: 60,
                     objectFit: 'contain'
                  }} src={material.thumbnail} />
               ) : (
                  <div><i>{(material.images ?? []).length > 0 ? '•' : 'NA'}</i></div>
               )}
            </div>
         )
      },
      {
         field: 'id',
         headerStyle: { fontWeight: 'bold' },
         editable: 'never',
         title: 'ID',
         filtering: false,
         sorting: true
      },
      {
         field: 'name',
         headerStyle: { fontWeight: 'bold' },
         editable: 'always',
         title: 'Name',
         filtering: false,
         sorting: true
      },
      {
         field: 'type',
         headerStyle: { fontWeight: 'bold' },
         editable: 'always',
         title: 'Type',
         filtering: false,
         sorting: true,
         lookup: MaterialTypes
      },
      {
         field: 'kind',
         headerStyle: { fontWeight: 'bold' },
         editable: 'never',
         title: 'Kind',
         render: (material) => {

            const kinds = material.kinds
            if (kinds == null) return <Text>--</Text>
            return (
               <View style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row'
               }}>
                  {renderChips([kinds.one.name, kinds.two.name, kinds.three.name])}
               </View>
            )
         },
         filtering: false,
         sorting: true,
      },
      {
         field: 'producer.name',
         headerStyle: { fontWeight: 'bold' },
         editable: 'never',
         title: 'Producer',
         filtering: false,
         sorting: true,
      },
      {
         field: 'owner.name',
         headerStyle: { fontWeight: 'bold' },
         editable: 'never',
         title: 'Owner',
         filtering: false,
         sorting: true,
      },
      {
         field: 'status',
         headerStyle: { fontWeight: 'bold' },
         editable: 'always',
         lookup: __MaterialStatusTypes,
         title: 'Status',
         filtering: true,
         sorting: true,
         initialEditValue: 'created'
      },
      {
         headerStyle: { fontWeight: 'bold' },
         editable: 'never',
         title: 'SEO',
         filtering: false,
         sorting: false,
         render: (m) => (
            <div>
               <label>🔍: {m.meta ? '✅' : '🚫'}</label><br/>
               <label>🔗: {(m.relatedLinks ?? []).length}</label>
            </div>
         )
      },
      {
         field: 'id',
         editable: 'never',
         title: '',
         render: (m) => (
            <View style={{
               flexDirection: 'row',
               alignItems: 'center'
            }}>
               <Icon
                  onPress={() => window.open('https://admin.ginferno.app/playground/materials/' + m.id)}
                  name='games' />
            </View>
         ),
         sorting: true,
      }
   ]

   if ((barcodeMode ?? false) == false) {
      return list
   } else {
      return list.filter(c => c.field != 'kind')
   }
}

function SortOptions() {
   return {
      'updatedAt': 'Updated At',
      'createdAt': 'Created At',
      'alcoholByVolume': 'Alcohol By Volume',
      'totalReviews': 'Total Reviews',
      'ratings.numberOfRatings': 'Number of Ratings',
      'ratings.absoluteAverage': 'Absolute Average'
   }
}

export default function MaterialsBeta() {

   const [materials, setMaterials] = useState<Materials.Material[]>([])
   const [showLoader, setShowLoader] = useState(true)
   const [lastDoc, setLastDoc] = useState<LastDocType>()
   const [searchText, setSearchText] = useState('')
   const debouncedSearchTerm = useDebounce(searchText, 1000);
   const [filter, setFilter] = useState<WhereType[]>([])
   const [sortFilter, setSortFilter] = useState<OrderType>({
      fieldPath: 'updatedAt',
      directionStr: 'desc'
   })
   const [selectedMaterialType, setSelectedMaterialType] = useState<MaterialType>()
   const [selectedMaterialStatusType, setSelectedMaterialStatusType] = useState<keyof typeof MaterialStatusTypes>()
   const [smartSearch, setSmartSearch] = useState(false)

   const [barcodesMode, _] = useState(window.document.URL.includes('barcodes'))

   useEffect(() => {
      fetchMaterials()
   }, [])

   useEffect(() => {

      const findIndex = filter.findIndex(f => f.fieldPath == 'type')
      const __filter = [...filter]
      if (selectedMaterialType) {

         const __whereType: WhereType = {
            fieldPath: 'type',
            opStr: '==',
            value: selectedMaterialType
         }

         if (findIndex >= 0) {
            __filter[findIndex] = __whereType
         } else {
            __filter.push(__whereType)
         }

      } else {
         if (findIndex >= 0) {
            __filter.splice(findIndex, 1)
         }
      }
      setFilter(__filter)

   }, [selectedMaterialType])

   useEffect(() => {

      const findIndex = filter.findIndex(f => f.fieldPath == 'status')
      const __filter = [...filter]
      if (selectedMaterialStatusType) {

         const __whereType: WhereType = {
            fieldPath: 'status',
            opStr: '==',
            value: selectedMaterialStatusType
         }

         if (findIndex >= 0) {
            __filter[findIndex] = __whereType
         } else {
            __filter.push(__whereType)
         }

      } else {
         if (findIndex >= 0) {
            __filter.splice(findIndex, 1)
         }
      }
      setFilter(__filter)

   }, [selectedMaterialStatusType])

   useEffect(() => {
      if (searchText.trim().length == 0) {
         fetchMaterials()
      } else {
         setShowLoader(true)
      }
   }, [searchText])

   useEffect(() => {
      fetchMaterials()
   }, [filter, sortFilter, smartSearch])

   useEffect(
      () => {
         // Make sure we have a value (user has entered something in input)
         if (debouncedSearchTerm) {
            // Set isSearching state
            //  setIsSearching(true);
            //  // Fire off our API call
            //  searchCharacters(debouncedSearchTerm).then(results => {
            // 	// Set back to false since request finished
            // 	setIsSearching(false);
            // 	// Set results state
            // 	setResults(results);
            //  });
            fetchMaterials()
         }
      },
      // This is the useEffect input array
      // Our useEffect function will only execute if this value changes ...
      // ... and thanks to our hook it will only change if the original ...
      // value (searchTerm) hasn't changed for more than 500ms.
      [debouncedSearchTerm]
   );

   async function fetchMaterials(paginating?: boolean) {

      setShowLoader(true)

      const whereTypes: WhereType[] = [...filter]
      if (searchText.trim().length > 0) {
         if (smartSearch == true) {

            searchText.trim().toLowerCase().split(' ')
               .filter((item) => item.trim().length > 0)
               .forEach(
                  (item) => {
                     let matchString = `matches.${item}`
                     const lastChar = matchString.charAt(matchString.length - 1)
                     if (lastChar == '.') {
                        matchString = matchString.slice(0, matchString.length - 1)
                     }
                     if (matchString.trim().length > 0) {
                        whereTypes.push({
                           fieldPath: matchString,
                           opStr: '==',
                           value: true
                        })
                     }
                  }
               )

         } else {
            whereTypes.push({
               fieldPath: 'tags',
               opStr: 'array-contains-any',
               value: searchText.toLowerCase().trim().split(' ').splice(0, 10)
            })
         }
      }

      try {

         let descriptor: CollectionDescriptor = {
            lastDoc: (paginating ?? false) == true ? lastDoc : undefined,
            limit: (paginating ?? false) == true ? PAGESIZE : INITIAL_PAGESIZE,
            where: whereTypes,
            orderBy: smartSearch == false ? sortFilter : undefined
         }

         console.log('Descriptor : ', descriptor)

         const response = await Materials.getMaterials(descriptor)
         if (paginating) {
            setMaterials([...materials, ...response.data])
         } else {
            setMaterials(response.data)
         }
         setLastDoc(response.lastDoc)
      } catch (error) {
         console.log('Error Getting Materials : ', error)
         Alert.alert(
            'Error Getting Materials',
            String(error)
         )
      }
      setShowLoader(false)
   }

   return (
      <View style={{
         padding: 15
      }}>
         <Helmet>
            <title>GINferno - Materials</title>
         </Helmet>
         {barcodesMode == false && <Text style={{
            backgroundColor: 'orange',
            padding: 5,
            paddingHorizontal: 10,
            fontWeight: '500',
            borderRadius: 2
         }}>Important : Not all filters are indexed yet. You might get an error if you filter with non indexed criteria. Anyone with access to firebase can add the filters incase it was missed by just clicking on the link which is printed in the console or copy paste the link in the alert box to your address bar or contact Gautham(gautham@logisian.in) with the filter criteria.</Text>}
         <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
            alignItems: 'center',
         }}>
            <Button
               containerStyle={{
                  width: 150,
               }}
               buttonStyle={{
                  borderColor: 'gray',
                  borderRadius: 15
               }}
               titleStyle={{
                  fontSize: 14,
                  fontWeight: '500'
               }}
               type='outline'
               onPress={() => fetchMaterials()}
               title='REFRESH' />
            <View style={{
               flexDirection: 'row',
               alignItems: 'center',
               justifyContent: 'center',
            }}>
               <CheckBox
                  containerStyle={{
                     margin: 0,
                     padding: 0,
                     borderWidth: 0
                  }}        
                  checkedIcon={<Icon name='check-box' />}
                  uncheckedIcon={<Icon name='check-box-outline-blank' />}
                  title='Smart Search'
                  onPress={() => setSmartSearch(!smartSearch)}
                  checked={smartSearch} />
               
               <SearchBar
                  value={searchText}
                  inputStyle={{
                     color: 'black'
                  }}
                  placeholder='Search with keywords'
                  onChangeText={(value) => setSearchText(value)}
                  containerStyle={{
                     backgroundColor: 'clear',
                     borderTopWidth: 0,
                     borderBottomWidth: 0,
                     width: 350,
                  }}
                  inputContainerStyle={{
                     backgroundColor: '#dddddd',
                     borderRadius: 25
                  }} />
            </View>
         </View>
         {barcodesMode == false && <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
            padding: 15,
         }}>
            <View></View>
            <View style={{
               flexDirection: 'row',
               alignItems: 'center',
            }}>
               {smartSearch == false && <select style={{
                  padding: 15,
                  fontSize: 16,
                  borderWidth: 0,
                  marginRight: 15,
                  textAlign: 'right',
                  background: 'transparent'
               }} value={sortFilter.fieldPath as string} onChange={onChangeSortFilter}>
                  {Object.keys(SortOptions()).map((key) => {
                     return (
                        <option value={key}>Sort By: {SortOptions()[key]}</option>
                     )
                  })}
               </select>}
               <select style={{
                  padding: 15,
                  fontSize: 16,
                  borderWidth: 0,
                  marginRight: 15,
                  textAlign: 'right',
                  background: 'transparent'
               }} value={selectedMaterialType} onChange={onChangeMaterialType}>
                  <option value='all'>All Materials</option>
                  {Object.keys(MaterialTypes).map((key) => {
                     return (
                        <option value={key}>{MaterialTypes[key]}</option>
                     )
                  })}
               </select>
               <select style={{
                  padding: 15,
                  fontSize: 16,
                  borderWidth: 0,
                  marginRight: 15,
                  textAlign: 'right',
                  background: 'transparent'
               }} value={selectedMaterialStatusType} onChange={onChangeMaterialStatusType}>
                  <option value='all'>All Status</option>
                  {Object.keys(MaterialStatusTypes).map((key) => {
                     return (
                        <option value={key}>{MaterialStatusTypes[key]}</option>
                     )
                  })}
               </select>
               <Button
                  containerStyle={{
                     width: 150,
                  }}
                  buttonStyle={{
                     borderColor: 'gray',
                     borderRadius: 15
                  }}
                  titleStyle={{
                     fontSize: 14,
                     fontWeight: '500',
                     color: 'black'
                  }}
                  disabled={filter.length == 0}
                  type='outline'
                  onPress={() => {
                     setSelectedMaterialStatusType(undefined)
                     setSelectedMaterialType(undefined)
                     setFilter([])
                  }}
                  title='Reset Filters' />
            </View>
         </View>}
         <MaterialTable
            title={barcodesMode == true ? 'Barcodes Mapping' : 'Materials'}
            actions={[
               {
                  icon: 'link',
                  tooltip: 'Link',
                  onClick: (event, rowData) => {
                     const material = rowData as Materials.Material
                     let url = `https://ginferno.app/materials/${material.id}`
                     if (material.uri != null) {
                        url = `https://ginferno.app/${material.type}/${material.uri}`
                     }
                     window.open(url)
                  }
               }
            ]}
            editable={{
               onRowAdd: onRowAddMaterial,
               // onRowUpdate: onRowUpdateMaterial,
            }}
            options={{
               search: false,
               pageSizeOptions: [PAGESIZE],
               pageSize: PAGESIZE,
               sorting: true,
               filtering: false,
               addRowPosition: 'first'
            }}
            onOrderChange={onOrderChange}
            onFilterChange={onFilterChange}
            detailPanel={renderDetailPanel}
            onChangePage={onChangePage}
            isLoading={showLoader}
            data={materials}
            columns={[
               ...Columns(barcodesMode),
               {
                  title: SortOptions()[sortFilter.fieldPath as string],
                  sorting: true,
                  render: (m) => {
                     const field = sortFilter.fieldPath as string
                     if (field == 'createdAt' || field == 'updatedAt') {
                        const timeStamp = m[field] as firebase.firestore.Timestamp
                        return (
                           <Text>{timeStamp.toDate().toDateString()}</Text>
                        )
                     } else {
                        return (
                           <Text>{lodash.get(m, field)}</Text>
                        )
                     }
                  },
                  defaultSort: sortFilter.directionStr,
                  align: 'center'
               }
            ]}
         />
         <View style={{
            marginTop: 10,
            flexDirection: 'row',
            justifyContent: 'space-between'
         }}>
            <View></View>
            <Button
               containerStyle={{
                  minWidth: 200
               }}
               type='outline'
               onPress={onPressExport}
               title='Export CSV' />
         </View>
      </View>
   )

   function onRowAddMaterial(newData: Materials.Material) {
      return new Promise<Materials.Material | Partial<Materials.Material>>(async (resolve, reject) => {

         let newMaterial = {
            ...createEmptyLanguageObject('translatedName'),
            description: translatedDefaultDescription()['en'],
            translatedDescription: {
               ...translatedDefaultDescription()
            },
            status: 'created',
            kinds: {
               one: {
                  id: 'todo',
                  name: 'Todo'
               },
               two: {
                  id: 'todo',
                  name: 'Todo'
               },
               three: {
                  id: 'hide',
                  name: 'Hide'
               }
            },
            social: {
               facebookUrl: 'n/a',
               homepage: 'n/a',
               instagramUrl: 'n/a',
               twitterUrl: 'n/a',
            },
            rolloutDate: createTimeStamp(-2208988800, 0),
         } as Materials.Material

         Columns()
            .filter(m => m.editable == 'always' || m.editable == 'onAdd')
            .forEach(m => {
               lodash.set(
                  newMaterial,
                  m.field ?? '',
                  lodash.get(newData, m.field ?? '') ?? null
               )
            })

         let isValid = true
         try {

            if ((newMaterial.name ?? '').trim().length == 0) {
               throw 'Please select a name for the material'
            }

            if (newMaterial.type == null) {
               throw 'Please select a type for the material'
            }

         } catch (error) {
            isValid = false
            Alert.alert(
               'Missing Fields',
               String(error)
            )
            reject()
         }

         if (newMaterial.type !== 'garnish') {

            //name will be copied for no translation
            newMaterial = {
               ...newMaterial,
               ...createEmptyLanguageObject('translatedName', newMaterial.name),
            }
         }

         if (isValid == false) return

         try {


            const response = await Materials.addMaterial(newMaterial)
            const __materials = [...materials] as Materials.Material[]
            setMaterials([response as Materials.Material, ...__materials])

            resolve(response)


         } catch (error) {

            console.log('Error Adding New Materials : ', error)
            Alert.alert(
               'Error Adding New Material',
               String(error)
            )
            reject(error)
         }
      })
   }


   function onRowUpdateMaterial(newData: Materials.Material, oldData?: Materials.Material) {
      return new Promise<any>(async (resolve, reject) => {
         try {

            const materialId = newData.id

            const __materials = [...materials] as Materials.Material[]

            const findIndex = __materials.findIndex(m => m.id == materialId)
            const material = __materials[findIndex]

            const changedData = {} as Materials.Material

            Columns()
               .filter(m => m.editable == 'always' || m.editable == 'onUpdate')
               .forEach(m => {
                  lodash.set(changedData, m.field ?? '', lodash.get(newData, m.field ?? '') ?? null)
               })


            if (oldData?.status != 'approved' && oldData?.status != 'completed') {
               if ((changedData.status == 'approved' || changedData.status == 'completed')) {
                  changedData.approvalDate = firebase.firestore.Timestamp.now()
               }
            }

            changedData.lastManualUpdate = User.getUserInfoSummary()

            const response = await updateMaterial(materialId, changedData)

            const updatedMaterial = {
               ...material,
               ...response,
            } as Materials.Material

            __materials[findIndex] = updatedMaterial

            setMaterials(__materials)
            resolve(newData)

         } catch (error) {
            Alert.alert('Error Update Material', String(error))
            console.log('Error Updating Material : ', error)
            reject(error)
         }
      })
   }

   function onOrderChange(_: number, orderDirection: "asc" | "desc") {
      if (orderDirection.length > 0) {
         setSortFilter({
            ...sortFilter,
            directionStr: orderDirection
         })
      } else {
         setSortFilter({
            ...sortFilter,
            directionStr: 'asc'
         })
      }
   }

   function onFilterChange(tableFilters: Filter<Materials.Material>[]) {

   }

   function onChangeSortFilter(event) {
      setSortFilter({
         fieldPath: event.target.value,
         directionStr: 'desc'
      })
   }

   function onChangeMaterialType(event) {
      if (event.target.value == 'all') {
         setSelectedMaterialType(undefined)
      } else {
         setSelectedMaterialType(event.target.value)
      }
   }

   function onChangeMaterialStatusType(event) {
      if (event.target.value == 'all') {
         setSelectedMaterialStatusType(undefined)
      } else {
         setSelectedMaterialStatusType(event.target.value)
      }
   }

   function onChangePage(page: number, pageSize: number) {
      if (materials.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE)) {
         console.log('Paginating', lastDoc)
         if (lastDoc != null) fetchMaterials(true)
      }
   }

   function renderDetailPanel(materialDetail: Materials.Material) {

      return (
         <DetailPanel
            onPressRefresh={onPressRefresh}
            material={materialDetail}
            onPressDelete={onPressDelete}
            isBarcodesMode={barcodesMode}
            onUpdateMaterial={onUpdateMaterial} />
      )
   }

   function onPressDelete(materialId: string) {
      return new Promise<void>(async (resolve, reject) => {

         try {

            const __materials = [...materials] as Materials.Material[]

            const findIndex = __materials.findIndex(m => m.id == materialId)
            await Materials.deleteMaterialWithId(materialId)

            if (findIndex >= 0) {
               __materials.splice(findIndex, 1)
            }

            setMaterials(__materials)
            resolve()

         } catch (error) {
            reject(error)
         }
      })
   }

   function onPressRefresh(materialId: string) {
      return new Promise<Materials.Material>(async (resolve, reject) => {

         try {

            const __materials = [...materials] as Materials.Material[]

            const findIndex = __materials.findIndex(m => m.id == materialId)
            const response = await Materials.getMaterialWithId(materialId)

            __materials[findIndex] = response

            setMaterials(__materials)
            resolve(response)

         } catch (error) {
            reject(error)
         }

      })
   }

   function onUpdateMaterial(
      materialId: string,
      document: Materials.Material,
      oldMaterial: Materials.Material
   ) {
      return new Promise<any>(async (resolve, reject) => {
         try {

            const material = lodash.cloneDeep(document)

            if (!validateUrl(material.social?.facebookUrl)) {
               throw 'Please Correct Facebook Url. Url must start with http:// or https://. If you do not know the url please enter n/a'
            }
            if (!validateUrl(material.social?.homepage)) {
               throw 'Please Correct Homepage Url. Url must start with http:// or https://. If you do not know the url please enter n/a'
            }
            if (!validateUrl(material.social?.instagramUrl)) {
               throw 'Please Correct Instagram Url. Url must start with http:// or https://. If you do not know the url please enter n/a'
            }

            delete (material as any).tableData;

            const __materials = [...materials] as Materials.Material[]

            const findIndex = __materials.findIndex(m => m.id == materialId)

            try {

               if ((material?.thumbnail ?? '').length > 0 &&
                  (material?.images ?? []).length > 0) {

                  if (material?.thumbnail?.startsWith('data') &&
                     material?.images?.[0].startsWith('data')) {

                     console.log('Uploading Thumbnail')

                     const thumbnailResponse = await Materials.uploadThumbnail(
                        materialId, material.thumbnail
                     )

                     console.log('Uploading Thumbnail Medium')

                     const thumbnailMediumResponse = await Materials.uploadThumbnail(
                        materialId, material.thumbnailMedium, '_medium'
                     )

                     console.log('Uploading Thumbnail Large')

                     const thumbnailLargeResponse = await Materials.uploadThumbnail(
                        materialId, material.thumbnailLarge, '_large'
                     )

                     console.log('Uploading Full Size Image')

                     const imagesResponse = await Materials.uploadImage(
                        materialId, material.images[0]
                     )

                     material.thumbnail = thumbnailResponse.downloadUrl
                     material.thumbnailMedium = thumbnailMediumResponse.downloadUrl
                     material.thumbnailLarge = thumbnailLargeResponse.downloadUrl
                     material.images = [imagesResponse.downloadUrl]

                     console.log('Images Uploaded')
                  }

               } else {
                  material.thumbnail = null as any
                  material.images = []
               }

            } catch (error) {

               console.log('Error Uploading Images : ', error)
               material.thumbnail = null as any
               material.images = []
            }

            if (oldMaterial?.status != 'approved' && oldMaterial?.status != 'completed') {
               if ((material.status == 'approved' || material.status == 'completed')) {
                  material.approvalDate = firebase.firestore.Timestamp.now()
               }
            }

            material.lastManualUpdate = User.getUserInfoSummary()

            const changes = getDiff(material, oldMaterial)
            delete (changes as any).tableData;
            console.log('Changes', changes)

            const flattenedChanges = flattenObject(changes)
            console.log('flattenedChanges', flattenedChanges)

            const updateResponse = await updateMaterial(materialId, flattenedChanges)
            console.log('Updated Changes', updateResponse)

            const response = await Materials.getMaterialWithId(materialId)
            console.log('Update Material', response)

            const updatedMaterial = response as Materials.Material

            __materials[findIndex] = updatedMaterial

            setMaterials(__materials)

            resolve(updatedMaterial)

         } catch (error) {
            Alert.alert(
               'Error Updating Material',
               String(error)
            )
            console.log('Error Updating Material : ', error)
            reject(error)
         }
      })
   }

   function addMaterial(material: Materials.Material) {
      return new Promise<Materials.Material | Partial<Materials.Material>>(async (resolve, reject) => {
         setShowLoader(true)
         try {
            const response = await Materials.addMaterial(material)
            resolve(response)
         } catch (error) {
            Alert.alert(
               'Error Updating Material',
               String(error)
            )
            reject(error)
         }
         setShowLoader(false)
      })
   }

   function updateMaterial(materialId: string, material: Materials.Material | Partial<Materials.Material>) {
      return new Promise<Materials.Material | Partial<Materials.Material>>(async (resolve, reject) => {
         setShowLoader(true)
         try {
            const response = await Materials.updateMaterial(materialId, material)
            resolve(response)
         } catch (error) {
            reject(error)
         }
         setShowLoader(false)
      })
   }

   async function onPressExport() {

      setShowLoader(true)
      try {
         
         const response = await Materials.getAll({
            limit: 10000
         })

         exportListToCSV(response.data, `MaterialsList_${new Date().toString()}_${response.data.length}`, [
            'id',
            'name',
            ...createFlatEmptyLanguageKeys('translatedName'),
            'type',
            'owner_name',
            'owner_id',
            'owner_address_country',
            ...createFlatEmptyLanguageKeys('owner.address.translatedCountry'),
            'owner_id',
            'producer_name',
            'producer_id',
            'alcoholByVolume',
            'description',
            ...createFlatEmptyLanguageKeys('translatedDescription'),
            'limitedEdition',
            'soldOut',
            'social_homepage',
            'social_facebookUrl',
            'social_instagramUrl',
            'kinds_one_id',
            'kinds_one_name',
            'kinds_two_id',
            'kinds_two_name',
            'kinds_three_id',
            'kinds_three_name',
            'kinds_four_id',
            'kinds_four_name',
            'rolloutDate_seconds',
            'thumbnail',
            'show',
            'deletion_flag',
            'comments',
            'status',
            'updatedAt_seconds',
            'updatedAt_nanoseconds',
            'updatedBy_email',
            'createdAt_seconds',
            'createdAt_nanoseconds',
            'createdBy_email',
            'ratings_absoluteTotal',
            'ratings_absoluteAverage',
            'ratings_numberOfRatings',
            'countryOfOrigin_code',
            'countryOfOrigin_name',
            'uri'
         ])

      } catch (error) {
         Alert.alert('Error Exporting Materials : ', String(error))
         console.log('Error Exporting Materials : ', error)
      }
      setShowLoader(false)
   }

}

function DetailPanel(props: {
   material: Materials.Material,
   onUpdateMaterial: (
      materialId: string,
      material: Materials.Material,
      oldMaterial: Materials.Material
   ) => Promise<Materials.Material> | Materials.Material,
   onPressRefresh: (materialId: string) => Promise<Materials.Material>,
   onPressDelete: (materialId: string) => Promise<void>,
   isBarcodesMode?: boolean
}) {

   const [material, setMaterial] = useState(lodash.cloneDeep(props.material))
   const materialId = material.id
   const [isEditing, setIsEditing] = useState(false)
   const [showLoader, setShowLoader] = useState(false)
   const [barcodeText, setBarcodeText] = useState('')
   const [checkingDuplicates, setCheckingDuplicates] = useState(false)
   const [otherNamesText, setOtherNamesText] = useState('')

   const [isOptimizing, setIsOptimizing] = useState(false)
   const [uri, setUri] = useState<string>(material.uri ?? '')
   const [validatingUri, setValidatingUri] = useState<'searching' | 'failed' | 'available' | 'success'>()
   const debouncedUriTerm = useDebounce(uri, 500);

   const [visualEditor, setVisualEditor] = useState(false)

   useEffect(
      () => {
         // Make sure we have a value (user has entered something in input)
         if (debouncedUriTerm) {
            // Set isSearching state
            //  setIsSearching(true);
            //  // Fire off our API call
            //  searchCharacters(debouncedSearchTerm).then(results => {
            // 	// Set back to false since request finished
            // 	setIsSearching(false);
            // 	// Set results state
            // 	setResults(results);
            //  });
            validateUri()
         }
      },
      // This is the useEffect input array
      // Our useEffect function will only execute if this value changes ...
      // ... and thanks to our hook it will only change if the original ...
      // value (searchTerm) hasn't changed for more than 500ms.
      [debouncedUriTerm]
   );

   return (
      <View style={{
         padding: 10,
         borderTopWidth: 10,
         borderTopColor: '#dddddd'
      }}>
         {showLoader == true && <View style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
            backgroundColor: '#11111190'
         }}>
            <ActivityIndicator size={'large'} color='white' />
         </View>}
         <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 15
         }}>
            <View>
               {isEditing == true ? (
                  <View style={{
                     flexDirection: 'row',
                     alignItems: 'center'
                  }}>
                     <Icon
                        style={{
                           marginRight: 15
                        }}
                        onPress={onPressCancelEdit}
                        name='cancel' />
                     <Icon
                        disabledStyle={{
                           backgroundColor: 'white',
                           opacity: 0.3
                        }}
                        disabled={lodash.isEqual(material, props.material)}
                        onPress={onPressSave}
                        name='save' />
                  </View>
               ) : (
                  <Icon
                     onPress={onPressEdit}
                     name='edit' />
               )}
            </View>
            {isEditing === false && <View style={{
               flexDirection: 'row',
               alignItems: 'center'
            }}>
               <Icon
                  containerStyle={{
                     marginRight: 15
                  }}
                  onPress={onPressForceUpdate}
                  name='sync-alt' />
               {isDev() == true && <Icon
                  containerStyle={{
                     marginRight: 15
                  }}
                  onPress={onPressDelete}
                  name='delete' />}
               <Icon
                  onPress={onPressRefresh}
                  name='refresh' />
            </View>}
         </View>

         {(props.isBarcodesMode ?? false) == true ? (
            <div>
               {renderBarcodesPanel()}
            </div>
         ) : (
            <View style={{
               flex: 1,
               flexDirection: 'row',

            }}>
               <View style={{
                  flex: 1,
               }}>
                  <SectionPanel
                     title='Name'
                     render={() => {
                        const translatedName = material.translatedName ?? {}
                        const name = material.name
                        return (
                           <View>
                              <View style={{
                                 flexDirection: 'row',
                                 alignItems: 'center',
                                 flexWrap: 'wrap'
                              }}>
                                 <TextField
                                    editable={isEditing}
                                    label={'Name'}
                                    style={{ marginVertical: 5, minWidth: 350 }}
                                    inputStyle={{
                                       padding: 20,
                                       marginRight: 10
                                    }}

                                    onChangeText={(value) => {
                                       onChangeValue(`name`, value)
                                    }}
                                    value={name} />
                                 <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    marginTop: 10
                                 }}>
                                    <GFAutoComplete
                                       style={{
                                          marginRight: 10,
                                          width: 250
                                       }}
                                       value={material.status != null ? {
                                          label: __MaterialStatusTypes[material.status],
                                          value: material?.status
                                       } : undefined}
                                       onChangeText={
                                          (value) => {
                                             onChangeValue(`status`, value)
                                          }
                                       }
                                       editing={isEditing}
                                       label={'Status'}
                                       options={Object.keys(__MaterialStatusTypes).map((key) => {
                                          return {
                                             label: __MaterialStatusTypes[key],
                                             value: key
                                          }
                                       })} />
                                    <GFAutoComplete
                                       style={{
                                          marginRight: 10,
                                          width: 250
                                       }}
                                       value={material.type != null ? {
                                          label: MaterialTypes[material.type],
                                          value: material.type
                                       } : undefined}
                                       onChangeText={
                                          (value) => {
                                             onChangeValue(`type`, value)
                                          }
                                       }
                                       editing={isEditing}
                                       options={Object.keys(MaterialTypes).map((key) => {
                                          return {
                                             label: MaterialTypes[key],
                                             value: key
                                          }
                                       })}
                                       label={'Type'} />
                                 </View>
                              </View>
                              <br/>
                              <View style={{
                                 flexDirection: 'row',
                                 flexWrap: 'wrap'
                              }}>
                                 {supportedLanguages.map((language) => {
                                    return (
                                       <TextField
                                          editable={isEditing}
                                          label={language.toUpperCase()}
                                          style={{ marginVertical: 5, marginRight: 10 }}
                                          inputStyle={{
                                             padding: 20
                                          }}
                                          placeholder={language.toUpperCase()}
                                          onChangeText={(value) => {
                                             onChangeValue(`translatedName.${language}`, value)
                                          }}
                                          value={translatedName[language]} />
                                    )
                                 })}
                              </View>
                           </View>
                        )
                     }} />
                     <SectionPanel
                        title='Other Names'
                        render={() => {
                           const otherNames = [...material.otherNames ?? []]
                           return (
                              <View style={{
                                 width: '60%'
                              }}>
                                 <TextField
                                    placeholder='Enter name and press return/enter to add'
                                    style={{ marginRight: 10, width: 400 }}
                                    editable={isEditing}
                                    onChangeText={(value) => setOtherNamesText(value)}
                                    onSubmitEditing={() => {
                                       const __otherNames = [...otherNames]
                                       __otherNames.push(otherNamesText)
                                       onChangeValue('otherNames', lodash.uniq(__otherNames))
                                       setOtherNamesText('')
                                    }}
                                    value={otherNamesText} />
                                 <View style={{
                                    flexDirection: 'row',
                                    flexWrap: 'wrap'
                                 }}>
                                    {renderChips(
                                       material.otherNames ?? [],
                                       isEditing ? (index) => {
                                          const __otherNames = [...material.otherNames ?? []]
                                          __otherNames.splice(index, 1)
                                          onChangeValue('otherNames', __otherNames)                                    
                                       } : undefined,
                                       undefined, {
                                       // maxWidth: 150
                                    })}
                                 </View>
                              </View>
                           )
                        }} />
                  <SectionPanel
                     title='Url'
                     render={() => (
                        <View style={{
                           flexDirection: 'row',
                           alignItems: 'center',
                        }}>
                           {/*isEditing == true*/false ? (
                              <View style={{
                                 flexDirection: 'row',
                                 alignItems: 'center'
                              }}>
                                 <TextField
                                    editable={true}
                                    label={'URI'}
                                    style={{ marginVertical: 5, marginRight: 10, minWidth: 600 }}
                                    inputStyle={{
                                       padding: 20
                                    }}
                                    placeholder={'Enter a unique URI'}
                                    onChangeText={(value) => {
                                       // onChangeValue(`uri`, value)
                                       setUri(value.trim().toLowerCase())
                                    }}
                                    value={uri} />
                                 <View>
                                    {validatingUri === 'searching' && <ActivityIndicator />}
                                    {validatingUri === 'available' && uri !== material.uri && (
                                       <Icon name='save' onClick={onClickUpdateUri} />
                                    )}
                                    {validatingUri === 'success' && <Text>✅</Text>}
                                    {validatingUri === 'failed' && <Text>🛑</Text>}
                                 </View>                                 
                              </View>
                           ) : (
                              <a
                                 rel="noreferrer"
                                 target={'_blank'}
                                 href={`https://ginferno.app/${material.type}/${material.uri}`}>{material.uri ?? 'URL NOT FOUND'}
                              </a>
                           )}
                        </View>
                     )} />
                  <SectionPanel
                     title='Owner, Producer and Social'
                     render={() => {
                        const owner = material.owner
                        const producer = material.producer
                        const social = material.social
                        return (
                           <View style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              paddingLeft: '-1em'
                           }}>
                              <SearchCompany 
                                 style={{
                                    margin: '1em'
                                 }}
                                 label='Owner'
                                 placeholder='Search Owner'
                                 value={owner}
                                 disabled={isEditing === false}
                                 onSelectCompany={(value) => {
                                    if (value) {

                                       const updatedValues = {
                                          owner: getCompanySummary(value) as Companies.Company,
                                          producer: material.producer == null ? getCompanySummary(value) : getCompanySummary(material.producer)
                                       } as Materials.Material

                                       const social = material.social
                                       if ((social?.facebookUrl == 'n/a' ||
                                          social?.instagramUrl == 'n/a' ||
                                          social?.homepage == 'n/a') ||
                                          ((social?.facebookUrl ?? '').length == 0 ||
                                             (social?.instagramUrl ?? '').length == 0 ||
                                             (social?.homepage ?? '').length == 0)) {
                                          updatedValues.social = value?.social
                                       }

                                       if(material.countryOfOrigin == null && value.address?.countryCode){
                                          const countryCode = value.address?.countryCode
                                          if(countryCode){
                                             updatedValues.countryOfOrigin = getCountryWithCode(countryCode, false)
                                          }
                                       }

                                       console.log('updatedValues', updatedValues)

                                       onChangeValues(updatedValues)
                                    } else {
                                       onChangeValue('owner', null)
                                    }
                                 }}/>
                              <SearchCompany 
                                 style={{
                                    margin: '1em'
                                 }}
                                 label='Producer'
                                 placeholder='Search Producer'
                                 value={producer}
                                 disabled={isEditing === false}
                                 onSelectCompany={(value) => {
                                    if (value) {
                                       onChangeValue('producer', getCompanySummary(value))
                                    } else {
                                       onChangeValue('producer', null)
                                    }
                                 }}/>
                              <View style={{
                                 flexDirection: 'row',
                                 flexWrap: 'wrap',
                                 alignItems: 'center',
                                 maxWidth: '80%'
                              }}>
                                 <TextField
                                    editable={isEditing}
                                    label='Facebook'
                                    style={{ marginRight: 10, width: 350 }}
                                    onChangeText={(value) => {
                                       onChangeValue('social.facebookUrl', value)
                                    }}
                                    value={social?.facebookUrl} />
                                 <TextField
                                    label='Website'
                                    style={{ marginRight: 10, width: 350 }}
                                    editable={isEditing}
                                    onChangeText={(value) => {
                                       onChangeValue('social.homepage', value)
                                    }}
                                    value={social?.homepage} />
                                 <TextField
                                    label='Instagram'
                                    style={{ marginRight: 10, width: 350 }}
                                    editable={isEditing}
                                    onChangeText={(value) => {
                                       onChangeValue('social.instagramUrl', value)
                                    }}
                                    value={social?.instagramUrl} />
                              </View>
                           </View>
                        )
                     }} />
                  <SectionPanel
                     title={'Material Kinds'}
                     render={() => {
                        return (
                           <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                           }}>
                              <GFAutoComplete
                                 style={{
                                    marginRight: 10,
                                    width: 250
                                 }}
                                 value={material.kinds?.one && {
                                    label: material.kinds.one.name,
                                    value: material.kinds.one
                                 }}
                                 onChangeText={
                                    (value) => {
                                       onChangeValue(`kinds.one`, value)
                                    }
                                 }
                                 editing={isEditing}
                                 options={getPrimaryKindsFor(material)}
                                 label={'Kind 1'} />
                              <GFAutoComplete
                                 style={{
                                    marginRight: 10,
                                    width: 250
                                 }}
                                 value={material.kinds?.two && {
                                    label: material.kinds.two.name,
                                    value: material.kinds.two
                                 }}
                                 onChangeText={
                                    (value) => {
                                       onChangeValue(`kinds.two`, value)
                                    }
                                 }
                                 editing={isEditing}
                                 options={getSecondaryKindsFor(material)}
                                 label={'Kind 2'} />
                              <GFAutoComplete
                                 style={{
                                    marginRight: 10,
                                    width: 250
                                 }}
                                 value={material.kinds?.three && {
                                    label: material.kinds?.three.name,
                                    value: material.kinds?.three
                                 }}
                                 onChangeText={
                                    (value) => {
                                       onChangeValue(`kinds.three`, value)
                                    }
                                 }
                                 editing={isEditing}
                                 options={getTertiaryKindsFor(material)}
                                 label={'Kind 3'} />
                              <GFAutoComplete
                                 style={{
                                    marginRight: 10,
                                    width: 250
                                 }}
                                 value={material.kinds?.four && {
                                    label: material.kinds?.four.name,
                                    value: material.kinds?.four
                                 }}
                                 onChangeText={(value) => { }}
                                 editing={false}
                                 label={'Kind 4'} />
                           </div>
                        )
                     }} />
					<SectionPanel
						title={'Flavour'}
						render={() => {
							const list = Object.keys(FlavourOptions).map((key) => key)
							return (
								<div style={{
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'wrap',
								}}>
									<select value={material.flavour ?? undefined} disabled={!isEditing} onChange={({ target }) => {
										onChangeValue('flavour', target.value)
									}} style={{
										width: 250,
										padding: '0.2em 1em'
									}}>
										<option disabled selected value={undefined}> -- Select a flavour -- </option>
										{list.map((key) => (
											<option value={key} key={key}>{FlavourOptions[key]['en']}</option>
										))}
									</select>
								</div>
							)
						}} />
                  <SectionPanel
                     title=''
                     render={() => {
                        const alcoholByVolume = material.alcoholByVolume ?? -1
                        const soldOut = material.soldOut ?? false
                        const limitedEdition = material.limitedEdition ?? false
                        const show = material.show ?? false
                        const light = material.light ?? false
                        const bio = material.bio ?? false
                        return (
                           <View style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              flexWrap: 'wrap'
                           }}>
                              <TextField
                                 label='Alcohol Percentage'
                                 style={{ marginRight: 10, maxWidth: 150 }}
                                 editable={isEditing}
                                 onBlur={() => {
                                    onChangeValue('alcoholByVolume', Number(alcoholByVolume))
                                 }}
                                 onChangeText={(value) => {
                                    onChangeValue('alcoholByVolume', value)
                                 }}
                                 value={alcoholByVolume} />
                              {material.type == 'gin' && (
                                 <View style={{
                                    flexDirection: 'row',
                                    marginRight: 10
                                 }}>
                                    <Autocomplete
                                       style={{ width: 300 }}
                                       options={countriesList}
                                       autoHighlight
                                       getOptionLabel={(option) => option.label}
                                       renderOption={(option) => option.label}
                                       disabled={isEditing == false}
                                       value={{
                                          label: material.countryOfOrigin?.name ?? '',
                                          value: countriesOptions[material.countryOfOrigin?.code ?? '']
                                       }}
                                       onChange={(e, option) => {
                                          if (option?.value) {
                                             onChangeValue('countryOfOrigin', getCountryWithCode(option.value.code, false))
                                          } else {
                                             onChangeValue('countryOfOrigin', null)
                                          }
                                       }}
                                       renderInput={(params) => (
                                          <MTextField
                                             {...params}
                                             label="Country"
                                             variant="outlined"
                                             inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                             }}
                                          />
                                       )}
                                    />
                                 </View>
                              )}
                              <View style={{
                                 flexDirection: 'row',
                                 flexWrap: 'wrap',
                                 alignItems: 'center',
                              }}>
                                 <CheckBox
                                    title='Sold Out'
                                    containerStyle={{
                                       marginLeft: 0
                                    }}
                                    disabled={isEditing == false}
                                    checkedIcon={<Icon name='check-box' />}
                                    uncheckedIcon={<Icon name='check-box-outline-blank' />}
                                    onPress={() => onChangeValue('soldOut', !soldOut)}
                                    checked={soldOut} />
                                 <CheckBox
                                    containerStyle={{
                                       marginLeft: 0
                                    }}
                                    disabled={isEditing == false}
                                    checkedIcon={<Icon name='check-box' />}
                                    uncheckedIcon={<Icon name='check-box-outline-blank' />}
                                    title='Limited Edition'
                                    onPress={() => onChangeValue('limitedEdition', !limitedEdition)}
                                    checked={limitedEdition} />
                                 <CheckBox
                                    containerStyle={{
                                       marginLeft: 0
                                    }}
                                    disabled={isEditing == false}
                                    checkedIcon={<Icon name='check-box' />}
                                    uncheckedIcon={<Icon name='check-box-outline-blank' />}
                                    title='Show'
                                    onPress={() => onChangeValue('show', !show)}
                                    checked={show} />
                                 <CheckBox
                                    containerStyle={{
                                       marginLeft: 0
                                    }}
                                    disabled={isEditing == false}
                                    checkedIcon={<Icon name='check-box' />}
                                    uncheckedIcon={<Icon name='check-box-outline-blank' />}
                                    title='Light/Zero'
                                    onPress={() => onChangeValue('light', !light)}
                                    checked={light} />
                                 <CheckBox
                                    containerStyle={{
                                       marginLeft: 0
                                    }}
                                    disabled={isEditing == false}
                                    checkedIcon={<Icon name='check-box' />}
                                    uncheckedIcon={<Icon name='check-box-outline-blank' />}
                                    title='Bio/Organic'
                                    onPress={() => onChangeValue('bio', !bio)}
                                    checked={bio} />
                              </View>
                           </View>
                        )
                     }} />
                  <div>{renderBarcodesPanel()}</div>
                  <SectionPanel
                     title={'Rollout Date'}
                     render={() => {
                        const rolloutDate = material.rolloutDate
                        const date = rolloutDate?.toDate?.()
                        const dateString = moment(date).format('YYYY-MM-DD')
                        return (
                           <View style={{
                              maxWidth: 150
                           }}>
                              <input
                                 disabled={!isEditing}
                                 onChange={(event) => {
                                    const date = moment(event.target.value, 'YYYY-MM-DD').toDate()
                                    onChangeValue('rolloutDate', createTimeStamp(date.getTime() / 1000))
                                 }}
                                 value={dateString}
                                 type='date' />
                           </View>
                        )}} />

                  {material.type == 'gin' && <SectionPanel
                     title={'Recommendation'}
                     render={() => {
                        const recommendation = material.recommendation
                        let startDateString: string | undefined
                        let endDateString: string | undefined
                        if (material.recommendation?.startDate?.toDate != null) {
                           startDateString = moment(recommendation?.startDate.toDate()).format('YYYY-MM-DD')
                        }
                        if (material.recommendation?.endDate?.toDate != null) {
                           endDateString = moment(recommendation?.endDate.toDate()).format('YYYY-MM-DD')
                        }

                        return (
                           <View style={{
                              maxWidth: 300,
                              flexDirection: 'row',
                              alignItems: 'center'
                           }}>
                              <CheckBox
                                 title='Recommend this Gin?'
                                 disabled={isEditing == false}
                                 containerStyle={{
                                    margin: 0,
                                    marginLeft: 0,
                                    borderWidth: 0
                                 }}
                                 checkedIcon={<Icon name='check-box' />}
                                 uncheckedIcon={<Icon name='check-box-outline-blank' />}
                                 onPress={() => {
                                    if (material.recommendation) {
                                       onChangeValue('recommendation', null)
                                    } else {
                                       const seconds = (new Date()).getTime() / 1000
                                       onChangeValue('recommendation.startDate', createTimeStamp(seconds))
                                    }
                                 }}
                                 checked={material.recommendation == undefined ? false : true} />
                              {material.recommendation != null && <div style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}>
                                 <View style={{
                                    marginRight: 15
                                 }}>
                                    <Text>Start Date</Text>
                                    <input
                                       disabled={!isEditing}
                                       onChange={(event) => {
                                          const date = moment(event.target.value, 'YYYY-MM-DD').toDate()
                                          onChangeValue(
                                             'recommendation.startDate',
                                             createTimeStamp(date.getTime() / 1000)
                                          )
                                       }}
                                       value={startDateString}
                                       type='date' />
                                 </View>
                                 <View>
                                    <Text>End Date</Text>
                                    <input
                                       disabled={!isEditing}
                                       onChange={(event) => {
                                          const date = moment(event.target.value, 'YYYY-MM-DD').toDate()
                                          onChangeValue(
                                             'recommendation.endDate',
                                             createTimeStamp(date.getTime() / 1000)
                                          )
                                       }}
                                       value={endDateString}
                                       type='date' />
                                 </View>
                              </div>}
                           </View>
                        )
                     }} />}
                  <SectionPanel
                     title='Description'
                     render={() => {
                        const translatedDescription = material.translatedDescription ?? {}
                        const description = material.description
                        return (
                           <View style={{
                              width: 600
                           }}>
                              {isEditing && (
                                 <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end'
                                 }}>
                                    <Button 
                                       type='outline'
                                       containerStyle={{
                                          bottom: -1,
                                          zIndex: 100
                                       }}
                                       buttonStyle={{
                                          borderRadius: 0,
                                          borderColor: '#dddddd',
                                          borderWidth: visualEditor === false ? 1 : 0,
                                          borderBottomWidth: 0,
                                          fontSize: 13
                                       }}
                                       onPress={() => setVisualEditor(false)}
                                       title='Text'/>
                                    <Button 
                                       type='outline'
                                       containerStyle={{
                                          bottom: -1,
                                          zIndex: 100
                                       }}
                                       buttonStyle={{
                                          borderRadius: 0,
                                          borderColor: '#dddddd',
                                          borderWidth: visualEditor === true ? 1 : 0,
                                          borderBottomWidth: 0,
                                          fontSize: 13
                                       }}
                                       onPress={() => setVisualEditor(true)}
                                       title='Visual'/>
                                    {/* <button 
                                       style={{ 
                                          all: 'unset',
                                          width: 100, 
                                          background: 'white',
                                          bottom: -1
                                       }}
                                       onClick={() => setVisualEditor(true)}>VISUAL</button> */}
                                    {/* <button 'unset', 
                                       style={{ 
                                          all: 'unset',
                                          width: 100, 
                                          background: 'white',
                                          bottom: -1
                                       }}
                                       onClick={() => setVisualEditor(false)}>TEXT</button> */}
                                 </View>
                              )}
                              <Paper elevation={0} variant='outlined' style={{ padding: '1em', zIndex: 99 }}>
                                 {isEditing ? (
                                    <div>
                                       {!visualEditor ? 
                                          <textarea
                                             style={{
                                                marginBottom: 5,
                                                minWidth: '100%',
                                                fontFamily: 'sans-serif',
                                                minHeight: 300,
                                                lineHeight: 1.3,
                                                padding: 15,
                                                background: 'white'
                                             }}
                                             value={description}
                                             onChange={(e) => {
                                                onChangeValue(`description`, e.target.value)
                                             }} /> : 
                                          <TextEditor 
                                             onChangeText={(text) => onChangeValue('description', text)}
                                             defaultValue={description}/>}
                                    </div>
                                 ) : (
                                    <Paper variant='outlined' style={{
                                       padding: '1em'
                                    }}>
                                       <div dangerouslySetInnerHTML={{ __html: description ?? '' }}></div>
                                    </Paper>
                                 )}                              
                                 {supportedLanguages.map((language) => {
                                    return (
                                       <View>
                                          <br/>
                                          <b><label>{language.toUpperCase()}</label></b>
                                          {isEditing ? (
                                             <div>
                                                {!visualEditor ? <textarea
                                                   style={{
                                                      marginBottom: 5,
                                                      minWidth: '100%',
                                                      fontFamily: 'sans-serif',
                                                      minHeight: 300,
                                                      lineHeight: 1.3,
                                                      padding: 15,
                                                      background: 'white'
                                                   }}
                                                   value={translatedDescription[language]}
                                                   onChange={(e) => {
                                                      const value = e.target.value
                                                      onChangeValue(`translatedDescription.${language}`, (value ?? ''))
                                                   }} /> : 
                                                <TextEditor 
                                                   onChangeText={(text) => {
                                                      onChangeValue(`translatedDescription.${language}`, (text ?? ''))
                                                   }}
                                                   defaultValue={translatedDescription[language]}/>}
                                             </div>
                                          ) : (
                                             <Paper variant='outlined' style={{
                                                padding: '1em'
                                             }}>
                                                <div dangerouslySetInnerHTML={{ __html: translatedDescription[language] ?? '' }}></div>
                                             </Paper>
                                          )}                                       
                                       </View>
                                    )
                                 })}
                              </Paper>
                           </View>
                        )
                     }} />
                  <SectionPanel
                     title={'Comments'}
                     render={() => {
                        const comments = material.comments ?? ''
                        return (
                           <textarea
                              disabled={isEditing == false}
                              style={{
                                 marginBottom: 5,
                                 minWidth: 600,
                                 fontFamily: 'sans-serif',
                                 minHeight: 100
                              }}
                              value={comments}
                              onChange={(e) => {
                                 const value = e.target.value
                                 onChangeValue('comments', value)
                              }} />
                        )
                     }} />
               </View>
               <View style={{
                  flex: 0.5,
               }}>
                  <SectionPanel
                     title='Images and Thumbnail'
                     render={() => (
                        <ImageUploading
                           value={[{
                              dataURL: material.thumbnail ?? ''
                           }]}
                           onChange={onSelectThumbnail}>
                           {({
                              onImageUpload,
                              onImageRemove,
                           }) => {
                              return (
                                 <View style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                 }}>
                                    <View style={{
                                       width: '100%',
                                       flexDirection: 'row',
                                       alignItems: 'center',
                                       justifyContent: 'flex-end',
                                       marginBottom: 5
                                    }}>
                                       {material.thumbnail != null && (
                                          <a rel="noreferrer" target='_blank' href={material.thumbnail}>S</a>
                                       )}
                                       &nbsp;
                                       &nbsp;
                                       {material.thumbnailMedium != null && (
                                          <a rel="noreferrer" target='_blank' href={material.thumbnailMedium}>M</a>
                                       )}
                                       &nbsp;
                                       &nbsp;
                                       {material.thumbnailLarge != null && (
                                          <a rel="noreferrer" target='_blank' href={material.thumbnailLarge}>L</a>
                                       )}
                                       &nbsp;
                                       &nbsp;
                                       {(material?.images ?? []).length > 0 && (
                                          <a rel="noreferrer" target='_blank' href={material?.images![0]}>F</a>
                                       )}
                                    </View>
                                    {material.thumbnail != null && <Image
                                       style={{
                                          width: 300,
                                          height: 300,
                                          borderWidth: 1,
                                          borderColor: '#dddddd',
                                          borderRadius: 2
                                       }}
                                       resizeMode='contain'
                                       source={{
                                          uri: material.thumbnailMedium ?? material.thumbnail,
                                          cache: 'force-cache'
                                       }} />}
                                    {isEditing == true && <Button
                                       type='clear'
                                       titleStyle={{
                                          fontSize: 12
                                       }}
                                       containerStyle={{
                                          marginTop: 10
                                       }}
                                       title={material.thumbnail != null ? 'Remove Image' : 'Upload Image'}
                                       onPress={() => {
                                          if (material.thumbnail) {
                                             onImageRemove(0)
                                          } else {
                                             onImageUpload()
                                          }
                                       }} />}
                                    <hr/>
                                    {isEditing === true && 
                                     !material.thumbnail?.startsWith('https://storage.googleapis.com') && 
                                     (material.images ?? []).length > 0 && (
                                       <button onClick={onPressOptimizeImage}>{isOptimizing ?
                                          <ActivityIndicator />
                                          : 'Optimize Image'}
                                       </button>
                                    )}
                                 </View>
                              )
                           }}
                        </ImageUploading>
                     )} />
                  <View style={{
                     borderWidth: 1,
                     borderRadius: 5,
                     borderColor: '#dddddd'
                  }}>
                     {material.lastManualUpdate != null && <SectionPanel
                        title={'Last Manual Updated By'}
                        render={() => {
                           const lastManualUpdate = material.lastManualUpdate
                           return (
                              <View>
                                 <Text>{lastManualUpdate?.displayName ?? 'No Name'} • {lastManualUpdate?.email ?? 'No Email'}</Text>
                              </View>
                           )
                        }} />}
                     {material.approvalDate != null && <SectionPanel
                        title={'Approval Date'}
                        render={() => {
                           const approvalDate = material.approvalDate
                           return (
                              <View>
                                 <Text>{approvalDate.toDate().toTimeString()} • {moment(approvalDate.toDate()).fromNow()}</Text>
                              </View>
                           )
                        }} />}
                     <SectionPanel
                        title={'Created At'}
                        render={() => {
                           const createdAt = material.createdAt
                           const createdBy = material.createdBy
                           return (
                              <View>
                                 <Text>{createdAt.toDate().toTimeString()} • {createdAt.toDate().toDateString()}</Text>
                                 <Text>{createdBy.displayName ?? 'No Name'} • {createdBy.email}</Text>
                              </View>
                           )
                        }} />
                     <SectionPanel
                        title={'Updated At'}
                        render={() => {
                           const updatedAt = material.updatedAt
                           const updatedBy = material.updatedBy
                           return (
                              <View>
                                 <Text>{updatedAt.toDate().toTimeString()} • {moment(updatedAt.toDate()).fromNow()}</Text>
                                 <Text>{updatedBy.displayName ?? 'No Name'} • {updatedBy.email}</Text>
                              </View>
                           )
                        }} />
                     <SectionPanel
                        title='Ratings'
                        render={() => {
                           const ratings = material.ratings
                           const totalReviews = material.totalReviews
                           return (
                              <View style={{
                                 flexDirection: 'row',
                                 flexWrap: 'wrap'
                              }}>
                                 <Text style={{ margin: 10 }}>Total Reviews : {totalReviews}</Text>
                                 <Text style={{ margin: 10 }}>Number Of Ratings : {ratings?.numberOfRatings}</Text>
                                 <Text style={{ margin: 10 }}>Absolute Average : {ratings?.absoluteAverage}</Text>
                                 <Text style={{ margin: 10 }}>Absolute Total : {ratings?.absoluteTotal}</Text>
                                 <Text style={{ margin: 10 }}>Relative Average : {ratings?.relativeAverage}</Text>
                                 <Text style={{ margin: 10 }}>Relative Total : {ratings?.relativeTotal}</Text>
                              </View>
                           )
                           }} />
                        {material.type == 'gin' && <SectionPanel
                           title='Static Badge'
                           render={() => {

                              const badge = `<iframe frameBorder={0} title="${material.name}" src="https://web.ginferno.app/ratings/${material.id}" />`
                              return (                                 
                                 <View>
                                    <View style={{
                                       marginBottom: 10
                                    }}>
                                       <iframe
                                          frameBorder={0}
                                          title={material.name}
                                          src={`https://web.ginferno.app/ratings/${material.id}`} />
                                    </View>
                                    <CopyToClipboard text={badge}
                                       onCopy={() => null}>
                                       <button style={{
                                          width: 100
                                       }}>Copy iFrame</button>
                                    </CopyToClipboard>
                                 </View>
                              )
                           }} />}
                        {material.type == 'gin' && <SectionPanel
                           title='SEO'
                           render={() => {
                              return (                                 
                                 <div>
                                    <label>Title</label><br/>
                                    <input
                                       style={{ minWidth: 350 }}
                                       disabled={isEditing == false}
                                       value={material.meta?.title}
                                       placeholder="Meta Title"
                                       onChange={(e) => {
                                          const value = e.target.value
                                          onChangeValue('meta.title', value)
                                       }} />
                                    <p>
                                    <label>Description</label><br/>
                                    <textarea
                                       style={{ minWidth: 350, minHeight: 150 }}
                                       disabled={isEditing == false}
                                       value={material.meta?.description}
                                       placeholder="Meta Description"
                                       onChange={(e) => {
                                          const value = e.target.value
                                          onChangeValue('meta.description', value)
                                       }} />
                                    </p>
                                    <p>
                                    <label>Robots</label><br/>
                                    <input
                                       style={{ minWidth: 350 }}
                                       disabled={isEditing == false}
                                       value={material.meta?.robots}
                                       placeholder="Meta Robots"
                                       onChange={(e) => {
                                          const value = e.target.value
                                          onChangeValue('meta.robots', value)
                                       }} />
                                    </p>
                                    {/* <p>
                                       <label>Keywords</label><br/>
                                       <input
                                       style={{ minWidth: 350 }}
                                       disabled={isEditing == false}
                                       value={material.meta?.keywords}
                                       placeholder="Meta Keywords"
                                       onChange={(e) => {
                                          const value = e.target.value
                                          onChangeValue('meta.keywords', value)
                                       }} /></p>
                                    <p>
                                       <label>Canonical</label><br/>
                                       <input
                                       style={{ minWidth: 350 }}
                                       disabled={isEditing == false}
                                       placeholder="Meta Canonical"
                                       value={material.meta?.canonical}
                                       onChange={(e) => {
                                          const value = e.target.value
                                          onChangeValue('meta.canonical', value)
                                       }} /></p> */}
                                 </div>
                              )
                           }} />}
                           {material.type == 'gin' && <SectionPanel
                              title='Related Links'
                              render={() => {
                                 return (                                 
                                    <div>
                                       <MaterialTable 
                                          title={''}
                                          options={{
                                             search: false,
                                          }}
                                          style={{
                                             borderWidth: 0,   
                                             boxShadow: 'none',                                             
                                          }}
                                          components={{
                                             Pagination: () => null,
                                          }}
                                          editable={isEditing ? {
                                             onRowAdd: onAddRelatedLink,
                                             onRowDelete: onDeleteRelatedLink,
                                             onRowUpdate: onUpdateRelatedLink
                                          } : undefined}
                                          columns={[
                                             {
                                                title: 'Title',
                                                field: 'title'
                                             },
                                             {
                                                title: 'Description',
                                                field: 'description'
                                             },
                                             {
                                                title: 'Type',
                                                field: 'type',
                                                lookup: RelatedLinkOptions
                                             },
                                             {
                                                title: 'Link',
                                                field: 'url',
                                                render: (r) => <a target={'_blank'} href={r.url}>Open</a>,
                                                editable: 'onAdd'
                                             },
                                             {
                                                title: 'Image Url',
                                                field: 'imageUrl',
                                                render: (r) => {r.imageUrl != null ? <a target={'_blank'} href={r.imageUrl}>Open</a> : <div/>},
                                                editable: 'always'
                                             }
                                          ]}
                                          data={material.relatedLinks ?? []}/>
                                    </div>
                                 )
                              }} />}
                     </View>
               </View>
            </View>
         )}
      </View>
   )

   function renderBarcodesPanel() {
      return (
         <SectionPanel
            title='Barcodes'
            render={() => {
               const barcodes = [...material.barcodes ?? []]
               return (
                  <View style={{
                     width: '60%'
                  }}>
                     <TextField
                        placeholder='Enter barcode and press return/enter to add'
                        style={{ marginRight: 10, width: 400 }}
                        editable={isEditing}
                        onChangeText={(value) => setBarcodeText(value.trim())}
                        onSubmitEditing={onSubmitBarcode}
                        value={barcodeText} />
                     <View style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                     }}>
                        {renderChips(barcodes, isEditing ? onPressDeleteBarcode : undefined, undefined, {
                           maxWidth: 150
                        })}
                     </View>
                     <div>
                        {checkingDuplicates ? (
                           <div>
                              <ActivityIndicator />
                              <Text>Checking duplicates...</Text>
                           </div>
                        ) : (
                           <div/>
                        )}
                     </div>
                  </View>
               )
            }} />
      )
   }

   function onAddRelatedLink(link: Materials.RelatedLink){
      return new Promise<void>((resolve, reject) => {
         try {
            const relatedLinks = material.relatedLinks ?? []
            relatedLinks.push({
               description: link.description ?? null,
               title: link.title ?? null,
               type: link.type,
               url: link.url,
               imageUrl: link.imageUrl ?? null
            })
            onChangeValue('relatedLinks', relatedLinks)
         } catch (error) {
            
         }
         resolve()
      })
   }

   function onDeleteRelatedLink(link: Materials.RelatedLink){
      return new Promise<void>((resolve, reject) => {
         try {
            const relatedLinks = material.relatedLinks ?? []
            const index = relatedLinks.findIndex((r) => r.url)
            if(index >= 0){
               relatedLinks.splice(index, 1)
            }
            onChangeValue('relatedLinks', relatedLinks)
         } catch (error) {
            
         }
         resolve()
      })
   }

   function onUpdateRelatedLink(link: Materials.RelatedLink){
      return new Promise<void>((resolve, reject) => {
         try {
            const relatedLinks = material.relatedLinks ?? []
            const index = relatedLinks.findIndex((r) => r.url)
            if(index >= 0){
               relatedLinks[index] = {
                  description: link.description ?? null,
                  title: link.title ?? null,
                  type: link.type,
                  url: link.url,
                  imageUrl: link.imageUrl ?? null
               }
            }
            onChangeValue('relatedLinks', relatedLinks)
         } catch (error) {
            
         }
         resolve()
      })
   }

   async function onSubmitBarcode() {
      try {
         const matches = await checkMatchingBarcodes(barcodeText)
         if(matches?.length == 0){
            const __barcodes = [...material.barcodes ?? []]
            __barcodes.push(barcodeText.trim())
            onChangeValue('barcodes', lodash.uniq(__barcodes))
         } else{
            Alert.alert(
               'Barcode already used',
               `List:\n\n${matches?.map((m, index) => `${index + 1}. ${m.id} - ${m.name}\n`)}`
            )
         }
         setBarcodeText('')
      } catch (error) {
         
      }
   }

   async function checkMatchingBarcodes(barcode:string){
      setCheckingDuplicates(true)
      try {
         
         const response = await Materials.getMaterials({
            limit: 100,
            where: [
               {
                  fieldPath: 'barcodes',
                  opStr: 'array-contains',
                  value: barcode
               }
            ]
         })
         const matches = response.data
         setCheckingDuplicates(false)
         return matches
      } catch (error) {
         console.log('Error Checking Duplicate Barcodes: ', error)
         Alert.alert('Error Checking Duplicate Barcodes', String(error))
         setCheckingDuplicates(false)
      }
   }

   async function onClickUpdateUri() {
      setValidatingUri('searching')
      try {
         await Materials.updateMaterial(materialId, {
            uri: uri
         })
         onChangeValue('uri', uri)
         setValidatingUri('success')
      } catch (error) {
         setValidatingUri('failed')
      }
   }

   async function validateUri(){
      setValidatingUri('searching')
      try {
         const response = await Materials.getMaterials({
            where: [
               {
                  fieldPath: 'uri',
                  opStr: '==',
                  value: uri
               },
               {
                  fieldPath: 'id',
                  opStr: '!=',
                  value: materialId
               }
            ]
         })
         if(response.data.length > 0){
            setValidatingUri('failed')
         }else{
            setValidatingUri('available')
         }
      } catch (error) {
         console.log('Error validating uri: ', error)
         setValidatingUri('failed')
      }
   }

   async function onPressOptimizeImage() {
      setIsOptimizing(true)
      const imageUrl = (material.images ?? [])[0]
      const response = await fetch(imageUrl, {
         method: 'GET'
      })

      const blob = await response.blob()
      const file = new File([blob], "thumbnail.png", { type: "image/png" })

      var reader = new FileReader();

      reader.readAsDataURL(blob);
      reader.onloadend = function () {
         var base64data = reader.result;
         onSelectThumbnail([
            {
               file: file,
               dataURL: base64data as string
            }
         ])
      }
      setIsOptimizing(false)
   }

   function onSelectThumbnail(value: ImageListType) {

      if ((value ?? []).length > 0) {

         const file = value![0].file as any
         const data = value![0].dataURL as any

         let thumbnail : any
         let thumbnailMedium : any

         Resizer.imageFileResizer(file, 128, 128, 'webp', 100, 0,
            async (resizedData) => {

               thumbnail = resizedData ?? null

               Resizer.imageFileResizer(file, 256, 256, 'webp', 100, 0,
                  async (resizedData) => {

                     thumbnailMedium = resizedData ?? null

                     Resizer.imageFileResizer(file, 512, 512, 'webp', 100, 0,
                        async (resizedData) => {

                           onChangeValues({
                              thumbnail,
                              thumbnailMedium,
                              thumbnailLarge : (resizedData ?? null) as any,
                              images: [data]
                           })

                        }, 'base64')

                  }, 'base64')

            }, 'base64')

      } else {

         onChangeValues({
            thumbnail: null as any,
            images: []
         })
      }
   }

   function onPressDeleteBarcode(index) {
      const __barcodes = [...material.barcodes ?? []]
      __barcodes.splice(index, 1)
      onChangeValue('barcodes', __barcodes)
   }

   function onPressEdit() {
      setIsEditing(true)
   }

   function onPressForceUpdate() {
      Alert.alert(
         'Confirmation',
         'This active will update the material details in all recipes, rooms, ratings etc. This is an expensive operation. Do you want to continue?',
         [
            {
               onPress: onConfirmForceUpdate,
               style: 'destructive',
               text: 'Continue'
            },
            {
               style: 'cancel',
               text: 'Cancel'
            }
         ]
      )
   }

   async function onConfirmForceUpdate() {
      setShowLoader(true)
      try {
         const response = await checkMaterialNameChange(material, material, true)
         console.log('Force Update Completed', response)
      } catch (error) {
         Alert.alert('Force Update Failed', String(error))
         console.log('Force Update Failed', error)
      }
      setShowLoader(false)
   }


   function onPressDelete() {

      Alert.alert(
         'Warning!',
         'Are you sure you want to delete this material? This cannot be undone',
         [
            {
               onPress: async () => {

                  setShowLoader(true)
                  setIsEditing(false)
                  try {
                     await props.onPressDelete(materialId)
                  } catch (error) {
                     console.log('Error Deleting Material : ', error)
                  }
                  setShowLoader(false)
               },
               style: 'destructive',
               text: 'Delete'
            },
            {
               style: 'cancel',
               text: 'Cancel'
            }
         ]
      )
   }

   async function onPressRefresh() {


	console.log('m', getSearchObject(material))
    //   setShowLoader(true)
    //   setIsEditing(false)
    //   try {
    //      const response = await props.onPressRefresh(materialId)
    //      setMaterial(lodash.cloneDeep(response))
    //      console.log('response', response)
    //   } catch (error) {
    //      console.log('Error Refreshing Material : ', error)
    //   }
    //   setShowLoader(false)
   }

   function onPressCancelEdit() {
      setIsEditing(false)
      setMaterial(lodash.cloneDeep(props.material))
   }

   async function onPressSave() {
      setShowLoader(true)
      try {
         const response = await props.onUpdateMaterial(material.id, material, props.material)
         setMaterial(lodash.cloneDeep(response))
         setIsEditing(false)
      } catch (error) {

      }
      setShowLoader(false)
   }

   function onChangeValue(path: string, value: any) {
      const __material = { ...material }
      lodash.set(__material, path, value)
      setMaterial(__material)
   }

   function onChangeValues(values: Partial<Materials.Material>) {
      const __material = {
         ...material,
         ...values
      }
      setMaterial(__material)
   }
}

export function SectionPanel(props: {
   title: string,
   render: () => any
}) {
   const { render, title } = props
   return (
      <View style={{
         padding: 15
      }}>
         <Text style={{
            marginBottom: 10,
            fontWeight: '600'
         }}>{title}</Text>
         {<View>
            {render()}
         </View>}
      </View>
   )
}

export function useDebounce(value, delay) {
   // State and setters for debounced value
   const [debouncedValue, setDebouncedValue] = useState(value);

   useEffect(
      () => {
         // Set debouncedValue to value (passed in) after the specified delay
         const handler = setTimeout(() => {
            setDebouncedValue(value);
         }, delay);

         // Return a cleanup function that will be called every time ...
         // ... useEffect is re-called. useEffect will only be re-called ...
         // ... if value changes (see the inputs array below). 
         // This is how we prevent debouncedValue from changing if value is ...
         // ... changed within the delay period. Timeout gets cleared and restarted.
         // To put it in context, if the user is typing within our app's ...
         // ... search box, we don't want the debouncedValue to update until ...
         // ... they've stopped typing for more than 500ms.
         return () => {
            clearTimeout(handler);
         };
      },
      // Only re-call effect if value changes
      // You could also add the "delay" var to inputs array if you ...
      // ... need to be able to change that dynamically.
      [value]
   );

   return debouncedValue;
}

export function renderChips(
   list: (string | undefined)[],
   onPressDelete?: (index) => void,
   lookUp?: any,
   chipStyle?: React.CSSProperties
) {
   return list?.filter(i => (i != null)).map((i, index) => (
      <Chip
         onDelete={onPressDelete ? () => onPressDelete(index) : undefined}
         style={{
            marginRight: 5,
            marginBottom: 5,
            ...chipStyle
         }}
         size="medium"
         label={((lookUp ?? {})[i!]) ?? i ?? '--'}
      />)
   )
}

function getPrimaryKindsFor(material: Materials.Material) {
   let kinds: Category[] = []
   if (material.type == 'gin') {
      kinds = primaryGinKinds()
   } else if (material.type == 'mixer') {
      kinds = primaryMixerKinds()
   } else {
      kinds = primaryGarnishKinds()
   }
   return kinds.map(category => {
      return {
         label: category.name,
         value: category
      }
   })
}

function getSecondaryKindsFor(material: Materials.Material) {
   let kinds: Category[] = []
   if (material.type == 'gin') {
      kinds = secondaryGinKinds()
   } else if (material.type == 'mixer') {
      kinds = secondaryMixerKinds().filter(k => k.field == material.kinds?.one?.id)
   } else {
      kinds = secondaryGarnishKinds()
   }
   return kinds.map(category => {
      return {
         label: category.name,
         value: category
      }
   })
}

function getTertiaryKindsFor(material: Materials.Material) {
   let kinds: Category[] = []
   if (material.type == 'gin') {
      kinds = tertiaryGinKinds()
   } else if (material.type == 'mixer') {
      kinds = tertiaryMixerKinds()
   } else {
      kinds = tertiaryGarnishKinds()
   }
   return kinds.map(category => {
      return {
         label: category.name,
         value: category
      }
   })
}