import { 
   Material as MaterialInterface,
   getAllMaterials,
   uploadMaterials,
   updateMaterial,
   createMaterial,
   fetchMaterial,
   searchMaterial,
   MaterialType,
   MaterialTypes
} from 'gf-connect/lib/Material'

import {
   Category
} from 'gf-connect/lib/Category'
import { WhereType, OrderType, CollectionDescriptor } from 'firestar'
import { Change, AppState } from '.'
// import { FilterType } from 'src/consumer/Filter'
import { addToRecentSearches } from './User'
import firebase from 'firebase/compat/app'

export type Material = MaterialInterface

const initialState : Material[] = []

export default function (state = initialState, action: Change) {
   switch (action.type) {
      case 'SYNC_MATERIALS' : {
         let currentList = [...state]            
         const newMaterials = [...action.payload as Material[]]
         newMaterials.forEach( (p:Material) => {
               const findIndex = currentList.findIndex( (c) => c.id ===  p.id)                  
               if(findIndex >= 0){
                  currentList[findIndex] = p
               }else{
                  currentList.push(p)
               }
            }
         )   
         return currentList
      }
      case 'CLEAR_MATERIALS' :{
         if(action.payload){
            let currentList = [...state]            
            currentList.filter( (p:Material) => p.type != action.payload)
            return currentList
         }else{
            return []  
         }         
      }
      default:
         return state
   }
}

export function getMaterials (
   category?:Category,   
   limit?:number,
   lastDoc? : Material) : any {

   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Material[]>((resolve, reject) => {

         const conditions : WhereType[] = []
         if (category) {
            conditions.push({
               fieldPath: 'kinds.one.id',
               opStr: '==',
               value: category.id
            })
         }
         
         getAllMaterials({
            limit : limit ?? 10000,
            orderBy : {
               fieldPath : 'updatedAt',
               directionStr : 'desc'
            },
            lastDoc : lastDoc,
            where : conditions
         })
         .then(
            (materials) => {
               // console.log('Materials : ',materials.data.map(m => m.name))
               resolve(materials.data as Material[])
               dispatch({
                  payload : materials.data,
                  type : 'SYNC_MATERIALS'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function getMaterialsList(descriptor?: CollectionDescriptor): any {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         getAllMaterials(descriptor)
            .then(
               (response) => {
                  resolve(response)
                  dispatch({
                     payload: response.data,
                     type: 'SYNC_MATERIALS'
                  })
               }
            )
            .catch((error) => reject(error))
      })
   }
}

export function paginateMaterials (
   type : MaterialType,  
   category?: Category,
   limit?:number,
   filters?:any[],
   lastDoc?:firebase.firestore.QueryDocumentSnapshot) : any {

   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         
         const conditions : WhereType[] = []
         const orderBys : OrderType[] = []

         const alcohol = filters?.find(f => f.id == 'alcohol')!
         const country = filters?.find(f => f.id == 'country')!
         const sorting = filters?.find(f => f.id == 'sorting')!

         if(type == 'garnish'){
            conditions.push({
               fieldPath : 'type',
               opStr : '==',
               value : 'garnish'
            })
         }else{
               const field = `${category?.field}.id`
               conditions.push({
                  fieldPath: field,
                  opStr: '==',
                  value: category?.id.split('#')[1]
               })
         }

         if(type == 'gin'){
            if(country.value != 'any'){
               conditions.push({
                  fieldPath: country.field,
                  opStr: '==',
                  value: country.value
               })
            }
            if(alcohol.value != 'any'){
               conditions.push({
                  fieldPath: alcohol.field,
                  opStr: '>',
                  value: parseInt(alcohol.value)
               })
               orderBys.push({
                  fieldPath : alcohol.field,
                  directionStr : 'asc'
               })
            }
         }

         orderBys.push({
            fieldPath : sorting.field,
            directionStr : sorting.value
         })

         console.log('Conditions',conditions)
         console.log('orderBys',orderBys)

         getAllMaterials({
            limit : limit ?? 10000,
            orderBys: orderBys,
            where : conditions,
            lastDoc : lastDoc
         })
         .then(
            (response) => {
               resolve(response)
               dispatch({
                  payload : response.data,
                  type : 'SYNC_MATERIALS'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function getGarnishes (
   limit?:number,
   lastDoc? : Material
) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         getAllMaterials({
            limit : limit ?? 10000,
            where : [
               {
                  fieldPath : 'type',
                  opStr : '==',
                  value : 'garnish'
               }
            ],
            lastDoc : lastDoc
         })
         .then(
            (response) => {
               resolve(response)
               dispatch({
                  payload : response.data,
                  type : 'SYNC_MATERIALS'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function searchMaterialWithName(
   searchText:string,
   materialType?: keyof typeof MaterialTypes,
   lastDoc?:firebase.firestore.QueryDocumentSnapshot) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         searchMaterial(searchText,materialType,lastDoc)
         .then(
            (response:any) => {
               const materials = response.data ?? []
               dispatch({
                  payload : materials,
                  type : 'SYNC_MATERIALS'
               })
               if(materials.length) dispatch(addToRecentSearches(searchText))
               resolve(response)
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function uploadMaterialsFromCSV(data:any[],onProgress?:any) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Material[]>((resolve, reject) => {
         uploadMaterials(data,onProgress)
         .then(
            (materials) => {
               resolve(materials)
               dispatch({
                  payload : materials,
                  type : 'SYNC_MATERIALS'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function updateMaterialInfo(material:Material) : Promise<Material> | any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         delete material.ratings
         delete material.totalReviews
         delete material.matches
         delete material.tags
         updateMaterial(material)
         .then(
            (response) => {
               resolve(response)
               dispatch({
                  payload : [response],
                  type : 'SYNC_MATERIALS'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function addMaterial(material:Material) : Promise<Material> | any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         createMaterial(material)
         .then(
            (response) => {
               resolve(response)
               dispatch({
                  payload : [response],
                  type : 'SYNC_MATERIALS'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function deleteMaterial(material:Material) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         updateMaterial({...material, status : 'deleted'})
         .then(
            (response) => {
               resolve(response)
               dispatch({
                  payload : [response],
                  type : 'SYNC_MATERIALS'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function getMaterial(materialId:string) : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         fetchMaterial(materialId)
         .then(
            (response) => {
               if(response){                  
                  dispatch({
                     payload : [response],
                     type : 'SYNC_MATERIALS'
                  })
               }
               resolve(response)
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function clearMaterials(type?:keyof typeof MaterialTypes) : Change{
   return {
      payload : type,
      type : 'CLEAR_MATERIALS'
   }
}