import React,{ useState, useEffect, useMemo, memo } from 'react'
import Button from '@material-ui/core/Button';
import MaterialTable,{
   EditComponentProps
} from 'material-table';
import Promotions, { createPromotion, updatePromotion, getPromotions, Promotion, PromotionRegionTypes, PromotionTypes } from 'gf-connect/lib/Promotion';
import firestar,{
   Actions,
   Storage
} from 'firestar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
   useSelector,
   useDispatch
} from 'react-redux'
import {
   ActivityIndicator,
   Text
} from 'react-native';
import {
   Material
} from './store/Materials';
import { TouchableOpacity, Image, View } from 'react-native'
import { AppState } from './store';
import TextField from '@material-ui/core/TextField';
import ImageUploader from 'react-images-upload';
import { GFAutoCompleteField, GFAutoCompleteOption, GFTextField } from './FormUtils';
import moment from 'moment';
import { createTimestampContext } from 'gf-connect/lib/Utils';
import {
   Alert
} from './Alert'
import {
   getSearchableCountries
} from './utils'
import { Chip, Modal, Paper } from '@material-ui/core';
import lodash from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import SaveIcon from '@material-ui/icons/Save'
import firebase from 'firebase/compat/app'
import {
	createTimeStamp
} from 'firestar/lib/Utils'
import Resizer from 'react-image-file-resizer';
import SearchMaterial from './SearchMaterial';

const searchableCountries = getSearchableCountries()
const countriesOptionList = searchableCountries.map((c) => {
   return {
      label: c.name,
      value: c.code
   }
})

const INITIAL_SIZE = 150
const PAGINATE_SIZE = 100

export default function PromotionsList() {

   const [promotions, setPromotions] = useState<Promotion[]>([])
   const materials : Material[] = useSelector((state:AppState) => state.materials)
   const [showLoader, setShowLoader] = useState(false)
   const [lastDoc, setLastDoc] = useState<any>()

   useEffect(() => {
      fetchPromotions()
   }, [])

   async function fetchPromotions(paginating?: boolean) {

      const __paginating = paginating ?? false
      setShowLoader(true)
      try {
         const response = await Promotions.getAll({
            limit: __paginating === true ? PAGINATE_SIZE : INITIAL_SIZE,
            lastDoc: __paginating === true ? lastDoc : undefined,
            orderBy: {
               fieldPath: 'endDate',
               directionStr: 'desc'
            },
         })
         if (__paginating === true) {
            setPromotions([...promotions, ...response.data])
         } else {
            setPromotions(response.data)
         }
         setLastDoc(response.lastDoc)
      } catch (error) {
         Alert.alert('Error Fetching Promotions', String(error))
         console.log('Error Fetching Promotions:', error)
      }
      setShowLoader(false)
   }

   return (
      <div style={{
         display: 'flex',
         flexDirection: 'column'
      }}>
         <MaterialTable
            data={promotions}
            options={{
               search: false,
               pageSize: PAGINATE_SIZE,
               pageSizeOptions: [PAGINATE_SIZE],
               addRowPosition: 'first',
               sorting: false
            }}
            isLoading={showLoader}
            title='Promotions'
            editable={{
               onRowAdd : (promotion: Promotion) => addPromotion(promotion),
               onRowUpdate : (promotion: Promotion) => onUpdatePromotion(promotion),
            }}
            detailPanel={(promotion) =>  (
               <PromotionDetail 
                  onPressSave={onUpdatePromotion}
                  promotion={promotion}/>
            )}
            onChangePage={onChangePage}
            columns={[
               {
                  title: 'id',
                  field: 'id',
                  editable: 'never'
               },
               {
                  title: 'Type',
                  field: 'type',
                  editable: 'onAdd',
                  lookup: PromotionTypes
               },
               // {
               //    title: 'Region',
               //    field: 'region',
               //    editable: 'onAdd',
               //    lookup: PromotionRegionTypes,
               // },
               {
                  title: 'Start Date',
                  field: 'startDate',   
                  // type : 'date',
                  editable: 'always',
                  render: (promotion) => promotion.startDate?.toDate().toDateString() ?? '--',
                  editComponent: (props) => {
                     const date = moment.unix(props.rowData.startDate?.seconds ?? ((new Date()).getTime()/1000)).format('YYYY-MM-DD')
                     return (
                        <GFTextField
                           style={{ marginRight: 5 }}
                           label='Rollout Date'
                           type='date'
                           onChangeText={(value) => {
                              const seconds = moment(value, "YYYY-MM-DD").toDate().getTime() / 1000
                              props.onRowDataChange({
                                 ...props.rowData,
                                 startDate: createTimeStamp(seconds,0)
                              })
                           }}
                           value={date}
                           editing={true} />
                     )
                  }
               },
               {
                  title: 'End Date',
                  field: 'endDate',
                  // type : 'date',
                  editable: 'always',
                  render: (promotion) => promotion.endDate?.toDate().toDateString() ?? '--',
                  editComponent: (props) => {
                     const date = moment.unix(props.rowData.endDate?.seconds ?? ((new Date()).getTime()/1000)).format('YYYY-MM-DD')
                     return (
                        <GFTextField
                           style={{ marginRight: 5 }}
                           label='Rollout Date'
                           type='date'
                           onChangeText={(value) => {
                              const seconds = moment(value, "YYYY-MM-DD").toDate().getTime() / 1000
                              props.onRowDataChange({
                                 ...props.rowData,
                                 endDate: createTimeStamp(seconds,0)
                              })
                           }}
                           value={date}
                           editing={true} />
                     )
                  }
               },
               {
                  title: 'Target',
                  field: 'title', 
                  render: (props) => {
                     if (props.type == 'material-of-the-month') {
                        return props.title
                     } else {
                        return (
                           <Button
                              color='primary'
                              variant='contained'
                              disableElevation
                              disabled={props.targetId == null}
                              onClick={() => window.open(props.targetId)}>Open</Button>
                        )
                     }
                  }, 
                  editable: (_,promotion) => (promotion?.type ?? 'custom') == 'custom',
                  editComponent: renderTitleComponent,
               },
               {
                  title: 'Background',
                  field: 'background',
                  editable: 'always',
                  render: (props) => (
                     <TouchableOpacity onPress={() => window.open(props.background)} style={{
                        flexDirection: 'row',
                        alignItems: 'center'
                     }}>
                        {props.thumbnail == null && <Text>•</Text>}
                        <Image style={{
                           height: 65,
                           aspectRatio: 3,
                           resizeMode: 'contain'
                        }} source={{
                           uri: props.thumbnail ?? props.background
                        }} />
                     </TouchableOpacity>
                  ),
                  editComponent: (props) => (
                     <ImageUploader
                        withIcon={false}
                        singleImage
                        withPreview
                        buttonText='Select Background'
                        onChange={
                           (files: File[], pictures: string[]) => {
                              if(files.length > 0){
                                 const file = files[0]
                                 Resizer.imageFileResizer(file, 512, 512, 'webp', 100, 0,
                                    async (resizedData) => {
                                       props.onRowDataChange({
                                          ...props.rowData,
                                          thumbnail: resizedData as any,
                                          background: pictures[0]
                                       })
                                    }, 'base64')
                              }
                           }
                        }
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880} />
                  ),
               },
               {
                  title: 'Status',
                  render: (promotion) => {
                     const currentTime = firebase.firestore.Timestamp.now().toDate().getTime()
                     const startTime = promotion?.startDate?.toDate().getTime()
                     const endTime = promotion?.endDate?.toDate().getTime()
                     if(currentTime > startTime && currentTime < endTime){
                        return 'Active'
                     }
                     if(currentTime < startTime){
                        return 'Scheduled'
                     }
                     if(currentTime > endTime){
                        return 'Completed'
                     }
                     return '--'
                  },        
                  editable: 'never'          
               },
               {
                  title: 'Created At',
                  render: (promotion) => (
                     <Text>{moment(promotion.createdAt?.toDate()).format('hh:mm A MMM DD YYYY')}</Text>
                  ),
                  editable: 'never'          
               }
            ]}
         />
      </div>
   )

   function onChangePage(page: number, pageSize: number){
      if((promotions.length/pageSize/page) <= (INITIAL_SIZE/PAGINATE_SIZE)){
         fetchPromotions(true)
      }
   }

   function renderTitleComponent(props:EditComponentProps<Promotion>) {

      if (props.rowData.type == 'custom') {
         return (
            <TextField 
               onChange={(event) => props.onRowDataChange({
                  ...props.rowData,
                  targetId: event.target.value
               })}
               label='Target Url' 
               placeholder='Target Url' />
         )
      }else if(props.rowData.type == 'material-of-the-month' || props.rowData.type == 'gin-of-the-month'){         
         return (
            <SearchMaterial onSelectMaterial={(material) => {
               const thumbnail = (material?.images ?? []).length > 0 ? (material?.images![0] ?? null) : (material?.thumbnail ?? null)
               const changes = {
                  ...props.rowData,
                  targetId: material?.id,
                  thumbnail:  thumbnail ?? null,
                  title: material?.name
               } as Promotion
               props.onRowDataChange(changes)
            }}/>
         )
      }else{
         return <div>Please Select a Promotion Type</div>
      }      
   }

   async function checkImageUpload(promotion: Promotion) {
      return new Promise<Promotion>(async (resolve, reject) => {

         try {

            if (promotion.background && promotion.background.includes('https') === false) {

               const thumbnailResponse = await Storage.uploadFileDataToPath(
                  `promotions/${promotion.id}/thumbnail.webp`,
                  promotion.thumbnail,
                  'data_url',
                  {
                     contentType: 'image/webp',
                     cacheControl: 'public,max-age=10000000',
                     customMetadata: {
                        access: 'public'
                     }
                  }
               )
               promotion.thumbnail = thumbnailResponse.downloadUrl

               const imageResponse = await Storage.uploadFileDataToPath(
                  `promotions/${promotion.id}/background.png`,
                  promotion.background,
                  'data_url',
                  {
                     contentType: 'image/png',
                     cacheControl: 'public,max-age=10000000',
                     customMetadata: {
                        access: 'public'
                     }
                  }
               )
               promotion.background = imageResponse.downloadUrl

               resolve(promotion)
            } else {
               resolve(promotion)
            }
         } catch (error) {
            reject(error)
         }
      })
   }

   async function addPromotion(incoming: Promotion) {
      return new Promise<Promotion>(async (resolve, reject) => {
         try {
            const id = Actions.generateIDForPath('promotions')

            const startDate = incoming?.startDate ?? createTimeStamp((new Date().getTime()/1000),0)
            const endDate = incoming?.endDate ?? createTimeStamp((new Date().getTime()/1000),0)

            let promotion = {
               ...incoming,
               id: id,
               startDate,
               endDate,
               regions: countriesOptionList.map(({ value }) => value.toLowerCase())
            } as Promotion

            promotion = await checkImageUpload(promotion)
            promotion = await createPromotion(promotion)

            setPromotions((previous) => [...previous, promotion])
            resolve(promotion)
            
         } catch (error) {
            console.log('Unable to Add Promotion : ',error)
            reject(error)
            Alert.alert(
               'Error Creating Promotion',
               String(error)
            )
         }
      })
   }

   async function onUpdatePromotion(incoming: Promotion) {
      return new Promise<Promotion>(async (resolve, reject) => {
         delete incoming.tableData         
         try {
            let promotion = {
               ...incoming,
               startDate: createTimestampContext(incoming.startDate),
               endDate: createTimestampContext(incoming.endDate),
               createdAt: createTimestampContext(incoming.createdAt),
               updatedAt: createTimestampContext(incoming.updatedAt),
            } as Promotion


            promotion = await checkImageUpload(promotion)
            console.log('promotion', promotion)
            promotion = await updatePromotion(promotion)
            fetchPromotions()
            resolve(promotion)  
         } catch (error) {
            Alert.alert('Error Updating Promotion',String(error))
            reject(error)
         }
      })
   }
}

const PromotionDetail = memo(function (props:{
   promotion: Promotion,
   onPressSave: (promotion: Promotion) => Promise<Promotion>
}) {

   const [promotion, setPromotion] = useState(lodash.cloneDeep(props.promotion))
   const regions = promotion.regions ?? []
   const [showLoader, setShowLoader] = useState(false)

   return (
      <View style={{
         padding: 20
      }}>
         <Modal open={showLoader}><ActivityIndicator/></Modal>
         <View style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: 15
         }}>
            <IconButton style={{
               width: 50
            }} onClick={onPressSave}><SaveIcon/></IconButton>
         </View>
         <Paper variant='outlined' elevation={1} style={{
            padding: 15
         }}>
            <View style={{
               flex: 1,
               justifyContent: 'space-between',
               alignItems: 'center',
               flexDirection: 'row'
            }}>
               <Text style={{
                  fontWeight: 'bold',
                  marginBottom: 10,
               }}>Available Regions</Text>
               <View style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row'
               }}>
                  <button onClick={() => {
                     onChangeValue('regions', countriesOptionList.map(({ value }) => value.toLowerCase()))
                  }}>Select All</button>
                  <Text> ┃ </Text>
                  <button onClick={() => {
                     onChangeValue('regions', [])
                  }}>Deselect All</button>
               </View>
            </View>
            <View style={{
               marginTop: 5,
               flexDirection: 'row',
               width: '70%',
               flexWrap: 'wrap'
            }}>
               {countriesOptionList.map(({ label, value }) => (
                  <View style={{
                     paddingRight: 10,
                     paddingVertical: 5,
                     flexDirection: 'row',
                     justifyContent: 'flex-start',
                     width: 250,
                     alignItems: 'center'
                  }}>
                     <input onChange={(e) => onSelectCountry(value.toLowerCase())} style={{
                        marginRight: 15
                     }} checked={regions.includes(value.toLowerCase())} type={'checkbox'}/>
                     <label>{label}</label>
                  </View>
               ))}
            </View>
         </Paper>
      </View>
   )

   function onSelectCountry(item: string) {
      if(item){
         const __regions = [...(promotion.regions ?? [])]
         const index = __regions.findIndex((c) => c === item)
         if (index >= 0) {
            __regions.splice(index, 1)
         }else{
            __regions.push(item)
         }
         onChangeValue('regions', __regions)
      }
   }

   async function onPressSave(){
      setShowLoader(true)
      try {
         const response = await props.onPressSave(promotion)  
         setPromotion(response)
      } catch (error) {
         console.log('Error Updating Promotion', error)
      }      
      setShowLoader(false)
   }

   function onChangeValue(path: string, value: any){
      const __promotion = {...promotion}
      lodash.set(__promotion, path, value)
      setPromotion(__promotion)
   }

})
