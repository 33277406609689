import { Change, AppState } from '.'

const initialState = []

export default function (state = initialState, action: Change) {
   switch (action.type) {
      case 'SYNC_ROOMS':{
         return []
      }
      case 'CLEAR_ROOMS':{
         return []
      }
      default:
         return state
   }
}

export function getRooms(){
   return (dispatch: (change: Change) => any, getState: () => AppState) => {
      return new Promise(async (resolve, reject) => {
         
      })
   }
}