"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = require("firestar");
var Recipes;
(function (Recipes) {
    function getAll(recipeId, descriptor) {
        return firestar_1.Actions.readCollection(`recipes/${recipeId}/reviews`, descriptor);
    }
    Recipes.getAll = getAll;
})(Recipes || (Recipes = {}));
exports.default = Recipes;
