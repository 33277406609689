import React, { useEffect, useState } from 'react'
import './App.css';
import firestar from 'firestar';
import Landing from './Landing';
import Login from './Login'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/analytics'
import {
	Switch,
	Route,
	withRouter
} from "react-router-dom";

import { Provider } from 'react-redux';
import { store } from './store/redux';
import { ActivityIndicator } from 'react-native';

if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: "AIzaSyDYdo-QKCZ2o5sLgMOlcG6A_H49anLphdc",
    authDomain: "ginfernov2.firebaseapp.com",
    databaseURL: "https://ginfernov2.firebaseio.com",
    projectId: "ginfernov2",
    storageBucket: "ginfernov2.appspot.com",
    messagingSenderId: "454729085155",
    appId: "1:454729085155:web:4c0f873b6d1512c5ad95d6",
    measurementId: "G-8T0Y3K6DB8",
  });
  firebase.analytics();
  firestar.init(
    "admin",
    "browser",
    () => firebase.firestore,
    () => firebase.auth,
    () => firebase.storage,
    () => firebase.database
  );
}

function App() {

	const [userId, setUserId] = useState<string>()
	const [showLoader, setShowLoader] = useState(true)

	useEffect(() => {
		firebase.auth().onAuthStateChanged(onAuthStateChanged)
	}, [])

	if (showLoader === true)
		return <ActivityIndicator />

	return (
		<div className="App">
			<Provider store={store}>
				{userId ?
					<Switch>
						<Route path="/">
							<Landing />
						</Route>
					</Switch> :
					<Switch>
						<Route path="/">
							<Login />
						</Route>
					</Switch>
				}
			</Provider>
		</div>
	)

	async function onAuthStateChanged(user: firebase.User | null) {
		setUserId(user?.uid)
		setShowLoader(false)
	}
}

export default withRouter(App)
