"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserRatings = exports.RatingsTypes = void 0;
const firestar_1 = __importStar(require("firestar"));
exports.RatingsTypes = {
    'relative': 'GINferno Ratings',
    'absolute': 'Normal'
};
function updateUserRatings(absoluteRating, relativeRating, userId, materialId) {
    return new Promise((resolve, reject) => {
        const path = `materials/${materialId}/ratings`;
        firestar_1.Actions.readDocument(path + `/${userId}`)
            .then((response) => {
            var _a, _b;
            const currentUserRatings = response;
            if (currentUserRatings) {
                const newRatings = {
                    id: userId,
                    highest: ((_a = currentUserRatings.highest) !== null && _a !== void 0 ? _a : 0) > absoluteRating ?
                        currentUserRatings.highest : absoluteRating,
                    relativeHighest: ((_b = currentUserRatings.relativeHighest) !== null && _b !== void 0 ? _b : 0) > relativeRating ?
                        currentUserRatings.relativeHighest : relativeRating
                };
                firestar_1.Actions.writeToCollection(path, newRatings)
                    .then(() => resolve(newRatings))
                    .catch(error => reject(error));
            }
            else {
                const rating = {
                    id: userId,
                    highest: absoluteRating,
                    relativeHighest: relativeRating,
                };
                firestar_1.Actions.writeToCollection(path, rating)
                    .then(() => resolve(rating))
                    .catch(error => reject(error));
            }
        })
            .catch(error => reject(error));
    });
}
exports.updateUserRatings = updateUserRatings;
var Ratings;
(function (Ratings) {
    function get(materialId, descriptor) {
        return firestar_1.Actions.readCollection(`materials/${materialId}/ratings`, descriptor);
    }
    Ratings.get = get;
    async function updateUserRating(path, review, didDelete) {
        return new Promise(async (resolve, reject) => {
            var _a;
            const userId = (_a = review.createdBy) === null || _a === void 0 ? void 0 : _a.uid;
            const firestore = firestar_1.default.getFirestore();
            const docRef = firestore().doc(path);
            try {
                const response = await firestore().runTransaction((transaction) => {
                    return transaction.get(docRef)
                        .then((response) => {
                        var _a, _b, _c, _d, _e, _f, _g;
                        const reviewSummary = {
                            id: review.id,
                            relativeRating: (_a = review.relativeRating) !== null && _a !== void 0 ? _a : 0,
                            absoluteRating: (_b = review.absoluteRating) !== null && _b !== void 0 ? _b : 0
                        };
                        let rating = response.data();
                        if (rating) {
                            let topReviews = [...((_c = rating.topReviews) !== null && _c !== void 0 ? _c : [])];
                            const findIndex = topReviews.findIndex((r) => r.id == reviewSummary.id);
                            if ((didDelete !== null && didDelete !== void 0 ? didDelete : false) == true) {
                                if (findIndex >= 0) {
                                    topReviews.splice(findIndex, 1);
                                }
                            }
                            else {
                                if (findIndex >= 0) {
                                    topReviews[findIndex] = reviewSummary;
                                }
                                else {
                                    topReviews.push(reviewSummary);
                                }
                            }
                            topReviews = topReviews.sort((a, b) => b.absoluteRating - a.absoluteRating);
                            topReviews.splice(10, topReviews.length - 1);
                            topReviews = topReviews.filter(r => { var _a; return ((_a = r.absoluteRating) !== null && _a !== void 0 ? _a : 0) > 0; });
                            rating.topReviews = topReviews;
                            if (topReviews.length > 0) {
                                const topAbsoluteRatings = rating.topReviews.sort((a, b) => b.absoluteRating - a.absoluteRating);
                                rating.relativeHighest = (_d = topReviews[0].relativeRating) !== null && _d !== void 0 ? _d : 0;
                                rating.absoluteHighest = (_e = topAbsoluteRatings[0].absoluteRating) !== null && _e !== void 0 ? _e : 0;
                                firestar_1.Actions.writeDocument(path, rating, transaction);
                                return reviewSummary;
                            }
                            else {
                                firestar_1.Actions.deleteDocument(path, transaction);
                                return undefined;
                            }
                        }
                        else {
                            rating = {
                                id: userId,
                                relativeHighest: (_f = reviewSummary.relativeRating) !== null && _f !== void 0 ? _f : 0,
                                absoluteHighest: (_g = reviewSummary.absoluteRating) !== null && _g !== void 0 ? _g : 0,
                                topReviews: [reviewSummary]
                            };
                            if (rating.absoluteHighest > 0) {
                                firestar_1.Actions.writeDocument(path, rating, transaction);
                            }
                            return rating;
                        }
                    });
                });
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Ratings.updateUserRating = updateUserRating;
    async function addRatingForMaterial(materialId, absoluteRating, relativeRating, transaction) {
        const path = `materials/${materialId}`;
        const firestore = firestar_1.default.getFirestore();
        const incrementRelativeRating = firestore.FieldValue.increment(relativeRating);
        const incrementAbsoluteRating = firestore.FieldValue.increment(absoluteRating);
        const incrementNumberOfRatings = firestore.FieldValue.increment(1);
        return firestar_1.Actions.updateDocument(path, {
            'ratings.absoluteTotal': incrementAbsoluteRating,
            'ratings.relativeTotal': incrementRelativeRating,
            'ratings.numberOfRatings': incrementNumberOfRatings,
        }, transaction);
    }
    Ratings.addRatingForMaterial = addRatingForMaterial;
    async function removeRatingForMaterial(materialId, absoluteRating, relativeRating, transaction) {
        const path = `materials/${materialId}`;
        const firestore = firestar_1.default.getFirestore();
        const incrementRelativeRating = firestore.FieldValue.increment(-Math.abs(relativeRating));
        const incrementAbsoluteRating = firestore.FieldValue.increment(-Math.abs(absoluteRating));
        const incrementNumberOfRatings = firestore.FieldValue.increment(-1);
        return firestar_1.Actions.updateDocument(path, {
            'ratings.absoluteTotal': incrementAbsoluteRating,
            'ratings.relativeTotal': incrementRelativeRating,
            'ratings.numberOfRatings': incrementNumberOfRatings,
        }, transaction);
    }
    Ratings.removeRatingForMaterial = removeRatingForMaterial;
    async function updateRatingsForMaterial(materialId, newAbsoluteRating, oldAbsoluteRating, newRelativeRating, oldRelativeRating, transaction) {
        const path = `materials/${materialId}`;
        const firestore = firestar_1.default.getFirestore();
        const absoluteDifference = newAbsoluteRating - oldAbsoluteRating;
        const changedAbsoluteRating = firestore.FieldValue.increment(absoluteDifference);
        const relativeDifference = newRelativeRating - oldRelativeRating;
        const changedRelativeRating = firestore.FieldValue.increment(relativeDifference);
        return firestar_1.Actions.updateDocument(path, {
            'ratings.absoluteTotal': changedAbsoluteRating,
            'ratings.relativeTotal': changedRelativeRating,
        }, transaction);
    }
    Ratings.updateRatingsForMaterial = updateRatingsForMaterial;
    async function updateAverageForMaterial(materialId, absoluteAverage, relativeAverage, transaction) {
        const path = `materials/${materialId}`;
        return firestar_1.Actions.updateDocument(path, {
            'ratings.absoluteAverage': absoluteAverage,
            'ratings.relativeAverage': relativeAverage,
        }, transaction);
    }
    Ratings.updateAverageForMaterial = updateAverageForMaterial;
    function calculateRelativeRatings(value, userRatings) {
        var _a, _b, _c, _d, _e;
        if (value == 0)
            return 0;
        if (value == null)
            return undefined;
        let ratings = userRatings;
        if (ratings) {
            if (value > ((_a = ratings === null || ratings === void 0 ? void 0 : ratings.highest) !== null && _a !== void 0 ? _a : 0)) {
                ratings.highest = value;
            }
            if (value < ((_b = ratings === null || ratings === void 0 ? void 0 : ratings.lowest) !== null && _b !== void 0 ? _b : 0)) {
                ratings.lowest = value;
            }
        }
        else {
            ratings = {
                highest: value,
                lowest: value
            };
        }
        userRatings = ratings;
        const delta = ((_c = ratings === null || ratings === void 0 ? void 0 : ratings.highest) !== null && _c !== void 0 ? _c : 0) - ((_d = ratings === null || ratings === void 0 ? void 0 : ratings.lowest) !== null && _d !== void 0 ? _d : 0);
        const factor = (delta == 0) ? 1 : (9.9 / delta);
        let relative;
        if (factor == 1) {
            relative = value;
        }
        else {
            relative = 0.1 + (value - ((_e = ratings === null || ratings === void 0 ? void 0 : ratings.lowest) !== null && _e !== void 0 ? _e : 0)) * factor;
        }
        return Number(relative.toFixed(2));
    }
    Ratings.calculateRelativeRatings = calculateRelativeRatings;
    function getDefaultRatings() {
        return {
            numberOfRatings: 0,
            absoluteAverage: 0,
            relativeAverage: 0,
            relativeTotal: 0,
            absoluteTotal: 0
        };
    }
    Ratings.getDefaultRatings = getDefaultRatings;
})(Ratings || (Ratings = {}));
exports.default = Ratings;
