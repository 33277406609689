"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePromotion = exports.createPromotion = exports.getUpcomingPromotions = exports.getActivePromotions = exports.getPromotions = exports.PromotionRegionTypes = exports.PromotionTypes = void 0;
const firestar_1 = __importStar(require("firestar"));
exports.PromotionTypes = {
    'material-of-the-month': 'Material of the Month',
    'gin-of-the-month': 'Gin of the Month',
    'tonic-of-the-month': 'Tonic of the Month',
    'custom': 'Custom'
};
exports.PromotionRegionTypes = {
    'all': 'All'
};
var Promotions;
(function (Promotions) {
    function getAll(descriptor) {
        return firestar_1.Actions.readCollection('promotions', descriptor);
    }
    Promotions.getAll = getAll;
    function getActivePromotions(region, lastDoc, readOptions) {
        return new Promise(async (resolve, reject) => {
            try {
                const firestore = firestar_1.default.getFirestore();
                const response = await firestar_1.Actions.readCollection('promotions', {
                    where: [
                        {
                            fieldPath: 'endDate',
                            opStr: '>=',
                            value: firestore.Timestamp.now()
                        }
                    ],
                    lastDoc: lastDoc,
                    limit: 1000
                }, readOptions);
                const promotions = response.data;
                const filtered = promotions.filter((p) => {
                    const startTime = p.startDate.toDate();
                    const nowTime = firestore.Timestamp.now().toDate();
                    return nowTime >= startTime;
                }).filter((p) => {
                    var _a;
                    const regions = (_a = p.regions) !== null && _a !== void 0 ? _a : [];
                    return regions.includes(region);
                });
                response.data = filtered;
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Promotions.getActivePromotions = getActivePromotions;
})(Promotions || (Promotions = {}));
exports.default = Promotions;
function getPromotions(limit, lastDoc) {
    return firestar_1.Actions.readCollection('promotions', {
        lastDoc: lastDoc,
        limit: limit,
        orderBy: {
            fieldPath: 'endDate',
            directionStr: 'desc'
        }
    });
}
exports.getPromotions = getPromotions;
function getActivePromotions() {
    return new Promise((resolve, reject) => {
        const firestore = firestar_1.default.getFirestore();
        firestar_1.Actions.readCollection('promotions', {
            where: [
                {
                    fieldPath: 'endDate',
                    opStr: '>=',
                    value: firestore.Timestamp.now()
                }
            ]
        })
            .then((response) => {
            const promotions = response.data;
            const filtered = promotions.filter((p) => {
                const startTime = p.startDate.toDate();
                const nowTime = firestore.Timestamp.now().toDate();
                return nowTime >= startTime;
            });
            resolve(filtered);
        })
            .catch((error) => reject(error));
    });
}
exports.getActivePromotions = getActivePromotions;
function getUpcomingPromotions() {
    return new Promise((resolve, reject) => {
        const firestore = firestar_1.default.getFirestore();
        return firestar_1.Actions.readCollection('promotions', {
            where: [
                {
                    fieldPath: 'startDate',
                    opStr: '>',
                    value: firestore.Timestamp.now()
                }
            ]
        })
            .then((response) => {
            const promotions = response.data;
            resolve(promotions);
        });
    });
}
exports.getUpcomingPromotions = getUpcomingPromotions;
function createPromotion(promotion) {
    return firestar_1.Actions.addToCollection('/promotions', promotion);
}
exports.createPromotion = createPromotion;
function updatePromotion(promotion) {
    return firestar_1.Actions.updateDocument('/promotions/' + promotion.id, promotion);
}
exports.updatePromotion = updatePromotion;
