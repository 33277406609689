import React, { memo, useState } from "react";
import RichTextEditor from "react-rte";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_ALIGNMENT_BUTTONS",
    "LINK_BUTTONS",
    "IMAGE_BUTTON",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    // { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    {label: 'Align Left', style: 'ALIGN_LEFT'},
    {label: 'Align Center', style: 'ALIGN_CENTER'},
    {label: 'Align Right', style: 'ALIGN_RIGHT'},
    {label: 'Align Justify', style: 'ALIGN_JUSTIFY'},  
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    // {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'},
    // {label: 'Code Block', style: 'code-block'},  
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    {label: 'Blockquote', style: 'blockquote'},
  ],
};

function TextEditor(props: {
  defaultValue?: string;
  onChangeText: (text: string) => void;
}) {
  const { defaultValue, onChangeText } = props;
  const [editorState, setEditorState] = useState(
    RichTextEditor.createValueFromString(defaultValue ?? '', "html")
  );

  return (
    <RichTextEditor
        value={editorState}
        toolbarConfig={toolbarConfig}
        onChange={(value) => {
            setEditorState(value)
            onChangeText(value.toString('html'))
        }}
    />
  )
}

export default memo(TextEditor);
