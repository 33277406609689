import React, { useState, useEffect } from 'react'
import MaterialTable, 
   { 
      Column   } from 'material-table'
import Paper from '@material-ui/core/Paper'
import { RecommendedBy } from 'gf-connect/lib/Recipe'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip';
import {
   Icon} from 'react-native-elements'
import { OrderType } from 'firestar'
import {
   Link,
   useHistory
} from 'react-router-dom'
import firebase from 'firebase/compat/app'
import lodash from 'lodash'
import TextEditor from './components/TextEditor'
import {
   useDispatch
} from 'react-redux';
import { View, Text } from 'react-native'
import {
   Recipe
} from './store/Recipes';
import { Recipes } from 'gf-connect';
import { SectionPanel } from './MaterialsBeta';
import { RelatedLinkOptions } from 'gf-connect/lib/Material';
import Materials from 'gf-connect/lib/Materials';
import {
	getDiff,
   flattenObject
} from 'firestar/lib/Utils'
import { exportListToCSV } from 'gf-connect/lib/Utils';

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

const columns : Column<Recipe>[] = [
   // {
   //    headerStyle: { fontWeight: 'bold' },
   //    editable: 'never',
   //    title: 'Image',
   //    filtering: false,
   //    sorting: false,
   //    render: (recipe) => (
   //       <div style={{
   //          textAlign: 'center'
   //       }}>
   //          <img alt={recipe.name} style={{
   //             width: 60,
   //             height: 60,
   //             objectFit: 'contain'
   //          }} src={recipe.thumbnail} />
   //       </div>
   //    )
   // },
   {
      title : 'Name',
      render : (recipe) => (
         <div>
            <div style={{ fontSize: 12 }}>{recipe.id}</div>
            <div style={{
               display : 'flex',
               flexWrap : 'wrap'
            }}>{(recipe.combinations ?? []).map(c => <Chip style={{
               marginRight : 5,
               marginBottom : 5
            }} size="small" label={c.name}/>)}
            </div>
         </div>
      )
   },
   {
      field : 'recommendedBy',
      title : 'Type',
      lookup : RecommendedBy
   },
   {
      headerStyle: { fontWeight: 'bold' },
      editable: 'never',
      title: 'SEO',
      filtering: false,
      sorting: false,
      render: (m) => (
         <div>
            <label>🔍: {m.meta ? '✅' : '🚫'}</label><br/>
            <label>🔗: {(m.relatedLinks ?? []).length}</label>
         </div>
      )
   }
]

function SortOptions() {
   return {
      'updatedAt': 'Updated At',
      'createdAt': 'Created At',
      'ratings.numberOfRatings': 'Number of Ratings',
      'ratings.absoluteAverage': 'Absolute Average'
   }
}

interface Props {
   incomingRecipes? : Recipe[]
}

export default function RecipesList(props:Props) {

   // const recipes = useSelector((state:AppState) => state.recipes)
   const [recipes, setRecipes] = useState<Recipes.Recipe[]>([])
   const [showLoader, setShowLoader] = useState(false)
   const [sortFilter, setSortFilter] = useState<OrderType>({
      fieldPath: 'updatedAt',
      directionStr: 'desc'
   })
   const dispatch = useDispatch()
   const history = useHistory()
   let tableRef : any

   useEffect(() => {
      fetchRecipes()
      // !props.incomingRecipes && dispatch(getRecipes())
   }, [sortFilter])

   return (
      <div>
         {!props.incomingRecipes && <div style={{
            display : 'flex',
            justifyContent : 'space-between',
            marginBottom : 10,            
         }}> 
            <View>
               <p>
                  <Link to='/recipes/search' style={{ textDecoration : 'none' }}>
                     <Button variant='outlined'>SEARCH RECIPE</Button>
                  </Link>
               </p>
               <p>
                  <Link to='/recipes/add-recipe' style={{ textDecoration : 'none' }}>
                     <Button variant='outlined'>ADD RECIPE</Button>
                  </Link>
               </p>
            </View>
            <View>
               <select style={{
                     padding: 15,
                     fontSize: 16,
                     borderWidth: 0,
                     marginRight: 15,
                     textAlign: 'right',
                     background: 'transparent'
                  }} value={sortFilter.fieldPath as string} onChange={onChangeSortFilter}>
                     {Object.keys(SortOptions()).map((key) => {
                        return (
                           <option value={key}>Sort By: {SortOptions()[key]}</option>
                        )
                     })}
               </select>
               <button onClick={onPressExportAll}>Export All</button>
            </View>
         </div>}         
         <MaterialTable
            isLoading={showLoader}
            tableRef={ref => tableRef = ref}
            title='Recipes'
            options={{
               searchFieldVariant : 'outlined',
               searchFieldStyle : {
                  marginTop : 15,
                  marginRight : 10
               },
               emptyRowsWhenPaging : false,
               search : false,
               pageSizeOptions: [PAGESIZE],
               pageSize: PAGESIZE,
               filtering: false,
               addRowPosition: 'first'
            }}
            onOrderChange={onOrderChange}
            editable={{
               // isDeletable : (recipe) => (
               //    recipe.recommendedBy == 'ginferno' || 
               //    recipe.recommendedBy == 'owner'
               // ),
               // isEditable : (recipe) => (
               //    recipe.recommendedBy == 'ginferno' || 
               //    recipe.recommendedBy == 'owner'
               // ),
               // isEditHidden : (recipe) => recipe.recommendedBy == 'user',
               // isDeleteHidden : (recipe) => recipe.recommendedBy == 'user',
               // onRowDelete : onDeleteRecipe,
               onRowUpdate : onEditRecipe,
            }}
            detailPanel={(props) => <RecipeDetail onUpdateRecipe={onUpdateRecipe} recipe={props}/>}
            actions={
               [
                  // {
                  //    onClick : (_,recipe:any) => history.push(`/recipes/edit/${recipe.id}`),
                  //    icon : () => <EditIcon/>
                  // },
                  {
                     icon: 'link',
                     tooltip: 'Link',
                     onClick: (event, rowData) => {
                        const recipe = rowData as Recipes.Recipe
                        let url = `https://ginferno.app/recipe/${recipe.uri}`
                        if (recipe.uri != null) {
                           url = `https://ginferno.app/recipes/${recipe.id}`
                        }
                        window.open(url)
                     }
                  }
               ]
            }
            data={recipes}
            columns={[
               ...columns,
               {
                  field: 'id',
                  editable: 'never',
                  title: '',
                  render: (m) => (
                     <View style={{
                        flexDirection: 'row',
                        alignItems: 'center'
                     }}>
                        <Icon
                           onPress={() => window.open('https://admin.ginferno.app/playground/recipes/' + m.id)}
                           name='games' />
                     </View>
                  ),
                  sorting: true,
               },
               {
                  title: SortOptions()[sortFilter.fieldPath as string],
                  sorting: true,
                  render: (m) => {
                     const field = sortFilter.fieldPath as string
                     if (field == 'createdAt' || field == 'updatedAt') {
                        const timeStamp = m[field] as firebase.firestore.Timestamp
                        return (
                           <div>{timeStamp.toDate().toDateString()}</div>
                        )
                     } else {
                        return (
                           <div>{lodash.get(m, field)}</div>
                        )
                     }
                  },
                  defaultSort: sortFilter.directionStr,
                  align: 'center'
               }
            ]}
            />         
      </div>
   )

   function onUpdateRecipe(recipeId: string, recipe: Recipes.Recipe, oldRecipe: Recipes.Recipe){
      return new Promise<Recipes.Recipe>(async (resolve, reject) => {

         setShowLoader(true)
         try {

            const __recipes = [...recipes]

            const findIndex = __recipes.findIndex(m => m.id === recipeId)
   
            const changes = getDiff(recipe, oldRecipe)
            delete (changes as any).tableData;
            console.log('Changes', changes)
   
            const flattenedChanges = flattenObject(changes)
            console.log('flattenedChanges', flattenedChanges)
   
            const updateResponse = await Recipes.update(recipeId, flattenedChanges)
            console.log('Updated Changes', updateResponse)
   
            const response = await Recipes.get(recipeId)
            console.log('Update Material', response)
   
            const updatedRecipe = response as Materials.Material
   
            __recipes[findIndex] = updatedRecipe
   
            setRecipes(__recipes)
   
            resolve(updatedRecipe)

         } catch (error) {
            reject(error)
         }
         setShowLoader(false)
      })
   }

   function onEditRecipe (recipe){
      return new Promise<Recipe>((resolve,reject) => {

      })
   }

   function onDeleteRecipe (recipe){
      return new Promise<Recipe>((resolve,reject) => {

      })
   }

   function onChangeSortFilter(event) {
      setSortFilter({
         fieldPath: event.target.value,
         directionStr: 'desc'
      })
   }

   function onOrderChange(_: number, orderDirection: "asc" | "desc") {
      if (orderDirection.length > 0) {
         setSortFilter({
            ...sortFilter,
            directionStr: orderDirection
         })
      } else {
         setSortFilter({
            ...sortFilter,
            directionStr: 'asc'
         })
      }
   }

   async function fetchRecipes(){
      setShowLoader(true)
      try {
         const response = await Recipes.getAll({
            limit: INITIAL_PAGESIZE,
            orderBy: sortFilter
         })
         setRecipes(response.data)
      } catch (error) {
         
      }
      setShowLoader(false)
   }

   async function onPressExportAll(){
      setShowLoader(true)
      try {
         const response = await Recipes.getAll({
            limit: 10000
         })

         exportListToCSV(response.data, `Recipes_${new Date().toString()}_${response.data.length}`, [
            'id',
            'combinations',
            'type',
            'ratings',
            'updatedAt_seconds',
            'updatedAt_nanoseconds',
            'updatedBy_email',
            'createdAt_seconds',
            'createdAt_nanoseconds',
            'createdBy_email',             
         ])

         setRecipes(response.data)
      } catch (error) {
         
      }
      setShowLoader(false)
   }
}

export function RecipeDetail(props:{
   recipe: Recipes.Recipe,
   onUpdateRecipe: (recipeId: string, recipe: Recipes.Recipe, oldRecipe: Recipes.Recipe) => Promise<Recipes.Recipe>
}){

   const [isEditing, setIsEditing] = useState(false)
   const [recipe, setRecipe] = useState<Recipes.Recipe>(lodash.cloneDeep(props.recipe))
   const [showLoader, setShowLoader] = useState(false)
   const [htmlEditor, setHtmlEditor] = useState(false)

   return (
      <div style={{
         padding: '1em'
      }}>
      <View style={{
         alignItems: 'flex-start',
         padding: '1em'
      }}>
         {isEditing == true ? (
            <View style={{
               flexDirection: 'row',
               alignItems: 'center'
            }}>
               <Icon
                  style={{
                     marginRight: 15
                  }}
                  onPress={onPressCancelEdit}
                  name='cancel' />
               <Icon
                  disabledStyle={{
                     backgroundColor: 'white',
                     opacity: 0.3
                  }}
                  disabled={lodash.isEqual(recipe, props.recipe)}
                  onPress={onPressSave}
                  name='save' />
            </View>
         ) : (
            <Icon
               onPress={onPressEdit}
               name='edit' />
         )}
      </View>
      <SectionPanel
            title='Description'
            render={() => {
               const description = recipe.description
               return (
                  <View style={{
                     width: 600
                  }}>
                     {isEditing && <button 
                        style={{ width: 100 }}
                        onClick={() => setHtmlEditor(!htmlEditor)}>{htmlEditor ? 'EDIT HTML' : 'EDIT TEXT'}</button>}
                     <br/>
                     {isEditing ? (
                        <div>
                           {htmlEditor ? 
                              <textarea
                                 style={{
                                    marginBottom: 5,
                                    minWidth: '100%',
                                    fontFamily: 'sans-serif',
                                    minHeight: 300,
                                    lineHeight: 1.3,
                                    padding: 15,
                                    background: 'white'
                                 }}
                                 value={description}
                                 onChange={(e) => {
                                    onChangeValue(`description`, e.target.value)
                                 }} /> : 
                              <TextEditor 
                                 onChangeText={(text) => onChangeValue('description', text)}
                                 defaultValue={description}/>}
                        </div>
                     ) : (
                        <Paper variant='outlined' style={{
                           padding: '1em'
                        }}>
                           <div dangerouslySetInnerHTML={{ __html: description ?? '' }}></div>
                        </Paper>
                     )}                              
                  </View>
               )
            }} />
      <SectionPanel
         title='SEO'
         render={() => {
            return (                                 
               <div>
                  <label>Title</label><br/>
                  <input
                     style={{ minWidth: 350 }}
                     disabled={isEditing == false}
                     value={recipe.meta?.title}
                     placeholder="Meta Title"
                     onChange={(e) => {
                        const value = e.target.value
                        onChangeValue('meta.title', value)
                     }} />
                  <p>
                  <label>Description</label><br/>
                  <textarea
                     style={{ minWidth: 350, minHeight: 150 }}
                     disabled={isEditing == false}
                     value={recipe.meta?.description}
                     placeholder="Meta Description"
                     onChange={(e) => {
                        const value = e.target.value
                        onChangeValue('meta.description', value)
                     }} />
                  </p>
                  <p>
                  <label>Robots</label><br/>
                  <input
                     style={{ minWidth: 350 }}
                     disabled={isEditing == false}
                     value={recipe.meta?.robots}
                     placeholder="Meta Robots"
                     onChange={(e) => {
                        const value = e.target.value
                        onChangeValue('meta.robots', value)
                     }} />
                  </p>
               </div>
            )
         }} />
         <SectionPanel
            title='Related Links'
            render={() => {
               return (                                 
                  <div>
                     <MaterialTable 
                        title={''}
                        options={{
                           search: false,
                        }}
                        style={{
                           borderWidth: 0,   
                           boxShadow: 'none',                                             
                        }}
                        components={{
                           Pagination: () => null,
                        }}
                        editable={isEditing ? {
                           onRowAdd: onAddRelatedLink,
                           onRowDelete: onDeleteRelatedLink,
                           onRowUpdate: onUpdateRelatedLink
                        } : undefined}
                        columns={[
                           {
                              title: 'Title',
                              field: 'title'
                           },
                           {
                              title: 'Description',
                              field: 'description'
                           },
                           {
                              title: 'Type',
                              field: 'type',
                              lookup: RelatedLinkOptions
                           },
                           {
                              title: 'Link',
                              field: 'url',
                              render: (r) => <a target={'_blank'} href={r.url}>Open</a>,
                              editable: 'onAdd'
                           },
                           {
                              title: 'Image Url',
                              field: 'imageUrl',
                              render: (r) => {r.imageUrl != null ? <a target={'_blank'} href={r.imageUrl}>Open</a> : <div/>},
                              editable: 'always'
                           }
                        ]}
                        data={recipe?.relatedLinks ?? []}/>
                  </div>
               )
            }} />
            <SectionPanel
               title='Ratings'
               render={() => {
                  const ratings = recipe.ratings
                  const totalReviews = recipe.totalReviews
                  return (
                     <View style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                     }}>
                        <Text style={{ margin: 10 }}>Total Reviews : {totalReviews}</Text>
                        <Text style={{ margin: 10 }}>Number Of Ratings : {ratings?.numberOfRatings}</Text>
                        <Text style={{ margin: 10 }}>Absolute Average : {ratings?.absoluteAverage}</Text>
                        <Text style={{ margin: 10 }}>Absolute Total : {ratings?.absoluteTotal}</Text>
                        <Text style={{ margin: 10 }}>Relative Average : {ratings?.relativeAverage}</Text>
                        <Text style={{ margin: 10 }}>Relative Total : {ratings?.relativeTotal}</Text>
                     </View>
                  )
               }} />
      </div>
   )

   function onChangeValue(path: string, value: any) {
      const __recipe = { ...recipe }
      lodash.set(__recipe, path, value)
      setRecipe(__recipe)
   }

   function onAddRelatedLink(link: Materials.RelatedLink){
      return new Promise<void>((resolve, reject) => {
         try {
            const relatedLinks = recipe.relatedLinks ?? []
            relatedLinks.push({
               description: link.description ?? null,
               title: link.title ?? null,
               type: link.type,
               url: link.url,
               imageUrl: link.imageUrl ?? null
            })
            onChangeValue('relatedLinks', relatedLinks)
         } catch (error) {
            
         }
         resolve()
      })
   }

   function onDeleteRelatedLink(link: Materials.RelatedLink){
      return new Promise<void>((resolve, reject) => {
         try {
            const relatedLinks = recipe.relatedLinks ?? []
            const index = relatedLinks.findIndex((r) => r.url)
            if(index >= 0){
               relatedLinks.splice(index, 1)
            }
            onChangeValue('relatedLinks', relatedLinks)
         } catch (error) {
            
         }
         resolve()
      })
   }


   function onPressCancelEdit() {
      setIsEditing(false)
      setRecipe(lodash.cloneDeep(props.recipe))
   }

   function onPressEdit() {
      setIsEditing(true)
   }

   async function onPressSave() {
      setShowLoader(true)
      try {
         const response = await props.onUpdateRecipe(recipe.id, recipe, props.recipe)
         setRecipe(lodash.cloneDeep(response))
         setIsEditing(false)
      } catch (error) {

      }
      setShowLoader(false)
   }

   function onUpdateRelatedLink(link: Materials.RelatedLink){
      return new Promise<void>((resolve, reject) => {
         try {
            const relatedLinks = recipe.relatedLinks ?? []
            const index = relatedLinks.findIndex((r) => r.url)
            if(index >= 0){
               relatedLinks[index] = {
                  description: link.description ?? null,
                  title: link.title ?? null,
                  type: link.type,
                  url: link.url,
                  imageUrl: link.imageUrl ?? null
               }
            }
            onChangeValue('relatedLinks', relatedLinks)
         } catch (error) {
            
         }
         resolve()
      })
   }
}