import { View, Text, ActivityIndicator } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
   Recipes, Reviews
} from 'gf-connect'
import { Recipe } from 'gf-connect/lib/Recipe'
import MaterialTable from 'material-table'
import { Alert } from './Alert'
import { Review } from 'gf-connect/lib/Review'
import { Rating } from 'gf-connect/lib/Ratings'
import { Icon } from 'react-native-elements'
import moment from 'moment'

const INITIAL_PAGESIZE = 50
const PAGESIZE = 30

export default function RecipeHealth() {

   const params = useParams() as any
   const [recipes, setRecipes] = useState<Recipe[]>([])
   const materialId = params.materialId
   const [showLoader, setShowLoader] = useState(false)

   useEffect(() => {
      fetchRecipes()
   }, [])

   async function fetchRecipes() {
      setShowLoader(true)
      try {
         const response = await Recipes.getAll({
            where: [
               {
                  fieldPath: 'materialId',
                  opStr: '==',
                  value: materialId
               }
            ],
            orderBy: {
               fieldPath: 'ratings.numberOfRatings',
               directionStr: 'desc'
            },
            limit: INITIAL_PAGESIZE
         })
         console.log('Response : ', materialId, response.data)
         setRecipes(response.data)
      } catch (error) {
         Alert.alert('Error Fetching Recipes', String(error))
         console.log('Error Fetching Recipes: ', error)
      }
      setShowLoader(false)
   }

   return (
      <View>
         <MaterialTable
            title={`Recipes (${recipes.length})`}
            options={{
               pageSize: PAGESIZE,
               search: false,
               rowStyle: {
                  background: '#f0d2aa'
               },
               pageSizeOptions: [PAGESIZE]
            }}
            isLoading={showLoader}
            data={recipes}
            columns={[
               {
                  title: 'Id',
                  field: 'id'
               },
               {
                  title: 'Combinations',
                  render: (recipe) =>
                     (recipe.combinations?.map(c => c.name).join(' + '))
               },
               {
                  title: 'Absolute Total',
                  field: 'ratings.absoluteTotal',
                  render: (recipe) => recipe.ratings?.absoluteTotal?.toFixed(1)
               },
               {
                  title: 'Number Of Ratings',
                  field: 'ratings.numberOfRatings'
               },
               {
                  title: 'Absolute Average',
                  field: 'ratings.absoluteAverage',
                  render: (recipe) => recipe.ratings?.absoluteAverage?.toFixed(1)
               },
               {
                  title: 'Average Check',
                  render: (recipe) => {
                     if ((recipe.ratings?.numberOfRatings ?? 0) === 0) { return 'No Ratings' }
                     const calculatedAverage = (recipe.ratings?.absoluteTotal ?? 0) / (recipe.ratings?.numberOfRatings ?? 0)
                     const absoluteAverage = recipe.ratings?.absoluteAverage ?? 0
                     if (parseFloat(absoluteAverage?.toFixed(2)) === parseFloat(calculatedAverage.toFixed(2))) {
                        return '✅'
                     } else {
                        return (
                           <div>
                              <p>{`❌ => `}{calculatedAverage} != {absoluteAverage}</p>
                           </div>
                        )
                     }
                  }
               },
               {
                  title: 'Updated At',
                  render: (recipe) => moment(recipe.updatedAt.toDate()).format('hh:mm A, DD/MM/YYYY')
               },
               {
                  title: 'Created At',
                  render: (recipe) => moment(recipe.createdAt.toDate()).format('hh:mm A, DD/MM/YYYY')
               },
               {
                  field: 'id',
                  editable: 'never',
                  render: (r) => (
                     <Icon
                        onPress={() => window.open(`https://admin.ginferno.app/playground/recipes/${r.id}`)}
                        name='games'/>
                  ),
               }
            ]}
            detailPanel={(rowData) => (
               <RecipeDetail 
                  ratings={rowData.ratings}
                  recipeId={rowData.id} />
            )} />
      </View>
   )
}

function RecipeDetail(props: {
   recipeId: string,
   ratings?: Rating
}) {

   const [reviews, setReviews] = useState<Review[]>([])
   const [showLoader, setShowLoader] = useState(false)
   const { recipeId, ratings } = props

   const [numberOfRatings, setNumberOfRatings] = useState(0)
   const [absoluteTotal, setAbsoluteTotal] = useState(0)
   const [absoluteAverage, setAbsoluteAverage] = useState<number>(0)

   useEffect(() => {
      fetchReviews()
   }, [])

   async function fetchReviews() {

      setShowLoader(true)
      try {
         const response = await Reviews.getAll(recipeId,{
            limit: 10000,
            orderBy: {
               fieldPath: 'createdAt',
               directionStr: 'desc'
            }
         })
         setReviews(response.data)
      } catch (error) {
         Alert.alert('Error Getting Review : ' + String(error))
         console.log('Error Getting Review : ', error)
      }
      setShowLoader(false)
   }

   useEffect(() => {

      if(reviews.length > 0){
         const __totalNumberOfRatings = reviews.reduce(
            (previous, current) => previous + ((current.absoluteRating ?? 0) > 0 ? 1 : 0)
         ,0)
         setNumberOfRatings(__totalNumberOfRatings)
   
         const __absoluteTotal = reviews.reduce(
            (previous, current) => previous + ((current.absoluteRating ?? 0) > 0 ? (current.absoluteRating ?? 0) : 0)
         , 0)
         setAbsoluteTotal(__absoluteTotal)      
      }
   }, [reviews])

   useEffect(() => {
     
      if(absoluteTotal > 0 && numberOfRatings > 0){
         const average = (absoluteTotal ?? 0) / (numberOfRatings ?? 0)
         const __absoluteAverage = parseFloat(average.toFixed(2))
         setAbsoluteAverage(__absoluteAverage)
      }
   }, [absoluteTotal, numberOfRatings])

   if (showLoader == true) return (
      <View>
         <ActivityIndicator />
      </View>
   )

   return (
      <View style={{
         padding: 15
      }}>
         {reviews.length > 0 && <View style={{
            marginBottom: 10
         }}>
            <Text>Number Of Ratings:
               {parseFloat(numberOfRatings.toFixed(2)) === parseFloat((ratings?.numberOfRatings ?? 0).toFixed(2)) ? '✅'
                  : `❌ => ${ratings?.numberOfRatings} != ${numberOfRatings}`}
            </Text>
            <Text>Absolute Total:
               {parseFloat(absoluteTotal.toFixed(2)) === parseFloat((ratings?.absoluteTotal ?? 0).toFixed(2)) ? '✅'
                  : `❌ => ${ratings?.absoluteTotal} != ${absoluteTotal}`}
            </Text>
            <Text>Absolute Average:
               {parseFloat(absoluteAverage.toFixed(2)) === parseFloat((ratings?.absoluteAverage ?? 0).toFixed(2)) ? '✅'
                  : `❌ => ${ratings?.absoluteAverage} != ${absoluteAverage}`}
            </Text>
         </View>}
         <MaterialTable
            isLoading={showLoader}
            title={`Reviews ${absoluteTotal}/${numberOfRatings}=${absoluteAverage}`}
            options={{
               pageSize: reviews.length,
               search: false,
               rowStyle: {
                  background: '#f199aa'
               },
               pageSizeOptions: [reviews.length]
            }}
            columns={
               [
                  {
                     title: 'Id',
                     field: 'id'
                  },
                  {
                     title: 'UserId',
                     field: 'createdBy.id'
                  },
                  {
                     title: 'Review By Name',
                     field: 'createdBy.displayName',
                     emptyValue: (review) => review.createdBy.email
                  },
                  {
                     title: 'Absolute Rating',
                     field: 'absoluteRating'
                  },
                  {
                     title: 'Created At',
                     render: (review) => moment(review.createdAt.toDate()).format('hh:mm A, DD/MM/YYYY')
                  },
                  {
                     field: 'id',
                     editable: 'never',
                     render: (r) => (
                        <Icon
                           onPress={() => window.open(`https://admin.ginferno.app/playground/recipes/${recipeId}/reviews/${r.id}`)}
                           name='games'/>
                     ),
                  },
               ]
            }
            data={reviews} />
      </View>
   )
}