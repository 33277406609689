"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = require("firestar");
const keys = {
    'BANNER_CLICK': 'Banner Click'
};
var Analytics;
(function (Analytics) {
    async function logEvent(name, params) {
        try {
            await firestar_1.Actions.addToCollection(`analytics/${name}/data`, Object.assign(Object.assign({}, params), { id: null }));
        }
        catch (error) {
            console.log('Error Logging Event : ', error);
        }
    }
    Analytics.logEvent = logEvent;
    async function getEventsById(eventId, descriptor) {
        return firestar_1.Actions.readCollection(`analytics/${eventId}/data`, descriptor);
    }
    Analytics.getEventsById = getEventsById;
    async function getAllEventsById(eventId, descriptor) {
        return firestar_1.Actions.readFullCollection(`analytics/${eventId}/data`, descriptor);
    }
    Analytics.getAllEventsById = getAllEventsById;
    function updateCompanies(document) {
        return firestar_1.Actions.writeDocument('analytics/companies', document);
    }
    Analytics.updateCompanies = updateCompanies;
    function getCompanies() {
        return firestar_1.Actions.readDocument('analytics/companies');
    }
    Analytics.getCompanies = getCompanies;
    function updateMaterials(document) {
        return firestar_1.Actions.writeDocument('analytics/materials', document);
    }
    Analytics.updateMaterials = updateMaterials;
    function getMaterials() {
        return firestar_1.Actions.readDocument('analytics/materials');
    }
    Analytics.getMaterials = getMaterials;
    function updateUsers(document) {
        return firestar_1.Actions.writeDocument('analytics/users', document);
    }
    Analytics.updateUsers = updateUsers;
    function getUsers() {
        return firestar_1.Actions.readDocument('analytics/users');
    }
    Analytics.getUsers = getUsers;
    function updateRecipes(document) {
        return firestar_1.Actions.writeDocument('analytics/recipes', document);
    }
    Analytics.updateRecipes = updateRecipes;
    function getRecipes() {
        return firestar_1.Actions.readDocument('analytics/recipes');
    }
    Analytics.getRecipes = getRecipes;
})(Analytics || (Analytics = {}));
exports.default = Analytics;
