import {
   combineReducers
} from 'redux'

import Companies from './Companies';
import Materials from './Materials';
import Settings from './Settings'
import Recipes from './Recipes'
import Categories from './Categories'
import User from './User'
import Reviews from './Reviews'
import SavedCategories from './SavedCategories'
import Favorites from './Favorites'
import Cabinet from './Cabinet'
import Promotions from './Promotions'
import Rooms from './Rooms'
import Linking from './Linking'

export interface Change {
   type : ActionTypes,
   payload : any
}

const appReducer = combineReducers({
   companies : Companies,
   materials : Materials,
   settings : Settings,
   recipes : Recipes,
   categories : Categories,
   user : User,
   reviews : Reviews,
   savedFavorites: Favorites,
   savedCategories: SavedCategories,
   cabinet: Cabinet,
   promotions: Promotions,
   rooms: Rooms,
   linking: Linking
})

export default (state={}, action:Change) => {
   switch (action.type) {
       default:
           return appReducer(state as any,action)
   }
}

export type AppState = ReturnType<typeof appReducer>

type ActionTypes = 
'SIGN_OUT' | 
'SYNC_COMPANIES' | 
'SYNC_MATERIALS' | 
'UPDATE_MATERIAL' | 
'UPDATE_SETTINGS' | 
'SYNC_RECIPES' | 
'DELETE_RECIPES' | 
'SYNC_GIN_CATEGORIES' | 
'SYNC_MIXER_CATEGORIES' | 
'GET_USER_DETAILS' | 
'SYNC_REVIEWS' | 
'DELETE_REVIEW' | 
'ADD_TO_RECENT_SEARCHES' | 
'CLEAR_MATERIALS' | 
'CLEAR_CATEGORIES' | 
'SYNC_SAVED_CATEGORIES' |
'DELETE_SAVED_CATEGORY' | 
'SYNC_FAVORITES' |
'DELETE_FAVORITE' | 
'SYNC_CABINET' |
'DELETE_FROM_CABINET' | 
'SYNC_SAVED_RECIPES' |
'DELETE_SAVED_RECIPE' | 
'SET_USER_DETAILS' | 
'SYNC_PROMOTIONS' | 
'CLEAR_PROMOTIONS' |
'SYNC_ROOMS' | 
'CLEAR_ROOMS' | 
'DELETE_RECIPES_IDS' |
'SET_REDIRECT_URL' |
'CLEAR_RECIPES'