import { Change, AppState } from '.'

import {
   User as UserType,
   createUser,
   updateUser,
   getUser,
   setUser
} from 'gf-connect/lib/User'
import firebase from 'firebase/compat/app'

export type User = UserType
const initialState : User | null = null

export default function _(state = initialState, action: Change) {
   switch (action .type) {
      case 'GET_USER_DETAILS' : {
         return {
            ...state,
            ...action.payload
         }
      }
      case 'SET_USER_DETAILS' : {
         return action.payload
      }
      case 'ADD_TO_RECENT_SEARCHES' : {
         const user = {...state} as User
         let recentSearches = [...user?.recentSearches ?? []]
         const searchText : string = action.payload
         if(!recentSearches.includes(searchText)){
            recentSearches = [searchText,...recentSearches]
         }else{
            recentSearches = [searchText,...recentSearches.filter((s) => s != searchText)]
         }
         return {
            ...user,
            recentSearches : recentSearches.slice(0,5)
         } as User
      }
      case 'SIGN_OUT' : {
         return null
      }
      default:
         return state ?? null
   }
}

// export function createNewUser(){
//    return (dispatch: (change: Change) => void, getState: () => AppState) => {
//       return new Promise<User>((resolve, reject) => {
//          createUser()
//          .then(
//             (userDetails) => {
//                dispatch({
//                   payload : userDetails,
//                   type : 'GET_USER_DETAILS'
//                })
//                resolve(userDetails)
//             }
//          )
//          .catch(error => reject(error))
//       })
//    }
// }

export function setUserDetails(user?:Partial<User>){
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<User>((resolve, reject) => {
         setUser(user)
         .then(
            (userDetails) => {
               dispatch({
                  payload : userDetails,
                  type : 'GET_USER_DETAILS'
               })
               resolve(userDetails)
            }
         )
         .catch(error => reject(error))
      })
   }
}

export function updateUserDetails(user:User) : any{
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<User>((resolve, reject) => {
         user.id =  firebase.auth().currentUser?.uid!   
         if(user.tags == null){
            user.tags = []
         }     
         updateUser(user)
         .then(
            (userDetails) => {
               dispatch({
                  payload : userDetails,
                  type : 'GET_USER_DETAILS'
               })
               resolve(userDetails)
            }
         )
         .catch(error => reject(error))
      })
   }
}

export function getUserDetails() : any {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<User>((resolve, reject) => {
         getUser(firebase.auth().currentUser?.uid!)
         .then(
            (userDetails) => {
               if(userDetails){
                  dispatch({
                     payload : userDetails,
                     type : 'SET_USER_DETAILS'
                  })
               }
               resolve(userDetails)
            }
         )
         .catch(error => reject(error))
      })
   }
}

export function addToRecentSearches(searchText:string){
   return {
      payload: searchText.trim(),
      type : 'ADD_TO_RECENT_SEARCHES'
   } as Change
}

export function completeSignOut(){
   return {
      payload: null,
      type: 'SIGN_OUT'
   } as Change
}