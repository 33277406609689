"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ignoredCategories = exports.tertiaryGarnishKinds = exports.secondaryGarnishKinds = exports.primaryGarnishKinds = exports.tertiaryMixerKinds = exports.secondaryMixerKinds = exports.primaryMixerKinds = exports.tertiaryGinKinds = exports.secondaryGinKinds = exports.primaryGinKinds = exports.getMixerCategories = exports.getGinCategories = exports.updateCountForCategory = exports.updateCategory = exports.updateCategories = exports.summarizeCategory = exports.CategoryTypes = void 0;
const firestar_1 = require("firestar");
const Material_1 = require("./Material");
exports.CategoryTypes = {
    'gins': 'Gins',
    'mixers': 'Mixers'
};
const collectionPath = 'categories/materials';
function summarizeCategory(category) {
    var _a, _b, _c, _d;
    return {
        id: (_a = category.id) !== null && _a !== void 0 ? _a : '',
        name: (_b = category.name) !== null && _b !== void 0 ? _b : '',
        field: (_c = category.field) !== null && _c !== void 0 ? _c : '',
        type: (_d = category.type) !== null && _d !== void 0 ? _d : '',
    };
}
exports.summarizeCategory = summarizeCategory;
function updateCategories(material, previousMaterial, deletion, batch) {
    return new Promise(async (resolve, reject) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
        const categoryType = material.type == 'gin' ? 'gins' : 'mixers';
        try {
            if (previousMaterial) {
                if (previousMaterial.type != material.type) {
                    (0, Material_1.updateMaterialCount)(previousMaterial.type, -1, batch);
                    (0, Material_1.updateMaterialCount)(material.type, 1, batch);
                }
            }
            else {
                if (material.type) {
                    (0, Material_1.updateMaterialCount)(material.type, 1, batch);
                }
            }
            if (material.type == 'gin') {
                if (previousMaterial) {
                    if (((_a = previousMaterial.kinds) === null || _a === void 0 ? void 0 : _a.one.id) != ((_b = material.kinds) === null || _b === void 0 ? void 0 : _b.one.id)) {
                        updateCountForCategory(categoryType, (_c = material.kinds) === null || _c === void 0 ? void 0 : _c.one, 1, 'kinds.one', batch);
                        updateCountForCategory(categoryType, (_d = previousMaterial.kinds) === null || _d === void 0 ? void 0 : _d.one, -1, 'kinds.one', batch);
                    }
                    if (((_e = previousMaterial.kinds) === null || _e === void 0 ? void 0 : _e.two.id) != ((_f = material.kinds) === null || _f === void 0 ? void 0 : _f.two.id)) {
                        updateCountForCategory(categoryType, (_g = material.kinds) === null || _g === void 0 ? void 0 : _g.two, 1, 'kinds.two', batch);
                        updateCountForCategory(categoryType, (_h = previousMaterial.kinds) === null || _h === void 0 ? void 0 : _h.two, -1, 'kinds.two', batch);
                    }
                    if (((_l = (_k = (_j = previousMaterial.kinds) === null || _j === void 0 ? void 0 : _j.four) === null || _k === void 0 ? void 0 : _k.id) !== null && _l !== void 0 ? _l : '') != ((_p = (_o = (_m = material.kinds) === null || _m === void 0 ? void 0 : _m.four) === null || _o === void 0 ? void 0 : _o.id) !== null && _p !== void 0 ? _p : '')) {
                        ((_q = material.kinds) === null || _q === void 0 ? void 0 : _q.four) && updateCountForCategory(categoryType, (_r = material.kinds) === null || _r === void 0 ? void 0 : _r.four, 1, 'kinds.four', batch);
                        ((_s = previousMaterial.kinds) === null || _s === void 0 ? void 0 : _s.four) && updateCountForCategory(categoryType, (_t = previousMaterial.kinds) === null || _t === void 0 ? void 0 : _t.four, -1, 'kinds.four', batch);
                    }
                }
                else {
                    if (((_w = (_v = (_u = material.kinds) === null || _u === void 0 ? void 0 : _u.one) === null || _v === void 0 ? void 0 : _v.id) !== null && _w !== void 0 ? _w : '').length > 0)
                        updateCountForCategory(categoryType, (_x = material.kinds) === null || _x === void 0 ? void 0 : _x.one, deletion ? -1 : 1, 'kinds.one', batch);
                    if (((_0 = (_z = (_y = material.kinds) === null || _y === void 0 ? void 0 : _y.two) === null || _z === void 0 ? void 0 : _z.id) !== null && _0 !== void 0 ? _0 : '').length > 0)
                        updateCountForCategory(categoryType, (_1 = material.kinds) === null || _1 === void 0 ? void 0 : _1.two, deletion ? -1 : 1, 'kinds.two', batch);
                    if ((_2 = material.kinds) === null || _2 === void 0 ? void 0 : _2.four) {
                        updateCountForCategory(categoryType, {
                            id: 'others',
                            name: 'Others'
                        }, deletion ? -1 : 1, 'kinds.four', batch);
                    }
                }
            }
            else if (material.type == 'mixer') {
                if (previousMaterial) {
                    if (((_3 = previousMaterial.kinds) === null || _3 === void 0 ? void 0 : _3.two.id) != ((_4 = material.kinds) === null || _4 === void 0 ? void 0 : _4.two.id)) {
                        updateCountForCategory(categoryType, (_5 = material.kinds) === null || _5 === void 0 ? void 0 : _5.two, 1, 'kinds.two', batch);
                        updateCountForCategory(categoryType, (_6 = previousMaterial.kinds) === null || _6 === void 0 ? void 0 : _6.two, -1, 'kinds.two', batch);
                    }
                }
                else {
                    if (((_9 = (_8 = (_7 = material.kinds) === null || _7 === void 0 ? void 0 : _7.two) === null || _8 === void 0 ? void 0 : _8.id) !== null && _9 !== void 0 ? _9 : '').length > 0)
                        updateCountForCategory(categoryType, (_10 = material.kinds) === null || _10 === void 0 ? void 0 : _10.two, deletion ? -1 : 1, 'kinds.two', batch);
                }
            }
            resolve();
        }
        catch (error) {
            resolve();
        }
    });
}
exports.updateCategories = updateCategories;
function updateCategory(type, category) {
    return firestar_1.Actions.updateDocument(`/categories/materials/${type}/${category.id}`, category);
}
exports.updateCategory = updateCategory;
function updateCountForCategory(type, category, by, field, batch) {
    const path = `/categories/materials/${type}/${field}#${category.id}`;
    if (ignoredCategories().includes(category.id)) {
        return Promise.resolve();
    }
    return firestar_1.Actions.updateCounter(path, 'totalItems', by, Object.assign(Object.assign({}, category), { field }), batch);
}
exports.updateCountForCategory = updateCountForCategory;
function getGinCategories(description) {
    return firestar_1.Actions.readCollection(collectionPath + '/gins', Object.assign(Object.assign({}, description), { limit: 50 }), {
        source: 'default'
    });
}
exports.getGinCategories = getGinCategories;
function getMixerCategories(description) {
    return firestar_1.Actions.readCollection(collectionPath + '/mixers', Object.assign(Object.assign({}, description), { limit: 50 }), {
        source: 'default'
    });
}
exports.getMixerCategories = getMixerCategories;
function primaryGinKinds() {
    return [
        {
            id: 'gin',
            name: 'Gin',
        },
        {
            id: 'distilled-gin',
            name: 'Distilled Gin',
        },
        {
            id: 'london-dry-gin',
            name: 'London Dry Gin',
        },
        {
            id: 'sloe-gin',
            name: 'Sloe Gin',
        },
        {
            id: 'no-EU',
            name: 'no Gin ABV<37.5%',
        },
        {
            id: 'hide',
            name: 'Hide',
        },
        {
            id: 'todo',
            name: 'Todo',
        },
        {
            id: 'n-a',
            name: 'N/A',
        },
    ];
}
exports.primaryGinKinds = primaryGinKinds;
function secondaryGinKinds() {
    return [
        {
            id: 'gin-liqueur',
            name: 'Gin Liqueur'
        },
        {
            id: 'zero-alcohol-gin',
            name: 'Zero Alcohol Gin'
        },
        {
            id: 'new-western-dry-gin',
            name: 'New Western Dry Gin'
        },
        {
            id: 'new-western-gin',
            name: 'New Western Gin'
        },
        {
            id: 'old-tom-gin',
            name: 'Old Tom Gin'
        },
        {
            id: 'genever',
            name: 'Genever'
        },
        {
            id: 'reserve-gin',
            name: 'Reserve Gin / Barrel Aged Gin'
        },
        {
            id: 'compound-gin',
            name: 'Compound Gin'
        },
        {
            id: 'cream-gin',
            name: 'Cream Gin'
        },
        {
            id: 'navy-strength-gin',
            name: 'Navy Strength Gin'
        },
        {
            id: 'dry-gin',
            name: 'Dry Gin'
        },
        {
            id: 'premix',
            name: 'Premix'
        },
        {
            id: 'todo',
            name: 'Todo'
        },
        {
            id: 'hide',
            name: 'Hide'
        },
        {
            id: 'n-a',
            name: 'N/A'
        }
    ];
}
exports.secondaryGinKinds = secondaryGinKinds;
function tertiaryGinKinds() {
    return [
        {
            id: 'plymouth-gin',
            name: 'Plymouth Gin'
        },
        {
            id: 'gin-de-mahon',
            name: 'Gin de Mahón'
        },
        {
            id: 'vilnius-gin',
            name: 'Vilnius Gin'
        },
        {
            id: 'todo',
            name: 'Todo'
        },
        {
            id: 'hide',
            name: 'Hide'
        },
        {
            id: 'n-a',
            name: 'N/A'
        }
    ];
}
exports.tertiaryGinKinds = tertiaryGinKinds;
function primaryMixerKinds() {
    return [
        {
            id: 'tonic-water',
            name: 'Tonic Water',
        },
        {
            id: 'other-mixers',
            name: 'Other Mixers',
        },
        {
            id: 'alcoholic-mixers',
            name: 'Alcoholic Mixers',
        }
    ];
}
exports.primaryMixerKinds = primaryMixerKinds;
function secondaryMixerKinds() {
    return [
        {
            id: 'indian-tonic',
            name: 'Indian Tonic Water',
            field: 'tonic-water'
        },
        {
            id: 'dry-tonic',
            name: 'Dry Tonic Water',
            field: 'tonic-water'
        },
        {
            id: 'mediterranean-tonic',
            name: 'Mediterranean Tonic Water',
            field: 'tonic-water'
        },
        {
            id: 'elderflower-tonic',
            name: 'Elderflower Tonic Water',
            field: 'tonic-water'
        },
        {
            id: 'other-tonics',
            name: 'Other Tonic Water',
            field: 'tonic-water'
        },
        {
            id: 'other-mixers',
            name: 'Other Mixers',
            field: 'other-mixers'
        },
        {
            id: 'alcoholic-mixers',
            name: 'Alcoholic Mixers',
            field: 'alcoholic-mixers'
        },
    ];
}
exports.secondaryMixerKinds = secondaryMixerKinds;
function tertiaryMixerKinds() {
    return [
        {
            id: 'hide',
            name: 'Hide',
        },
        {
            id: 'todo',
            name: 'Todo',
        },
        {
            id: 'n-a',
            name: 'N/A',
        },
    ];
}
exports.tertiaryMixerKinds = tertiaryMixerKinds;
function primaryGarnishKinds() {
    return [
        {
            id: 'hide',
            name: 'Hide',
        },
        {
            id: 'todo',
            name: 'Todo',
        },
        {
            id: 'n-a',
            name: 'N/A',
        },
    ];
}
exports.primaryGarnishKinds = primaryGarnishKinds;
function secondaryGarnishKinds() {
    return [
        {
            id: 'hide',
            name: 'Hide',
        },
        {
            id: 'todo',
            name: 'Todo',
        },
        {
            id: 'n-a',
            name: 'N/A',
        },
    ];
}
exports.secondaryGarnishKinds = secondaryGarnishKinds;
function tertiaryGarnishKinds() {
    return [
        {
            id: 'hide',
            name: 'Hide',
        },
        {
            id: 'todo',
            name: 'Todo',
        },
        {
            id: 'n-a',
            name: 'N/A',
        },
    ];
}
exports.tertiaryGarnishKinds = tertiaryGarnishKinds;
function ignoredCategories() {
    return ['todo', 'hide', 'n-a'];
}
exports.ignoredCategories = ignoredCategories;
