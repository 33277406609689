import { 
   Recipe as RecipeType,
   addRecipe as uploadRecipe,
   getAllRecipes,
   findMatchForCombinations,
   getRecipe,
   updateRecipe
} from 'gf-connect/lib/Recipe'
import { getReviewForRecipeForUser, readAllReviews, Review } from 'gf-connect/lib/Review'
import { Change, AppState } from '.'
import { Material } from './Materials'
import firestar,{ WhereType } from 'firestar'
import { Rating } from 'gf-connect/lib/Ratings'
import firebase from 'firebase/compat/app'

export type Recipe = RecipeType

const initialState : Recipe[] = []

export default function (state = initialState, action: Change) {
   switch (action.type) {
      case 'SYNC_RECIPES' : {
         let currentList = [...state]            
         const newMaterials = [...action.payload as RecipeType[]]
         newMaterials.forEach( (p:RecipeType) => {
               const findIndex = currentList.findIndex( (c) => c.id ===  p.id)                  
               if(findIndex >= 0){
                  currentList[findIndex] = p
               }else{
                  currentList.push(p)
               }
            }
         )   
         return currentList
      }
      case 'DELETE_RECIPES' : {
         let currentList = [...state]            
         const materialId = action.payload
         currentList = currentList.filter((recipe) => {
            return !(recipe.materialId === materialId)
         })
         return currentList
      }
      case 'DELETE_RECIPES_IDS': {
         let currentList = [...state]
         const recipesIds = action.payload as string[]
         currentList = currentList.filter(() => {
            const find = currentList.findIndex(
               (c) => recipesIds.includes(c.id)
            )
            return find != null
         })
         return currentList
      }
      case 'CLEAR_RECIPES' : {
         return []
      }
      case 'SIGN_OUT' : {
         return []
      }
      default:
         return state
   }
}

export function updateRecipeDetails(recipe: Recipe): any {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Recipe>(async (resolve, reject) => {
         delete recipe.ratings
         updateRecipe(recipe)
         .then(
            (response) => {
               dispatch({
                  payload : [response],
                  type : 'SYNC_RECIPES'
               })
               resolve(response)
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function addRecipe(recipe: Recipe) : any {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Recipe>(async (resolve, reject) => {

         console.log('Finding Combinations...')
         findMatchForCombinations(recipe.combinations!,undefined,true)
         .then(
            async (response) => {
               const matches = response.data ?? []
               if(matches.length > 0){
                  console.log('Match Found. Updating',matches[0])
                  const _recipe : Recipe = matches[0]
                  dispatch({
                     payload : [_recipe],
                     type : 'SYNC_RECIPES'
                  })
                  resolve(_recipe)
               }else{
                  console.log('No Match Found. Creating : ',recipe)
                  uploadRecipe({
                     ...recipe,
                     ratings: {
                        numberOfRatings: 0,
                        relativeAverage: 0,
                        relativeTotal: 0
                     } as Rating
                  })
                  .then(
                     (response) => {
                        dispatch({
                           payload : [response],
                           type : 'SYNC_RECIPES'
                        })
                        resolve(response)
                     }
                  )
                  .catch((error) => reject(error))
               }
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function getRecipes() {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Recipe[]>((resolve, reject) => {
         getAllRecipes()
         .then(
            (response) => {
               resolve(response.data)
               dispatch({
                  payload : response.data,
                  type : 'SYNC_RECIPES'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function getRecipeDetails(recipeId:string) : any{
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Recipe|undefined>((resolve, reject) => {
         getRecipe(recipeId)
         .then(
            (response) => {
               resolve(response)
               dispatch({
                  payload : [response],
                  type : 'SYNC_RECIPES'
               })
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function getRecommendedRecipes(material:Material) : any {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Recipe[]>((resolve, reject) => {
         const promises : Promise<any>[] = []

         const condition : WhereType = {
            fieldPath : `matches.${material.id}`,
            opStr : '==',
            value : true
         }
         promises.push(getAllRecipes({
            where : [
               {
                  fieldPath : 'recommendedBy',
                  opStr : '==',
                  value : 'ginferno'
               },
               condition
            ]
         }))
         promises.push(getAllRecipes({
            where : [
               {
                  fieldPath : 'recommendedBy',
                  opStr : '==',
                  value : 'owner'
               },
               condition
            ]
         }))
         promises.push(getAllRecipes({
            where : [
               {
                  fieldPath : 'recommendedBy',
                  opStr : '==',
                  value : 'owner-ginferno'
               },
               condition
            ]
         }))
         Promise.all(promises)
         .then(
            async (responses) => {
               const userId = firebase.auth().currentUser?.uid
               let recipes : Recipe[] = []
               responses.forEach((r) => recipes = [...recipes,...r.data])
               if(userId){
                  const promises = recipes.map((recipe) => getReviewForRecipeForUser(userId,recipe.id))
                  const reviews = await Promise.all(promises)
                  recipes = recipes.map((recipe) => {
                     const reviewForRecipe = reviews.find(r => r?.recipeId == recipe.id)
                     recipe.review = reviewForRecipe
                     return recipe
                  })
               }
               dispatch({
                  payload : recipes,
                  type : 'SYNC_RECIPES'
               })
               resolve(recipes)
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function getFrontRunner(
   materialId:string,
   limit?:number,
   lastDoc?: any,
   fetchReviews?: boolean
) : any {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         getAllRecipes({
            where : [
               {
                  fieldPath : `materialId`,
                  opStr : '==',
                  value : materialId
               }
            ],
            limit : limit,
            lastDoc: lastDoc,
            orderBys:[
               {
                  fieldPath: 'ratings.numberOfRatings',
                  directionStr: 'desc'
               }
            ]                           
         })
         .then(async (response) => {
               let recipes : Recipe[] = response.data
               const userId = firebase.auth().currentUser?.uid
               if(fetchReviews && userId){
                  const promises = recipes.map((recipe) => getReviewForRecipeForUser(userId,recipe.id))
                  const reviews = await Promise.all(promises)
                  recipes = recipes.map((recipe) => {
                     const reviewForRecipe = reviews.find(r => r?.recipeId == recipe.id)
                     recipe.review = reviewForRecipe
                     return recipe
                  })
               }
               dispatch({
                  payload : recipes,
                  type : 'SYNC_RECIPES'
               })
               resolve(response)
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function clearRecipesWithMaterialId(materialId:string){
   return {
      payload: materialId,
      type: 'DELETE_RECIPES'
   } as Change
}

export function clearRecipesWithIds(recipeIds:string[]){
   return {
      payload: recipeIds,
      type: 'DELETE_RECIPES_IDS'
   } as Change
}

export function getUserFavoritesForMaterial(
   materialId:string,
   lastDoc?: any
) : any {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Recipe[]>(async (resolve, reject) => {
         const userId = firebase.auth().currentUser?.uid
         if(!userId){
            dispatch({
               payload: [],
               type: 'SYNC_RECIPES'
            })
            resolve([])
            return
         }
         try {
            const response = await readAllReviews({
               where: [
                  {
                     fieldPath : 'createdBy.uid',
                     opStr : '==',
                     value : userId
                  },
                  {
                     fieldPath: 'materialId',
                     opStr: '==',
                     value: materialId
                  }
               ],
               limit: 5,
               lastDoc: lastDoc
            })  
            const unFiltered = response.data as Review[]
            const reviews = unFiltered.filter(
               (review) => review.path?.includes('rooms') == false
            )
            const promises = reviews.map((review) => getRecipe(review.recipeId))
            let recipes = await Promise.all(promises) as Recipe[]
            recipes = recipes.map((recipe) => {
               const reviewForRecipe = reviews.find(r => r.recipeId == recipe.id)
               recipe.review = reviewForRecipe
               return recipe
            })
            resolve(recipes)
            dispatch({
               payload: recipes,
               type: 'SYNC_RECIPES'
            })
         } catch (error) {
            reject(error)            
         }
      })
   }
}

export function searchRecipeWithCombinations(
   combinations: Material[],
   exactMatch?: boolean
) {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         findMatchForCombinations(combinations, undefined, exactMatch, 100)
            .then(
               (response) => {
                  dispatch({
                     payload: response.data,
                     type: 'SYNC_RECIPES'
                  })
                  resolve(response)
               }
            )
            .catch((error) => reject(error))
      })
   }
}

export function clearRecipes(){
   return {
      payload: [],
      type: 'CLEAR_RECIPES'
   } as Change
}