import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import firebase from 'firebase/compat/app'
import Main from './Main';
import {
   Link
} from 'react-router-dom';
import { getWebAppVersion } from './utils';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         display: 'flex',
      },
      appBar: {
         zIndex: theme.zIndex.drawer + 1,
         transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
      },
      appBarShift: {
         marginLeft: drawerWidth,
         width: `calc(100% - ${drawerWidth}px)`,
         transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
         }),
      },
      menuButton: {
         marginRight: 36,
      },
      hide: {
         display: 'none',
      },
      drawer: {
         width: drawerWidth,
         flexShrink: 0,
         whiteSpace: 'nowrap',
      },
      drawerOpen: {
         width: drawerWidth,
         transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
         }),
      },
      drawerClose: {
         transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
         overflowX: 'hidden',
         width: theme.spacing(7) + 1,
         [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
         },
      },
      toolbar: {
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'space-between',
         padding: theme.spacing(0, 1),
         // necessary for content to be below app bar
         ...theme.mixins.toolbar,
      },
      content: {
         flexGrow: 1,
         padding: theme.spacing(3),
      },
   }),
);

interface MenuListItem {
   name: string
   id: string
   icon : string
}

const MenuList: MenuListItem[] = [
   {
      id: '/',
      name: 'Dashboard',
      icon : 'dashboard'
   },
   // {
   //    id: '/companies',
   //    name: 'Companies',
   //    icon : 'business'
   // },
   {
      id: '/companies',
      name: 'Companies',
      icon : 'business'
   },
   // {
   //    id: '/materials/old',
   //    name: 'Materials (Old)',
   //    icon : 'local_bar'
   // },
   // {
   //    id: '/materials/barcodes',
   //    name: 'Barcodes Mapping',
   //    icon : 'qr_code'
   // },
   {
      id: '/materials',
      name: 'Materials',
      icon : 'local_bar'
   },
   {
      id: '/recipes',
      name: 'Recipes',
      icon : 'kitchen'
   },
   {
      id: '/shops',
      name: 'Shops',
      icon : 'store'
   },
   {
      id: '/promotions',
      name: 'Promotions',
      icon : 'loyalty'
   },
   {
      id: '/users',
      name: 'Users',
      icon : 'people'
   },
   {
      id: '/rooms',
      name: 'Rooms',
      icon : 'groups'
   },
   {
      id: '/playground',
      name: 'Playground',
      icon : 'games'
   },
   {
      id: '/media',
      name: 'Media',
      icon : 'photos'
   },
   {
      id: '/settings',
      name: 'Settings',
      icon : 'settings'
   }
]

export default function MiniDrawer() {
   const classes = useStyles();
   const theme = useTheme();
   const [open, setOpen] = React.useState(false);

   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const menuOpen = Boolean(anchorEl);
 
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
     setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };


   const handleDrawerOpen = () => {
      setOpen(true);
   };

   const handleDrawerClose = () => {
      setOpen(false);
   };

   return (
      <div className={classes.root}>
         <CssBaseline />
         <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
               [classes.appBarShift]: open,
            })}>
            <Toolbar style={{
               display : 'flex',
               justifyContent : 'space-between'
            }}>
               <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
               }}>
                  <IconButton
                     color="inherit"
                     aria-label="open drawer"
                     onClick={handleDrawerOpen}
                     edge="start"
                     className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                     })}>
                     <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" noWrap>
                     GINferno Admin
               </Typography>
               </div>
               <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems : 'center'
               }}>
                  <Avatar 
                     aria-controls="fade-menu" 
                     aria-haspopup="true" 
                     style={{
                        background : 'rgb(17,41,62)'
                     }}
                     onClick={handleClick} 
                     alt="Remy Sharp">                        
                     </Avatar>
                  <Menu
                     id="fade-menu"
                     anchorEl={anchorEl}
                     keepMounted
                     style={{
                        marginTop : 40
                     }}
                     open={menuOpen}
                     onClose={handleClose}
                     TransitionComponent={Fade}>
                     <MenuItem onClick={()=> {
                        handleClose()
                        firebase.auth().signOut()
                     }}>Logout</MenuItem>
                  </Menu>
               </div>
            </Toolbar>
         </AppBar>
         <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
               [classes.drawerOpen]: open,
               [classes.drawerClose]: !open,
            })}
            classes={{
               paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
               }),
            }}>
            <div className={classes.toolbar}>
               <div style={{
                  marginLeft: 10
               }}>
                  v{getWebAppVersion()}
               </div>
               <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
               </IconButton>
            </div>
            <Divider />
            <List>
               {MenuList.map((item, index) => (
                  <Link to={item.id} style={{
                     textDecoration: 'none',
                     color: 'gray',
                     display: 'flex',
                     flexDirection: 'row'
                  }}>
                     <ListItem
                        button key={item.id}>
                        <ListItemIcon>
                           <i className='material-icons'>{item.icon}</i>
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                     </ListItem>
                  </Link>
               ))}
            </List>
         </Drawer>
         <main className={classes.content}>
            <div className={classes.toolbar} />
            <Main/>
         </main>
      </div>
   );
}