import React ,{ CSSProperties, memo, useEffect, useState } from 'react'
import {
   useDebounce
} from './MaterialsBeta';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Companies } from 'gf-connect';

function SearchCompany(props:{
   placeholder?: string,
   label?: string
   disabled?: boolean
   onSelectCompany: (company: Companies.Company | null) => void,
   style?: CSSProperties,
   value?: Companies.Company
}) {
   const { onSelectCompany, placeholder, style, disabled, value, label } = props
   const [searchText, setSearchText] = useState('')
   const debounceSearchText = useDebounce(searchText, 100)
   const [searchResults, setSearchResults] = useState<Companies.Company[]>([])
   const [showLoader, setShowLoader] = useState(false)
   const [open, setOpen] = useState(false)

   useEffect(
      () => {
         if (debounceSearchText) {
            getCompany(debounceSearchText)
         }
      },
      [debounceSearchText]
   );

   async function getCompany(text: string) {
      setShowLoader(true)
      try {

         let searchText = text
         const response = await Companies.searchCompany(searchText)
         setSearchResults(response.data)
      } catch (error) {
         console.log('Error Searching Company', error)
      }
      setShowLoader(false)
   }

   return (
      <Autocomplete
         open={open}
         onOpen={() => {
            setOpen(true);
         }}
         onClose={() => {
            setOpen(false);
         }}
         value={value}
         disabled={disabled}
         getOptionSelected={(option, value) => option.name === value.name}
         getOptionLabel={(option) => option.name}
         filterOptions={(options, state) => options}
         options={searchResults}
         loading={showLoader}
         onChange={(e,value) => onSelectCompany(value)}
         renderInput={(params) => (
            <TextField
               {...params}
               style={{
                  width: 350,
                  ...style
               }}
               placeholder={placeholder ?? 'Search Company'}
               value={searchText}
               variant='outlined'
               label={label}
               onChange={(e) => setSearchText(e.target.value)}
               InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                     <React.Fragment>
                        {showLoader ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                     </React.Fragment>
                  ),
               }}
            />
         )}
      />
   )
}

export default memo(SearchCompany)