import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { Material } from './store/Materials'
import MaterialTable, { MTableToolbar } from 'material-table'
import Recipes from './Recipes';
import {
   findMatchForCombinations
} from 'gf-connect/lib/Recipe';
import {
   GFAutoCompleteField
} from './FormUtils';

import {
   Button as NativeButton,
   Text
} from 'react-native';

import { 
   useSelector, useDispatch
} from 'react-redux'
import { AppState } from './store';
import { Recipe, searchRecipeWithCombinations, clearRecipes } from './store/Recipes';
import { Link } from 'react-router-dom';
import { searchMaterial } from 'gf-connect/lib/Material';
import { Alert } from './Alert';

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      textField : {
         flex : 1        
      },
      addButton : {
         width : 100,
         marginLeft : 15       
      }
   })
)

export default function SearchRecipe() {

   const styles = useStyles()
   const [combinations, setCombinations] = useState<Material[]>([])
   const [newCombination, setNewCombination] = useState<Material|undefined>()
   const [recipes, setRecipes] = useState<Recipe[]|undefined>()
   const [searchText, setSearchText] = useState('')
   const [searchResult, setSearchResult] = useState<Material[]>([])
   const [selectedMaterial, setSelectedMaterial] = useState<Material>()
   const dispatch = useDispatch()
   const [isSearching, setIsSearching] = useState(false)

   useEffect(() => {
      setCombinations(combinations)
   }, [])

   return (
      <div style={{
         padding : 15,
         display : 'flex',
         alignItems : 'flex-start',
         flexDirection : 'column'
      }}>
         <Typography variant='h5'>Search Recipe</Typography>
         <div style={{
            marginTop : 15,
            width: 500
         }}>
            <div>
               {/* <MTableToolbar {...props} /> */}
               <div style={{
                  margin: 15,
                  display: 'flex',
                  justifyContent: 'space-around'
               }}>
                  {/* <Autocomplete
                              className={styles.textField}
                              value={newCombination}
                              options={materials}
                              onChange={(_, value) => onChange(value)}
                              getOptionLabel={(option: Material) => option.name}
                              renderInput={
                                 (params) => (
                                    <TextField {...params}
                                       label={newCombination?.type}
                                       name='category'
                                       placeholder='Select Material'
                                       variant="outlined" />
                                 )} /> */}
                  <GFAutoCompleteField
                     style={{
                        width: 300
                     }}
                     isLoading={isSearching}
                     label='Search Material'
                     onChangeText={onChangeSearchText}
                     onSelectItem={onSelectMaterial}
                     editing={true}
                     value={selectedMaterial != null ? {
                        label: selectedMaterial.name,
                        value: selectedMaterial
                     } : undefined}
                     options={searchResult.map((m) => {
                        return {
                           label: m.name,
                           value: m
                        }
                     })} />
                  <Button
                     className={styles.addButton}
                     variant={'contained'}
                     onClick={onClickAdd}>Add</Button>
               </div>
            </div>
            <MaterialTable
               title='Enter Combinations'
               data={combinations}
               editable={{
                  onRowDelete: oldData =>
                  new Promise<void>((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...combinations];
                      const index = combinations.findIndex(c => c.id == oldData.id)
                      index >= 0 && dataDelete.splice(index, 1);
                      setCombinations([...dataDelete]);                     
                      resolve()
                    }, 1000)
                  }),
               }}
               options={{
                  search: false
               }}
               components={{
                  // Toolbar: (props) => (
                     
                  // ),
                  Pagination : () => (
                     <div>
                        <div>
                           <Button
                              onClick={ () => onClickSearch()}
                              variant='contained'
                              style={{
                                 margin: 10
                              }}>Search</Button>
                           <Button
                              onClick={ () => onClickSearch(true)}
                              variant='contained'
                              style={{
                                 margin: 10
                              }}>Search Exact Match</Button>
                           <Button
                              onClick={() => {
                                 setCombinations([])
                                 setRecipes([])
                              }}
                              variant='outlined'
                              style={{
                                 margin: 10
                              }}>Clear</Button>
                        </div>
                     </div>
                  )
               }}
               columns={[
                  {
                     field: 'name',
                     title: 'Name'
                  },
                  {
                     field: 'type',
                     title: 'Type'
                  }
               ]}
            />
         </div>
         {recipes && <div style={{
            marginTop : 20,
            maxWidth : 800,
            width : '100%'
         }}>
            <Typography variant='h6'>Search Result</Typography>
            {recipes.length > 0 ?
               <Recipes incomingRecipes={recipes}/> : 
               <div>
                  <Typography>No Recipe Found</Typography>
                  <Link to={{
                     pathname: '/recipes/add-recipe',
                     state: {
                        combinations : combinations
                     }
                  }}><Button variant='outlined'>Create</Button></Link>
               </div>
            }
         </div>}
      </div>
   )

   function onSelectMaterial(value){
      onChange(value)
   }

   async function onChangeSearchText(text:string){
      if(text.trim().length > 0){
         setIsSearching(true)
         try {
            setSearchText(text)
            const response = await searchMaterial(text)
            console.log('response',response)
            setSearchResult(response.data)
         } catch (error) {
            
         }
         setIsSearching(false)
      }
   }

   function onClickAdd(){
      if(newCombination){
         setCombinations([...combinations,newCombination])
         setNewCombination(undefined)
      }
   }

   async function onClickSearch (exactMatch?:boolean) {
      
      try {
         dispatch(clearRecipes())
         const response = await dispatch(searchRecipeWithCombinations(combinations,exactMatch)) as any
         setRecipes(response.data)
      } catch (error) {
         Alert.alert('Error Seaching Combinations',String(error))
      }

   }

   function onChange(value){
      setNewCombination(value)
   }
}
