import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Root from 'gf-connect/lib/Root';

export interface GFAutoCompleteOption {
	label : string,
	value : any
}

export function GFAutoComplete (props:{
	label : string,
	value? : GFAutoCompleteOption,
	editing? : boolean,
	options?:GFAutoCompleteOption[]
	style? : React.CSSProperties,
	onOpenModel? : (completion:(options:GFAutoCompleteOption[])=>void) => void,
	onChangeText : (value:any) => void
}) {

	const [autoCompleteOptions, setAutoCompleteOptions] = useState(props.options ?? [])

	useEffect(() => {	
		setAutoCompleteOptions(props.options ?? [])
	}, [props.options])

	return (
		<Autocomplete
			disabled={!props.editing}
			defaultValue={props.value}
			style={props.style}
			options={autoCompleteOptions}
			onOpen={ () => props.onOpenModel && props.onOpenModel(
				(options) => setAutoCompleteOptions(options)
			)}
			value={props.value}
			loading={autoCompleteOptions.length == 0 && true}
			onChange={(event,value) => props.onChangeText(value?.value)}
			getOptionLabel={(option:GFAutoCompleteOption) => option.label}
			renderInput={
				(params) => (
					<TextField {...params}
						label={props.label}
						variant="outlined" />
				)}
		/>
	)
}

export function GFTextField(props:{
	value?:any,
	editing : boolean,
	label? : string,
	style? : React.CSSProperties,
	onChangeText? : (value:string) => void,
	type?: string,
	defaultValue? : any,
	onBlur?:(value:any) => void
}) {
	return (
		<TextField
			style={props.style}
			variant='outlined'
			defaultValue={props.defaultValue}
			label={props.label}
			type={props.type}
			disabled={!props.editing}
			onBlur={(event) => {
				props.onBlur && props.onBlur(event.target.value)
			}}
			onChange={(event) => props.onChangeText && props.onChangeText(event.target.value)}
			value={props.value} />
	)
}

export function GFTextArea(props:{
	value?:string,
	editing : boolean,
	label? : string,
	style? : React.CSSProperties,
	onChangeText : (value:string) => void
}) {
	return (
		<TextField
			multiline
			style={props.style}
			variant='outlined'
			label={props.label}
			disabled={!props.editing}
			onChange={(event) => props.onChangeText(event.target.value)}
			value={props.value} />
	)
}

export function GFSelect (props:{
	value? : any,
	label : string,
	types : any,
	editing : boolean,
	style? : React.CSSProperties,
	onSelectValue : (string:any) => void
}) {
	return (
		<FormControl 
			style={props.style}
			variant="outlined">
			<InputLabel>{props.label}</InputLabel>
			<Select
				disabled={!props.editing}
				value={props.value}
				onChange={(event) => props.onSelectValue(event.target.value)}
				label={props.label}>
				{Object.keys(props.types).map(
					(key) => <MenuItem value={key}>{props.types[key]}</MenuItem>
				)}
			</Select>
		</FormControl>
	)
}

export function GFCheckbox(props: { 
	value? : boolean,
	label : string,
	editing : boolean,
	style? : React.CSSProperties,
	onChangeValue : (value:boolean) => void
}) {
	return (
		<FormControlLabel
			style={props.style}
			control={
				<Checkbox
					disabled={!props.editing}
					icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
					checked={props.value}
					onChange={(event) => props.onChangeValue(event.target.checked)}
					checkedIcon={<CheckBoxIcon fontSize="small" />}/>
			}
			label={props.label} />
	)
}

export function validateUrl(url) {

	if(url == 'n/a') return true;
	if (/^(?:f|ht)tps?\:\/\//.test(url)) { return true }
	return false;
}

export function GFAutoCompleteField (props:{
	label : string,
	value? : GFAutoCompleteOption,
	editing? : boolean,
	options?:GFAutoCompleteOption[]
	style? : React.CSSProperties,
	onOpenModel? : (completion:(options:GFAutoCompleteOption[])=>void) => void,
	onChangeText : (value:any) => void,
	onSelectItem: (value:any) => void,
	text?:string,
	isLoading?: boolean,
	onFocus? : () => void
}) {

	const [autoCompleteOptions, setAutoCompleteOptions] = useState(props.options ?? [])
	const { isLoading } = props

	useEffect(() => {	
		setAutoCompleteOptions(props.options ?? [])
	}, [props.options])

	return (
		<Autocomplete
			disabled={!props.editing}
			defaultValue={props.value}
			style={props.style}
			options={autoCompleteOptions}
			onOpen={ () => props.onOpenModel && props.onOpenModel(
				(options) => setAutoCompleteOptions(options)
			)}
			onFocus={props.onFocus}
			value={props.value}
			loading={isLoading ?? (autoCompleteOptions.length == 0 && true)}
			onChange={(event,value) => props.onSelectItem(value?.value)}
			getOptionLabel={(option:GFAutoCompleteOption) => option.label}
			renderInput={
				(params) => (
					<TextField {...params}
						value={props.text ?? props.value?.label ?? ''}
						onChange={(event) => props.onChangeText(event?.target?.value)}
						label={props.label}
						variant="outlined" />
				)}
		/>
	)
}