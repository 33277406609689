import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from './store';
import { 
	getCompanies, 
	uploadCompaniesFromCSV, 
	Company,
	addCompany,
	updateCompanyInfo,
	deleteCompany,
	getCompanyDetails
} from './store/Companies'
import { checkCompanyNameChange, CompanyStatusTypes, updateCompany } from 'gf-connect/lib/Company';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import lodash from 'lodash'
import { default as Label } from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/DeleteRounded'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Popover from '@material-ui/core/Popover';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import _ from 'lodash';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment'
import firestar from 'firestar';
import {
	validateUrl
} from './Materials';
import {
	GFAutoComplete,
	GFCheckbox,
	GFSelect,
	GFTextField,
	GFAutoCompleteOption,
	GFTextArea
} from './FormUtils';

import CSVReader, {
	IFileInfo
} from 'react-csv-reader'
import { Text, View } from 'react-native';
import {
	exportListToCSV,
	orderTranslationObject,
	compareObjectsWithTime,
	createFlatEmptyLanguageKeys,
	createEmptyLanguageObject
} from 'gf-connect/lib/Utils'
import { Address, convertCountryToAddressObject } from 'gf-connect/lib/Address';
import { getSummarizeUserInfo } from 'gf-connect/lib/User';
import { getSearchableCountries, isDev } from './utils';

const countries = getSearchableCountries()
const countriesOptions = countries.map((c) => { 
	return { label : c.name, value : c } 
})

interface Column {
	id : string
	label: string;
	minWidth?: number;
	align?: 'right';
	type? : 'text' | 'select' | 'checkbox' | 'autocomplete',
	selectTypes? : any,
	targetPath? : string
	options? : GFAutoCompleteOption[]
	format?: (value: number) => string,
	editable?: boolean
}

const columns: Column[] = [
	{ 
		id: 'id', 
		label: 'Id', 
		minWidth: 170,
		type : 'text',
		editable : false
	},
	{ 
		id: 'name', 
		label: 'Name', 
		minWidth: 170,
		type : 'text'
	},
	{ 
		id: 'email', 
		label: 'Email', 
		minWidth: 100, 
		align : 'right',
		type : 'text'
	},
	{
		id: 'address.country',
		label: 'Country',
		minWidth: 170,
		align: 'right',
		type : 'autocomplete',
		targetPath : 'address.country',
		options : countriesOptions,
		editable: false
	},
	{ 
		id: 'status', 
		label: 'Status', 
		minWidth: 100, 
		align: 'right',
		type : 'select',
		format: (type) => CompanyStatusTypesDropdown()[type],
		selectTypes : CompanyStatusTypesDropdown()
	},
];

function CompanyStatusTypesDropdown(){
	const types = {...CompanyStatusTypes} as any
	delete types.deleted
	return types
}

const useRowStyles = makeStyles({
	root: {
	  '& > *': {
		 borderBottom: 'unset',
	  },
	},
 });

const useStyles = makeStyles({
	root: {
		marginLeft: '1%',
		marginTop : '2%',
		width: '95%',
	},
	container: {
		maxHeight: '100%',
	},
	title : {
		display : 'flex',
		alignSelf : 'flex-start',
		marginBottom : 10
	},
	searchField :  {
		height: 5,
		fontSize: 12  
	}
});

interface Filter {
	searchText : string
}


export default function Companies() {

	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [CSVData, setCSVData] = React.useState<any[]>([]);
	const [CSVFileInfo, setCSVFileInfo] = React.useState<IFileInfo | undefined>();
	const [rowsPerPage, setRowsPerPage] = React.useState(100);
	const [newCompany, setNewCompany] = useState<Company|undefined>()
	const [showFilter, setShowFilter] = useState<HTMLButtonElement|undefined>()
	const [filteredCompanies, setFilteredCompanies] = useState<Company[]|undefined>(undefined)
	const [searchText, setSearchText] = useState('')
	let [filters, setFilters] = useState<Filter>()
	const [showProgress, setShowProgress] = useState({
		completed : 0,
		total : 0,
		visible : false
	})

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	let allCompanies = useSelector((state: AppState) => state.companies)
	let companiesState = allCompanies.filter(
		(company) => {
			return company.status != 'deleted'
		}
	).sort(
		(a,b) => compareObjectsWithTime(a,b,'newer','updatedAt')
	)


	let companies : Company[] = []
	if(filteredCompanies){
		companies = (filteredCompanies ?? [])
	}else{
		companies = companiesState
	}

	useEffect(() => {

		if (companies.length == 0) {
			setShowProgress({
				visible: true,
				completed: 0,
				total: 0
			})
			dispatch(getCompanies(isDev() ? 10 : undefined))
				.then(
					() => {
						setShowProgress({
							visible: false,
							completed: 0,
							total: 0
						})
					}
				)
				.catch(
					(error) => {
						console.log('Error', error)
						setShowProgress({
							visible: false,
							completed: 0,
							total: 0
						})
					}
				)
		}
	}, []);  

	const dispatch = useDispatch()

	const parserOptions = {
		header: true,
		dynamicTyping: true,
		skipEmptyLines: true,
		transformHeader: (header:any) =>
		  header
			 .replace(/\W/g, '_')
	 }

	const onParseError = (error:Error) => {
		console.log('Error Parsing : ',error)
		alert('Error Loading CSV\n\n'+String(error))
	}

	const onFileLoaded = (data: Array<any>, fileInfo:IFileInfo) => {
		setCSVData(data)
		setCSVFileInfo(fileInfo)
	}

	const onClickUpload = () => {
		dispatch(uploadCompaniesFromCSV(CSVData,onProgressUpload))
		.then(
			() => setShowProgress({
				completed : 0,
				total : 0,
				visible : false
			})
		)
		.catch(
			(error) => {
				console.log('Error Uploading Companies : ',error)
				setShowProgress({
					completed : 0,
					total : 0,
					visible : false
				})
				alert('Error Uploading Companies : ' + String(error))
			}
		)
		setCSVData([])
		setCSVFileInfo(undefined)
	}

	function onProgressUpload (completed:number,totalCount:number) {
		setShowProgress({
			completed : completed,
			total : totalCount,
			visible : true
		})
	}

	const onClickExport = () => {
		exportListToCSV(allCompanies,'companies',[
			'id',
			'name',
			'address_street',
			'address_postalCode',
			'address_city',
			...createFlatEmptyLanguageKeys('address.translatedCity'),
			'address_state',
			'address_country',
			// ...createFlatEmptyLanguageKeys('address.translatedCountry'),
			'email',
			'social_homepage',
			'social_facebookUrl',
			'social_instagramUrl',
			'description',
			...createFlatEmptyLanguageKeys('translatedDescription'),
			'parent_name',
			'parent_id',
			'comments',
			'status'
		])		
	}

	const onClickAdd = () => {
		const newCompany = {
			id : '',
			name : '',
			translatedDescription : {
				...createEmptyLanguageObject()
			},
			address : {
				city :  '',
				country : '',
				postalCode : '',
				state : '',
				street : '',
				translatedCity : {
					...createEmptyLanguageObject()
				},
				translatedCountry : {
					...createEmptyLanguageObject()
				}
			},
			social: {
				facebookUrl: 'n/a',
				homepage: 'n/a',
				instagramUrl: 'n/a',
				twitterUrl: 'n/a'
			}
		} as Company
		setNewCompany(newCompany)
	}

	const onClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
		setShowFilter(event.currentTarget)
	 };

	 const onCloseFilter = () => {
		setShowFilter(undefined)
	 }

	 function onSubmitSearch (event?) {

		event?.preventDefault && event.preventDefault()

		if(!filters && searchText.trim().length == 0){
			setFilteredCompanies(undefined)
			return
		}

		let filtered = companiesState.filter(
			(company) => {
				return company.status != 'deleted'
			}
		)

		if (searchText.trim().length > 0) {
			filtered = filtered.filter(
				(company) => 
					company.name?.toLowerCase().includes(searchText.trim().toLowerCase()) || 
					company.address?.country?.toLowerCase().includes(searchText.trim().toLowerCase()) || 
					company.comments?.toLowerCase().includes(searchText.trim().toLowerCase()) ||
					company.tags?.includes(searchText.trim().toLowerCase())
			)
		}
		
		setFilteredCompanies(filtered)
	}


	function onClickRefresh(){
		
		const latest = companiesState[0]

		setShowProgress({
			visible: true,
			completed: 0,
			total: 0
		})

		dispatch(getCompanies())
		.then(
			(response) => {
				setTimeout(() => {
					setFilteredCompanies([])
					setShowProgress({
						visible: false,
						completed: 0,
						total: 0
					})
					setFilteredCompanies(undefined)	
				}, 500);	
			}
		)
		.catch(
			(error) => {
				setShowProgress({
					visible: false,
					completed: 0,
					total: 0
				})
				console.log('Error Getting Updated : ',error)
			}
		)
	}

	return (
		<div className={classes.root}>
			<View style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center'
			}}>
				<Label className={classes.title} variant='h5'>Companies</Label>
				{/* <Button onClick={onClickRunUpdate}>Run Update</Button> */}
			</View>
			<Backdrop style={{
               zIndex: 1000,
					color: '#fff',
					display : 'flex',
					flexDirection : 'column'
            }} open={showProgress.visible}>
               <CircularProgress color="inherit" />
					{showProgress.total > 0 && <Label 
						style={{
							marginTop : 10
						}}
						variant='h6'>Uploaded {showProgress.completed} of {showProgress.total}</Label>}
			</Backdrop>
			<div style={{
				display : 'flex',
				flexDirection : 'row',
				alignItems : 'center',
				marginBottom: 10,
				justifyContent : 'space-between'
			}}>
				<Button variant='contained' color='primary'>
					<CSVReader
						cssClass="csv-reader-input"
						label="Upload CSV"
						onFileLoaded={onFileLoaded}
						onError={onParseError}
						parserOptions={parserOptions}
						inputId="ObiWan"
						inputStyle={{ opacity: 0, width: '170px', marginLeft: '-170px' }} />
				</Button>
				{CSVFileInfo ? <div>
					{CSVFileInfo && <Text style={{
						marginRight : 10
					}}>({CSVFileInfo.name})</Text>}
					{CSVData.length > 0 && <Text style={{
						marginRight : 10
					}}>{`Found ${CSVData.length} Companies`}</Text>}
					{CSVData.length > 0 && 
					<Button 
						onClick={onClickUpload} 
							variant='outlined'>Upload</Button>}
				</div> :
				<div>
					<Button
						onClick={onClickRefresh}
						variant='outlined'>Refresh</Button>
				</div>}
			</div>
			<Paper>
				<TableContainer className={classes.container}>
					<div style={{
						display: 'flex',
						flex: 1,
						justifyContent: 'space-between',
						width: '100%',
						background: 'rgb(250,250,250)',
						paddingTop : 5,
						paddingBottom : 5
					}}>
						<IconButton
							onClick={onClickAdd}
							aria-label="filter list">
							{!newCompany && <AddIcon />}
						</IconButton>
						<form 
							onSubmit={onSubmitSearch}
							style={{
							display : 'flex',
							flex: 1,
							flexDirection : 'column',
						}}>
							<TextField
								style={{
									flex: 1
								}}
								value={searchText}
								onChange={(event) => {
									setSearchText(event.target.value)
								}}
								onBlur={
									(event) => onSubmitSearch(event.target.value)
								}
								InputProps={{ classes: { input: classes.searchField } }}
								variant='outlined'
								placeholder='Search for a Company' />
							{filteredCompanies && <Typography style={{
								alignSelf : 'flex-start'
							}}>Found {(filteredCompanies ?? []).length} companies</Typography>}
						</form>
						<IconButton
							onClick={onClickFilter}
							aria-label="filter list">
							<FilterListIcon />
						</IconButton>
						<Popover
							open={showFilter ? true : false}
							anchorEl={showFilter}
							onClose={onCloseFilter}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}>
							<div style={{
								width : 300,
								padding : 15,
								display : 'flex',
								flexDirection : 'column'
							}}>
								<Typography>Filters</Typography>	
								<div style={{
									display : 'flex',
									flex : 1
								}}>
									<Button
										style={{
											marginTop: 15,
											marginRight : 5,
											flex : 1
										}}
										onClick={() => {
											filters = undefined
											setFilters(undefined)											
											setShowFilter(undefined)
											onSubmitSearch()
										}}
										variant='contained'>Clear</Button>
									<Button
										style={{
											marginTop: 15,
											marginLeft : 5,
											flex : 1
										}}
										onClick={() => {
											setShowFilter(undefined)
											onSubmitSearch()
										}}
										variant='contained'>Apply</Button>
								</div>														
							</div>
						</Popover>
					</div>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>		
								<TableCell></TableCell>	
								<TableCell></TableCell>	
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{newCompany && <Row
								key={newCompany.id}
								row={newCompany}
								onAddMaterial={() => setNewCompany(undefined)}
								onCancelNewMaterial={() => setNewCompany(undefined)}
								isEditing={true} />}
							{companies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (<Row key={row.name} row={row} />)
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[50, 100, 500, 1000, { label: 'All', value: 100000 }]}
					component="div"
					count={companies.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={() => null}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
			{companies.length > 0 && <div style={{
				display: 'flex',
				justifyContent : 'space-between',
				marginTop: 10
			}}>
				<div/>
				<Button					
					onClick={onClickExport}
					variant='outlined'>
						Export CSV
				</Button>
			</div>}
		</div>
	);

	async function onClickRunUpdate() {

		// const chunks = lodash.chunk(companies, 500)
		// await chunks.reduce((previous, chunk, index) =>
		// 	previous.then(
		// 		() => {
		// 			return new Promise(async (resolve, reject) => {
		// 				console.log('Chunk : ', index, chunk.length)
		// 				const batch = firestar.firestore().batch()
		// 				try {
		// 					chunk.forEach((company) => {
		// 						const existingName: string = company.address?.country ?? ''
		// 						const countryObject = countries.find(
		// 							(c) => {
		// 								const countryName: string = c. ?? '' as string
		// 								if (countryName.trim().toLowerCase() == existingName.trim().toLowerCase()) {
		// 									return c
		// 								}
		// 							}
		// 						)
		// 						if (countryObject) {
		// 							const updatedCompany = {
		// 								...company,
		// 								id: company.id,
		// 								address: {
		// 									...company.address,
		// 									translatedCountry: {
		// 										en: countryObject.countryName,
		// 										de: countryObject.countryNameDe,
		// 										es: countryObject.countryNameEs,
		// 									},
		// 									country: countryObject.countryName,
		// 									countryCode: countryObject.code
		// 								}
		// 							} as Company
		// 							console.log('Matching : ', updatedCompany)
		// 							updateCompany(updatedCompany, batch)
		// 						} else {
		// 							console.log('Excluded', company.address?.country)
		// 						}
		// 					})
		// 					await batch.commit()
		// 				} catch (error) {
		// 					console.log('Error Updating Chunk : ',error)
		// 				}
		// 				resolve()
		// 			})
		// 		}
		// 	), Promise.resolve())
	}
}

function Row(props: { row: Company, isEditing?:boolean, 
	onAddMaterial?:()=>void,
	onCancelNewMaterial?:() => void
}) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();
	let companiesState = useSelector((state: AppState) => state.companies)
	const [company, setCompany] = useState<Company>(lodash.cloneDeep(companiesState.find(c => c.id == row.id) ?? row))
	const [isEditing, setIsEditing] = useState<boolean>(props.isEditing ?? false)
	const [showLoader, setShowLoader] = useState<boolean>(false)
	const [showSnackbar, setShowSnackbar] = useState(false)	

	const dispatch = useDispatch()

	function Alert(props: AlertProps) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}

	function onChangeText(name:string,value:any) {
		const updatingCompany = { ...company }
		_.set(updatingCompany, name, value)
		setCompany(updatingCompany)
	}

	async function onClickUpdate() {
		setShowLoader(true)

		const oldCopy = companiesState.find(c => c.id == company.id)
		if(oldCopy)
			await checkCompanyNameChange(oldCopy,company)

		company.lastManualUpdate = getSummarizeUserInfo()
		
		dispatch(updateCompanyInfo(company))
			.then(
				() => {
					setShowLoader(false)
					setShowSnackbar(true)
				}
			)
			.catch(
				(error) => alert('Error Updating Material' + String(error))
			)
	}

	function onClickAdd() {

		setShowLoader(true)
		dispatch(addCompany(company))
			.then(
				() => {
					setShowLoader(false)
					setShowSnackbar(true)
					props.onAddMaterial && props.onAddMaterial()
				}
			)
			.catch(
				(error) => alert('Error Updating Material' + String(error))
			)
	}

	function onClickDeleteCompany (){
		setShowLoader(true)
		dispatch(deleteCompany(company))
			.then(
				() => {
					setShowLoader(false)
					setShowSnackbar(true)
				}
			)
			.catch(
				(error) => alert('Error Updating Material' + String(error))
			)
	}

	if (showLoader)
		return (
			<React.Fragment>
				<TableRow
					className={classes.root}>
					<TableCell
						style={{
							background: '#dddddd',
							justifyContent: 'center',
							alignItems: 'center'
						}}
						colSpan={columns.length + 2}>
						<CircularProgress color="inherit" />
					</TableCell>
				</TableRow>
			</React.Fragment>
		)

	return (
	  <React.Fragment>
		  <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
				<Alert onClose={() => setShowSnackbar(false)} severity="success">
					Company <b>{company.name}</b> Updated Successfully
				</Alert>
			</Snackbar>
		 <TableRow className={classes.root}>
		 <TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell style={{
					paddingRight: 0,
					paddingLeft: 0,
					display : 'flex',
					flexDirection : 'column'
				}}>
					<IconButton onClick={() => {	
						if (isEditing) {
							setCompany(row)
						}
						setIsEditing(!isEditing)
						props.onCancelNewMaterial && props.onCancelNewMaterial()
					}}>
						{isEditing ? <CancelIcon /> : <EditIcon />}
					</IconButton>
					{isEditing && <IconButton 
						onClick={() => {
							if(company.name?.trim().length == 0){
								alert('Company Name is mandatory!')
								return 
							}
							if(!validateUrl(company.social?.facebookUrl)){
								alert('Please Correct Facebook Url. Url must start with http:// or https://. If you do not know the url please enter n/a')
								return 
							}
							if(!validateUrl(company.social?.homepage)){
								alert('Please Correct Homepage Url. Url must start with http:// or https://. If you do not know the url please enter n/a')
								return 
							}
							if(!validateUrl(company.social?.instagramUrl)){
								alert('Please Correct Instagram Url. Url must start with http:// or https://. If you do not know the url please enter n/a')
								return 
							}
							setIsEditing(false)
							company.id.length > 0 ? onClickUpdate() : onClickAdd()
						}}>
						<CheckIcon />
					</IconButton>}
					{isEditing && company.id?.length > 0 && <IconButton 
						onClick={onClickDeleteCompany}>
						{/* <DeleteIcon />	 */}
					</IconButton>}
				</TableCell>
				{columns.map((column) => {
					const value = lodash.get(company, column.id)
					if ((column.editable ?? true) == false || !isEditing)
						return (
							<TableCell key={column.id} align={column.align}>
								{column.format ? column.format(value) : value}
							</TableCell>
						)
					if (!isEditing)
						return (
							<TableCell key={column.id} align={column.align}>
								{column.format ? column.format(value) : value}
							</TableCell>
						)

					if (column.type == 'text') {
						return (
							<TableCell key={column.id} align={column.align}>
								<GFTextField 
									label={column.label}
									onChangeText={(value) => onChangeText(column.id,value)}
									editing={isEditing}
									value={column.format ? column.format(value) : value}/>
							</TableCell>
						)
					}else if(column.type == 'checkbox'){
						return (
							<TableCell key={column.id} align={column.align}>
								<GFCheckbox 
									onChangeValue={(value) => onChangeText(column.id,value)}
									value={value} 
									editing={isEditing}
									label={column.label}/>
							</TableCell>
						)
					}else if(column.type == 'select'){
						return (
							<TableCell key={column.id} align={column.align}>
								<GFSelect 
									label={column.label}
									onSelectValue={(value) => onChangeText(column.id,value)}
									editing={isEditing}
									types={column.selectTypes}
									value={value}/>
							</TableCell>
						)
					}else if(column.type == 'autocomplete'){

						const option = _.get(company,column.targetPath!) 
						const options = column.options ?? []
						return (
							<TableCell key={column.id} align={column.align}>
								<GFAutoComplete 
									value={{
										label : option,
										value : option
									}}
									onChangeText={
										(value) => onChangeText(column.targetPath!,value)
									}
									editing={isEditing}
									options={options}
									label={column.label}/>
							</TableCell>
						)
					}
				})}
		 </TableRow>
		 <TableRow>
				<TableCell style={{ padding: 0 }} colSpan={2} />
				<TableCell style={{ padding: 0 }} colSpan={5}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<CompanyForm 
							company={company} 
							isEditing={isEditing} 
							onPressRefresh={
								() => {									
									setShowLoader(true)
									dispatch(getCompanyDetails(company.id))
									.then(
										(response) => {
											setShowLoader(false)
											setCompany(response)
										}
									)
									.catch(
										(error) => {
											console.log('error',error)
											setShowLoader(false)
										}
									)
								}
							}
							onChangeText={onChangeText}/>
					</Collapse>
				</TableCell>
			</TableRow>
	  </React.Fragment>
	);
 }


function CompanyForm(props: {
	company: Company,
	isEditing: boolean,
	onChangeText : (name:string,value:any) => void,
	onPressRefresh : () => void
}) {

	const company = props.company
	const isEditing = props.isEditing

	return (
		<Box margin={1} style={{
			paddingBottom: 20
		}}>
			<div style={{
				marginBottom : 15
			}}>
				<div style={{
					marginBottom : 15
				}}>
					<Typography style={{
						marginBottom : 5
					}}>Social</Typography>
					<GFTextField
						style={{ marginRight : 10 }}
						label='Facebook Url'
						onChangeText={(value) => props.onChangeText('social.facebookUrl', value)}
						value={company.social?.facebookUrl}
						editing={isEditing} />
					<GFTextField
						onChangeText={(value) => props.onChangeText('social.instagramUrl', value)}
						style={{ marginRight : 10 }}
						label='Instagram Url'
						value={company.social?.instagramUrl}
						editing={isEditing} />
					<GFTextField
						onChangeText={(value) => props.onChangeText('social.homepage', value)}
						style={{ marginRight : 10 }}
						label='Homepage'
						value={company.social?.homepage}
						editing={isEditing} />
				</div>
				<div style={{
					marginBottom : 15
				}}>
					<Typography style={{ marginBottom : 10 }}>Description</Typography>
					<GFTextArea
						style={{ marginRight: 5, width: '80%' }}
						onChangeText={(value) => props.onChangeText('description', value)}
						label='Description'
						value={company.description}
						editing={isEditing} />
				</div>
				{company.translatedDescription && <div style={{
					marginBottom : 15
				}}>
					{Object.keys(orderTranslationObject(company.translatedDescription)).map(
						(key) => <GFTextArea
							style={{ marginBottom: 10, width: '80%' }}
							label={'Description '+key.toUpperCase()}
							onChangeText={(value) => props.onChangeText(`translatedDescription.${key}`, value)}
							value={company.translatedDescription[key]}
							editing={isEditing} />
					)}
				</div>}
				<div style={{
					marginBottom : 30
				}}>
					<Typography style={{
						marginBottom: 15
					}}>Address</Typography>
					<GFTextField
						label='Street'
						style={{ 
							marginRight : 10,
							minWidth: 300
						}}
						onChangeText={(value) => props.onChangeText('address.street', value)}
						value={company.address?.street}
						editing={isEditing} />
					<GFTextField
						label='State'
						style={{ 
							marginRight : 10,
							minWidth: 300
						}}
						onChangeText={(value) => props.onChangeText('address.state', value)}
						value={company.address?.state}
						editing={isEditing} />										
					{company.address?.translatedCity && <View style={{
						marginVertical: 15,
						flexDirection: 'row',
						flexWrap: 'wrap',	
						width: '80%'				
					}}>
						<GFTextField
							label='City'
							style={{ 
								marginRight: 10,
								minWidth: 300,
								marginBottom: 10
							}}
							onBlur={() => {
								Object.keys(orderTranslationObject(company.address?.translatedCity)).map(
									(key) => {
										props.onChangeText(
											`address.translatedCity.${key}`, 
											company.address?.city
										)
									}
								)								
							}}
							onChangeText={(value) => props.onChangeText('address.city', value)}
							value={company.address?.city}
							editing={isEditing} />
						{Object.keys(orderTranslationObject(company.address?.translatedCity)).map(
							(key) => <GFTextField
								style={{ 
									marginRight: 10,
									minWidth: 300,
									marginBottom: 10
								}}
								label={'City ' + key.toUpperCase()}
								onChangeText={(value) => props.onChangeText(`address.translatedCity.${key}`, value)}
								value={company.address?.translatedCity[key]}
								editing={isEditing} />
						)}
					</View>}
					<View style={{
						flexDirection: 'row',
						maxWidth: 500,
						alignItems: 'center'
					}}>
						<GFAutoComplete
							editing={isEditing}
							style={{
								minWidth: 300,
								marginRight: 10
							}}
							value={{
								label: company.address?.country ?? '',
								value: company.address?.country ?? ''
							}}
							onChangeText={(value) => {
								props.onChangeText(`address`, {
									...company.address,
									...convertCountryToAddressObject(value ?? {})
								} as Address)
							}}
							options={countriesOptions}
							label='Country' />
						<GFTextField
							style={{
								minWidth: 150
							}}
							label='Postal Code'
							onChangeText={(value) => props.onChangeText('address.postalCode', value)}
							value={company.address?.postalCode}
							editing={isEditing} />
						{/* {company.address?.translatedCountry && <div style={{
							marginBottom: 15
						}}>
						{Object.keys(orderTranslationObject(company.address?.translatedCountry)).map(
							(key) => <GFTextField
								style={{ marginRight : 10 }}
								label={'Country ' + key.toUpperCase()}
								onChangeText={(value) => props.onChangeText(`address.translatedCountry.${key}`, value)}
								value={company.address?.translatedCountry[key]}
								editing={false} />
						)}
					</div>} */}
					</View>
				</div>
				<div style={{
					marginBottom: 15
				}}>
					<Typography style={{ marginBottom: 5 }}>Comments</Typography>
					<GFTextArea
						style={{ marginRight: 5, width: '80%' }}
						onChangeText={(value) => props.onChangeText('comments', value)}
						label='Description'
						value={company.comments}
						editing={isEditing} />
				</div>
			</div>
			<div style={{
				display: 'flex'
			}}>
				<div style={{
					marginRight: 20
				}}>
					{company.lastManualUpdate &&
						<Typography style={{ marginBottom: 5 }}>
							<b>Last Manual Update By</b> : {company.lastManualUpdate?.email}
						</Typography>}
					{company.updatedBy?.email &&
						<Typography style={{ marginBottom: 5 }}><b>Last Updated By</b> : {company.updatedBy?.email}</Typography>}
					{company.updatedAt &&
						<Typography style={{ marginBottom: 5 }}><b>Last Updated At</b> : {moment(company.updatedAt.toDate()).fromNow()}</Typography>}
				</div>
				<div>
					{company.createdBy?.email &&
						<Typography style={{ marginBottom: 5 }}><b>Last Created By</b> : {company.createdBy?.email}</Typography>}
					{company.createdAt &&
						<Typography style={{ marginBottom: 5 }}><b>Last Created At</b> : {moment(company.createdAt.toDate()).format('DD/MMM/YYYY')}</Typography>}
				</div>
			</div>
			{!isEditing && <button
				onClick={props.onPressRefresh}>Refresh</button>}
		</Box>
	)
}