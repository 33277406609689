"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = __importStar(require("firestar"));
var Recipes;
(function (Recipes) {
    function getAll(descriptor) {
        return firestar_1.Actions.readCollection('recipes', descriptor);
    }
    Recipes.getAll = getAll;
    function getByMaterialId(materialId, descriptor) {
        var _a;
        return firestar_1.Actions.readCollection('recipes', Object.assign(Object.assign({}, descriptor), { where: [
                {
                    fieldPath: 'materialId',
                    opStr: '==',
                    value: materialId
                },
                ...(_a = descriptor.where) !== null && _a !== void 0 ? _a : []
            ] }));
    }
    Recipes.getByMaterialId = getByMaterialId;
    function get(recipeId) {
        return firestar_1.Actions.readDocument(`recipes/${recipeId}`);
    }
    Recipes.get = get;
    function update(recipeId, document) {
        return firestar_1.Actions.updateDocument(`recipes/${recipeId}`, document);
    }
    Recipes.update = update;
    function updateUri(recipeId, document, batch) {
        let urlTags = [];
        const name = document.combinations.map(m => m.name).join(' ');
        const names = name.replace(/[^a-zA-Z0-9]/g, " ").split(' ');
        urlTags = urlTags
            .concat(names.map(n => n.trim().toLowerCase()))
            .filter(n => n.length > 0);
        return firestar_1.Actions.updateDocument(`recipes/${recipeId}`, {
            uri: urlTags.join('-')
        }, batch);
    }
    Recipes.updateUri = updateUri;
    function updateAverage(recipeId, absoluteAverage, relativeAverage, batch) {
        const path = `recipes/${recipeId}`;
        return firestar_1.Actions.updateDocument(path, {
            'ratings.relativeAverage': relativeAverage,
            'ratings.absoluteAverage': absoluteAverage
        }, batch);
    }
    Recipes.updateAverage = updateAverage;
    function addRating(recipeId, rating, batch) {
        const path = `recipes/${recipeId}`;
        const firestore = firestar_1.default.getFirestore();
        const increment = firestore.FieldValue.increment(rating !== null && rating !== void 0 ? rating : 0);
        const numberOfRatings = firestore.FieldValue.increment(1);
        return firestar_1.Actions.updateDocument(path, {
            'ratings.absoluteTotal': increment,
            'ratings.numberOfRatings': numberOfRatings
        }, batch);
    }
    Recipes.addRating = addRating;
    function updateRating(recipeId, rating, batch) {
        const firestore = firestar_1.default.getFirestore();
        const path = `recipes/${recipeId}`;
        const increment = firestore.FieldValue.increment(rating !== null && rating !== void 0 ? rating : 0);
        return firestar_1.Actions.updateDocument(path, {
            'ratings.absoluteTotal': increment,
        }, batch);
    }
    Recipes.updateRating = updateRating;
    function deleteRating(recipeId, rating, batch) {
        const path = `recipes/${recipeId}`;
        const firestore = firestar_1.default.getFirestore();
        const increment = firestore.FieldValue.increment(Math.abs(rating) * -1);
        const numberOfRatings = firestore.FieldValue.increment(-1);
        return firestar_1.Actions.updateDocument(path, {
            'ratings.absoluteTotal': increment,
            'ratings.numberOfRatings': numberOfRatings
        }, batch);
    }
    Recipes.deleteRating = deleteRating;
})(Recipes || (Recipes = {}));
exports.default = Recipes;
