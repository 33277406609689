import { Actions } from 'firestar'
import { Platform } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import CountriesList from '../res/CountriesList.json'
import { Country } from 'gf-connect/lib/User'
import { createFlatEmptyLanguageKeys } from 'gf-connect/lib/Utils'
import lodash from 'lodash'
import * as RNLocalize from "react-native-localize";
import Countries from '../res/countries.json'
import firebase from 'firebase/compat/app'

export function emailVerificationStatus() {
   const provider = firebase.auth().currentUser?.providerData[0]
   if (provider?.providerId == 'password') {
      return firebase.auth().currentUser?.emailVerified
   }
   return true
}

export function validateEmail(email) {
   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase());
}

export function getWebAppVersion(){
   return ''
}

async function getDeviceInfo() {
   return new Promise<any>(async (resolve) => {
      const manufacturer = await DeviceInfo.getManufacturer()
      const name = await DeviceInfo.getDeviceName()
      resolve({
         OS: {
            name: DeviceInfo.getSystemName(),
            version: DeviceInfo.getSystemVersion()
         },
         application: {
            buildNumber: DeviceInfo.getBuildNumber(),
            version: DeviceInfo.getVersion()
         },
         brand: DeviceInfo.getBrand(),
         deviceType: DeviceInfo.getDeviceType(),
         manufacturer: manufacturer,
         model: DeviceInfo.getModel(),
         name: name,
         country: RNLocalize.getCountry()
      })
   })
}

export async function prepareDevice(){
   return new Promise<any>(async (resolve,reject) => {
      try {
         const deviceInfo = await getDeviceInfo()
         var device = {
            uid: DeviceInfo.getUniqueId(),
            metadata: deviceInfo,
            status: 'offline',
            id: DeviceInfo.getUniqueId()
         }
         resolve(device)
      } catch (error) {
         reject(error)
      }
   })
}

export function isDev(){
   if(Platform.OS == 'web'){
      return (window.location.hostname.includes('localhost'))
   }else{
      return __DEV__
   }
}

export function createCountriesList(){
   const list = Countries.map((c) => {
      return {
         code: c.code,
         extendedCode: c.codeExtended,
         name: c.countryName,
         translatedName: {
            en: c.countryName,
            de: c.countryNameDe,
            es: c.countryNameEs,
            it: c.countryNameIt,
            fr: c.countryNameFr
         },
         numericCode: String(c['ISO 3166 numeric']),
      }
   }) as Country[]
   console.log('Countries',JSON.stringify(list))
}

export function getSearchableCountries(sortKey?:string) : Country[]{
   return CountriesList.map((c) => {
      const __country = {
         ...c
      } as Country
      Actions.appendSearchTags<Country>(__country,{
         splitKeys: [
            'name',
            'extendedCode',
            ...createFlatEmptyLanguageKeys('translatedName','.')
         ],
         absoluteKeys: [],
         createMatches: true,
         includeDocumentId: true,
         includeSpecialCharacters: true
      })
      return __country
   }).sort((a,b) => a.translatedName[sortKey ?? 'en'].localeCompare(b.translatedName[sortKey ?? 'en'])) as Country[]
}

export function getSearchableCountryObject(key:string,value?:string) : {[key: string]: Country}{

   const countryObject = {} as {[key: string]: Country}
   getSearchableCountries().forEach((c) => {
      countryObject[c[key]] = value ? c[value] : c
   })
   return countryObject
}

export function getCountryWithCode(code:string,includeTranslations?:boolean) : Country{
   const result = lodash.cloneDeep(CountriesList.find((c) => c.code == code)!) as any
   if((includeTranslations ?? false) == false){
      delete result.translatedName
   }
   return result
}

