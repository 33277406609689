import React, { Component } from 'react'

import {
	Switch,
	Route,
} from "react-router-dom";

import Dashboard from './Dashboard'
import Companies from './Companies'
import Materials from './Materials'
import Users from './Users'
import Recipes from './Recipes';
import AddRecipe from './AddRecipe';
import SearchRecipe from './SearchRecipe';
import Settings from './Settings';
import Promotions from './Promotions'
import RatingsHealth from './RatingsHealth';
import MaterialsBeta from './MaterialsBeta';
import Shops from './Shops';
import CompaniesBeta from './CompaniesBeta'
import Playground from './Playground';
import RecipeHealth from './RecipeHealth';
import BarcodesMapping from './BarcodesMapping';
import Rooms from './Rooms';
import Media from './Media';

export class Main extends Component {

	constructor(props: any) {
		super(props)
	}

	render() {
		return (
			<div>
				<Switch>
					<Route exact path="/">
						<Dashboard />
					</Route>
					<Route exact path="/companies">
						<CompaniesBeta />
					</Route>
					<Route exact path="/materials">
						<MaterialsBeta />
					</Route>
					<Route exact path="/companies/old">
						<Companies />
					</Route>
					<Route exact key='standard' path="/materials/old">
						<Materials />
					</Route>
					<Route exact key='barcodes' path="/materials/barcodes">
						<BarcodesMapping />
					</Route>
					<Route path="/users">
						<Users />
					</Route>
					<Route exact path="/recipes">
						<Recipes />
					</Route>
					<Route path="/recipes/add-recipe">
						<AddRecipe />
					</Route>
					<Route path="/recipes/search">
						<SearchRecipe />
					</Route>
					<Route path="/recipes/edit/:id">
						<AddRecipe />
					</Route>
					<Route exact path="/settings">
						<Settings />
					</Route>
					<Route exact path="/settings/ratings-health">
						<RatingsHealth />
					</Route>
					<Route path="/promotions">
						<Promotions />
					</Route>
					<Route exact path="/shops">
						<Shops />
					</Route>
					<Route exact path="/shops/company/:companyId" component={Shops}/>
					<Route path="/rooms">
						<Rooms />
					</Route>
					<Route path="/playground">
						<Playground />
					</Route>
					<Route exact path="/settings/ratings-health/:materialId">
						<RecipeHealth />
					</Route>
					<Route exact path="/media">
						<Media />
					</Route>
				</Switch>
			</div>
		)
	}
}

export default Main