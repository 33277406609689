import React, { useState, useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MaterialTable from 'material-table';
import {
   User,
   getUsers
} from 'gf-connect/lib/User';
import {
   Icon,
   SearchBar
} from 'react-native-elements'
import { WhereType, CollectionDescriptor } from 'firestar';
import {
   View, Text
} from 'react-native';
import { getSearchableCountryObject } from './utils';
import moment from 'moment';
import { useDebounce } from './MaterialsBeta';
import Paper from '@material-ui/core/Paper';
import { Alert } from './Alert'

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

const FilterTypes = {
   'language': 'Language',
   'currentDevice.metadata.OS.name': 'Operating System',
   'shippingCountry.code': 'Shipping Country',
}

const FilterOptions = {
   'language': {
      'none': 'None',
      'en': 'English',
      'de': 'German',
      'es': 'Spanish',
   },
   'currentDevice.metadata.OS.name': {
      'none': 'None',
      'iOS': 'iOS',
      'Android': 'Android',
   },
   'shippingCountry.code': {
      'none': 'None',
      ...getSearchableCountryObject('code', 'name')
   }
}

const FilterNameOptions = {
   'language': 'Language',
   'currentDevice.metadata.OS.name': 'OS',
   'shippingCountry.code': 'Shipping Country'
}

export default function Users() {

   const [title, setTitle] = useState('')
   const [description, setDescription] = useState('')
   const [link, setLink] = useState('')
   const [topic, setTopic] = useState('')
   const [condition, setCondition] = useState('')
   const [imageUrl, setImageUrl] = useState('')
   const [searchText, setSearchText] = useState('')
   const [showLoader, setShowLoader] = useState(false)
   const [users, setUsers] = useState<User[]>([])
   const [selectedUsers, setSelectedUsers] = useState<User[]>([])
   const tableRef = useRef<any>()
   const debouncedSearchTerm = useDebounce(searchText, 1000);

   const [selectedFilter, setSelectedFilter] = useState<{
      'language': string,
      'currentDevice.metadata.OS.name': string,
      'shippingCountry.code': string,
   }>({
      'language': 'none',
      'currentDevice.metadata.OS.name': 'none',
      'shippingCountry.code': 'none',
   })

   const [lastDoc, setLastDoc] = useState<any>()

   useEffect(() => {
      if (searchText.trim().length == 0) {
         getUserList(searchText)
      } else {
         setShowLoader(true)
      }
   }, [searchText])

   useEffect(
      () => {
         // Make sure we have a value (user has entered something in input)
         if (debouncedSearchTerm) {
            // Set isSearching state
            //  setIsSearching(true);
            //  // Fire off our API call
            //  searchCharacters(debouncedSearchTerm).then(results => {
            // 	// Set back to false since request finished
            // 	setIsSearching(false);
            // 	// Set results state
            // 	setResults(results);
            //  });
            getUserList(searchText)
         }
      },
      // This is the useEffect input array
      // Our useEffect function will only execute if this value changes ...
      // ... and thanks to our hook it will only change if the original ...
      // value (searchTerm) hasn't changed for more than 500ms.
      [debouncedSearchTerm]
   );

   useEffect(() => {
      if (selectedFilter) {
         getUserList(searchText)
      }
   }, [selectedFilter])

   return (
      <div>
         <View style={{
            flex: 1,
            marginTop: 10,
            marginBottom: 10,
            justifyContent: "space-between",
            flexDirection: 'column'
         }}>
            <View style={{
               display: 'flex',
               flex: 1,
               flexDirection: 'row',
               justifyContent: 'flex-end'
            }}>
               <View></View>
               <Paper elevation={0} variant='outlined' style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '1em',
                  flex: 0.6,
                  textAlign: 'left'
               }}>
                  <h2>Messaging</h2>
                  <TextField
                     style={{
                        marginRight: 10
                     }}
                     variant='outlined'
                     label='Title'
                     value={title}
                     onChange={(event) => setTitle(event.target.value)}
                     placeholder='Title' />
                  <br />
                  <TextField
                     style={{
                        marginRight: 10
                     }}
                     multiline
                     variant='outlined'
                     label='Description'
                     value={description}
                     onChange={(event) => setDescription(event.target.value)}
                     placeholder='Description' />
                  <br />
                  <TextField
                     style={{
                        marginRight: 10
                     }}
                     variant='outlined'
                     label='Link'
                     value={link}
                     onChange={(event) => setLink(event.target.value.trim())}
                     placeholder='Link' />
                  <br />
                  <TextField
                     style={{
                        marginRight: 10
                     }}
                     variant='outlined'
                     label='Topic'
                     value={topic}
                     onChange={(event) => setTopic(event.target.value.trim())}
                     placeholder='Topic' />
                  <span style={{ color: 'gray' }}>Eg: language_de</span>
                  <br />
                  <TextField
                     style={{
                        marginRight: 10
                     }}
                     variant='outlined'
                     label='Condition'
                     value={condition}
                     onChange={(event) => setCondition(event.target.value)}
                     placeholder='Condition' />
                  <span style={{ color: 'gray' }}>Eg: {`'language_de' in topics && 'region_de' in topics && 'allowNotifications' in topics`}</span>
                  <br />
                  <TextField
                     style={{
                        marginRight: 10
                     }}
                     variant='outlined'
                     label='Image Url'
                     value={imageUrl}
                     onChange={(event) => setImageUrl(event.target.value.trim())}
                     placeholder='Image Url' />
                  <br />
                  <Button
                     onClick={onPressSendNotification}
                     disabled={selectedUsers.length === 0 /*&& topic.length === 0 && condition.length === 0*/}
                     variant='contained'>SEND</Button>
               </Paper>
            </View>
            <br />
            <br />
            <View style={{
               flexDirection: 'row',
               justifyContent: 'flex-end',
            }}>
               {Object.keys(FilterTypes).map((key) => {
                  const options = FilterOptions[key] ?? []
                  return (
                     <div>
                        <label>{FilterNameOptions[key]}: </label>
                        <select onChange={(event) => {
                           setSelectedFilter({
                              ...selectedFilter,
                              [key]: event.target.value
                           })
                        }}>
                           {Object.keys(options).map((name) => (
                              <option value={name}>{options[name]}</option>
                           ))}
                        </select>
                        &nbsp;
                     </div>
                  )
               })}
            </View>
            <br />
            <View style={{
               alignItems: 'center',
               flexDirection: 'row',
               justifyContent: "space-between"
            }}>
               <Button disableElevation variant='outlined' onClick={() => {
                  setLastDoc(undefined)
                  getUserList(searchText)
               }}>Refresh</Button>
               <SearchBar
                  value={searchText}
                  inputStyle={{
                     color: 'black'
                  }}
                  placeholder='Search with keywords'
                  onChangeText={(value) => setSearchText(value)}
                  containerStyle={{
                     backgroundColor: 'clear',
                     borderTopWidth: 0,
                     borderBottomWidth: 0,
                     width: 350,
                  }}
                  inputContainerStyle={{
                     backgroundColor: '#dddddd',
                     borderRadius: 25
                  }} />
            </View>
         </View>
         <MaterialTable
            tableRef={(ref) => tableRef.current = ref!}
            title='Users'
            data={users}
            isLoading={showLoader}
            options={{
               search: false,
               pageSizeOptions: [PAGESIZE],
               pageSize: PAGESIZE,
               selection: true,
               selectionProps: rowData => ({
                  disabled: rowData.token == null
               })
            }}
            onSelectionChange={onSelectionChange}
            onSearchChange={searchUser}
            onChangePage={onChangePage}
            columns={[
               {
                  field: 'displayName',
                  title: 'Name',
                  headerStyle: { fontWeight: 'bold' },
                  render: (user) => {
                     return (
                        <View>
                           <Text>{(user.isAnonymous ?? false) ? 'Anonymous' : user.displayName}</Text>
                           <Text style={{
                              fontSize: 10,
                           }}>{user.email}</Text>
                           <Text style={{
                              fontSize: 10,
                           }}>{user.username}</Text>
                           <Text style={{
                              color: 'gray',
                              fontSize: 10,
                           }}>{user.id}</Text>
                        </View>
                     )
                  }
               },
               {
                  field: 'id',
                  title: 'Id',
                  hidden: true
               },
               {
                  title: 'Last Signed In',
                  headerStyle: { fontWeight: 'bold' },
                  render: (user) => {
                     const date = user.lastSignInAt?.toDate()
                     return (
                        <div style={{
                           textAlign: 'center'
                        }}>
                           <div style={{
                              fontSize: 11,
                           }}>{moment(date).fromNow()} • {moment(date).format('HH:MM A • dd:mm:yyyy')}</div>
                        </div>
                     )
                  }
               },
               {
                  title: 'Language & Region',
                  headerStyle: { fontWeight: 'bold' },
                  render: (user) => (
                     <div>
                        <div><b>Language:</b> {user.language ?? 'NA'}</div>
                        <div><b>Region:</b> {user.region ?? 'NA'}</div>
                     </div>
                  )
               },
               {
                  field: 'currentDevice.metadata.OS.name',
                  title: 'Device & App',
                  headerStyle: { fontWeight: 'bold' },
                  render: (user) => {
                     const metadata = user.currentDevice?.metadata
                     const app = metadata?.application
                     return (
                        <div style={{
                           textAlign: 'center'
                        }}>
                           <div>{metadata?.name}</div>
                           <div>{metadata?.OS?.name} • {metadata?.OS?.version}</div>
                           {app != null && <div>{app?.version}({app?.buildNumber})</div>}
                        </div>
                     )
                  }
               },
               {
                  field: 'shippingCountry.code',
                  title: 'Shipping Country',
                  headerStyle: { fontWeight: 'bold' },
               },
               {
                  field: 'token',
                  title: 'Notification',
                  render: (user) => (
                     <div style={{
                        textAlign: 'center'
                     }}>
                        {user.token ?
                           (<Text style={{ color: 'green', fontWeight: 'bold' }}>Available</Text>) :
                           (<Text style={{ color: 'red', fontWeight: 'bold' }}>Unavailable</Text>)}
                        <br />
                        <Text>{(user.allowNotifications ?? true) ? 'Enabled' : 'Disabled'}</Text>
                     </div>
                  ),
                  headerStyle: { fontWeight: 'bold' },
               },
               {
                  field: 'id',
                  editable: 'never',
                  title: '',
                  render: (u) => (
                     <View style={{
                        flexDirection: 'row',
                        alignItems: 'center'
                     }}>
                        <Icon
                           onPress={() => window.open('https://admin.ginferno.app/playground/users/' + u.id)}
                           name='games' />
                     </View>
                  ),
                  sorting: true,
               },
               {
                  field: 'createdAt',
                  title: 'Created At',
                  headerStyle: { fontWeight: 'bold' },
                  render: (user) => {
                     const date = user.createdAt?.toDate?.()
                     if(date == null){
                        return <div style={{ textAlign: 'center' }}>Date Error</div>
                     }
                     return (
                        <div style={{
                           textAlign: 'center'
                        }}>
                           <div>{moment(date).fromNow()}</div>
                           <span style={{
                              fontSize: 12,
                           }}>{moment(date).format('HH:MM A • dd:mm:yyyy')}</span>
                        </div>
                     )
                  }
               },
            ]} />
      </div>
   )

   async function getUserList(text: string, lastDoc?: any) {

      setShowLoader(true)
      const searchTerm = text.trim().toLowerCase()
      try {

         const whereList: WhereType[] = []
         // let isFilterAdded = false
         Object.keys(selectedFilter).forEach((key) => {
            const value = selectedFilter[key]
            if (value != 'none') {
               whereList.push({
                  fieldPath: key,
                  opStr: '==',
                  value: value
               })
               // isFilterAdded = true
            }
         })

         if (searchTerm.trim().length > 0) {
            whereList.push({
               fieldPath: 'tags',
               opStr: 'array-contains-any',
               value: searchTerm.trim().split(' ')
            })
         }

         const descriptor : CollectionDescriptor = {
            limit: lastDoc ? PAGESIZE : INITIAL_PAGESIZE,
            where: whereList,
            lastDoc: lastDoc,
            orderBy: {
               fieldPath: 'createdAt',
               directionStr: 'desc'
            }
         }

         console.log('descriptor', descriptor)
         const response = await getUsers(descriptor)

         setLastDoc(response.lastDoc)

         if (lastDoc) {
            setUsers([...users, ...response.data as User[]])
         } else {
            const users = response.data as User[]
            setUsers(users)
         }
      } catch (error) {
         Alert.alert('Error Getting Users', String(error))
         console.log('Error Getting Users : ', error)
         setUsers([])
      }
      setShowLoader(false)

   }

   function onChangePage(page: number, pageSize: number) {
      if (users.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE)) {
         startPagination()
      }
   }

   function startPagination() {
      if (lastDoc) {
         getUserList(searchText, lastDoc)
      }
   }

   async function onPressSendNotification() {

      if (topic.length > 0 || condition.length > 0) {
         Alert.alert(
            'Warning',
            'Are you sure want to send out this notification to all the select topics, conditions and users?',
            [
               {
                  text: 'Cancel',
                  style: 'cancel'
               },
               {
                  onPress: async () => {
                     onConfirmSendNotification()
                  },
                  text: 'Send',
                  style: 'destructive'
               }
            ]
         )
      } else {
         onConfirmSendNotification()
      }
   }

   async function onConfirmSendNotification() {
      setShowLoader(true)
      const url = 'https://us-central1-ginfernov2.cloudfunctions.net/sendNotification'
      // const url = 'http://127.0.0.1:5001/ginfernov2/us-central1/sendNotification'
      try {
         const payload = {
            notification: {
               title: title,
               description: description,
               imageUrl: imageUrl,
            },
            data: {
               link: link,
               imageUrl: imageUrl
            },
            deviceTokens: selectedUsers.map(u => u.token),
            topic: topic,
            condition: condition
         } as {
            notification: {
               title: string,
               description: string,
               imageUrl: string,
            },
            data: any,
            deviceTokens?: string[],
            topic?: string,
            condition?: string
         }
         console.log('Payload', payload)
         const response = await fetch(url, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
         })
         const notificationResponse = await response.json() as {
            payload: any,
            response: {
               failureCount: number,
               successCount: number,
               responses: {
                  success: boolean;
                  messageId?: string;
                  error?: {
                     code: string;
                     message: string;
                  }
               }[]
            }
         }
         const { responses } = notificationResponse.response ?? {
            failureCount: 0,
            successCount: 0,
            responses: []
         }

         const allResult = responses.map((r) => {
            const result = [] as string[]
            result.push('Result: ' + (r.success ? 'Success' : 'Failure'))
            if (r.messageId) {
               result.push('Message Id: ' + r.messageId)
            }
            if (r.error) {
               result.push('Error Code: ' + r.error.code)
               result.push('Error Message: ' + r.error.message)
            }
            return result.join('\n')
         }).join('\n\n')

         alert(allResult)
         console.log('Notification Result: ', allResult)

         setShowLoader(false)
         tableRef.current.onAllSelected(false)
      } catch (error) {
         setShowLoader(false)
         console.log('Failed to Send Notification : ', error)
         alert('Failed to Send Notification : ' + JSON.stringify(error))
      }
   }

   function onSelectionChange(data: User[], rowData?: User) {
      const userWithTokens = data.filter(u => u.token != null)
      setSelectedUsers(userWithTokens)
   }

   function searchUser(searchText) {
      setSearchText(searchText)
      getUserList(searchText)
   }

}
