import React, { useEffect, useState } from 'react'
import {
   Users
} from 'firestar'
import firebase from 'firebase/compat/app'
import { Alert } from './Alert'
import Home from './Home'

export default function Landing() {

   const [claims, setClaims] = useState<Users.UserClaims>()
   const roles = claims?.roles ?? {}
   const [showLoader, setShowLoader] = useState(true)

   useEffect(() => {
      fetchClaims()
   }, [])

   if (showLoader === true) {
      return (<div>Checking Access Roles...</div>)
   } else {
      if (roles['ginferno-admin'] != null) {
         return (<Home/>)
      } else {
         return (
            <div>
               <p>You do not have access this panel</p>
               <p><button onClick={onClickLogout}>Logout</button></p>
            </div>
         )
      }
   }

   async function onClickLogout() {
      setShowLoader(true)
      await firebase.auth().signOut()
      setShowLoader(false)
   }

   async function fetchClaims() {
      setShowLoader(true)
      try {
         const response = await firebase.auth().currentUser?.getIdTokenResult(true)
         setClaims(response?.claims)
      } catch (error) {
         Alert.alert('Error Getting Roles', String(error))
      }
      setShowLoader(false)
   }
}
