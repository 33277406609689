import React ,{ useEffect, useState } from 'react'
import {
   addRecipe, 
   Recipe,
   updateRecipeDetails
} from './store/Recipes';
import {
   Material
} from './store/Materials';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
   useDebounce
} from './MaterialsBeta';

import { GFAutoComplete } from './FormUtils';

import MaterialTable, 
   { 
      Column, 
      MTableToolbar, 
      QueryResult, 
      Query 
   } from 'material-table';

import { AppState } from './store';

import {
   MaterialTypes, summarizeMaterial
} from 'gf-connect/lib/Material';
import {
   RecommendedBy
} from 'gf-connect/lib/Recipe'

import {
   useSelector,
   useDispatch
} from 'react-redux'

import {
   useHistory,
   useParams,
   useLocation
} from 'react-router-dom';
import SearchMaterial from './SearchMaterial';

export default function AddRecipe() {

   const params : any = useParams()
   const recipes = useSelector((state:AppState) => state.recipes)
   const location = useLocation()
   const currentRecipe = recipes.find(r => r.id == params.id)
   const [recipe, setRecipe] = useState<Recipe|undefined>(currentRecipe ?? {
      combinations: (location.state as any)?.combinations ?? []
   } as Recipe)
   const materials : Material[] = useSelector((state:AppState) => state.materials)
   const [showProgress, setShowProgress] = useState(false)
   const dispatch = useDispatch()
   const history = useHistory()

   const fetchData = (query: Query<Material>) =>
   new Promise<QueryResult<Material>>((resolve, reject) => {
      resolve({
         data : recipe?.combinations ?? [],
         page : 0,
         totalCount : recipe?.combinations?.length ?? 0
      })
   })
   
   return (
      <div >
         <Backdrop style={{
               zIndex: 1000,
					color: '#fff',
					display : 'flex',
					flexDirection : 'column'
            }} open={showProgress}>
               <CircularProgress color="inherit" />
			</Backdrop>
         <div style={{
            display : 'flex',
            justifyContent : 'space-between',
            marginBottom : 10
         }}>
            <Typography                
               variant='h5'>{currentRecipe ? 'Edit Recipe' : 'Add Recipe'}</Typography>
            <Button 
               disabled={(recipe?.combinations ?? []).length < 1 || recipe?.recommendedBy == null}
               color='primary'
               onClick={onPressAddRecipe}
               variant='contained'>{(recipe?.id ?? '').length > 0 ? 'SAVE' : 'ADD'}</Button>
         </div>
         <Paper>
            <div style={{
               padding : 30,
               width: 400
            }}>
               <FormControl 
                  style={{
                  width: 340,
                  marginBottom : 10
               }} variant="outlined">
                  <InputLabel>Recommended By</InputLabel>
                  <Select
                     value={recipe?.recommendedBy}
                     onChange={(event) => {
                        const _recipe = {...recipe } as Recipe
                        _recipe.recommendedBy = event.target.value as any
                        setRecipe(_recipe)
                     }}>
                     {Object.keys(RecommendedBy).map(
                           (key) => <MenuItem value={key}>{(RecommendedBy as any)[key]}</MenuItem>
                        )}
                  </Select>
               </FormControl>
               <MaterialTable
                  data={fetchData}
                  title={'Combinations'}
                  options={{
                     search: false,
                  }}
                  columns={[
                     {
                        field: 'material',
                        lookup : materials,
                        editComponent: (props) => (
                           <SearchMaterial onSelectMaterial={(m) => {
                              props.onChange(m)
                           }}/>
                        ),
                        render : (row) => (
                           <div style={{
                              display : 'flex',
                              justifyContent : 'space-between'
                           }}>
                              <p>{row.name}</p>
                              <p>{MaterialTypes[row.type]}</p>
                           </div>
                        )
                     }
                  ]}
                  editable={{
                     isEditHidden : () => recipe?.id ? true : false,
                     isDeleteHidden : () => recipe?.id ? true : false,
                     onRowAdd : recipe?.id ? undefined : ({material} : any) => (
                        new Promise<void>((resolve, reject) => {
                           const _recipe = {...recipe} as Recipe
                           const combinations = recipe?.combinations ?? []                        
                           const findIndex = combinations?.findIndex(
                              (c) => c.id == material.id
                           ) ?? -1
                           if(findIndex == -1){
                              combinations.push(summarizeMaterial(material))
                              _recipe.combinations = combinations
                              setRecipe(_recipe)
                           }
                           _recipe.materialId = combinations[0].id
                           _recipe.materialName = combinations[0].name
                           resolve()
                        })
                     ),
                     onRowDelete : (material) => (
                        new Promise<void>((resolve, reject) => {
                           const _recipe = {...recipe} as Recipe
                           const findIndex = _recipe.combinations?.findIndex(
                              (c) => c.id == material.id
                           ) ?? -1
                           if(findIndex >= 0)
                              _recipe.combinations?.splice(findIndex,1)
                           setRecipe(_recipe)
                           resolve()
                        })
                     )
                     
                  }}
                  components={{
                     Header: () => null,
                     Pagination: () => null
                  }}/>
            </div>            
         </Paper>
      </div>
   )

   function onPressAddRecipe (){

      if(!recipe)
         return 

      if(!recipe.recommendedBy){
         alert('Please select recommended by')
         return
      }

      console.log('Saving',recipe)

      if(currentRecipe){
         setShowProgress(true)
         dispatch(updateRecipeDetails(recipe))
            .then(
               () => history.push('/recipes')
            )
            .catch(
               (error) => {
                  alert('Error Updating Perfect Serve' + String(error))
                  setShowProgress(false)
               }
            )
      }else{
         setShowProgress(true)
         dispatch(addRecipe(recipe))
            .then(
               () => history.push('/recipes')
            )
            .catch(
               (error) => {
                  alert('Error Uploading Perfect Serve' + String(error))
                  setShowProgress(false)
               }
            )
      }
   }
}

