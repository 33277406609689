import React, { useState, useEffect, useMemo } from 'react';
import { User } from 'gf-connect/lib/User';
import {
	View
} from 'react-native'
import {
	Admin,
	Users
} from 'gf-connect'
import MaterialTable,{
	Column
} from 'material-table'
import { RoleLevelOptions } from 'gf-connect/lib/Admin';
import lodash from 'lodash'
import { Alert } from './Alert';

function InternalUsers() {

	const [users, setUsers] = useState<Admin.InternalUser[]>([])
	const [showLoader, setShowLoader] = useState(false)

	const columns = useMemo<Column<User>[]>(() => [
		{
			field: 'displayName',
			editable: 'never',			
			title: 'Name'
		},
		{
			field: 'email',
			editable: 'onAdd',
			title: 'Email'
		},
		{
			field: 'role.level',
			lookup: RoleLevelOptions,
			editable: 'onAdd',
			title: 'Role'
		}
	], [])

	useEffect(() => {
		fetchUsers()
	}, [])

	return (
		<View style={{
			flex: 1,
			alignItems: 'flex-start',
			paddingVertical: 20
		}}>
			<MaterialTable
				style={{
					flex: 1,
					width: '100%'
				}}
				isLoading={showLoader}
				title={'Users'}
				options={{
					search: false,
					pageSize: 15
				}}
				components={{
					Pagination: () => null
				}}
				editable={{
					onRowAdd: onRowAdd,
					onRowDelete: onRowDelete
				}}
				columns={columns}
				data={users} />
		</View>
	)

	function onRowAdd(rowData: User) {
		return new Promise<User>(async (resolve, reject) => {
			try {
				const newUser = {
					role: {
						id: 'ginferno-admin',
						level: '',
						name: 'Ginferno Admin',
						paths: ['*'],
						type: 'global'
					}
				} as Admin.InternalUser
				columns.forEach(({ field }) => {
					const path = field as string
					const value = lodash.get(rowData, path)
					lodash.set(newUser, path, value)
				})

				const email = newUser.email
				const response = await Users.getAll({
					where: [
						{
							fieldPath: 'email',
							opStr: '==',
							value: email
						}
					]
				})
				if(response.data.length > 0){
					const responseUser = response.data[0]
					newUser.uid = responseUser.id
					newUser.displayName = responseUser.displayName
					const addResponse = await Admin.addUser(newUser.uid, newUser)
					const __users = [...users]
					__users.push(addResponse as Admin.InternalUser)
					setUsers(__users)
					resolve(newUser)
				}else{
					throw new Error("User with email not found");
				}
			} catch (error) {
				reject(error)
				Alert.alert('Error Adding User' + String(error))
			}
		})
	}

	function onRowDelete(rowData: User) {
		return new Promise<void>(async (resolve, reject) => {
			setShowLoader(true)
			try {
				await Admin.deleteUser(rowData.id)
				const __users = [...users]
				const index = __users.findIndex(u => u.id === rowData.id)
				__users.splice(index, 1)
				setUsers(__users)
				resolve()
			} catch (error) {
				reject(error)
			}
			setShowLoader(false)
		})
	}

	async function fetchUsers() {
		setShowLoader(true)
		try {
			const response = await Admin.getUsers()
			setUsers(response.data)
		} catch (error) {
			console.log('Error Fetching Users', error)
		}
		setShowLoader(false)
	}
}

export default InternalUsers