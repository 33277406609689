import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter
} from 'react-router-dom'

// Generate required css
import iconMaterialFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
const iconMaterialFontStyles = `@font-face {
  src: url(${iconMaterialFont});
  font-family: MaterialIcons;
}`;
const styleMaterial = document.createElement('style');
styleMaterial.type = 'text/css';
if (styleMaterial.styleSheet) {
  styleMaterial.styleSheet.cssText = iconMaterialFontStyles;
} else {
  styleMaterial.appendChild(document.createTextNode(iconMaterialFontStyles));
}
document.head.appendChild(styleMaterial);

ReactDOM.render(
  <React.StrictMode>
    <style type="text/css">{`
      @font-face {
      font-family: 'MaterialIcons';
      src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
    } 
      @font-face {
        font-family: 'FontAwesome';
        src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
      }
    `}</style>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
