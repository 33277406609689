import React, { useState, useEffect, useRef, useContext } from 'react'
import MaterialTable from 'material-table';
import {
   SearchBar
} from 'react-native-elements'
import { 
   Material, 
   searchMaterial, 
   fetchMaterial, 
   updateMaterial,
   getAllMaterials
} from 'gf-connect/lib/Material';
import Shops, {
   FeedItemType, IgnoreReasonOptions, IgnoreReasonType
} from 'gf-connect/lib/Shops';
import { WhereType, OrderType, LastDocType, Actions } from 'firestar';
import Button from '@material-ui/core/Button';
import {
   GFSelect,
   GFAutoCompleteField,
} from './FormUtils'
import {
   Text,
   View
} from 'react-native';
import { Alert } from './Alert';
import {
   FeedItemKeys
} from 'gf-connect/lib/Shops';
import {
   EditingContext
} from './Shops';
import lodash from 'lodash';
import Materials from 'gf-connect/lib/Materials';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

const twoDaysAgo = (new Date())
twoDaysAgo.setHours(-48, 0, 0, 0);


const FilterOptions = {
   'none': 'No Filter',
   'without-barcodes': 'Without Barcodes',
   'with-barcodes': 'With Barcodes',
   'linked-materials': 'Linked Materials',
   'ignored-materials': 'Ignored Materials',
   'valid-materials': 'Valid Materials',
   'unlinked-materials': 'Unlinked Materials',
   'unlinked-without-barcodes': 'Todo (Unlinked Without Barcodes)',
   'unmapped-with-barcodes': 'Todo (Unmapped With Barcodes)'
}

export default function ShopFeed(props: {
   shopId: string
}) {

   const { shopId } = props
   const [feed, setFeed] = useState<FeedItemType[]>([])
   const [showLoader, setShowLoader] = useState(true)
   const [searchText, setSearchText] = useState('')
   const [lastDoc, setLastDoc] = useState<LastDocType>()
   const filterRef = useRef<WhereType[]>([])
   const ordersRef = useRef<OrderType[]>([])
   type FilterType = keyof typeof FilterOptions
   const [filterValue, setFilterValue] = useState<FilterType>('none')   
   const tableRef = useRef<any>()
   const debouncedSearchTerm = useDebounce(searchText, 500);
   const previousSearchText = usePrevious(debouncedSearchTerm)

   // useEffect(() => {
   //    console.log('lastDoc',lastDoc?.data().id)
   // }, [lastDoc]);

	useEffect(
		() => {
		  // Make sure we have a value (user has entered something in input)
         if (debouncedSearchTerm.trim().length > 0) {
            // Set isSearching state
            //  setIsSearching(true);
            //  // Fire off our API call
            //  searchCharacters(debouncedSearchTerm).then(results => {
            // 	// Set back to false since request finished
            // 	setIsSearching(false);
            // 	// Set results state
            // 	setResults(results);
            //  });
            onsChangeSearchText(debouncedSearchTerm)
         } else {
            if (previousSearchText?.trim().length > 0) {
               onsChangeSearchText('')
            }
         }
      },
		// This is the useEffect input array
		// Our useEffect function will only execute if this value changes ...
		// ... and thanks to our hook it will only change if the original ...
		// value (searchTerm) hasn't changed for more than 500ms.
   [debouncedSearchTerm]);

   useEffect(() => {

      console.log('Filter Changed', filterValue)

      if (filterValue === 'none') {
         filterRef.current = []
         ordersRef.current = [
            {
               fieldPath: 'createdAt',
               directionStr: 'desc'
            }
         ]

      } else if (filterValue === 'without-barcodes') {
         ordersRef.current = []
         filterRef.current = [
            {
               fieldPath: 'barcode',
               opStr: '==',
               value: ""
            }
         ]
      } else if (filterValue === 'with-barcodes') {
         ordersRef.current = [
            {
               fieldPath: 'barcode',
               directionStr: 'desc'
            }
         ]
         filterRef.current = [
            {
               fieldPath: 'barcode',
               opStr: '!=',
               value: ''
            }
         ]
      }else if (filterValue === 'linked-materials') {
         filterRef.current = [
            {
               fieldPath: 'linkedMaterialId',
               opStr: '!=',
               value: null
            }
         ]
         ordersRef.current = [
            {
               fieldPath: 'linkedMaterialId',
               directionStr: 'desc'
            }
         ]
      } else if (filterValue === 'unlinked-materials') {
         ordersRef.current = []
         filterRef.current = [
            {
               fieldPath: 'linkedMaterialId',
               opStr: '==',
               value: null
            }
         ]
      }else if (filterValue === 'ignored-materials') {
         filterRef.current = [
            {
               fieldPath: 'isIgnored',
               opStr: '==',
               value: true
            }
         ]
         ordersRef.current = []
      }else if (filterValue === 'valid-materials') {
         filterRef.current = [
            {
               fieldPath: 'isIgnored',
               opStr: '!=',
               value: true
            }
         ]
         ordersRef.current = []
      } else if (filterValue === 'unlinked-without-barcodes') {
         filterRef.current = [
            {
               fieldPath: 'linkedMaterialId',
               opStr: '==',
               value: null
            },
            {
               fieldPath: 'barcode',
               opStr: '==',
               value: ''
            },
            {
               fieldPath: 'isIgnored',
               opStr: '!=',
               value: true
            }
         ]
         ordersRef.current = []
         getFeedData(false, 10000)
         return 
      }else if(filterValue === 'unmapped-with-barcodes'){
         filterRef.current = []
         ordersRef.current = [
            {
               fieldPath: 'barcode',
               directionStr: 'desc'
            }
         ]
         exportUnmappedWithBarcodes()
         return
      }
      getFeedData()
   }, [filterValue]);
   

   async function getFeedData(
      isPaginating?:boolean,
      limit?: number
   ){
      return new Promise<Shops.FeedItem[]>(async (resolve, reject) => {

         setShowLoader(true)

         const filters = filterRef.current.concat(searchText.trim().length > 0 ? [
            {
               fieldPath: 'tags',
               opStr: 'array-contains-any',
               value: searchText.toLowerCase().trim().split(' ')
            },
         ] : [])

         const __isPaginating = (isPaginating ?? false)

         const __limit = limit ?? (__isPaginating === true ? PAGESIZE : INITIAL_PAGESIZE)
         const __lastDoc = __isPaginating === true ? lastDoc : undefined

         if(__isPaginating === false){
            setFeed([])
            setLastDoc(undefined)
         }else{
            console.log('Paginating After : ',lastDoc?.data().id)
         }

         // if (true) {
         //    filters.push({
         //       fieldPath: 'createdAt',
         //       opStr: '>',
         //       value: twoDaysAgo
         //    })
         // }

         try {
            const response = await Shops.getFeedDataForShop(
               shopId,
               __lastDoc,
               __limit,
               filters,
               ordersRef.current
            )

            let feedItems = response.data
            if(filterValue === 'unlinked-without-barcodes'){
               feedItems = feedItems.filter((feedItem) => {
                  if(feedItem.createdAt.toDate?.().getTime() < twoDaysAgo.getTime()){
                     return false
                  }
                  return true
               })
            }

            if (__isPaginating === true) {
               setFeed([...feed, ...feedItems])
            } else {
               setFeed(feedItems)
            }

            setLastDoc(response.lastDoc)
            
            resolve(feedItems)

         } catch (error) {
            console.log('Error Getting Shop Feed: ', error)
            alert('Error Getting Shop Feed : ' + JSON.stringify(error))
         }
         setShowLoader(false)
      })
   }

   return (
     <div>
       <div
         style={{
           display: "flex",
           justifyContent: "space-between",
           marginBottom: 10,
           alignItems: "center",
         }}
       >
         <div>
           <Button
             variant="contained"
             disableElevation
             onClick={onPressDeleteAllItems}
           >
             DELETE ALL ITEMS
           </Button>
         </div>
         <div style={{}}>
           <GFSelect
             editing={true}
             label="Filter"
             style={{
               minWidth: 200,
             }}
             onSelectValue={(value) => {
               onClickFilter(value);
             }}
             value={filterValue}
             types={FilterOptions}
           />
           {/* <IconButton style={{
                  color: filterRef.current ? 'red' : 'gray',
               }} onClick={onClickFilter}>
                  <FilterIcon />
               </IconButton> */}
         </div>
       </div>
       <View>
         <SearchBar
           containerStyle={{
             backgroundColor: "white",
             borderWidth: 1,
             borderColor: "#dddddd",
           }}
           inputContainerStyle={{
             backgroundColor: "#dddddd",
           }}
           inputStyle={{
             color: "black",
           }}
           value={searchText}
           onClear={() => setSearchText("")}
           onChangeText={(value) => setSearchText(value)}
         />
       </View>
       <MaterialTable
         page={feed.length == 0 ? 0 : undefined}
         tableRef={(ref) => (tableRef.current = ref!)}
         style={{
           width: window.innerWidth - 200,
         }}
         isLoading={showLoader}
         title="Feed Items"
         onSearchChange={onsChangeSearchText}
         options={{
           searchFieldVariant: "outlined",
           searchFieldStyle: {
             marginTop: 10,
           },
           pageSizeOptions: [PAGESIZE],
           pageSize: PAGESIZE,
           search: false,
           searchText: searchText,
           debounceInterval: 500,
           filtering: false,
         }}
         onChangePage={onChangePage}
         detailPanel={(item) => (
           <FeedDetail onUpdateFeedItem={onUpdateFeedItem} feedItem={item} />
         )}
         columns={[
            {
               title: 'Validation',
               render: (item) => (
                  <div>
                     {item.isIgnored === true ? (
                        <div>{IgnoreReasonOptions[item.ignoreReason ?? ''] ?? 'Reason Not Found'}</div>
                     ) : (
                        <div>
                           {(item.isIgnored === '' || item.isIgnored == null) ? (
                              <div>--</div>
                           ) : (
                              <div>Gin or Mixer</div>
                           )}
                        </div>
                     )}
                  </div>
               )
            },
           ...[
             ...Object.keys(FeedItemKeys),
             "linkedMaterialId",
             "createdAt",
             "updatedAt",
           ].map((key) => {
             return {
               field: key,
               title: key,
               headerStyle: {
                 fontWeight: "bold",
               },
               render: (data: Shops.FeedItem) => {
                 if (key === "link") {
                   return (
                     <a href={data.link} target="_blank">
                       {data.link}
                     </a>
                   );
                 } else if (key === "url") {
                   return (
                     <a href={data.url} target="_blank">
                       {data.url}
                     </a>
                   );
                 } else if (key === "barcodes") {
                   return (
                     <View>
                        {data.barcodes?.length > 0 && (data.barcodes ?? []).map((b) => (
                           <Text
                           style={{
                              marginRight: 5,
                              backgroundColor: "#dddddd",
                              borderRadius: 15,
                              paddingVertical: 2,
                              paddingHorizontal: 10,
                              fontSize: 12,
                           }}>{b}
                           </Text>
                        ))}
                     </View>
                  )
                 } else if (key === "createdAt") {
                   return (
                     <View>
                       <Text>{data.createdAt.toDate().toDateString()}</Text>
                       {data.createdAt.toDate().getTime() < twoDaysAgo.getTime() && (
                         <Text
                           style={{
                             backgroundColor: "brown",
                             color: "white",
                             padding: 2,
                             textAlign: "center",
                             borderRadius: 5,
                           }}>
                           Outdated
                         </Text>
                       )}
                     </View>
                   );
                 } else  if (key === "isIgnored") {
                   return (
                     <View>
                       <Text>{String(data.isIgnored ?? false)}</Text>
                       <Text
                         style={{
                           fontSize: 11,
                           color: "gray",
                         }}>
                         {IgnoreReasonOptions[data.ignoreReason as string]}
                       </Text>
                     </View>
                   );
                 } else if (key === "updatedAt") {
                   return <Text>{data.updatedAt.toDate().toDateString()}</Text>;
                 }
                 return <Text>{String(data[key])}</Text>;
               },
             };
           }),
         ]}
         data={feed}
       />
     </div>
   );

   async function onUpdateFeedItem(feedItemId: string) {
      try {
         const findIndex = feed.findIndex(f => f.id == feedItemId)
         const response = await Shops.getFeedItem(feedItemId)
         if (response != null && findIndex >= 0) {
            const __feed = [...feed] as Shops.FeedItem[]
            __feed[findIndex] = response
            setFeed(__feed)
         }
      } catch (error) {
         console.log('Error Fetching Updated Feed Item: ', error)
      }
   }

   async function onClickFilter(value) {
      setFilterValue(value)
   }

   async function exportUnmappedWithBarcodes(){

      try {
      
         const feedItems = await getFeedData(false,10000)    
         setShowLoader(true)  
         console.log('Total Feed Items : ',feedItems.length)

         const materialResponse = await getAllMaterials({
            limit: 10000,
            where: [
               {
                  fieldPath: 'type',
                  opStr: 'in',
                  value: [
                     'gin',
                     'mixer'
                  ]
               }
            ]
         })
         
         const allMaterials = materialResponse.data
         
         console.log('Total Materials : ',allMaterials.length)
      
         const unmappedFeedItems = feedItems.filter(
            (feedItem) => {
               const barcode = (feedItem.barcode ?? '')
               if((feedItem.isIgnored === true)){
                  return false
               }
               if(barcode.length == 0 || barcode == 'null' || barcode == null){
                  return false
               }
               if(feedItem.createdAt.toDate?.().getTime() < twoDaysAgo.getTime()){
                  return false
               }
               const result = allMaterials.find(
                  (m) => lodash.intersection((m.barcodes ?? []), (feedItem.barcodes ?? [])).length > 0)
               return result == null
            }
         )

         console.log('Total Unmapped Items : ',unmappedFeedItems.length)

         setFeed(unmappedFeedItems)
      
      } catch (error) {
         console.log('Error Download Feed Items  :',error)
         Alert.alert('Error Downloading Feed Items',String(error))
      }

      setShowLoader(false)
   }

   async function onChangePage(page: number, pageSize: number) {
      if (feed.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE)) {
         if(lastDoc != null){
            getFeedData(true)
         }
      }
   }

   async function onsChangeSearchText(text:string) {
      setSearchText(text)
      if(text.trim().length > 0){
         setShowLoader(true)
         try {
            const response = await Shops.searchFeedWithText(shopId,text,undefined,15,filterRef.current)
            console.log('Found Items : ',response.data.length)
            setFeed(response.data)
            setLastDoc(response.lastDoc)
         } catch (error) {
            console.log('Error Searching : ',error)
            alert('Error Searching..'+JSON.stringify(error))         
         }
         setShowLoader(false)
      }else{
         getFeedData()
      }
   }

   async function onPressDeleteAllItems(){
      setShowLoader(true)
      try {
         await Shops.deleteFeedItems(shopId)
         getFeedData()
      } catch (error) {
         console.log('Error Deleting : ',error)
         alert('Error Deleting..'+JSON.stringify(error))         
      }
      setShowLoader(false)
   }
}

function FeedDetail(props:{
   feedItem: FeedItemType,
   onUpdateFeedItem: (feedItemId: string) => void
}){

   const { feedItem, onUpdateFeedItem } = props

   const [linkedMaterial, setLinkedMaterial] = useState<any|undefined>()
   const [searchText, setSearchText] = useState('')
   const [searchResult, setSetSearchResult] = useState<Material[]>([])
   const [showLoader, setShowLoader] = useState(false)
   const editing = useContext(EditingContext)
   const [searching, setSearching] = useState(false)
   const [ignoredFeedItem, setIgnoredFeedItem] = useState<{
      reason: IgnoreReasonType
   }>()

   useEffect(() => {
      getLinkedMaterial()
   }, [])

   async function getLinkedMaterial() {
      setShowLoader(true)
      const linkedMaterialResponse = await Shops.getLinkedMaterialForFeedItem(feedItem.shopId, feedItem.id)
      setLinkedMaterial(linkedMaterialResponse)

      const ignoreFeedItemResponse = await Shops.getIgnoredFeedItem(feedItem.shopId, feedItem.id)
      setIgnoredFeedItem(ignoreFeedItemResponse as any)

      setShowLoader(false)
   }

   const badgeUrl = `https://web.ginferno.app/ratings/${feedItem.id}?type=shop&shopId=${feedItem.shopId}`
   const badge = `<iframe frameBorder={0} title="${feedItem.title}" src="${badgeUrl}" />`

   if(showLoader){
      return <div>Loading...</div>
   }

   return (
      <div style={{
         padding: 20
      }}>
         <div>
            <div><b>Linked Material</b> {linkedMaterial != null && <div>({linkedMaterial?.materialId})</div>}</div>
            <div style={{
               display: 'flex',
               alignItems: 'center',
               marginTop: 10
            }}>
               <GFAutoCompleteField
                  style={{
                     width: 300
                  }}
                  label='Linked Material'
                  onChangeText={onChangeSearchText}
                  onSelectItem={onSelectMaterial}
                  editing={true}
                  value={linkedMaterial != null ? {
                     label: linkedMaterial.name,
                     value: linkedMaterial
                  } : undefined}
                  isLoading={searching}
                  options={searchResult.map((m) => {
                     return {
                        label: m.name,
                        value: m
                     }
                  })} />
               <View style={{
                  marginLeft: 15
               }}>
                  <select 
                     value={ignoredFeedItem?.reason ?? (feedItem.isIgnored === false ? 'valid' : undefined)} 
                     onChange={(event) => {
                        const value = event.target.value as IgnoreReasonType
                        onSelectIgnoreReason(value);
                     }}>
                     {Object.keys(IgnoreReasonOptions).map((key) => (
                        <option value={key}>{IgnoreReasonOptions[key]}</option>
                     ))}
                  </select>
               </View>               
            </div>
         </div>
         <View>
            <View style={{
               marginVertical: 10
            }}>
               <label>Static Badge</label>
               <iframe
                  frameBorder={0}
                  title={`${feedItem.title}`}
                  src={badgeUrl} />
            </View>
            <CopyToClipboard text={badge}
               onCopy={() => null}>
               <button style={{
                  width: 100
               }}>Copy iFrame</button>
            </CopyToClipboard>
         </View>
      </div>
   )

   async function onSelectIgnoreReason(reason: IgnoreReasonType | null) {

      setShowLoader(true)
      try {
         console.log('Reason', reason)
         if(reason === 'valid'){
            await Shops.ignoreFeedItem(feedItem.shopId, feedItem.id)
            onUpdateFeedItem(feedItem.id)
            setIgnoredFeedItem(undefined)
         }else if(reason === 'default'){
            await Shops.ignoreFeedItem(feedItem.shopId, feedItem.id, null)
            onUpdateFeedItem(feedItem.id)
            setIgnoredFeedItem(undefined)
         }else{
            const response = await Shops.ignoreFeedItem(feedItem.shopId, feedItem.id, reason)
            onUpdateFeedItem(feedItem.id)
            setIgnoredFeedItem(response as any)
         }
      } catch (error) {
         console.log('Error Copy Barcodes to Material : ', error)
         Alert.alert('Error Copying Barcodes', String(error))
      }
      setShowLoader(false)
   }

   async function onPressCopyBarcodes(materialId){

      setShowLoader(true)
      try {
         const material = await fetchMaterial(materialId)  
         const barcodes = material?.barcodes ?? []

         const feedItemBarcodes = (feedItem.barcodes ?? []) as string[]
         feedItemBarcodes.forEach(
            (barcode) => {
               if(barcodes.includes(barcode) == false && barcode != materialId){
                  barcodes.push(barcode)
               }
            }  
         )
         await updateMaterial({
            id: materialId,
            barcodes: barcodes
         } as Material)
      } catch (error) {
         console.log('Error Copy Barcodes to Material : ',error)
         Alert.alert('Error Copying Barcodes',String(error))
      }
      setShowLoader(false)
   }

   // async function onPressRemoveBarcodes(materialId){

   //    setShowLoader(true)
   //    try {
   //       const material = await fetchMaterial(materialId)  
   //       const barcodes = material?.barcodes ?? []

   //       const feedItemBarcodes = (feedItem.barcodes ?? []) as string[]
   //       feedItemBarcodes.forEach(
   //          (barcode,index) => {
   //             if(barcodes.includes(barcode) == true){
   //                barcodes.splice(index,1)
   //             }
   //          }  
   //       )
   //       await updateMaterial({
   //          id: materialId,
   //          barcodes: barcodes
   //       } as Material)
   //    } catch (error) {
   //       console.log('Error Copy Barcodes to Material : ',error)
   //       Alert.alert('Error Copying Barcodes',String(error))
   //    }
   //    setShowLoader(false)
   // }

   async function onSelectMaterial(material) {

      setShowLoader(true)
      try {
         if (material) {
            console.log('Linking Material with ID : ',material)
            const response = await Shops.addMaterialToFeedItem(feedItem.id, feedItem.shopId, material)
            setLinkedMaterial(response)
            await onPressCopyBarcodes(material.id)
            await Shops.linkMaterialWithFeedItem(feedItem)
         } else {
            console.log('UnLinking Material with ID : ',feedItem.linkedMaterialId)
            const response = await Shops.removeMaterialToFeedItem(
               feedItem.id, 
               feedItem.shopId, 
               feedItem.linkedMaterialId ?? ''
            )
            console.log("Response",response)
            // await onPressRemoveBarcodes(linkedMaterial.materialId)
            setLinkedMaterial(undefined)            
         }
         onUpdateFeedItem(feedItem.id)
      } catch (error) {
         console.log('Error Adding Material to Feed Item ', error)
         alert('Error Adding Material to Feed Item :' + JSON.stringify(error))
      }
      setShowLoader(false)
   }

   async function onChangeSearchText(text){

      setSearching(true)
      if(text.trim().length > 0){
         console.log('onChangeSearchText',text)
         setSearchText(text)
         const response = await searchMaterial(text)
         console.log('response',response)
         setSetSearchResult(response.data)
      }
      setSearching(false)
   }
}

function usePrevious(value) {
   const ref = useRef<typeof value>();
   useEffect(() => {
      ref.current = value;
   });
   return ref.current;
}

export function useDebounce(value, delay) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);
 
	useEffect(
	  () => {
		 // Set debouncedValue to value (passed in) after the specified delay
		 const handler = setTimeout(() => {
			setDebouncedValue(value);
		 }, delay);
 
		 // Return a cleanup function that will be called every time ...
		 // ... useEffect is re-called. useEffect will only be re-called ...
		 // ... if value changes (see the inputs array below). 
		 // This is how we prevent debouncedValue from changing if value is ...
		 // ... changed within the delay period. Timeout gets cleared and restarted.
		 // To put it in context, if the user is typing within our app's ...
		 // ... search box, we don't want the debouncedValue to update until ...
		 // ... they've stopped typing for more than 500ms.
		 return () => {
			clearTimeout(handler);
		 };
	  },
	  // Only re-call effect if value changes
	  // You could also add the "delay" var to inputs array if you ...
	  // ... need to be able to change that dynamically.
	  [value] 
	);
 
	return debouncedValue;
 }