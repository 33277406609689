import { 
   Category as CategoryType,
   getGinCategories,
   getMixerCategories,
   ignoredCategories
} from 'gf-connect/lib/Category'

import { Change, AppState } from '.'

export type Category = CategoryType

export interface Categories {
   gins? : Category[],
   mixers? : Category[]
}

const initialState : Categories = {
   gins : [],
   mixers : []
}

export default function (state = initialState, action: Change) : Categories {
   switch (action.type) {
      case 'SYNC_GIN_CATEGORIES' : {
         return {
            ...state,
            gins : action.payload
         }
      }
      case 'SYNC_MIXER_CATEGORIES' : {
         return {
            ...state,
            mixers : action.payload
         }
      }
      case 'CLEAR_CATEGORIES' : {
         return {
            ...state,
            mixers : [],
            gins: [],            
         }
      }
      default:
         return state
   }
}

export function clearCategories(): any {
   return {
      type: 'CLEAR_CATEGORIES'
   }
}

export function syncGinCategories () : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Category[]>((resolve, reject) => {
         getGinCategories()
         .then(
            (response) => {               
               const categories = response.data as Category[]
               dispatch({
                  payload : categories,
                  type : 'SYNC_GIN_CATEGORIES'
               })
               resolve(categories)
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function syncMixerCategories () : any { 
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Category[]>((resolve, reject) => {
         getMixerCategories()
         .then(
            (response) => {
               const categories = response.data as Category[]
               dispatch({
                  payload : categories,
                  type : 'SYNC_MIXER_CATEGORIES'
               })
               resolve(categories)             
            }
         )
         .catch((error) => reject(error))
      })
   }
}

// function combineIgnoredCategories (response){
//    const others : Category = {
//       id : 'others',
//       name : 'Others'
//    }
//    const categories = (response.data as Category[]).filter(
//       (c) => {
//          if(ignoredCategories().includes(c.id)){
//             others.totalItems = (others.totalItems ?? 0) + (c.totalItems ?? 0)
//             return false
//          }else{
//             return true
//          }
//       }
//    )
//    if((others.totalItems ?? 0) > 0) categories.push(others)
//    return categories
// }