"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRecipe = exports.getRecipesForMaterialId = exports.getAllRecipes = exports.summarizeRecipe = exports.getHighestRatedRecipe = exports.getRecipesByUser = exports.replaceMaterialInCombination = exports.updateMaterialInCombination = exports.findMatchForCombinations = exports.updateRecipe = exports.removeRatingForRecipe = exports.updateRatingsForRecipe = exports.modifyRatingsForRecipe = exports.addRecipe = exports.RecommendedBy = void 0;
const Material_1 = require("./Material");
const firestar_1 = __importStar(require("firestar"));
const collectionPath = 'recipes';
exports.RecommendedBy = {
    'owner': 'Owner',
    'ginferno': 'GINferno',
    'owner-ginferno': 'Owner & GINferno',
    'user': 'User'
};
function addRecipe(recipe) {
    return new Promise((resolve, reject) => {
        prepareRecipe(recipe);
        firestar_1.Actions.addToCollection(collectionPath, recipe)
            .then((response) => resolve(response))
            .catch(error => reject(error));
    });
}
exports.addRecipe = addRecipe;
function modifyRatingsForRecipe(recipe, batch) {
    return new Promise((resolve, reject) => {
        firestar_1.Actions.updateDocument(collectionPath + `/${recipe.id}`, recipe, batch)
            .then((response) => resolve(response))
            .catch(error => reject(error));
    });
}
exports.modifyRatingsForRecipe = modifyRatingsForRecipe;
function updateRatingsForRecipe(recipeId, newRelativeRating, oldRelativeRating, newAbsoluteRating, oldAbsoluteRating, numberOfRatings) {
    const path = `recipes/${recipeId}`;
    const firestore = firestar_1.default.getFirestore();
    const docRef = firestore().doc(path);
    return firestore().runTransaction((transaction) => {
        return transaction.get(docRef)
            .then((response) => {
            var _a, _b, _c, _d;
            const recipe = response.data();
            const relativeChange = (newRelativeRating !== null && newRelativeRating !== void 0 ? newRelativeRating : 0) - (oldRelativeRating !== null && oldRelativeRating !== void 0 ? oldRelativeRating : 0);
            const absoluteChange = (newAbsoluteRating !== null && newAbsoluteRating !== void 0 ? newAbsoluteRating : 0) - (oldAbsoluteRating !== null && oldAbsoluteRating !== void 0 ? oldAbsoluteRating : 0);
            const relativeDifference = ((_b = (_a = recipe.ratings) === null || _a === void 0 ? void 0 : _a.relativeTotal) !== null && _b !== void 0 ? _b : 0) + relativeChange;
            const absoluteDifference = ((_d = (_c = recipe.ratings) === null || _c === void 0 ? void 0 : _c.absoluteTotal) !== null && _d !== void 0 ? _d : 0) + absoluteChange;
            const relativeTotal = Number(relativeDifference.toFixed(2));
            const absoluteTotal = Number(absoluteDifference.toFixed(2));
            const incrementNumberOfRatings = firestore.FieldValue.increment(numberOfRatings);
            return firestar_1.Actions.updateDocument(path, {
                'ratings.relativeTotal': relativeTotal,
                'ratings.absoluteTotal': absoluteTotal,
                'ratings.numberOfRatings': incrementNumberOfRatings,
            }, transaction);
        });
    });
}
exports.updateRatingsForRecipe = updateRatingsForRecipe;
async function removeRatingForRecipe(recipeId, relativeRating) {
    const path = `recipes/${recipeId}`;
    const firestore = firestar_1.default.getFirestore();
    const docRef = firestore().doc(path);
    return firestore().runTransaction((transaction) => {
        return transaction.get(docRef)
            .then((response) => {
            var _a, _b;
            const recipe = response.data();
            const difference = ((_b = (_a = recipe.ratings) === null || _a === void 0 ? void 0 : _a.relativeTotal) !== null && _b !== void 0 ? _b : 0) - (relativeRating !== null && relativeRating !== void 0 ? relativeRating : 0);
            const relativeTotal = Number(difference.toFixed(2));
            const incrementNumberOfRatings = firestore.FieldValue.increment(-1);
            return firestar_1.Actions.updateDocument(path, {
                'ratings.relativeTotal': relativeTotal,
                'ratings.numberOfRatings': incrementNumberOfRatings,
            }, transaction);
        });
    });
}
exports.removeRatingForRecipe = removeRatingForRecipe;
function updateRecipe(recipe) {
    return new Promise((resolve, reject) => {
        prepareRecipe(recipe);
        firestar_1.Actions.updateDocument(collectionPath + `/${recipe.id}`, recipe)
            .then((response) => resolve(response))
            .catch(error => reject(error));
    });
}
exports.updateRecipe = updateRecipe;
function prepareRecipe(recipe) {
    var _a;
    const matches = {};
    const tags = [];
    (_a = recipe.combinations) === null || _a === void 0 ? void 0 : _a.forEach((c) => {
        matches[c.id] = true;
        tags.push(c.name);
        tags.push(c.id);
    });
    recipe.tags = tags;
    recipe.matches = matches;
}
function findMatchForCombinations(combinations, matchTypes, exactMatch, limit) {
    let where = [];
    if (exactMatch) {
        const matchObject = {};
        combinations === null || combinations === void 0 ? void 0 : combinations.forEach((c) => matchObject[c.id] = true);
        where.push({
            fieldPath: 'matches',
            opStr: '==',
            value: matchObject
        });
    }
    else {
        where = combinations === null || combinations === void 0 ? void 0 : combinations.map((c) => {
            return {
                fieldPath: `matches.${c.id}`,
                opStr: '==',
                value: true
            };
        });
    }
    if (matchTypes) {
        matchTypes.forEach((matchType) => where.push({
            fieldPath: 'type',
            opStr: '==',
            value: matchType
        }));
    }
    return firestar_1.Actions.readCollection(collectionPath, {
        where: where,
        limit: limit
    });
}
exports.findMatchForCombinations = findMatchForCombinations;
function updateMaterialInCombination(recipe, material, transaction) {
    var _a;
    const combinations = (_a = recipe.combinations) !== null && _a !== void 0 ? _a : [];
    const findIndex = combinations.findIndex((c) => material.id == c.id);
    combinations[findIndex] = (0, Material_1.summarizeMaterial)(material);
    return firestar_1.Actions.updateDocument(`recipes/${recipe.id}`, {
        id: recipe.id,
        combinations: combinations
    }, transaction);
}
exports.updateMaterialInCombination = updateMaterialInCombination;
function replaceMaterialInCombination(recipe, materialId, newMaterial, transaction) {
    var _a;
    const combinations = (_a = recipe.combinations) !== null && _a !== void 0 ? _a : [];
    const findIndex = combinations.findIndex((c) => materialId == c.id);
    if (findIndex >= 0) {
        combinations[findIndex] = (0, Material_1.summarizeMaterial)(newMaterial);
        const updatingRecipe = {
            id: recipe.id,
            combinations: combinations
        };
        prepareRecipe(updatingRecipe);
        return firestar_1.Actions.updateDocument(`recipes/${recipe.id}`, updatingRecipe, transaction);
    }
    else {
        return Promise.reject('Material not found');
    }
}
exports.replaceMaterialInCombination = replaceMaterialInCombination;
function getRecipesByUser(userId, descriptor) {
    const path = `recipes`;
    const where = [];
    where.push({
        fieldPath: 'createdBy.uid',
        opStr: '==',
        value: userId
    });
    where.push({
        fieldPath: 'recommendedBy',
        opStr: '==',
        value: 'user'
    });
    return firestar_1.Actions.readCollection(path, Object.assign(Object.assign({}, descriptor), { where: where }));
}
exports.getRecipesByUser = getRecipesByUser;
function getHighestRatedRecipe(userId, materialId) {
    return new Promise((resolve, reject) => {
        firestar_1.Actions.readDocument(`materials/${materialId}/ratings/${userId}`)
            .then((response) => resolve(response))
            .catch(error => reject(error));
    });
}
exports.getHighestRatedRecipe = getHighestRatedRecipe;
function summarizeRecipe(recipe) {
    return {
        id: recipe.id,
        combinations: recipe.combinations,
        materialId: recipe.materialId,
    };
}
exports.summarizeRecipe = summarizeRecipe;
function getAllRecipes(descriptor) {
    return firestar_1.Actions.readCollection(collectionPath, descriptor);
}
exports.getAllRecipes = getAllRecipes;
function getRecipesForMaterialId(materialId, lastDoc, descriptor) {
    return new Promise(async (resolve) => {
        const path = `recipes`;
        const response = await firestar_1.Actions.readCollection(path, Object.assign({ where: [
                {
                    fieldPath: 'materialId',
                    opStr: '==',
                    value: materialId
                }
            ], lastDoc: lastDoc }, descriptor !== null && descriptor !== void 0 ? descriptor : {}));
        resolve(response);
    });
}
exports.getRecipesForMaterialId = getRecipesForMaterialId;
function getRecipe(recipeId) {
    return firestar_1.Actions.readDocument(collectionPath + `/${recipeId}`);
}
exports.getRecipe = getRecipe;
