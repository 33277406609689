import {
   Change
} from '.'

const initialState = null

export default function(state = initialState,change:Change){
   switch (change.type) {
      case 'SET_REDIRECT_URL':
         return change.payload   
      default:
         return state
   }
}

export function setRedirectUrl(url?:string) : Change{
   return {
      payload: url ?? null,
      type: 'SET_REDIRECT_URL'
   } 
}