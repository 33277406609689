import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Text, View } from 'react-native'
import {
   Analytics
} from 'gf-connect'

function Dashboard() {

   const [totalUsers, setTotalUsers] = useState<number>()
   const [currentUsers, setCurrentUsers] = useState<number>()
   const [totalMaterials, setTotalMaterials] = useState<number>()
   const [totalCompanies, setTotalCompanies] = useState<number>()
   const [totalRecipes, setTotalRecipes] = useState<number>()

   const [showLoader] = useState(false)
   
   useEffect(() => {
      fetchItems()
   }, [])

   if(showLoader === true){
      return <div>Loading...</div>
   }

   return (
      <View style={{
         alignItems: 'flex-start'
      }}>
         <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center'
         }}>
            <h2>Dashboard</h2>
            <button onClick={fetchItems}>Refresh</button>
         </View>
         <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginLeft: -10
         }}>
            <CountBadge title='Total Users' count={totalUsers} />
            <CountBadge title='Current Users' count={currentUsers} />
            <CountBadge title='Total Materials' count={totalMaterials} />
            <CountBadge title='Total Companies' count={totalCompanies} />
            <CountBadge title='Total Recipes' count={totalRecipes} />
         </View>
         <View style={{
            alignItems: 'flex-start'
         }}>
            <label>HARAHORN Small Batch Gin</label>
            <iframe frameBorder={0} title="HARAHORN Small Batch Gin" src="https://web.ginferno.app/ratings/OJGIEGHwxJ8FQwb9yeJA" />
         </View>
         <View style={{
            marginTop: 10,
            alignItems: 'flex-start'
         }}>
            <label>Dynamic Badge</label>
            <a target={'_blank'} href="https://ginferno.app/product-category/gins/">Click Here</a>
         </View>
      </View>
   )

   async function fetchItems() {

      try {

         setTotalUsers(undefined)
         setCurrentUsers(undefined)
         setTotalCompanies(undefined)
         setTotalMaterials(undefined)
         setTotalRecipes(undefined)

         const promises : Promise<any>[] = []
         promises.push(Analytics.getUsers())
         promises.push(Analytics.getCompanies())
         promises.push(Analytics.getMaterials())
         promises.push(Analytics.getRecipes())

         const response = await Promise.allSettled(promises)

         const usersResponse = response[0]
         if(usersResponse.status === 'fulfilled'){
            const users = usersResponse.value as Analytics.Users
            setTotalUsers(users.total)
            setCurrentUsers(users.total)
         }

         const companiesResponse = response[1] 
         if(companiesResponse.status === 'fulfilled'){
            const companies = companiesResponse.value as Analytics.Companies
            setTotalCompanies(companies.total)
         }

         const materialsResponse = response[2]
         if(materialsResponse.status === 'fulfilled'){
            const materials = materialsResponse.value as Analytics.Materials
            setTotalMaterials(materials.total)
         }

         const recipesResponse = response[3]
         if(recipesResponse.status === 'fulfilled'){
            const recipes = recipesResponse.value as Analytics.Materials
            setTotalRecipes(recipes.total)
         }

      } catch (error) {

      }
   }
}

export default Dashboard

function CountBadge(props: {
   title: string,
   count: number | undefined
}) {
   return (
      <View style={{
         width: 250,
         borderWidth: 1,
         margin: 10,
         paddingVertical: 35,
         borderColor: "#dddddd",
         borderRadius: 10
      }}>
         {props.count != null && <Text style={{
            fontSize: 40,
            marginBottom: 5
         }}>{props.count}</Text>}
         {props.count == null && <ActivityIndicator style={{
            height: 51
         }} />}
         <Text>{props.title}</Text>
      </View>
   )
}
