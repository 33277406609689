"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertCountryToAddressObject = void 0;
function convertCountryToAddressObject(country) {
    return {
        country: country.name,
        countryCode: country.code,
        numericCountryCode: country.numericCode,
        extendedCountryCode: country.extendedCode
    };
}
exports.convertCountryToAddressObject = convertCountryToAddressObject;
