import {
   createStore,
   applyMiddleware,
} from 'redux'

import { 
   persistStore,
   persistReducer,
   createTransform
} from 'redux-persist'

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { composeWithDevTools } from 'redux-devtools-extension';
import { WhereType } from 'firestar'
import thunk from 'redux-thunk'
import firebase from 'firebase/compat/app'

import rootReducer from '.'
import { Settings } from './Settings';

const middleware = [thunk]

const SettingsTransform = createTransform(
   (inboundState:Settings, key) => {
      return inboundState
   },
   (outboundState: Settings, key) => {      
      if(outboundState.roomFilter){
         const roomFilter = outboundState.roomFilter
         const whereType = roomFilter.find((f) => f.id == 'where')
         whereType?.options.forEach(
            (option) => {
               const timestamp = firebase.firestore.Timestamp.now()
               if(option && option.value){
                  (option.value as WhereType).value = timestamp
               }
            }
         )
      }
      return outboundState
   },
   {
      whitelist: ['settings']
   },
);

const persistConfig = {
   key: 'root',
   timeout: 0,
   storage,
   autoMergeLevel2,
   transforms: [SettingsTransform],
   whitelist : ['settings','user']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
   persistedReducer, 
   composeWithDevTools(applyMiddleware(...middleware))
) 

export const persistor = persistStore(store)
