import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from './store';
import { 
	getMaterials, 
	uploadMaterialsFromCSV, 
	Material, 
	updateMaterialInfo, 
	addMaterial, 
	deleteMaterial,
	getMaterial,
	searchMaterialWithName
} from './store/Materials'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import lodash from 'lodash'
import { default as Label } from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/DeleteRounded'
import CancelIcon from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Popover from '@material-ui/core/Popover';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ImageUploader from 'react-images-upload';
import moment from 'moment'
import Resizer from 'react-image-file-resizer';
import {
	createTimeStamp
} from 'firestar/lib/Utils'
import _ from 'lodash';
import firebase from 'firebase/compat/app'

import CSVReader, {
	IFileInfo
} from 'react-csv-reader'
import { Text } from 'react-native';

import {
	exportListToCSV,
	orderTranslationObject,
	compareObjectsWithTime,
	getSupportedLanguages,
	createEmptyLanguageObject,
	createFlatEmptyLanguageKeys
} from 'gf-connect/lib/Utils'

import { Address, convertCountryToAddressObject } from 'gf-connect/lib/Address';
import {
	MaterialTypes,
	Kinds,
	MaterialStatusTypes,
	checkMaterialNameChange,
	updateMaterial,
} from 'gf-connect/lib/Material';

import {
	Company,
	getCompanies
} from './store/Companies';

import {
	primaryGinKinds,
	secondaryGinKinds,
	tertiaryGinKinds,
	primaryMixerKinds,
	secondaryMixerKinds,
	tertiaryMixerKinds,
	primaryGarnishKinds,
	secondaryGarnishKinds,
	tertiaryGarnishKinds
} from 'gf-connect/lib/Category';

import firestar, { Actions } from 'firestar';

import {
	GFAutoComplete,
	GFCheckbox,
	GFSelect,
	GFTextField,
	GFTextArea
} from './FormUtils';
import { Category } from './store/Categories';
import { getSummarizeUserInfo } from 'gf-connect/lib/User';
import { getCountryWithCode, getSearchableCountries, isDev } from './utils';
// import Strings from './utils/Strings';
// import { getCompanySummary } from 'gf-connect/lib/Company';
// import { Alert } from './Alert';

const countries = getSearchableCountries('en')
const countriesOptions = countries.map((c) => { 
	return { label : c.name, value : c } 
})

interface Column {
	id: string
	label: string;
	minWidth?: number;
	align?: 'right';
	type? : 'text' | 'select' | 'checkbox' | 'autocomplete',
	selectTypes? : any,
	targetPath? : string
	options? : [],
	editable? : boolean,
	format?: (value:any) => any;
	isEditable?: (value:any) => boolean;
}

const EmptySupportedLanguages = createEmptyLanguageObject()

const columns: Column[] = [
	{ 
		id: 'id', 
		label: 'Id', 
		minWidth: 170,
		type : 'text',
		editable : false
	},
	{ 
		id: 'name', 
		label: 'Name', 
		minWidth: 170,
		type : 'text'
	},
	{
		id: 'type',
		label: 'Material Type',
		minWidth: 170,
		align: 'right',
		type : 'select',
		format: (type) => MaterialTypes[type],
		selectTypes : MaterialTypes
	},	
	{
		id: 'kinds', 
		label: 'Kind', 
		minWidth: 100, 
		align: 'right',
		type : 'text',
		editable : false,
		format: (kinds: Kinds) => kinds ? (
			<div style={{
				display: 'flex',
				flexWrap: 'wrap'
			}}>{[kinds.one,kinds.two,kinds.three]
				.map((k: Category) => <Chip style={{
					marginRight: 5,
					marginBottom: 5
				}} size="small" label={k?.name ?? '--'} />)}</div>
		) : '--'
	},
	{
		id: 'producer.name',
		label: 'Producer',
		minWidth: 170,
		type : 'autocomplete',
		align: 'right',
		targetPath : 'producer',
		isEditable : (item:Material) => item.type != 'garnish'
	},
	{
		id: 'owner.name',
		label: 'Owner',
		minWidth: 170,
		align: 'right',
		type : 'autocomplete',
		targetPath : 'owner',
		isEditable : (item:Material) => item.type != 'garnish'
	},
	{ 
		id: 'status', 
		label: 'Status', 
		minWidth: 100, 
		align: 'right',
		type : 'select',
		format: (type) => MaterialStatusDropDownTypes()[type],
		selectTypes : MaterialStatusDropDownTypes()
	},
]

function MaterialStatusDropDownTypes(){
	const types = {...MaterialStatusTypes} as any
	delete types.deleted
	return types
}

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	editableTextField: {

	},
	textField: {

	},
	formLabel: {
		marginLeft: 10
	}
});

const useStyles = makeStyles({
	root: {
		marginLeft: '1%',
		marginTop: '2%',
		width: '95%',
	},
	container: {
		maxHeight: '100%',
	},
	title: {
		display: 'flex',
		alignSelf: 'flex-start',
		marginBottom: 10
	},
	editableTextField: {

	},
	textField: {

	},
	formLabel: {
		marginLeft: 10
	},
	searchField :  {
		height: 5,
		fontSize: 12  
	}
});

interface Filter {
	type? : keyof typeof MaterialTypes
}

const translatedDefaultDescription = () => {
	return {
		en: 'At the moment there is no information available.\n\nWe are already in contact with the producer in order to be able to present you details and pictures shortly.',
		de: 'Momentan stehen uns noch keine Infos zur Verfügung.\n\nWir sind bereits mit dem Hersteller in Kontakt, um dir in kürze Details und Bilder präsentieren zu können.',
		es: 'Actualmente no hay información disponible. \n\nYa estamos en contacto con el fabricante para poder ofrecerte detalles y fotos en breve.',
		it: 'Pour l\'instant, aucune information n\'est disponible.\n\nNous sommes déjà en contact avec le producteur afin de pouvoir vous présenter des détails et des photos prochainement.',
		fr: 'Al momento non ci sono informazioni disponibili.\n\nSiamo già in contatto con il produttore per potervi presentare a breve dettagli e immagini.'
	}
}


export default function Materials() {

	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [CSVData, setCSVData] = React.useState<any[]>([]);
	const [CSVFileInfo, setCSVFileInfo] = React.useState<IFileInfo | undefined>();
	const [rowsPerPage, setRowsPerPage] = React.useState(100);
	const [newMaterial, setNewMaterial] = useState<Material|undefined>()
	const [showFilter, setShowFilter] = useState<HTMLButtonElement|undefined>()
	const [searchText, setSearchText] = useState('')
	const [filteredMaterials, setFilteredMaterials] = useState<Material[]|undefined>(undefined)
	let [filters, setFilters] = useState<Filter>()
	const [showProgress, setShowProgress] = useState({
		completed : 0,
		total : 0,
		visible : false
	})

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	let allMaterials = useSelector((state: AppState) => state.materials)
	let materialsState = allMaterials.filter(
		(material) => {
			return material.status != 'deleted'
		}
	).sort(
		(a,b) => compareObjectsWithTime(a,b,'newer','updatedAt')
	)

	let materials : Material[] = []
	if(filteredMaterials){
		materials = (filteredMaterials ?? [])
	}else{
		materials = materialsState
	}

	// console.log('Materials',materials)

	useEffect(() => {

		if (materials.length == 0) {
			setShowProgress({
				visible: true,
				completed: 0,
				total: 0
			})
			dispatch(getMaterials(undefined,isDev() ? 10 : undefined))
				.then(
					() => {
						setShowProgress({
							visible: false,
							completed: 0,
							total: 0
						})
					}
				)
				.catch(
					(error) => console.log('Error', error)
				)
		}
	},[]);

	const dispatch = useDispatch()

	const parserOptions = {
		header: true,
		dynamicTyping: true,
		skipEmptyLines: true,
		transformHeader: (header: any) =>
			header
				.replace(/\W/g, '_')
	}

	const onParseError = (error: Error) => {
		console.log('Error Parsing : ', error)
		alert('Error Loading CSV\n\n' + String(error))
	}

	const onFileLoaded = (data: Array<any>, fileInfo: IFileInfo) => {
		setCSVData(data)
		setCSVFileInfo(fileInfo)
	}

	const onClickUpload = () => {
		dispatch(uploadMaterialsFromCSV(CSVData,onProgressUpload))
		.then(
			() => setShowProgress({
				completed : 0,
				total : 0,
				visible : false
			})
		)
		.catch(
			(error) => {
				setShowProgress({
					completed : 0,
					total : 0,
					visible : false
				})
				alert('Error Uploading Materials : ' + String(error))
				console.log('Error Uploading Materials : ',)
			}
		)
		setCSVData([])
		setCSVFileInfo(undefined)
	}

	function onProgressUpload (completed:number,totalCount:number) {
		setShowProgress({
			completed : completed,
			total : totalCount,
			visible : true
		})
	}

	const onClickExport = () => {
		exportListToCSV(allMaterials, 'materials',[
			'id',
			'name',
			...createFlatEmptyLanguageKeys('translatedName'),
			'type',
			'owner_name',
			'owner_id',
			'owner_address_country',
			...createFlatEmptyLanguageKeys('owner.address.translatedCountry'),
			'owner_id',
			'producer_name',
			'producer_id',
			'alcoholByVolume',
			'description',
			...createFlatEmptyLanguageKeys('translatedDescription'),
			'limitedEdition',
			'soldOut',
			'social_homepage',
			'social_facebookUrl',
			'social_instagramUrl',
			'kinds_one_id',
			'kinds_one_name',
			'kinds_two_id',
			'kinds_two_name',
			'kinds_three_id',
			'kinds_three_name',
			'kinds_four_id',
			'kinds_four_name',
			'rolloutDate_seconds',
			'thumbnail',
			'show',
			'deletion_flag',
			'comment',
			'status',
			'updatedAt_seconds',
			'updatedAt_nanoseconds',
			'updatedBy_email',
			'createdAt_seconds',
			'createdAt_nanoseconds',
			'createdBy_email',
			'ratings_absoluteTotal',
			'ratings_absoluteAverage',
			'ratings_numberOfRatings',
			'countryOfOrigin_code',
			'countryOfOrigin_name',
		])
	}

	const onClickAdd = () => {
		const newMaterial = {
			id : '',
			name : '',
			type : 'gin',
			...createEmptyLanguageObject('translatedName'),
			description: translatedDefaultDescription()['en'],			
			translatedDescription : {
				...translatedDefaultDescription()
			},
			kinds: {
				one: {
					id: 'todo',
					name: 'Todo'
				},
				two: {
					id: 'todo',
					name: 'Todo'
				},
				three: {
					id: 'todo',
					name: 'Todo'
				}
			},
			rolloutDate: createTimeStamp(-2208988800,0)
		} as Material
		setNewMaterial(newMaterial)
	}

	const onClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
		setShowFilter(event.currentTarget)
	 };


	const onCloseFilter = () => {
		setShowFilter(undefined)
	}

	async function onSubmitSearch(event?) {

		event?.preventDefault && event.preventDefault()

		if(!filters && searchText.trim().length == 0){
			setFilteredMaterials(undefined)
			return
		}

		let filtered = materialsState.filter(
			(material) => {
				return filters?.type ? material.type == filters?.type : true
			}
		)

		if (searchText.trim().length > 0) {
			filtered = filtered
				.filter(
					(material) =>
						material.name?.toLowerCase && material.name.toLowerCase().includes(searchText?.toLowerCase()) ||
						material.owner?.name?.toLowerCase && material.owner?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
						material.producer?.name?.toLowerCase && material.producer?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
						material.comments?.toLowerCase && material.comments?.toLowerCase().includes(searchText?.toLowerCase()) || 
						(Object.values(material.kinds ?? {}) ?? []).includes(searchText?.toLowerCase()) ||
						material.tags?.includes(searchText.toLowerCase())
				)			
		}		
		if(filtered.length == 0){
			try {
				const response = await dispatch(searchMaterialWithName(searchText))
				setFilteredMaterials(response.data)	
			} catch (error) {
				
			}
		}else{
			setFilteredMaterials(filtered)
		}
	}

	function onClickRefresh(){
		
		setShowProgress({
			visible: true,
			completed: 0,
			total: 0
		})

		dispatch(getMaterials(undefined,isDev() ? 10 : undefined))
		.then(
			(response) => {
				
				setFilteredMaterials([])
				setTimeout(() => {
					setShowProgress({
						visible: false,
						completed: 0,
						total: 0
					})
					setFilteredMaterials(undefined)	
				}, 500);						
			}
		)
		.catch(
			(error) => {
				setShowProgress({
					visible: false,
					completed: 0,
					total: 0
				})
				console.log('Error Getting Updated : ',error)
			}
		)
	}

	async function onClickRunUpdate() {

		try {

			const response = await Actions.readCollection<Material>('materials', {
				limit: 10000,
				where: [
					{
						fieldPath: 'type',
						opStr: '!=',
						value: 'garnish'
					}
				]
			})			

			const ___materials = response.data
			console.log('Total Materials : ', ___materials.length)

			await Actions.batchUpdate<Material>(___materials.map((m) => {
				return {
					path: `materials/${m.id}`,
					document: {
						
					}
				}
			}),(index) => {
				console.log('Progress : ',index)
			})


			console.log('Completed!')
		} catch (error) {
			console.log('Error Occurred : ', error)
		}

	}

	return (
		<div className={classes.root}>
			<Label className={classes.title} variant='h5'>Materials</Label>
			<Backdrop style={{
               zIndex: 1000,
					color: '#fff',
					display : 'flex',
					flexDirection : 'column'
            }} open={showProgress.visible}>
               <CircularProgress color="inherit" />
					{showProgress.total > 0 && <Label 
						style={{
							marginTop : 10
						}}
						variant='h6'>Uploaded {showProgress.completed} of {showProgress.total}</Label>}
			</Backdrop>
			<div style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginBottom: 10,
				justifyContent: 'space-between'
			}}>
				<Button variant='contained' color='primary'>
					<CSVReader
						cssClass="csv-reader-input"
						label="Upload CSV"
						onFileLoaded={onFileLoaded}
						onError={onParseError}
						parserOptions={parserOptions}
						inputId="ObiWan"
						inputStyle={{ opacity: 0, width: '170px', marginLeft: '-170px' }} />
				</Button>
				{CSVFileInfo ? <div>
					{CSVFileInfo && <Text style={{
						marginRight: 10
					}}>({CSVFileInfo.name})</Text>}
					{CSVData.length > 0 && <Text style={{
						marginRight: 10
					}}>{`Found ${CSVData.length} Materials`}</Text>}
					{CSVData.length > 0 &&
						<Button
							onClick={onClickUpload}
							variant='outlined'>Upload</Button>}
				</div> : 
				<div>
					{materials.length > 0 && <Button
							onClick={onClickRefresh}
							variant='outlined'>Refresh</Button>}
				</div>}
				{isDev() && <Button
					onClick={onClickRunUpdate}
					variant='outlined'>Run Update</Button>}
			</div>
			<Paper>
				<TableContainer className={classes.container}>
					<div style={{
						display: 'flex',
						flex: 1,
						justifyContent: 'space-between',
						width: '100%',
						background: 'rgb(250,250,250)',
						paddingTop : 5,
						paddingBottom : 5
					}}>
						<IconButton 
							onClick={onClickAdd}
							aria-label="filter list">
							{!newMaterial && <AddIcon/>}
						</IconButton>
						<form 
							onSubmit={onSubmitSearch}
							style={{
							display : 'flex',
							flex: 1,
							flexDirection : 'column',
						}}>
							<TextField
								style={{
									flex: 1
								}}
								value={searchText}
								onChange={(event) => {
									setSearchText(event.target.value)
								}}
								onBlur={
									(event) => onSubmitSearch(event.target.value)
								}
								InputProps={{ classes: { input: classes.searchField } }}
								variant='outlined'
								placeholder='Search for Material' />
							{filteredMaterials && <Typography style={{
								alignSelf : 'flex-start'
							}}>Found {(filteredMaterials ?? []).length} materials</Typography>}
						</form>
						<IconButton 
							onClick={onClickFilter}
							aria-label="filter list">
							<FilterListIcon />
							{filters?.type && <div style={{
								background: 'green',
								height: 15,
								width: 15,
								position: 'absolute',
								right: 5,
								top: 10,
								borderRadius: 7.5
							}}/>}
						</IconButton>
						<Popover
							open={showFilter ? true : false}
							anchorEl={showFilter}
							onClose={onCloseFilter}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}>
							<div style={{
								width : 300,
								padding : 15,
								display : 'flex',
								flexDirection : 'column'
							}}>
								<Typography>Filters</Typography>
								<GFSelect
									editing={true}
									style={{
										marginTop : 15
									}}
									label='Material type'
									value={filters?.type}
									types={MaterialTypes}
									onSelectValue={
										(value) => {
											const _filters = {...filters} as Filter
											_filters.type = value
											setFilters(_filters)
										}
									}
									/>	
								<div style={{
									display : 'flex',
									flex : 1
								}}>
									<Button
										style={{
											marginTop: 15,
											marginRight : 5,
											flex : 1
										}}
										onClick={() => {
											filters = undefined
											setFilters(undefined)											
											setShowFilter(undefined)
											onSubmitSearch()
										}}
										variant='contained'>Clear</Button>
									<Button
										style={{
											marginTop: 15,
											marginLeft : 5,
											flex : 1
										}}
										onClick={() => {
											setShowFilter(undefined)
											onSubmitSearch()
										}}
										variant='contained'>Apply</Button>
								</div>															
							</div>
						</Popover>
					</div>
					<Table
						stickyHeader
						aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell></TableCell>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{newMaterial && <Row
								key={newMaterial.id}
								row={newMaterial}
								onAddMaterial={() => setNewMaterial(undefined)}
								onCancelNewMaterial={() => setNewMaterial(undefined)}
								isEditing={true} />}
							{materials.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (<Row key={row.id} row={row} />)
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[50, 100, 500, 1000, { label: 'All', value: 100000 }]}
					component="div"
					count={materials.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={() => null}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
			{materials.length > 0 && <div style={{
				display: 'flex',
				justifyContent: 'space-between',
				marginTop: 10
			}}>
				<div />
				<Button
					onClick={onClickExport}
					variant='outlined'>
					Export CSV
				</Button>
			</div>}
		</div>
	);
}

function Row(props: { row: Material, isEditing?:boolean, 
		onAddMaterial?:()=>void,
		onCancelNewMaterial?:() => void,
		onDeleteMaterial?: () => void
	}) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();
	const [isEditing, setIsEditing] = useState<boolean>(props.isEditing ?? false)
	const [showLoader, setShowLoader] = useState<boolean>(false)
	const [showSnackbar, setShowSnackbar] = useState(false)
	const [companies, setCompanies] = useState<Company[]>(useSelector((s:AppState) => s.companies))
	const materials = useSelector((s:AppState) => s.materials)
	const [material, setMaterial] = useState<Material>(lodash.cloneDeep(materials.find(m => row.id == m.id) ?? row))
	const [forceUpdate, setForceUpdate] = useState(false)
	const dispatch = useDispatch()

	function Alert(props: AlertProps) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}

	function onChangeText(name:string,value:any) {
		const updatingMaterial = { ...material }
		_.set(updatingMaterial, name, value)
		setMaterial(updatingMaterial)
	}

	function onChangeValues(values:any) {
		const updatingMaterial = { 
			...material,
			...values
		}
		setMaterial(updatingMaterial)
	}

	async function onClickUpdate() {
		setShowLoader(true)
		const materialToUpdate = lodash.cloneDeep(material)
		delete materialToUpdate.ratings
		delete materialToUpdate.totalReviews

		const oldCopy = materials.find(m => m.id == materialToUpdate.id)

		if(oldCopy){
			let __otherNames = (oldCopy.otherNames ?? []) as string[]
			__otherNames = __otherNames.concat(checkNameChanges(oldCopy,materialToUpdate))
			materialToUpdate.otherNames = lodash.uniq(__otherNames)
		}

		if(oldCopy?.status != materialToUpdate.status){

			console.log(oldCopy?.status,materialToUpdate.status)

			if((materialToUpdate.status == 'approved' || materialToUpdate.status == 'completed') && 
				oldCopy?.status != 'approved' && 
				oldCopy?.status != 'completed'){

				if(materialToUpdate.approvalDate == null){
					materialToUpdate.approvalDate = firebase.firestore.Timestamp.now()
					console.log('Approval Date is Set')
				}				
			}
		}
		materialToUpdate.lastManualUpdate = getSummarizeUserInfo()
		
		try {

			await dispatch(updateMaterialInfo(materialToUpdate))

		} catch (error) {
			console.log('Error Updating Materials : ',error)
			alert('Error Updating Material' + String(error))
		}
		setShowLoader(false)
		
	}

	function checkNameChanges(
		oldMaterial:Material, 
		newMaterial:Material) : string[] {

		const changedNames = [] as string[]
		const _ = [
			'name',
			...createFlatEmptyLanguageKeys('translatedName','.')
		].forEach((key) => {
			const oldName = lodash.get(oldMaterial,key)
			const newName = lodash.get(newMaterial,key)
			if(oldName && oldName != newName){
				changedNames.push(oldName)
			}
		})
		return changedNames
	}

	function fetchCompanies () {
		return dispatch(getCompanies())
	}

	function onClickAdd() {

		setShowLoader(true)
		dispatch(addMaterial(material))
			.then(
				() => {
					setShowSnackbar(true)
					setShowLoader(false)
					props.onAddMaterial && props.onAddMaterial()
				}
			)
			.catch(
				(error) => {
					setShowLoader(false)
					alert('Error Updating Material' + String(error))
				}
			)
	}

	function onClickRefreshMaterial() {
		setShowLoader(true)
		dispatch(getMaterial(material.id))
			.then(
				(response) => {
					setShowLoader(false)
					setMaterial(response)
				}
			)
			.catch(
				(error) => {
					console.log('error', error)
					setShowLoader(false)
				}
			)
	}

	function onClickDeleteMaterial (){
		setShowLoader(true)
		dispatch(deleteMaterial(material))
			.then(
				() => {
					setShowSnackbar(true)
					setShowLoader(false)
					props.onCancelNewMaterial && props.onCancelNewMaterial()
				}
			)
			.catch(
				(error) => {
					setShowLoader(false)
					alert('Error Updating Material' + String(error))
				}
			)
	}

	if (showLoader)
		return (
			<React.Fragment>
				<TableRow
					className={classes.root}>
					<TableCell 
						style={{
							background : '#dddddd',
							justifyContent : 'center',
							alignItems : 'center'
						}}
						colSpan={columns.length + 2}>
						<CircularProgress color="inherit" />
					</TableCell>
				</TableRow>
			</React.Fragment>
		)

	return (
		<React.Fragment>			
			<Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
				<Alert onClose={() => setShowSnackbar(false)} severity="success">
					Material <b>{material.name}</b> Updated Successfully
				</Alert>
			</Snackbar>
			<TableRow 
				className={classes.root}>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell style={{
					paddingRight: 0,
					paddingLeft: 0,
					display : 'flex',
					flexDirection : 'column'
				}}>
					<IconButton onClick={() => {		
						if(isEditing){
							setMaterial(row)
						}				
						setIsEditing(!isEditing)
						props.onCancelNewMaterial && props.onCancelNewMaterial()
					}}>
						{isEditing ? <CancelIcon /> : <EditIcon />}
					</IconButton>
					{isEditing && <div style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					}}>
						<IconButton
							onClick={() => {
								if (material.name?.trim().length == 0) {
									alert('Material Name is mandatory!')
									return
								}
								if (!validateUrl(material.social?.facebookUrl)) {
									alert('Please Correct Facebook Url. Url must start with http:// or https://. If you do not know the url please enter n/a')
									return
								}
								if (!validateUrl(material.social?.homepage)) {
									alert('Please Correct Homepage Url. Url must start with http:// or https://. If you do not know the url please enter n/a')
									return
								}
								if (!validateUrl(material.social?.instagramUrl)) {
									alert('Please Correct Instagram Url. Url must start with http:// or https://. If you do not know the url please enter n/a')
									return
								}
								// if(!validateUrl(material.social?.twitterUrl)){
								// 	alert('Please Correct Twitter Url. Url must start with http:// or https://. If you do not know the url please enter n/a')
								// 	return 
								// }
								setIsEditing(false)
								material.id.length > 0 ? onClickUpdate() : onClickAdd()
							}}>
							<CheckIcon />
						</IconButton>						
					</div>}
					{isEditing && material.id?.length > 0 && <IconButton 
						onClick={onClickDeleteMaterial}>
						{/* <DeleteIcon /> */}
					</IconButton>}					
				</TableCell>
				{columns.map((column) => {
					const value = lodash.get(material, column.id)
					if ((column.editable ?? true) == false ||  !isEditing)
						return (
							<TableCell key={column.id} align={column.align}>
								{column.format ? column.format(value) : value}
							</TableCell>
						)

					if (column.type == 'text') {
						return (
							<TableCell key={column.id} align={column.align}>
								<GFTextField 
									onChangeText={(value) => onChangeText(column.id,value)}
									editing={isEditing}
									value={column.format ? column.format(value) : value}/>
							</TableCell>
						)
					}else if(column.type == 'checkbox'){
						return (
							<TableCell key={column.id} align={column.align}>
								<GFCheckbox 
									onChangeValue={(value) => onChangeText(column.id,value)}
									value={value} 
									editing={isEditing}
									label={column.label}/>
							</TableCell>
						)
					}else if(column.type == 'select'){
						return (
							<TableCell key={column.id} align={column.align}>
								<GFSelect 
									label={''} 
									onSelectValue={(value) => onChangeText(column.id,value)}
									editing={isEditing}
									types={column.selectTypes}
									value={value}/>
							</TableCell>
						)
					}else if(column.type == 'autocomplete'){

						const option = _.get(material,column.targetPath!) 
						const options = companies.filter(
							(company) => company.status != 'deleted'
						).map(
							(c:Company) => {
								return {
									label : c.name,
									value : c
								}
							}
						)
						return (
							<TableCell key={column.id} align={column.align}>
								<GFAutoComplete 
									value={option && {
										label : option.name,
										value : option
									}}
									onChangeText={
										(value) => {
											if (!value) return
											if (column.id == 'owner.name') {

												let updatingMaterial: Material = { ...material }
												const company = value as Company
												updatingMaterial.owner = lodash.cloneDeep(company)
												if (updatingMaterial.social == null) {
													updatingMaterial.social = lodash.cloneDeep(company.social)
												}
												if(updatingMaterial.countryOfOrigin == null){
													updatingMaterial.countryOfOrigin = getCountryWithCode(updatingMaterial.owner.address?.countryCode ?? '')
												}
												onChangeValues(updatingMaterial)
											} else {
												onChangeText(column.targetPath!, value)
											}
										}
									}
									onOpenModel={
										(completion) => {
											if(companies.length == 0){
												fetchCompanies()
												.then(
													(companies:Company[]) => {
														setCompanies(companies.sort((a,b) => a.name.localeCompare(b.name)))
													}
												)
												.catch(
													() => alert('Error Getting Companies')
												)
											}
										}
									}
									editing={(column.isEditable && column.isEditable(material) && isEditing) ?? isEditing}
									options={options}
									label={column.label}/>
							</TableCell>
						)
					}
				})}
			</TableRow>
			<TableRow>
				<TableCell style={{ padding: 0 }} colSpan={2} />
				<TableCell style={{ padding: 0 }} colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<MaterialForm 
							onChangeValues={
								(values) => onChangeValues(values)
							}
							material={material} 
							isEditing={isEditing} 
							onChangeText={onChangeText}
							onPressRefresh={onClickRefreshMaterial}/>
						{isEditing == true && <div style={{
							marginLeft: 10,
							marginTop: -20,
							marginBottom: 20
						}}>
							<GFCheckbox
								onChangeValue={(value) => setForceUpdate(value)}
								value={forceUpdate}
								editing={isEditing}
								label='Force Update' />
							<div>Use Force Update only if you need to refresh name or images in all cabinets, favorites, recipes etc.</div>
						</div>}
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

export function validateUrl(url) {

	if(url == 'n/a') return true;
	if (/^(?:f|ht)tps?\:\/\//.test(url)) { return true }
	return false;
}

function MaterialForm(props: {
	material: Material,
	isEditing: boolean,
	onChangeText : (name:string,value:any) => void
	onChangeValues : (material:Material) => void
	onPressRefresh : () => void
}) {

	const material = props.material
	const isEditing = props.isEditing
	const rolloutDateString = material.rolloutDate?.seconds && moment.unix(material.rolloutDate.seconds).format('YYYY-MM-DD')
	const [showImageLoader, setShowImageLoader] = useState(false)

	return (
		<Box margin={1} style={{
			paddingBottom: 20,
		}}>
			<div style={{
				display : 'flex',
				flex : 1,
				justifyContent : 'space-between',		
			}}>				
				<div style={{
					marginBottom: 30,
					marginRight : 20,
					flex : 0.6,
				}}>
					{material.translatedName && <div style={{
						marginBottom: 30
					}}>
						{/* <Typography style={{ marginBottom : 5 }}>Translated Name</Typography> */}
						{Object.keys(orderTranslationObject(EmptySupportedLanguages)).map(
							(key) => (
								<GFTextField
									style={{ marginRight: 5 }}
									label={'Name ' + key.toUpperCase()}
									onChangeText={(value) => props.onChangeText(`translatedName.${key}`, value)}
									value={material.translatedName[key]}
									editing={isEditing} />
							)
						)}
					</div>}
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						flex: 1,
						marginBottom: 10
					}}>
						<GFAutoComplete
							style={{
								marginRight: 10,
								width: 250
							}}
							value={material.kinds?.one && {
								label: material.kinds.one.name,
								value: material.kinds.one
							}}
							onChangeText={
								(value) => {
									props.onChangeText(`kinds.one`, value)
								}
							}
							editing={isEditing}
							options={getPrimaryKindsFor(material)}
							label={'Kind 1'} />
						<GFAutoComplete
							style={{
								marginRight: 10,
								width: 250
							}}
							value={material.kinds?.two && {
								label: material.kinds.two.name,
								value: material.kinds.two
							}}
							onChangeText={
								(value) => {
									props.onChangeText(`kinds.two`, value)
								}
							}
							editing={isEditing}
							options={getSecondaryKindsFor(material)}
							label={'Kind 2'} />
						<GFAutoComplete
							style={{
								marginRight: 10,
								width: 250
							}}
							value={material.kinds?.three && {
								label: material.kinds?.three.name,
								value: material.kinds?.three
							}}
							onChangeText={
								(value) => {
									props.onChangeText(`kinds.three`, value)
								}
							}
							editing={isEditing}
							options={getTertiaryKindsFor(material)}
							label={'Kind 3'} />
					</div>					
					<div style={{
						display: 'flex',
						flexWrap: 'wrap',
						flex: 1,
						flexDirection: 'row',
						alignItems: 'center'
					}}>
						{material.type == 'gin' && <GFAutoComplete
							style={{
								marginBottom: 10,
								width: 250,
								marginRight: 10
							}}
							value={{
								label: material.countryOfOrigin?.name ?? '',
								value: material.countryOfOrigin?.name ?? ''
							}}
							onChangeText={
								(value) => {
									props.onChangeText(`countryOfOrigin`, getCountryWithCode(value.code, false))
								}
							}
							editing={isEditing}
							options={countriesOptions}
							label='Country Of Origin' />}
						<div>
							{material.type == 'gin' && <GFCheckbox
								style={{ marginRight: 10 }}
								onChangeValue={(value) => props.onChangeText('soldOut', value)}
								value={material.soldOut ?? false}
								editing={isEditing}
								label='Sold Out' />}
							{material.type == 'gin' && <GFCheckbox
								style={{ marginRight: 10 }}
								onChangeValue={(value) => props.onChangeText('limitedEdition', value)}
								value={material.limitedEdition ?? false}
								editing={isEditing}
								label='Limited Edition' />}
							<GFCheckbox
								style={{ marginRight: 10 }}
								onChangeValue={(value) => props.onChangeText('show', value)}
								editing={isEditing}
								value={material.show ?? false}
								label='Show' />
							<GFCheckbox
								style={{ marginRight: 10 }}
								onChangeValue={(value) => props.onChangeText('light', value)}
								editing={isEditing}
								value={material.light ?? false}
								label='Light/Zero' />
							<GFCheckbox
								style={{ marginRight: 10 }}
								onChangeValue={(value) => props.onChangeText('bio', value)}
								editing={isEditing}
								value={material.bio ?? false}
								label='Bio/Organic' />
						</div>
					</div>
				</div>
				<div style={{
					borderRadius : 5,
					flex : 0.3,
					justifyContent: 'center',
					alignItems: 'center',
				}}>
					<div style={{
						position: 'absolute',
						alignSelf: 'center',
						border: '1px solid #dddddd',
					}}>
						{showImageLoader ? <div style={{
							width: 250,
							height: 250,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
							<CircularProgress color="inherit" />
						</div> : <div>
								{material.id && <img style={{
									width: 250,
									height: 250,
									objectFit: 'contain',
								}} src={(material.images ?? [].length > 0) ? material.images![0] : material.thumbnail} />}
								{isEditing && material.id && <div>
									<ImageUploader
										withIcon={false}
										singleImage
										buttonText='Select Thumbnail'
										onChange={
											(files: File[], pictures: string[]) => {
												onSelectThumbnail(pictures[0])
											}
										}
										imgExtension={['.jpg', '.gif', '.png', '.gif']}
										maxFileSize={5242880} />
								</div>}
								{isEditing && material.thumbnail && <button
									onClick={() => {
										props.onChangeValues({
											thumbnail: null,
											images: []
										} as any)
									}}
									style={{
										position: 'absolute',
										top: 0,
										right: 0
									}}>ⓧ</button>}
							</div>}
					</div>
				</div>
			</div>
			{material.type == 'gin' && <div style={{
				marginBottom: 15
			}}>
				<GFTextField
					style={{ marginRight: 5 }}
					label='% Alcohol'
					onChangeText={(value) => props.onChangeText('alcoholByVolume', value)}
					value={String(material.alcoholByVolume ?? '')}
					editing={isEditing} />
			</div>}
			<div style={{
				marginBottom: 15
			}}>
				<Typography style={{ marginBottom: 5 }}>Social</Typography>
				<GFTextField
					style={{ marginRight: 5 }}
					label='Facebook Url'
					onChangeText={(value) => props.onChangeText('social.facebookUrl', value)}
					value={material.social?.facebookUrl}
					editing={isEditing} />
				<GFTextField
					style={{ marginRight: 5 }}
					onChangeText={(value) => props.onChangeText('social.instagramUrl', value)}
					label='Instagram Url'
					value={material.social?.instagramUrl}
					editing={isEditing} />
				<GFTextField
					style={{ marginRight: 5 }}
					onChangeText={(value) => props.onChangeText('social.homepage', value)}
					label='Homepage'
					value={material.social?.homepage}
					editing={isEditing} />
			</div>
			{material.type != 'garnish' && <div style={{
				marginBottom: 15
			}}>
				<Typography style={{ marginBottom: 5 }}>Description</Typography>
				<GFTextArea
					style={{ marginRight: 5, width : '80%' }}
					onChangeText={(value) => props.onChangeText('description', value)}
					label='Description'
					value={material.description}
					editing={isEditing} />
			</div>}
			{material.type != 'garnish' && <div style={{
					marginBottom: 15
				}}>
					{/* <Typography style={{ marginBottom : 5 }}>Translated Description</Typography> */}
					{Object.keys(orderTranslationObject(EmptySupportedLanguages)).map(
						(key) => <GFTextArea
						style={{ marginRight: 5, marginTop : 10, width : '80%' }}
							label={'Description ' + key.toUpperCase()}
							onChangeText={(value) => props.onChangeText(`translatedDescription.${key}`, value)}
							value={(material.translatedDescription ?? EmptySupportedLanguages)[key]}
							editing={isEditing} />
					)}
				</div>}
			{material.type == 'gin' && <div style={{
				marginBottom: 15
			}}>
				<GFTextField
					style={{ marginRight: 5 }}
					label='Barcode'
					onChangeText={(value) => null}
					value={String(material.barcodes?.join(','))}
					editing={false} />
			</div>}
			{material.type == 'gin' && <div style={{
				marginBottom: 15
			}}>
				<GFTextField
					style={{ marginRight: 5 }}
					label='Rollout Date'
					type='date'
					onBlur={(value) => {
						const seconds = moment(value,"YYYY-MM-DD").toDate().getTime()/1000
						props.onChangeText('rolloutDate', createTimeStamp(seconds))
					}}
					defaultValue={rolloutDateString}
					editing={isEditing} />
			</div>}
			{material.type == 'gin' && <div style={{
				marginBottom: 15,
				display: 'flex',
				flexDirection: 'column',
			}}>
				<GFCheckbox
					style={{ marginRight: 10 }}
					onChangeValue={(value) => {
						if(material.recommendation){
							props.onChangeText('recommendation', null)
						}else{
							const seconds = (new Date()).getTime() / 1000
							props.onChangeText('recommendation.startDate', createTimeStamp(seconds))
						}
					}}
					editing={isEditing}
					value={material.recommendation == undefined ? false : true}
					label='Recommend this Gin?' />
				{material.recommendation != null && <div style={{
					display: 'flex',
					flexDirection: 'row',
				}}>
					<GFTextField
						style={{ marginRight: 5, width: 200 }}
						label='Start Date'
						type='date'
						onChangeText={(value) => {
							const seconds = moment(value, "YYYY-MM-DD").toDate().getTime() / 1000
							props.onChangeText('recommendation.startDate',createTimeStamp(seconds))
						}}
						value={moment.unix(material.recommendation?.startDate?.seconds ?? (new Date().getTime())/1000).format('YYYY-MM-DD')}
						editing={isEditing} />	
					<GFTextField
						style={{ marginRight: 5, width: 200 }}
						label='End Date'
						type='date'
						onChangeText={(value) => {
							const seconds = moment(value, "YYYY-MM-DD").toDate().getTime() / 1000
							props.onChangeText('recommendation.endDate',createTimeStamp(seconds))
						}}
						value={moment.unix(material.recommendation?.endDate?.seconds ?? (new Date().getTime())/1000).format('YYYY-MM-DD')}
						editing={isEditing} />				
				</div>}
				{material.recommendation?.endDate == null && 
					<Text>Select an end date for the recommendation</Text>}
			</div>}
			<div style={{
				marginBottom: 15
			}}>
				<Typography style={{ marginBottom: 5 }}>Comments</Typography>
				<GFTextArea
					style={{ marginRight: 5, width : '80%' }}
					onChangeText={(value) => props.onChangeText('comments', value)}
					label='Description'
					value={material.comments}
					editing={isEditing} />
			</div>
			<div style={{
				display: 'flex'
			}}>
				<div style={{
					marginRight: 20
				}}>
					{material.lastManualUpdate &&
						<Typography style={{ marginBottom: 5 }}>
							<b>Last Manual Update By</b> : {material.lastManualUpdate?.email}
						</Typography>}
					{material.updatedBy?.email &&
						<Typography style={{ marginBottom: 5 }}><b>Last Updated By</b> : {material.updatedBy?.email}</Typography>}
					{material.updatedAt &&
						<Typography style={{ marginBottom: 5 }}><b>Last Updated At</b> : {moment(material.updatedAt.toDate()).fromNow()}</Typography>}
				</div>
				<div>
					{material.createdBy?.email &&
						<Typography style={{ marginBottom: 5 }}><b>Last Created By</b> : {material.createdBy?.email}</Typography>}
					{material.createdAt &&
						<Typography style={{ marginBottom: 5 }}><b>Last Created At</b> : {moment(material.createdAt.toDate()).format('DD/MMM/YYYY')}</Typography>}
				</div>
			</div>
			{!isEditing && <button
				onClick={props.onPressRefresh}>Refresh</button>}
		</Box>
	)

	function onSelectThumbnail(data: any) {

		setShowImageLoader(true)
		fetch(data)
			.then(res => res.blob())
			.then(async (blob) => {
				Resizer.imageFileResizer(blob, 300, 180, 'png', 100, 0,
					async (resizedData) => {
						var thumbnailRef = firebase.storage().ref()
							.child(`materials/${material.id}/thumbnail.png`)
						var imageRef = firebase.storage().ref()
							.child(`materials/${material.id}/images/1.png`)

						const thumbnailSnapshot = await thumbnailRef.putString(resizedData as string, 'data_url')
						const thumbnailUrl = await thumbnailSnapshot.ref.getDownloadURL()

						const imageSnapshot = await imageRef.putString(data as string, 'data_url')
						const imageUrl = await imageSnapshot.ref.getDownloadURL()	
						
						props.onChangeValues({
							images:[imageUrl],
							'thumbnail': thumbnailUrl
						} as Material)

						setShowImageLoader(false)
					},'base64')
			})
	}
}

function getPrimaryKindsFor(material: Material) {
	let kinds: Category[] = []
	if (material.type == 'gin') {
		kinds = primaryGinKinds()
	} else if (material.type == 'mixer') {
		kinds = primaryMixerKinds()
	} else {
		kinds = primaryGarnishKinds()
	}
	return kinds.map(category => {
		return {
			label: category.name,
			value: category
		}
	})
}

function getSecondaryKindsFor(material: Material) {
	let kinds: Category[] = []
	if (material.type == 'gin') {
		kinds = secondaryGinKinds()
	} else if (material.type == 'mixer') {
		kinds = secondaryMixerKinds().filter( k => k.field == material.kinds?.one?.id)
	} else {
		kinds = secondaryGarnishKinds()
	}
	return kinds.map(category => {
		return {
			label: category.name,
			value: category
		}
	})
}

function getTertiaryKindsFor(material: Material) {
	let kinds: Category[] = []
	if (material.type == 'gin') {
		kinds = tertiaryGinKinds()
	} else if (material.type == 'mixer') {
		kinds = tertiaryMixerKinds()
	} else {
		kinds = tertiaryGarnishKinds()
	}
	return kinds.map(category => {
		return {
			label: category.name,
			value: category
		}
	})
}