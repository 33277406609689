import React, { useEffect, useMemo, useState } from 'react'
import {
	Companies
} from 'gf-connect';
import { CompanyStatusTypes } from 'gf-connect/lib/Company';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import lodash from 'lodash'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import {
	CollectionDescriptor,
	WhereType,
	OrderType
} from 'firestar';
import {
	useDebounce
} from './MaterialsBeta'
import {
	ActivityIndicator,
	StyleProp,
	Text,
	View,
	ViewStyle
} from 'react-native';
import {
	createEmptyLanguageObject,
	getSupportedLanguages
} from 'gf-connect/lib/Utils'
import { Address, convertCountryToAddressObject } from 'gf-connect/lib/Address';
import { getSearchableCountries, getSearchableCountryObject } from './utils';
import MaterialTable, {
	Column
} from 'material-table';
import {
	SearchBar,
	Button,
	CheckBox,
	Icon
} from 'react-native-elements';
import { Alert } from './Alert';
import {
   getDiff,
   flattenObject
} from 'firestar/lib/Utils'
import { Link } from 'react-router-dom';

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

const countries = getSearchableCountries()
const countriesOptions = getSearchableCountryObject('code','name')
const countriesList = countries.map((c) => {
	return { label: c.name, value: c }
})

export default function CompaniesList() {

	const [searchText, setSearchText] = useState('')
	const [showLoader, setShowLoader] = useState(false)

	const [companies, setCompanies] = useState<Companies.Company[]>([])
	const [smartSearch, setSmartSearch] = useState(false)
	const [lastDoc, setLastDoc] = useState<any>()
	const debouncedSearchText = useDebounce(searchText, 200)
	const [orderBy,] = useState<OrderType>({
		fieldPath: 'updatedAt',
		directionStr: 'desc'
	})
	const columns = useMemo<Column<Companies.Company>[]>(() => {

		const list: Column<Companies.Company>[] = [
			{
				field: 'id',
				title: 'Id',
				editable: 'never',
				headerStyle: { fontWeight: 'bold' },
				filtering: false,
				sorting: true
			},
			{
				field: 'name',
				title: 'Name',
				editable: 'always',
				headerStyle: { fontWeight: 'bold' },
				filtering: false,
				sorting: true
			},
			{
				field: 'email',
				title: 'Email',
				editable: 'always',
				headerStyle: { fontWeight: 'bold' },
				filtering: false,
				sorting: true
			},
			{
				field: 'address.country',
				title: 'Country',
				editable: 'never',
				headerStyle: { fontWeight: 'bold' },
				filtering: false,
				sorting: true
			},
			{
				editable: 'never',
				headerStyle: { fontWeight: 'bold' },
				filtering: false,
				sorting: false,
				render: (c) => (
					<View style={{
						flexDirection: 'row',
						alignItems: 'center'
					}}>
						<Icon
							onPress={() => window.open('https://admin.ginferno.app/playground/companies/' + c.id)}
							name='games' />
					</View>
				),
			},
			{
				field: 'status',
				title: 'Status',
				editable: 'always',
				headerStyle: { fontWeight: 'bold' },
				filtering: false,
				sorting: true,
				lookup: CompanyStatusTypes
			}
		]

		if (smartSearch == false) {
			list.push({
				render: (m) => {
					const value = m[orderBy.fieldPath as string]
					return moment(value.toDate()).format('hh:mm a • DD-MM-YYYY')
				},
				title: orderBy.fieldPath as string,
				headerStyle: { fontWeight: 'bold' },
				filtering: false,
				sorting: true,
			})
		}

		return list
	}, [smartSearch])

	useEffect(() => {
		if (debouncedSearchText) {
			fetchCompanies(false, debouncedSearchText)
		} else {
			fetchCompanies(false)
		}
		return () => {

		}
	}, [debouncedSearchText, orderBy, smartSearch]);

	return (
		<View>
			<View style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 15
			}}>
				<Button
					onPress={onPressRefresh}
					buttonStyle={{
						paddingHorizontal: 50,
						borderRadius: 10
					}}
					title='Refresh' />
				<View style={{
					flexDirection: 'row',
					alignItems: 'center'
				}}>
					<CheckBox
						containerStyle={{
							margin: 0,
							padding: 0,
							borderWidth: 0
						}}
						checkedIcon={<Icon name='check-box' />}
						uncheckedIcon={<Icon name='check-box-outline-blank' />}
						checked={smartSearch}
						onPress={() => setSmartSearch(!smartSearch)}
						title='Smart Search' />
					<SearchBar
						value={searchText}
						inputStyle={{
							color: 'black'
						}}
						placeholder='Search companies'
						onChangeText={(value) => setSearchText(value)}
						containerStyle={{
							backgroundColor: 'clear',
							borderTopWidth: 0,
							borderBottomWidth: 0,
							width: 350,
						}}
						inputContainerStyle={{
							backgroundColor: '#dddddd',
							borderRadius: 25
						}} />
				</View>
			</View>
			<MaterialTable
				title='Companies'
				actions={[
					{
					   icon: 'link',
					   tooltip: 'Link',
					   onClick: (event, rowData) => {
						  const company = rowData as Companies.Company
						  let url = `https://ginferno.app/companies/${company.id}`
						  if (company.uri != null) {
							 url = `https://ginferno.app/company/${company.uri}`
						  }
						  window.open(url)
					   }
					}
				 ]}
				options={{
					search: false,
					pageSizeOptions: [PAGESIZE],
					pageSize: PAGESIZE,
					sorting: true,
					filtering: false,
					addRowPosition: 'first'
				}}
				onChangePage={onChangePage}
				isLoading={showLoader}
				detailPanel={(data) => (
					<CompanyDetail
						onPressUpdate={onPressUpdate}
						company={data} />
				)}
				editable={{
					onRowAdd: onRowAdd,
					onRowUpdate: onRowUpdate
				}}
				columns={columns}
				data={companies} />
		</View>
	);

	function onChangePage(page: number, pageSize: number) {
		if (companies.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE)) {
			if (lastDoc != null) fetchCompanies(true, searchText)
		}
	}

	async function fetchCompanies(
		isPaginating?: boolean,
		text?: string
	) {
		setShowLoader(true)
		const __searchText = text ?? ''
		const __filter: WhereType[] = []
		if (__searchText.trim().length > 0) {
			if (smartSearch == true) {

				__searchText.trim().toLowerCase().split(' ')
					.filter((item) => item.trim().length > 0)
					.forEach(
						(item) => {
							__filter.push({
								fieldPath: `matches.${item}`,
								opStr: '==',
								value: true
							})
						}
					)

			} else {
				__filter.push({
					fieldPath: 'tags',
					opStr: 'array-contains-any',
					value: __searchText.toLowerCase().trim().split(' ').splice(0, 10)
				})
			}
		}

		const descriptor: CollectionDescriptor = {
			lastDoc: (isPaginating ?? false) === true ? lastDoc : undefined,
			limit: (isPaginating ?? false) === true ? PAGESIZE : INITIAL_PAGESIZE,
			where: __filter,
			orderBy: smartSearch === false ? orderBy : undefined
		}

		console.log('Fetching Companies:', descriptor)

		try {
			const response = await Companies.getAll(descriptor)
			if (isPaginating === true) {
				setCompanies([...companies, ...response.data])
			} else {
				setCompanies(response.data)
			}
			setLastDoc(response.lastDoc)
		} catch (error) {
			console.log('Error Fetching Companies: ', error)
		}
		setShowLoader(false)
	}

	function onPressRefresh() {
		fetchCompanies()
	}

	function onRowAdd(rowData: Companies.Company) {
		return new Promise<Companies.Company>(async (resolve, reject) => {

			try {

				let __companies = [...companies] as Companies.Company[]

				const newCompany = {
					social: {
						facebookUrl: 'n/a',
						homepage: 'n/a',
						instagramUrl: 'n/a',
						twitterUrl: 'n/a'
					}
				} as Companies.Company
				columns.filter((column) => column.editable === 'onAdd' || column.editable === 'always')
					.forEach((column) => {
						const path = column.field as string
						const value = lodash.get(rowData, path)
						lodash.set(newCompany, path, value)
					})

				console.log('newCompany', newCompany)

				const response = await Companies.add(newCompany)

				__companies = [response, ...__companies]

				setCompanies(__companies)

				resolve(response)

			} catch (error) {
				Alert.alert('Error Adding Company', String(error))
				console.log('Error Adding Company', error)
				reject(error)
			}
		})
	}

	function onRowUpdate(rowData: Companies.Company) {
		return new Promise<Companies.Company>(async (resolve, reject) => {

			try {

				const companyId = rowData.id
				const __companies = [...companies] as Companies.Company[]
				const findIndex = __companies.findIndex(m => m.id === companyId)
				const company = __companies[findIndex]

				const updatingCompany = {
					...company
				} as Companies.Company

				columns.filter((column) => column.editable === 'onUpdate' || column.editable === 'always')
					.forEach((column) => {
						const path = column.field as string
						const value = lodash.get(rowData, path)
						lodash.set(updatingCompany, path, value)
					})

				const response = await onPressUpdate(companyId, updatingCompany, company)

				resolve(response)

			} catch (error) {
				reject(error)
			}
		})
	}

	function onPressUpdate(
		companyId: string,
		company: Companies.Company,
		oldCompany: Companies.Company
	) {
		return new Promise<Companies.Company>(async (resolve, reject) => {
			try {

				const __companies = [...companies] as Companies.Company[]
				const findIndex = __companies.findIndex(m => m.id === companyId)

				console.log('company', company)

				const changes = getDiff(company, oldCompany)
            delete (changes as any).tableData;

            console.log('Changes', changes)

            const flattenedChanges = flattenObject(changes)
            console.log('flattenedChanges', flattenedChanges)

				await Companies.update(companyId, flattenedChanges)

				const response = await Companies.get(company.id)
				const updatedCompany = response as Companies.Company

				__companies[findIndex] = updatedCompany
				setCompanies(__companies)
				resolve(updatedCompany)

			} catch (error) {
				Alert.alert('Error Updating Company', String(error))
				console.log('Error Updating Company', error)
				reject(error)
			}
		})
	}

	function CompanyDetail(props: {
		company: Companies.Company,
		onPressUpdate: (
			companyId: string,
			company: Companies.Company,
			oldCompany: Companies.Company
		) => Promise<Companies.Company>
	}) {
		const [company, setCompany] = useState(lodash.cloneDeep(props.company))
		const [isEditing, setIsEditing] = useState(false)
		const [showLoader, setShowLoader] = useState(false)

		const createdAt = company.createdAt
		const createdBy = company.createdBy
		const updatedAt = company.updatedAt
		const updatedBy = company.updatedBy
		const lastManualUpdate = company.lastManualUpdate

		useEffect(() => {
			if (company.address?.city != null && props.company.address?.city == null) {
				const translatedCity = {}
				getSupportedLanguages().forEach((key) => {
					translatedCity[key] = company.address?.city
				})
				onChangeValue('address.translatedCity', translatedCity)
			}
		}, [company.address?.city])

		if (showLoader === true) {
			return (
				<p><ActivityIndicator /></p>
			)
		}

		return (
			<Grid container md={12} style={{
				padding: 20
			}}>
				<Grid item md={8} style={{
					padding: 15
				}}>
					<View style={{
						flexDirection: 'row',
						justifyContent: 'flex-start'
					}}>
						{isEditing == true ? (
							<View style={{
								flexDirection: 'row',
								alignItems: 'center'
							}}>
								<Icon
									style={{
										marginRight: 15
									}}
									onPress={onPressCancelEdit}
									name='cancel' />
								<Icon
									disabledStyle={{
										backgroundColor: 'white',
										opacity: 0.3
									}}
									disabled={lodash.isEqual(company, props.company)}
									onPress={onPressSave}
									name='save' />
							</View>
						) : (
							<Icon
								onPress={() => setIsEditing(true)}
								name='edit' />
						)}
					</View>
					<SectionPanel title=''>
						<TextField
							disabled={isEditing === false}
							variant='outlined'
							label='Company Name'
							value={company.name}
							onChange={(e) => {
								const value = e.target.value
								onChangeValue(`name`, value)
							}}
							placeholder='Company Name' />
					</SectionPanel>
					<SectionPanel title='Social Links' style={{
						flexDirection: 'row',
						flexWrap: 'wrap'
					}}>
						<p>
							<TextField
								style={{
									marginRight: 10,
									width: 300
								}}
								disabled={isEditing === false}
								value={company.social?.facebookUrl}
								onChange={(e) => {
									const value = e.target.value
									onChangeValue(`social.facebookUrl`, value)
								}}
								variant='outlined'
								label='Facebook Url'
								placeholder='Facebook Url' />
						</p>
						<p>
							<TextField
								style={{
									marginRight: 10,
									width: 300
								}}
								disabled={isEditing === false}
								value={company.social?.instagramUrl}
								onChange={(e) => {
									const value = e.target.value
									onChangeValue(`social.instagramUrl`, value)
								}}
								variant='outlined'
								label='Instagram Url'
								placeholder='Instagram Url' />
						</p>
						<p>
							<TextField
								style={{
									marginRight: 10,
									width: 300
								}}
								disabled={isEditing === false}
								value={company.social?.homepage}
								onChange={(e) => {
									const value = e.target.value
									onChangeValue(`social.homepage`, value)
								}}
								variant='outlined'
								label='Homepage Url'
								placeholder='Homepage Url' />
						</p>
					</SectionPanel>
					{/* <SectionPanel title='Description'>
						<p>
							<TextField
								multiline
								style={{
									width: 700,
									marginRight: 10,
								}}
								value={company.description}
								onBlur={() => {
									if((company?.description ?? '').length > 0 && company.translatedDescription == null){
										onChangeValue('translatedDescription',createEmptyLanguageObject())
									}
								}}
								onChange={(e) => {
									const value = e.target.value
									onChangeValue(`description`, value)
								}}
								disabled={isEditing === false}
								variant='outlined'
								label={'Default'}
								placeholder={'Default'} />
						</p>
						{getSupportedLanguages().map((key) => {
							return (
								<p>
									<TextField
										multiline
										style={{
											width: 700
										}}
										value={(company.translatedDescription ?? {})[key]}
										onChange={(e) => {
											const value = e.target.value
											onChangeValue(`translatedDescription.${key}`, value)
										}}
										disabled={isEditing === false}
										variant='outlined'
										label={key}
										placeholder={key} />
								</p>
							)
						})}
					</SectionPanel> */}
					<SectionPanel title='Address'>
						<p>
							<TextField
								style={{
									marginRight: 10,
									width: 300
								}}
								value={company.address?.street}
								onChange={(e) => {
									const value = e.target.value
									onChangeValue(`address.street`, value)
								}}
								disabled={isEditing === false}
								variant='outlined'
								label='Street'
								placeholder='Street' />
							<TextField
								style={{
									marginRight: 10,
									width: 300
								}}
								value={company.address?.state}
								onChange={(e) => {
									const value = e.target.value
									onChangeValue(`address.state`, value)
								}}
								disabled={isEditing === false}
								variant='outlined'
								label='State'
								placeholder='State' />
						</p>
						<p>
							<b><label>City</label></b>
							<View style={{
								flexDirection: 'row',
								flexWrap: 'wrap'
							}}>
								<p>
									<TextField
										multiline
										variant='outlined'
										label={'Default'}
										value={company.address?.city}
										onChange={(e) => {
											const value = e.target.value
											onChangeValue(`address.city`, value)
										}}
										disabled={isEditing === false}
										style={{
											width: 300,
											marginRight: 10
										}}
										placeholder={'Default City'} />
								</p>
								{getSupportedLanguages().map((key) => {
									return (
										<p>
											<TextField
												multiline
												variant='outlined'
												label={key}
												value={(company.address?.translatedCity ?? {})[key]}
												onChange={(e) => {
													const value = e.target.value
													onChangeValue(`address.translatedCity.${key}`, value)
												}}
												disabled={isEditing === false}
												style={{
													width: 300,
													marginRight: 10,
												}}
												placeholder={key} />
										</p>
									)
								})}
							</View>
						</p>
						<p>
							<Autocomplete
								style={{ width: 300 }}
								options={countriesList}
								autoHighlight
								getOptionLabel={(option) => option.label}
								renderOption={(option) => option.label}
								value={{
									label: company.address?.country ?? '',
									value: countriesOptions[company.address?.countryCode ?? '']
								}}
								disabled={isEditing == false}
								onChange={(e, option) => {
									setCompany({
										...company,
										address: {
											...company.address,
											translatedCountry: option?.value.translatedName,
											...convertCountryToAddressObject(option?.value!)
										} as Address
									})
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Country"
										variant="outlined"
										inputProps={{
											...params.inputProps,
											autoComplete: 'new-password', // disable autocomplete and autofill
										}}
									/>
								)}
							/>
							<TextField
								style={{
									marginRight: 10,
									marginTop: 15
								}}
								value={company.address?.postalCode}
								onChange={(e) => {
									const value = e.target.value
									onChangeValue('address.postalCode', value)
								}}
								disabled={isEditing === false}
								variant='outlined'
								label='Postal Code'
								placeholder='Postal Code' />
						</p>
					</SectionPanel>
					<Link style={{
						textDecoration: 'none'
					}} to={`/shops/company/${company.id}`}>VIP Shops →</Link>
				</Grid>
				<Grid item md={4}>
					<View style={{
						borderWidth: 1,
						borderRadius: 5,
						borderColor: '#dddddd',
						padding: 15
					}}>
						<SectionPanel
							title={'Comments'}>
							<textarea
								disabled={isEditing == false}
								style={{
									marginBottom: 5,
									fontFamily: 'sans-serif',
									minHeight: 100
								}}
								value={company.comments}
								onChange={(e) => {
									const value = e.target.value
									onChangeValue('comments', value)
								}} />
						</SectionPanel>
						<SectionPanel
							title={'Last Manual Updated By'}>
							<View>
								<Text>{lastManualUpdate?.displayName ?? 'No Name'} • {lastManualUpdate?.email ?? 'No Email'}</Text>
							</View>
						</SectionPanel>
						<SectionPanel
							title={'Created At'}>
							<View>
								<Text>{createdAt.toDate().toTimeString()} • {createdAt.toDate().toDateString()}</Text>
								<Text>{createdBy.displayName ?? 'No Name'} • {createdBy.email}</Text>
							</View>
						</SectionPanel>
						<SectionPanel
							title={'Updated At'}>
							<View>
								<Text>{updatedAt.toDate().toTimeString()} • {moment(updatedAt.toDate()).fromNow()}</Text>
								<Text>{updatedBy.displayName ?? 'No Name'} • {updatedBy.email}</Text>
							</View>
						</SectionPanel>
					</View>
				</Grid>
			</Grid>
		)

		function onChangeValue(path: string, value: any) {
			const __company = { ...company }
			lodash.set(__company, path, value)
			setCompany(__company)
		}

		function onPressCancelEdit() {
			setIsEditing(false)
			setCompany(lodash.cloneDeep(props.company))
		}

		async function onPressSave() {
			setShowLoader(true)
			try {
				const response = await props.onPressUpdate(company.id, company, props.company)
				setCompany(lodash.cloneDeep(response))
				setIsEditing(false)
			} catch (error) {

			}
			setShowLoader(false)
		}
	}
}

function SectionPanel(props: {
	title: string,
	children?: React.ReactNode,
	style?: StyleProp<ViewStyle>
}) {
	const { title, style } = props
	return (
		<View>
			<p>
				<Text style={{
					marginBottom: 10,
					fontWeight: '600'
				}}>{title}</Text>
				<View style={style}>{props.children}</View>
			</p>
		</View>
	)
}