import React, { useState, useEffect, createContext, useContext, useMemo, useRef } from 'react'
import MaterialTable,{
   Column
} from 'material-table';
import Shops, {
   ShopType,
   ShopStatusTypes,
   FeedFileType,
   ParseOptions,
   FileTypes,
   ReadStatusTypes
} from 'gf-connect/lib/Shops';
import {
   Text,
   Image,
} from 'react-native';
import {
   Icon
} from 'react-native-elements'
import Paper from '@material-ui/core/Paper';
import TabContext from '@material-ui/lab/TabContext';
import Tab from '@material-ui/core/Tab';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {
   ActivityIndicator, View
} from 'react-native';
import lodash from 'lodash'
import EditOutlined from '@material-ui/icons/EditOutlined'
import SaveOutlined from '@material-ui/icons/SaveOutlined'
import CloseOutlined from '@material-ui/icons/CloseOutlined'
import IconButton from '@material-ui/core/IconButton';
import firebase from 'firebase/compat/app'
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { WhereType } from 'firestar';
import {
   FeedItemKeys
} from 'gf-connect/lib/Shops';
import {
   GFSelect,
   GFAutoCompleteField,
   GFTextField,
} from './FormUtils'
import { 
   getAllMaterials
} from 'gf-connect/lib/Material';
import { exportListToCSV, downloadJSON, getSupportedLanguages } from 'gf-connect/lib/Utils';
import { Alert } from './Alert';
import {
   renderChips
} from './BarcodesMapping'
import ShopBilling from './ShopBilling';
import { getSearchableCountries, getSearchableCountryObject } from './utils';
import ShopFeed from './ShopFeed';
import {
   createTimeStamp,
   getDiff,
   flattenObject
} from 'firestar/lib/Utils'
import { useParams } from 'react-router-dom';
import { Companies } from 'gf-connect';
import { SectionPanel } from './MaterialsBeta';
import TextField from './utils/TextField'

const supportedLanguages = getSupportedLanguages()

const searchableCountries = getSearchableCountries()
const countriesOptionList = searchableCountries.map((c) => {
   return {
      label: c.name,
      value: c.code
   }
})

export interface Params {
   companyId?: string
}

const countries = getSearchableCountries()

export const EditingContext = createContext(false)

function getExportOptions(){
   return [
      {
         id: 'export-options',
         title : 'Export Options'
      },
      {
         id: 'all-feed',
         title : 'All Feed Items'
      },
      {
         id: 'unmapped-with-barcode',
         title : 'Unmapped With Barcode'
      },
      {
         id: 'unlinked-without-barcode',
         title: 'Unlinked Without Barcode'
      }
   ]
}

function getExportKeys(){
   return [
      'barcode',
      'title',
      'brand',
      'link',
      'shop_id',
      'shop_name',
      'id',
      'price',
      'currency',
      'availability',
      'type',
      'category',
      'volume',
      'volumeUnit',
      'volumeValue',
      'shippingCountry',
      'barcodes_0',
      'barcodes_1',
      'barcodes_2',
      'barcodes_3',
      'barcodes_4',
      'barcodes_5',
   ]
}

export default function ShopsList() {

   const [shops, setShops] = useState<ShopType[]>([])
   const [lastDoc, setLastDoc] = useState<any>()
   const [showLoader, setShowLoader] = useState(false)
   const params = useParams() as Params
   const { companyId } = params ?? {}
   const [company, setCompany] = useState<Companies.Company>()

   const columns = useMemo<Column<any>[]>(() => {
      return [
         {
            title: 'ID',
            field: 'id',
            editable: 'never',
         },
         {
            title: 'Shop Name',
            field: 'name',
            editable: 'always'
         },
         {
            title: 'Contact Person',
            render: (shop) => (
               <View>
                  <Text>{shop.contactPerson?.displayName}</Text>
                  <Text>{shop.contactPerson?.email}</Text>
                  <Text>{shop.contactPerson?.phoneNumber}</Text>
               </View>
            ),
            editable: 'always'
         },
         {
            title: 'Status',
            field: 'status',
            lookup: ShopStatusTypes,
            editable: 'always'
         },
         {
            title: 'Read Status',
            render: (shop) => (
               <View>
                  {shop.lastRead?.toDate != null ? <Text style={{
                     textAlign: 'center',
                  }}>{moment(shop.lastRead?.toDate?.()).format('hh:mm a DD:MM:YYYY')}</Text> : (
                     <Text style={{
                        textAlign: 'center',
                     }}>Not Read</Text>
                  )}
                  {shop.lastRead?.toDate != null && <Text style={{
                     textAlign: 'center',
                  }}>{moment(shop.lastRead?.toDate?.()).fromNow()}</Text>}
                  {shop.readStatus != null && <Text style={{
                     backgroundColor: shop.readStatus == 'failed' ? 'red' : 'green',
                     color: 'white',
                     padding: 2,
                     paddingHorizontal: 10,
                     textAlign: 'center',
                     borderRadius: 5,
                     marginTop: 5
                  }}>{ReadStatusTypes[shop.readStatus]}</Text>}
               </View>
            ),
            editable: 'never',
            hidden: companyId != null
         },
         {
            title: 'Added On',
            render: (shop) => {
               const timestamp = createTimeStamp(shop.createdAt.seconds ?? 0)
               const dateString = moment(timestamp.toDate()).format('DD MMM, YYYY')
               const fromNow = moment(timestamp.toDate()).fromNow()
               return (
                  <div>
                     <div>{dateString}</div>
                     <div>{fromNow}</div>
                  </div>
               )
            },
            editable: 'never'
         },
         {
            field: 'id',
            editable: 'never',
            title: '',
            render: (m) => (
               <View style={{
                  flexDirection: 'row',
                  alignItems: 'center'
               }}>
                  <Icon
                     onPress={() => window.open('https://admin.ginferno.app/playground/shops/' + m.id)}
                     name='games' />
               </View>
            ),
            sorting: true,
         }
      ]
   }, [])

   useEffect(() => {
      fetchShops(companyId)
   }, [companyId])

   async function fetchShops(companyId?: string) {
      setShowLoader(true)
      try {
         const where = [] as WhereType[]
         if(companyId){
            where.push({
               fieldPath: 'companyInfo.id',
               opStr: '==',
               value: companyId
            })
            const companyResponse = await Companies.get(companyId)
            setCompany(companyResponse)
         }else{
            where.push({
               fieldPath: 'type',
               opStr: '!=',
               value: 'company'
            })
         }
         const response = await Shops.getShops({
            orderBys:[
               {
                  fieldPath: 'type',
                  directionStr: 'desc'
               },
               {
                  fieldPath: 'createdAt',
                  directionStr: 'desc'
               }
            ],
            where: where,
            limit: 100,
            lastDoc: lastDoc
         })
         setLastDoc(response.lastDoc)
         setShops(response.data)  
      } catch (error) {
         Alert.alert('Error Fetching Shops', String(error))
         console.log('Error Fetching Shops: ', error)
      }
      setShowLoader(false)
   }

   if(showLoader == true){
      return (
         <View style={{
            padding: 50
         }}>
            <ActivityIndicator/>
         </View>
      )
   }

   return (
      <div>
         <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10
         }}>
            <Button
               variant='outlined'
               style={{
                  padding: '0 5em 0 5em'
               }}
               onClick={() => fetchShops()}>Refresh</Button>
            <div>
            <select style={{
                  padding: 15,
                  fontSize: 16,
                  borderWidth: 0,
                  marginRight: 15,
                  textAlign: 'right',
                  background: 'transparent'
               }} value={'export-options'} onChange={onSelectExportOption}>
                  {getExportOptions().map(({ id, title }) => {
                     return (
                        <option value={id}>{title}</option>
                     )
                  })}
               </select>
            </div>
         </div>
         <MaterialTable
            isLoading={showLoader}
            data={shops}
            title={(
               <View style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
               }}>
                  {companyId && company?.thumbnail != null && <Image 
                     style={{
                        width: 50,
                        height: 50,
                        marginRight: '1em',
                        borderRadius: 25
                     }}
                     source={{ 
                        uri: company?.thumbnail
                     }}/>}
                  <h2>{companyId != null ? `${company?.name} - Shops` : 'Shops'}</h2>
               </View>
            )}
            options={{
               pageSize: shops.length,
               pageSizeOptions: [shops.length],
               searchFieldVariant: 'outlined',
               searchFieldStyle: {
                  margin: 10,
                  marginTop: 15
               },
               sorting: false
            }}
            editable={{
               onRowAdd: onPressRowAdd,
               onRowDelete: onPressRowDelete,
               onRowUpdate: (newData: ShopType, oldData?: ShopType) => {

                  const columnData = {} as ShopType
                  columns.filter(m => m.editable === 'always' || m.editable === 'onAdd').forEach((m) => {
                     lodash.set(columnData, m.field ?? '', lodash.get(newData, m.field ?? '') ?? null)
                  })
                  return onPressRowUpdate(newData.id, columnData, oldData)
               }
            }}
            detailPanel={(shop) => (
               <DetailPanel
                  onPressUpdate={onPressRowUpdate}
                  shop={shop}
               />
            )}
            columns={columns}
         />
      </div>
   )

   function onPressRowAdd(newData: ShopType) {
      return new Promise<void>(async (resolve, reject) => {
         try {
            const newCompany = newData
            if(company){
               newData.companyInfo = Companies.getInfo(company)
               newData.type = 'company'
            }else{
               newData.type = 'other'
            }
            const response = await Shops.createShop(newCompany) as ShopType
            setShops([...shops, response])
            resolve()
         } catch (error) {
            console.log('Error onPressRowAdd : ', error)
            reject(error)
         }
      })
   }

   function onSelectExportOption(event){
      if(event.target.value == 'all-feed'){
         exportAllFeedItems()
      }else if(event.target.value == 'unmapped-with-barcode'){
         exportUnmappedWithBarcodes()
      }else if(event.target.value == 'unlinked-without-barcode'){
         exportUnlinkedWithoutBarcodes()
      }
   }

   async function exportAllFeedItems(){
      setShowLoader(true)
      try {         

         const twoDaysAgo = (new Date())
         twoDaysAgo.setHours(-48, 0, 0, 0);

         const response = await Shops.getFeedData({
            limit: 10000,
            where: [
               {
                  fieldPath: 'createdAt',
                  opStr: '>',
                  value: twoDaysAgo
               }
            ],
            orderBy: {
               fieldPath: 'createdAt',
               directionStr: 'desc'
            }
         })

         console.log('Items to Download ',response.data.length)

         exportListToCSV(
            response.data,
            'All_Feed_Items_'+response.data.length+'_items',
            getExportKeys()
         )
         
      } catch (error) {
         console.log('Error Download Feed Items  :',error)
         Alert.alert('Error Downloading Feed Items',String(error))
      }
      setShowLoader(false)
   }


   async function exportUnmappedWithBarcodes(){
      setShowLoader(true)

      try {
      
         const twoDaysAgo = (new Date())
         twoDaysAgo.setHours(-48, 0, 0, 0);

         const feedResponse = await Shops.getFeedData({
            limit: 10000,
            where: [
               {
                  fieldPath: 'createdAt',
                  opStr: '>',
                  value: twoDaysAgo
               }
            ],
            orderBy: {
               fieldPath: 'createdAt',
               directionStr: 'desc'
            }
         })
         const feedItems = feedResponse.data

         const materialResponse = await getAllMaterials({
            limit: 10000,
            orderBy: {
               fieldPath: 'createdAt',
               directionStr: 'desc'
            }
         })

         const allMaterials = materialResponse.data
         
         console.log('Total Feed Items : ',feedItems.length)
         console.log('Total Materials : ',allMaterials.length)
      
         const unmappedFeedItems = feedItems.filter(
            (feedItem) => {
               const barcode = (feedItem.barcode ?? '')
               if(barcode.length == 0 || barcode == 'null' || barcode == null){
                  return false
               }
               const result = allMaterials.find(
                  (m) => lodash.intersection((m.barcodes ?? []), (feedItem.barcodes ?? [])).length > 0)
               return result == null
            }
         )

         console.log('Total Unmapped Items : ',unmappedFeedItems.length)

         exportListToCSV( 
            unmappedFeedItems,
            'Unmapped_With_Barcodes_'+unmappedFeedItems.length+'_items',
            getExportKeys()
         )
      
      } catch (error) {
         console.log('Error Download Feed Items  :',error)
         Alert.alert('Error Downloading Feed Items',String(error))
      }

      setShowLoader(false)
   }

   async function exportUnlinkedWithoutBarcodes(){

      setShowLoader(true)

      const twoDaysAgo = (new Date())
      twoDaysAgo.setHours(-48, 0, 0, 0);
      
      try {
      
         const response = await Shops.getFeedData({
            limit: 10000,
            where: [
               {
                  fieldPath: 'createdAt',
                  opStr: '>',
                  value: twoDaysAgo
               }
            ],
            orderBy:{
               fieldPath: 'createdAt',
               directionStr: 'desc'
            }
         })
         const feedItems = response.data         

         const unlinkedFeedItems = feedItems.filter((feedItem) => {
            return (feedItem.linkedMaterialId == null && (feedItem.barcodes ?? []).length == 0)
         }) ?? []

         exportListToCSV(
            unlinkedFeedItems,
            'Unlinked_Without_Barcodes_'+unlinkedFeedItems.length+'_items',
            getExportKeys()
         )
      
      
      } catch (error) {
         console.log('Error Download Feed Items  :',error)
         Alert.alert('Error Downloading Feed Items',String(error))
      }

      setShowLoader(false)      
   }

   function onPressRowDelete(newData: ShopType) {
      return new Promise<void>(async (resolve, reject) => {
         try {
            await Shops.deleteShop(newData.id)
            const __shops = [...shops]
            const findIndex = __shops.findIndex(s => s.id == newData.id)
            if (findIndex >= 0) {
               __shops.splice(findIndex, 1)
               setShops(__shops)
            }
            resolve()
         } catch (error) {
            console.log('Error onPressRowDelete : ', error)
            reject(error)
         }
      })
   }

   function onPressRowUpdate(shopId: string, newData: ShopType, oldData?: ShopType) {
      delete (newData as any).tableData
      return new Promise(async (resolve, reject) => {
         try {

            const changes = getDiff(newData, oldData)
            delete (changes as any).tableData;
            console.log('Changes', changes)

            const flattenedChanges = flattenObject(changes)
            console.log('flattenedChanges', flattenedChanges)

            await Shops.updateShop(shopId, flattenedChanges) as ShopType

            const response = await Shops.getShopWithId(shopId)
            const __shops = [...shops]
            const findIndex = __shops.findIndex(s => s.id === response.id)

            if (findIndex >= 0) {
               __shops[findIndex] = response
               setShops(__shops)
            }
            resolve(response)

         } catch (error) {
            console.log('Error onPressRowUpdate : ', error)
            reject(error)
         }
      })
   }

}

function DetailPanel(props: {
   shop: ShopType,
   onPressUpdate: (shopId: string, shop: ShopType, oldShop: ShopType) => any
}) {

   const { onPressUpdate } = props
   const [shop, setShop] = useState(lodash.cloneDeep(props.shop))
   const [editing, setEditing] = useState(false)
   const [loaderIndexes, setLoaderIndexes] = useState<string[]>([])
   const [countriesLookUp] = useState(getSearchableCountryObject('code','name'))
   const [showLoader, setShowLoader] = useState(false)
   const [showRegionLoader, setShowRegionLoader] = useState(false)

   const [tabIndex, setTabIndex] = useState(0)

   const [filterText, setFilterText] = useState('')

   const mappingObject = shop.mappingObject ?? {}
   const mappingObjectTransformers = shop.mappingObjectTransformers ?? {}
   const regionTableRef = useRef<any>()

   if(showLoader == true){
      return <ActivityIndicator/>
   }

   return (
      <div style={{
         display: 'flex',
         alignItems: 'flex-start',
         flexDirection: 'column',
         padding: 15
      }}>
         <IconButton
            onClick={editing == false ? onClickEdit : onClickSave}>
            {editing == false ? <EditOutlined /> : <SaveOutlined />}
         </IconButton>
         {editing == true && <IconButton
            onClick={onClickClose}>
            <CloseOutlined />
         </IconButton>}

         {shop.companyInfo != null && <SectionPanel
            title='Name'
            render={() => {
               const translatedName = shop.translatedName ?? {}
               const localizedUrls = shop.localizedUrls ?? {}
               const name = shop.name
               return (
                  <View>
                     <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                     }}>
                        <TextField
                           editable={editing}
                           label={'Name'}
                           style={{ marginVertical: 5, minWidth: 350 }}
                           inputStyle={{
                              padding: 20,
                              marginRight: 10
                           }}
                           onBlur={() => {
                              const translatedName = {}
                              getSupportedLanguages().forEach((key) => {
                                 const currentValue = ((shop.translatedName ?? {})[key]) ?? ''
                                 if(currentValue.length === 0){
                                    translatedName[key] = shop.name
                                 }
                              })
                              onChangeValue('translatedName', translatedName)           
                           }}
                           onChangeText={(value) => {
                              onChangeValue(`name`, value)
                           }}
                           value={name} />
                     </View>
                     <br/>
                     <View style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                     }}>
                        {supportedLanguages.map((language) => {
                           return (
                              <TextField
                                 editable={editing}
                                 label={language.toUpperCase()}
                                 style={{ marginVertical: 5, marginRight: 10 }}
                                 inputStyle={{
                                    padding: 20
                                 }}
                                 placeholder={language.toUpperCase()}
                                 onChangeText={(value) => {
                                    onChangeValue(`translatedName.${language}`, value)
                                 }}
                                 value={translatedName[language]} />
                           )
                        })}
                     </View>
                     <br/>
                     <br/>
                     <TextField
                        editable={editing}
                        label={'Url'}
                        style={{ marginVertical: 5, minWidth: 350 }}
                        inputStyle={{
                           padding: 20,
                           marginRight: 10
                        }}
                        onBlur={() => {
                           const localizedUrls = {}
                           getSupportedLanguages().forEach((key) => {
                              const currentValue = ((shop.localizedUrls ?? {})[key]) ?? ''
                              if(currentValue.length === 0){
                                 localizedUrls[key] = shop.url
                              }
                           })
                           onChangeValue('localizedUrls', localizedUrls)           
                        }}
                        onChangeText={(value) => {
                           onChangeValue(`url`, value)
                        }}
                        value={shop.url} />
                     <View style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                     }}>
                        {supportedLanguages.map((language) => {
                           return (
                              <TextField
                                 editable={editing}
                                 label={language.toUpperCase()}
                                 style={{ marginVertical: 5, marginRight: 10, minWidth: 350 }}
                                 inputStyle={{
                                    padding: 20
                                 }}
                                 placeholder={language.toUpperCase()}
                                 onChangeText={(value) => {
                                    onChangeValue(`localizedUrls.${language}`, value)
                                 }}
                                 value={localizedUrls[language]} />
                           )
                        })}
                     </View>
                     <br/>
                     {/* {showRegionLoader === false && <MaterialTable 
                        tableRef={(ref) => regionTableRef.current = ref!}
                        options={{
                           search: false,
                           pageSize: (shop.localizedRegions ?? []).length,
                        }}
                        isLoading={showRegionLoader}
                        components={{
                           Pagination: () => null
                        }}
                        title='Localized Regions'
                        columns={[
                           {
                              title: 'Name',
                              field: 'name'
                           },
                           {
                              title: 'Url',
                              field: 'url'
                           },
                           {
                              title: 'Region',
                              field: 'code',
                              lookup: countriesLookUp
                           }
                        ]}
                        editable={editing ? {
                           onRowAdd: (region) => new Promise<void>((resolve) => {
                              const localizedRegions = [...shop.localizedRegions ?? []]
                              localizedRegions.push({
                                 name: region.name,
                                 code: region.code,
                                 url: region.url
                              })
                              onChangeValue('localizedRegions', localizedRegions)
                              setShowRegionLoader(true)
                              setTimeout(() => {
                                 setShowRegionLoader(false)
                                 resolve()
                              }, 1000);
                           }),
                           onRowUpdate: (region) => new Promise<void>((resolve) => {
                              const localizedRegions = [...shop.localizedRegions ?? []]
                              const findIndex = localizedRegions.findIndex((r) => r.code === region.code)
                              if(findIndex >= 0){
                                 localizedRegions[findIndex] = {
                                    name: region.name,
                                    code: region.code,
                                    url: region.url
                                 }
                              }
                              onChangeValue('localizedRegions', localizedRegions)
                              resolve()
                           }),
                           onRowDelete: (region) => new Promise<void>((resolve) => {
                              const localizedRegions = [...shop.localizedRegions ?? []]
                              const findIndex = localizedRegions.findIndex((r) => r.code === region.code)
                              if(findIndex >= 0){
                                 localizedRegions.splice(findIndex, 1)
                              }
                              onChangeValue('localizedRegions', localizedRegions)

                              resolve()
                           })
                        } : undefined}
                        data={shop.localizedRegions ?? []}/>} */}
                  </View>
               )
            }} />}

         {shop.companyInfo != null && <SectionPanel title='Start and End Date' render={() => {
            let startDateString: string | undefined
            let endDateString: string | undefined
            if (shop.membership?.startDate?.toDate != null) {
               startDateString = moment(shop?.membership?.startDate.toDate()).format('YYYY-MM-DD')
            }
            if (shop.membership?.endDate?.toDate != null) {
               endDateString = moment(shop?.membership?.endDate.toDate()).format('YYYY-MM-DD')
            }
            return (
               <div style={{
                  display: 'flex',
                  flexDirection: 'row',
               }}>
                  <View style={{
                     marginRight: 15
                  }}>
                     <Text>Start Date</Text>
                     <input
                        disabled={!editing}
                        onChange={(event) => {
                           const date = moment(event.target.value, 'YYYY-MM-DD').toDate()
                           if(shop.membership?.endDate == null){
                              const endDate = new Date()
                              endDate.setFullYear(2099, 12, 31)
                              onChangeValues({
                                 membership: {
                                    ...shop.membership,
                                    startDate: createTimeStamp(date.getTime() / 1000),
                                    endDate: createTimeStamp(endDate.getTime() / 1000),
                                 }
                              })
                           }else{
                              onChangeValue('membership.startDate',createTimeStamp(date.getTime() / 1000))
                           }
                        }}
                        value={startDateString}
                        type='date' />
                  </View>
                  <View>
                     <Text>End Date</Text>
                     <input
                        disabled={!editing}
                        onChange={(event) => {
                           const date = moment(event.target.value, 'YYYY-MM-DD').toDate()
                           onChangeValue('membership.endDate',createTimeStamp(date.getTime() / 1000))
                        }}
                        value={endDateString}
                        type='date' />
                  </View>
               </div>
            )
         }}/>}

         {shop.companyInfo == null && <EditingContext.Provider value={editing}>

            <TabContext value={String(tabIndex)}>
               <TabList
                  value={String(tabIndex)}
                  onChange={(_, value) => setTabIndex(value)}
                  indicatorColor="primary"
                  textColor="primary"
                  centered>
                  <Tab label="Feed Files" value="0" />
                  <Tab label="Mapping" value="1" />
                  <Tab label="Feed Items" value="2" />
                  {/* <Tab label="Linked Materials" value="3" /> */}
                  <Tab label="Billing" value="4" />
               </TabList>
               <TabPanel value={"0"}>
                  <div style={{
                     margin: 20
                  }}>
                     <View style={{
                        marginBottom: 10
                     }}>
                        <GFTextField
                           style={{
                              marginBottom: 10,
                              maxWidth: 400
                           }}
                           editing={editing}
                           value={shop.itemsPath}
                           label='Enter Items Path'
                           onChangeText={(value) => onChangeValue('itemsPath', value)} />
                        <GFTextField
                           style={{
                              marginBottom: 10,
                              maxWidth: 400
                           }}
                           editing={editing}
                           value={shop.filterKey}
                           label='Enter Filter Key'
                           onChangeText={(value) => onChangeValue('filterKey',value)} />
                        <Text style={{
                           fontWeight: 'bold',
                           marginBottom: 7
                        }}>Category Filters</Text>
                        <View style={{
                           display: 'flex',
                           alignItems: 'flex-start',
                           flexDirection: 'column',
                        }}>
                           <form onSubmit={onSubmitFilter} >
                              <GFTextField
                                 editing={editing}
                                 value={filterText}
                                 label='Enter Filters'
                                 onChangeText={(value) => setFilterText(value)} />
                           </form>
                           <div style={{
                              marginTop: 10
                           }}>
                              {renderChips(
                                 shop.filters ?? [],
                                 editing ? onDeleteFilter : undefined,
                                 countriesLookUp
                              )}
                           </div>
                        </View>
                     </View>
                     <MaterialTable
                        components={{
                           Container: props => <Paper {...props} elevation={1} />
                        }}
                        options={{
                           search: false,
                           paging: false,
                        }}
                        editable={editing === true ? {
                           onRowAdd: onPressAddFeedFile,
                           onRowDelete: onPressDeleteFeedFile,
                           onRowUpdate: onPressUpdateFeedFile
                        } : undefined}
                        title='Feed Files'
                        columns={[
                           {
                              field: 'id',
                              title: 'ID',
                              editable: 'never'
                           },
                           {
                              field: 'title',
                              title: 'Title',
                              editable: 'always'
                           },
                           {
                              field: 'url',
                              title: 'URL',
                              render: (feedFile) => <a href={feedFile.url}>Feed File URL</a>
                           },
                           {
                              field: 'fileType',
                              title: 'File Type',
                              lookup: FileTypes
                           },
                           {
                              field: 'status',
                              title: 'Status',
                              lookup: ShopStatusTypes
                           },
                           {
                              title: 'Read Status',
                              render: (file) => (
                                 <View>
                                    {file.lastRead?.toDate != null ? <Text style={{
                                       textAlign: 'center',
                                    }}>{moment(file.lastRead?.toDate?.()).format('hh:mm a DD:MM:YYYY')}</Text> : (
                                       <Text style={{
                                          textAlign: 'center',
                                       }}>Not Read</Text>
                                    )}
                                    {file.lastRead?.toDate != null && <Text style={{
                                       textAlign: 'center',
                                    }}>{moment(file.lastRead?.toDate?.()).fromNow()}</Text>}
                                    {file.readStatus != null && <Text style={{
                                       backgroundColor: file.readStatus == 'failed' ? 'red' : 'green',
                                       color: 'white',
                                       padding: 2,
                                       paddingHorizontal: 10,
                                       textAlign: 'center',
                                       borderRadius: 5,
                                       marginTop: 5
                                    }}>{ReadStatusTypes[file.readStatus]}</Text>}
                                 </View>
                              )
                           },
                           {
                              field: 'shippingCountries',
                              title: 'Shipping Countries',
                              render: (feedFile) => {
                                 return (
                                    <div style={{
                                       width: 400
                                    }}>{renderChips(feedFile.shippingCountries, undefined, countriesLookUp)}</div>
                                 )
                              },
                              editComponent: ({rowData, onRowDataChange, onChange}) => {
                                 return (
                                    <View>
                                       <EditShippingCountries 
                                          feedFile={rowData}
                                          onChangeDetails={(shippingCountries) => {
                                             onChange(shippingCountries)
                                          }}/>
                                    </View>
                                 )
                              }
                           },   
                           {
                              field: 'idPrefix',
                              title: 'ID Prefix',
                              editable: 'always'
                           },                        
                           {
                              title: 'Last Read',
                              render: (feedFile) => {
                                 const timestamp = createTimeStamp(feedFile.lastRead?.seconds ?? 0)
                                 return feedFile.lastRead ? moment(timestamp.toDate()).format('hh:mm A DD-MM-YYYY') : '--'
                              }
                           },
                           {
                              title: 'Action',
                              render: (feedFile) => loaderIndexes.includes(feedFile.id) ? (
                                 <ActivityIndicator />
                              ) : (
                                 <Button
                                    variant='contained'
                                    disabled={feedFile.status !== 'enabled'}
                                    onClick={() => onPressRun(feedFile)}>Run Now</Button>
                              )
                           },
                           {
                              title: 'JSON/CSV',
                              render: (feedFile) => loaderIndexes.includes(feedFile.id) ? (
                                 <ActivityIndicator />
                              ) : (
                                 <View>
                                    <GFSelect
                                    style={{
                                       minWidth: 150
                                    }}
                                    label='Download'
                                    onSelectValue={(value) => onPressDownloadFile(feedFile, value)}
                                    types={{
                                       'json-parsed': 'Parsed (JSON)',
                                       'csv-parsed': 'Parsed (CSV)',
                                       'json-valid-parsed': 'Parsed (JSON) - Valid',
                                       'csv-valid-parsed': 'Parsed (CSV) - Valid',
                                       'json-raw' : 'Raw (JSON)',
                                       'csv-raw' : 'Raw (CSV)',
                                    }}
                                    editing={true} />
                                    <Text style={{
                                       fontSize: 10
                                    }}>Downloads the Parsed File from GINferno Storage</Text>
                                 </View>
                              )
                           }
                        ]}
                        data={shop.feedFiles ?? []} />
                  </div>
               </TabPanel>
               <TabPanel value="1">
                  <div style={{
                     marginTop: 20,
                     marginBottom: 20
                  }}>
                     <MaterialTable
                        options={{
                           search: false,
                           paging: false
                        }}
                        editable={editing == true ? {
                           onRowAdd: onUpdateMappingRow,
                           onRowDelete: onDeleteMappingRow,
                           onRowUpdate: onUpdateMappingRow,
                        } : undefined}
                        title='Mapped Objects'
                        columns={[
                           {
                              field: 'key',
                              title: 'Target Key',
                              editable: 'always',
                              headerStyle: {
                                 fontWeight: 'bold'
                              },
                              lookup: FeedItemKeys
                           },
                           {
                              field: 'value',
                              title: 'Source Key',
                              editable: 'always',
                              headerStyle: {
                                 fontWeight: 'bold'
                              },
                           },
                           {
                              field: 'transformer.parseOptions',
                              title: 'Parse Options',
                              editable: 'always',
                              headerStyle: {
                                 fontWeight: 'bold'
                              },
                              lookup: ParseOptions
                           },
                           {
                              field: 'transformer.pattern',
                              title: 'Pattern',
                              editable: 'always',
                              headerStyle: {
                                 fontWeight: 'bold'
                              }
                           }
                        ]}
                        data={Object.keys(mappingObject).map((key) => {
                           return {
                              key,
                              value: mappingObject[key],
                              transformer: (shop.mappingObjectTransformers ?? {})[key]
                           }
                        })}
                     />
                  </div>
               </TabPanel>
               <TabPanel value="2">
                  <div>
                     <ShopFeed shopId={shop.id}/>
                  </div>
               </TabPanel>
               {/* <TabPanel value="3">
                  <div>
                     <LinkedMaterials shopId={shop.id} />
                  </div>
               </TabPanel> */}
               <TabPanel value="4">
                  <div>
                     <ShopBilling shopName={shop.name} shopId={shop.id} />
                  </div>
               </TabPanel>
            </TabContext>
         </EditingContext.Provider>}         
         {shop.companyInfo != null && <View>
            <View style={{
               flex: 1,
               justifyContent: 'space-between',
               alignItems: 'center',
               flexDirection: 'row',
               padding: '1em'
            }}>
               <Text style={{
                  fontWeight: 'bold',
                  // marginBottom: 10,
                  marginRight: 10,
               }}>Available Regions</Text>
               {editing === true && <View style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
               }}>
                  <button onClick={() => {
                     onChangeValue('regions', countriesOptionList.map(({ value }) => value.toLowerCase()))
                  }}>Select All</button>
                  <Text> ┃ </Text>
                  <button onClick={() => {
                     onChangeValue('regions', [])
                  }}>Deselect All</button>
               </View>}
            </View>
            <View style={{
               marginTop: 5,
               flexDirection: 'row',
               width: '70%',
               flexWrap: 'wrap',
               padding: '1em'
            }}>
               {countriesOptionList.map(({ label, value }) => (
                  <View style={{
                     paddingRight: 10,
                     paddingVertical: 5,
                     flexDirection: 'row',
                     justifyContent: 'flex-start',
                     width: 250,
                     alignItems: 'center'
                  }}>
                     <input disabled={editing === false} onChange={(e) => onSelectCountry(value.toLowerCase())} style={{
                        marginRight: 15
                     }} checked={shop.regions?.includes(value.toLowerCase())} type={'checkbox'}/>
                     <label>{label}</label>
                  </View>
               ))}
            </View>
         </View>}
      </div>
   )

   function onSelectCountry(item: string) {
      if(item){
         const __regions = [...(shop.regions ?? [])]
         const index = __regions.findIndex((c) => c === item)
         if (index >= 0) {
            __regions.splice(index, 1)
         }else{
            __regions.push(item)
         }
         onChangeValue('regions', __regions)
      }
   }

   function onDeleteFilter(index){
      const __filters = [...shop.filters ?? []]
      __filters.splice(index,1)
      onChangeValue('filters',__filters)
   }

   function onSubmitFilter(event){
      event.preventDefault();
      const __filters = [...shop.filters ?? []]
      const result = __filters.find(f => f == filterText)
      if(result == null){
         __filters.push(filterText)
      }
      onChangeValue('filters',__filters)
   }

   function EditShippingCountries(props: {
      feedFile: FeedFileType,
      onChangeDetails: (shippingCountries:any[]) => void,
   }) {

      const [feedFile, setFeedFile] = useState(props.feedFile)
      const shippingCountries = feedFile.shippingCountries ?? []
      const editing = useContext(EditingContext)

      return (
         <View style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',         
            padding: 15
         }}>
               <GFAutoCompleteField
                  style={{
                     width: 300
                  }}
                  label='Select Shipping Countries'
                  editing={editing}
                  options={countries.map(c => {
                     return {
                        label: c.name,
                        value: c
                     }
                  })}
                  onSelectItem={onSelectItem}
                  onChangeText={() => null} />
            <div style={{
               marginTop: 10
            }}>
               {renderChips(
                  shippingCountries, 
                  editing ? onPressDeleteCountry : undefined, 
                  countriesLookUp
               )}
            </div>
         </View>
      )
      
      function onSelectItem(value:any){
         if(value){
            const __shippingCountries = [...shippingCountries]
            const find = __shippingCountries.find((c) => c == value.code)
            if(find == null){
               __shippingCountries.push(value.code)  
            }
            const __feedFile = {
               ...feedFile,
               shippingCountries: __shippingCountries
            }
            setFeedFile(__feedFile)
            props.onChangeDetails(__shippingCountries)
         }
      }

      function onPressDeleteCountry(index:number){
         const __shippingCountries = [...shippingCountries]
         __shippingCountries.splice(index,1)
         const __feedFile = {
            ...feedFile,
            shippingCountries: __shippingCountries
         }
         setFeedFile(__feedFile)
         props.onChangeDetails(__shippingCountries)
      }
   }

   async function onPressDownloadFile(feedFile: FeedFileType, value: string) {
      showLoaderAtIndex(true, feedFile.id)
      try {

         if(value.endsWith('-raw')){

            const path = `shops/${shop.id}/feedFiles/${feedFile.id}.json`
            const url = await firebase.storage().ref(path).getDownloadURL()
   
            const response = await fetch(url, { method: 'GET' })
            const JSONObject = await response.json()
   
            const itemsPath = shop.itemsPath ?? ''
            let feedList : any[] = []
            if(itemsPath.length === 0){
               feedList = JSONObject
            }else{
               feedList = lodash.get(JSONObject, itemsPath) as any[]
            }

            if(value.startsWith('json')){

               downloadJSON(`${shop.name}-${feedList.length}-${value}.json`,JSON.stringify(feedList))

            }else if(value.startsWith('csv')){

               exportListToCSV(feedList, `${shop.name}-${feedList.length}-${value}`)

            }

         }else if(value.endsWith('-parsed')){

            let filters = [] as any[]
            if(value.includes('valid')){
               const twoDaysAgo = (new Date())
               twoDaysAgo.setHours(-48, 0, 0, 0);
               filters = [
                  {
                     fieldPath: 'createdAt',
                     opStr: '>',
                     value: twoDaysAgo
                  }
               ]   
            }

            const response = await Shops.getFeedDataForShop(shop.id, undefined, 10000, filters)
            const feedItems = response.data

            if(value.startsWith('json')){

               downloadJSON(`${shop.name}-${feedItems.length}-${value}.json`,JSON.stringify(feedItems))

            }else if(value.startsWith('csv')){

               exportListToCSV(feedItems, `${shop.name}-${feedItems.length}-${value}`)

            }
            
         }
         
         showLoaderAtIndex(false, feedFile.id)

      } catch (error) {
         console.log('Error Downloading File : ', error)
         alert('Error Downloading Feed File' + String(error))
         showLoaderAtIndex(false, feedFile.id)
      }
   }

   function onPressAddFeedFile(newData: FeedFileType) {
      return new Promise<void>((resolve, reject) => {
         const ___shop = { ...shop } as ShopType
         const feedFiles = ___shop.feedFiles ?? []
         newData.id = (new Date().getTime()).toString()
         feedFiles.push(newData)
         onChangeValue('feedFiles', feedFiles)
         resolve()
      })
   }

   function onPressDeleteFeedFile(data: FeedFileType) {
      return new Promise<void>((resolve, reject) => {
         const ___shop = { ...shop } as ShopType
         const feedFiles = (___shop.feedFiles ?? []) as FeedFileType[]
         const findIndex = feedFiles?.findIndex(f => f.id == data.id)
         if (findIndex >= 0) {
            feedFiles.splice(findIndex, 1)
         }
         onChangeValue('feedFiles', feedFiles)
         resolve()
      })
   }

   function onPressUpdateFeedFile(data: FeedFileType) {
      return new Promise<void>((resolve, reject) => {
         const ___shop = { ...shop } as ShopType
         const feedFiles = (___shop.feedFiles ?? []) as FeedFileType[]
         const findIndex = feedFiles?.findIndex(f => f.id == data.id)
         if (findIndex >= 0) {
            feedFiles[findIndex] = data
         }
         onChangeValue('feedFiles', feedFiles)
         resolve()
      })
   }

   function onChangeValues(values: Partial<Shops.Shop>) {
      const __shop = { 
         ...shop,
         ...values
      }
      setShop(__shop)
   }

   function onChangeValue(path: string, value: any) {
      const __shop = { ...shop }
      lodash.setWith(__shop, path, value)
      setShop(__shop)
   }

   function onClickClose() {
      setShop(lodash.cloneDeep(props.shop))
      setEditing(false)
   }

   function onClickEdit() {
      setEditing(true)
   }

   function showLoaderAtIndex(show: boolean, index: string) {
      const ___loaderIndexes = [...loaderIndexes]
      const findIndex = ___loaderIndexes.findIndex(i => i == index)
      if (show) {
         if (findIndex < 0) {
            ___loaderIndexes.push(index)
         }
      } else {
         if (findIndex >= 0) {
            ___loaderIndexes.splice(findIndex, 1)
         }
      }
      setLoaderIndexes(___loaderIndexes)
   }

   async function onPressRun(feedFile: FeedFileType) {
      showLoaderAtIndex(true, feedFile.id)
      try {
         const body = {
            feedFileId: feedFile.id,
            shopId: shop.id
         }
         const url = 'https://us-central1-ginfernov2.cloudfunctions.net/executeFeedFile'
         // const url = 'http://127.0.0.1:5001/ginfernov2/us-central1/executeFeedFile'
         console.log('body', url, body)
         const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
               "Content-Type": "application/json"
            }
         })
         const jsonResponse =  await response.json()
         console.log('Run Response',jsonResponse)
         if(jsonResponse.status != 'success'){
            throw new Error(await response.statusText);
         }
      } catch (error) {
         console.log('Failed to Run Feed File',String(error))
         Alert.alert('Failed to Run Feed File',String(error))
      }
      showLoaderAtIndex(false, feedFile.id)
   }

   async function onClickSave() {
      setEditing(false)
      setShowLoader(true)
      await onPressUpdate(shop.id, shop, props.shop)
      setShowLoader(false)
   }

   function onUpdateMappingRow(rowData){
      return new Promise((resolve,reject) => {
         const ___mappingObjectTransformers = {...mappingObjectTransformers}
         const ___mappingObject = {...mappingObject}
         ___mappingObjectTransformers[rowData.key] = rowData.transformer
         ___mappingObject[rowData.key] = rowData.value
         onChangeValues({
            mappingObject: ___mappingObject,
            mappingObjectTransformers: ___mappingObjectTransformers
         })
         resolve(undefined)
      })
   }

   function onDeleteMappingRow(rowData){
      return new Promise((resolve,reject) => {
         const ___mappingObject = {...mappingObject}
         delete ___mappingObject[rowData.key]
         onChangeValue('mappingObject',___mappingObject) 
         resolve(undefined)
      })
   }
}