import { Review, updateReview, deleteReview as deleteUserReview } from 'gf-connect/lib/Review'
import { Change, AppState } from '.'
import { submitReview, readReviews, getReviewsForUserId } from 'gf-connect/lib/Review'
import firestar,{ WhereType } from 'firestar'
import { Recipe } from 'gf-connect/lib/Recipe'

const initialState : Review[] = []

export default function (state = initialState, action: Change) {
   switch (action.type) {
      case 'SYNC_REVIEWS' : {
         let currentList = [...state]            
         const newReviews = [...action.payload as Review[]]
         newReviews.forEach( (p:Review) => {
               const findIndex = currentList.findIndex( (c) => c.id ===  p.id)                  
               if(findIndex >= 0){
                  currentList[findIndex] = p
               }else{
                  currentList.push(p)
               }
            }
         )   
         return currentList
      }
      case 'DELETE_REVIEW' : {
         let currentList = [...state]            
         const reviewToBeDeleted = action.payload as Review
         const findIndex = currentList.findIndex((c) => c.id === reviewToBeDeleted.id)
         currentList.splice(findIndex,1)
         return currentList
      }
      case 'SIGN_OUT' : {
         return []
      }
      default:
         return state
   }
}

export function addReview(review: Review): any {
   return (dispatch: (change: Change) => any, getState: () => AppState) => {
      return new Promise<Review>(async (resolve, reject) => {
         submitReview(review)
         .then(
            (response) => {
               dispatch({
                  type: 'SYNC_REVIEWS',
                  payload: [response]
               })
               const recipe = getState().recipes.find(r => review.recipeId == r.id)
               if(recipe){
                  dispatch({
                     type: 'SYNC_RECIPES',
                     payload: [{
                        ...recipe,
                        review: response
                     } as Recipe]
                  })
               }
               resolve(response as Review)
            }
         )
         .catch(error => reject(error))
      })
   }
}

export function deleteReview(review: Review): any {
   return (dispatch: (change: Change) => any, getState: () => AppState) => {
      return new Promise<Review>(async (resolve, reject) => {
         deleteUserReview(review)
         .then(
            () => {
               dispatch({
                  type: 'DELETE_REVIEW',
                  payload: review
               })
               const recipe = getState().recipes.find(r => review.recipeId == r.id)
               if(recipe){
                  dispatch({
                     type: 'SYNC_RECIPES',
                     payload: [{
                        ...recipe,
                        review: undefined
                     } as Recipe]
                  })
               }
               resolve(review)
            }
         )
         .catch(error => reject(error))
      })
   }
}

export function updateReviewDetails(review: Review): any {
   return (dispatch: (change: Change) => any, getState: () => AppState) => {
      return new Promise<Review>(async (resolve, reject) => {

         updateReview(review)
         .then(
            (response) => {
               dispatch({
                  type: 'SYNC_REVIEWS',
                  payload: [response]
               })
               resolve(response as Review)
            }
         )
         .catch(error => reject(error))
      })
   }
}

export function getReviewsForRecipe(recipeId:string,userId:string) : any {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         const where : WhereType[] = [
            {
               fieldPath : 'recipe.id',
               opStr : '==',
               value : recipeId
            }
         ]
         if(userId){
            where.push({
               fieldPath : 'createdBy.id',
               opStr : '==',
               value : userId
            })
         }
         readReviews(recipeId,{
            limit : userId ? 1 : 10,
            where : where
         })
         .then(
            (response) => {
               // dispatch({
               //    type : 'SYNC_REVIEWS',
               //    payload : response.data
               // })
               resolve(response.data)
            }
         )
         .catch(error => reject(error))
      })
   }
}

export function getMyReviews(lastDoc?: any): any {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise((resolve, reject) => {
         const userId = firestar.getUserId() ?? ''
         getReviewsForUserId(userId, {
            lastDoc: lastDoc
         })
            .then(
               (response) => {
                  dispatch({
                     type: 'SYNC_REVIEWS',
                     payload: response.data
                  })
                  resolve({
                     data: response.data,
                     lastDoc: response.lastDoc
                  })
               }
            )
            .catch((error) => reject(error))
      })
   }
}
