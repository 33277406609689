"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleLevelOptions = void 0;
const firestar_1 = require("firestar");
exports.RoleLevelOptions = {
    'owner': 'Owner',
    'admin': 'Admin',
    'editor': 'Editor',
    'viewer': 'Viewer'
};
var Admin;
(function (Admin) {
    function getUsers() {
        return firestar_1.Actions.readCollection(`settings/security/users`, {
            limit: 10000
        });
    }
    Admin.getUsers = getUsers;
    function addUser(userId, userInfo) {
        return firestar_1.Actions.writeDocument(`settings/security/users/${userId}`, userInfo);
    }
    Admin.addUser = addUser;
    function deleteUser(userId) {
        return firestar_1.Actions.deleteDocument(`settings/security/users/${userId}`);
    }
    Admin.deleteUser = deleteUser;
})(Admin || (Admin = {}));
exports.default = Admin;
