import React, {
   useState,
   useEffect
} from 'react'
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import EditOutlined from '@material-ui/icons/EditOutlined'
import SaveOutlined from '@material-ui/icons/SaveOutlined'
import CloseOutlined from '@material-ui/icons/CloseOutlined'
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from './store';
import {
   getMaterialsList, updateMaterialInfo, searchMaterialWithName, getMaterials
} from './store/Materials'
import {
   Material,
   getAllMaterials
} from 'gf-connect/lib/Material';
import lodash from 'lodash'
import {
   CollectionResponse
} from 'firestar';
import { exportListToCSV } from 'gf-connect/lib/Utils';
import Shops, { FeedItemKeys } from 'gf-connect/lib/Shops';
import { Alert } from './Alert';

export default function BarcodesMapping() {

   const materials = useSelector((state:AppState) => state.materials)
   const dispatch = useDispatch()
   const [lastDoc, setLastDoc] = useState<any>()
   const [showLoader, setShowLoader] = useState(false)
   const [searchText, setSearchText] = useState<string|undefined>(undefined)

   useEffect(() => {
      onLoad()
   }, [])

   async function onLoad(){
      setShowLoader(true)
      const response = await dispatch(getMaterialsList({
         limit: 20,
         orderBy: {
            fieldPath: 'updatedAt',
            directionStr: 'desc'
         }
      })) as CollectionResponse<any>
      setLastDoc(response.lastDoc)
      setShowLoader(false)
   }

   async function startPagination(){
      setShowLoader(true)
      const response = await dispatch(getMaterialsList({
         limit: 10,
         lastDoc: lastDoc,
         orderBy: {
            fieldPath: 'updatedAt',
            directionStr: 'desc'
         }
      })) as CollectionResponse<any>
      setLastDoc(response.lastDoc)
      setShowLoader(false)
   }

   return (
      <div>
         <div style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            marginBottom: 10
         }}>
            <Button
               style={{
                  width: 250,
               }}
               variant='outlined'
               color='primary'
               onClick={onPressDownloadEANData}>Download Barcode Data</Button>
         </div>
         <MaterialTable
            title='Barcodes'
            options={{
               debounceInterval: 1,
               pageSize: 10,
               pageSizeOptions:[10],
               searchFieldVariant:'outlined',
               searchFieldStyle: {
                  marginTop: 10
               },
               searchText: searchText
            }}
            detailPanel={(material) => (
               <DetailPanel
                  material={material}
                  onChangeMaterial={(__material) => onMaterialUpdate(__material)}
               />
            )}
            onSearchChange={onSearchChange}
            onChangePage={onChangePage}
            isLoading={showLoader}
            data={lodash.cloneDeep(materials)}
            columns={[
               {
                  field: 'id',
                  title: 'Id',
                  editable: 'never',
               },
               {
                  field: 'name',
                  title: 'Name',
                  editable: 'never',
               },
               {
                  field: 'type',
                  title: 'Type',
                  editable: 'never',
               },
               {
                  field: 'producer.name',
                  title: 'Producer',
                  editable: 'never',
               },
               {
                  field: 'owner.name',
                  title: 'Owner',
                  editable: 'never',
               },
               {
                  field: 'status',
                  title: 'Status',
                  editable: 'never',
               }
            ]}
            />
      </div>
   )

   async function onPressDownloadEANData(){
      setShowLoader(true)
      const response : CollectionResponse<any> = await dispatch(getMaterialsList({
         limit: 10000,
         orderBy:{
            fieldPath: 'name',
            directionStr: 'desc'
         }
      }))   
      const materials = response.data as Material[]
      const exportData : any[] = []
      materials.forEach((material) => {
         const barcodes = material.barcodes ?? []
         if(barcodes.length > 0){
            barcodes.forEach((barcode) => {
               exportData.push({
                  id: `${material.id}_${barcode ?? ''}`,
                  materialId: material.id,
                  name: material.name,
                  barcode: barcode
               })
            })
         }else{
            exportData.push({
               id: `${material.id}_`,
               materialId: material.id,
               name: material.name,
               barcode: ''
            })
         }
      })
      exportListToCSV(exportData,'materials_barcode_data',[
         'id',
         'materialId',
         'name',
         'barcode'
      ])
      setShowLoader(false)
   }

   async function onMaterialUpdate(material:Material){
      setShowLoader(true)
      delete (material as any).tableData
      await dispatch(updateMaterialInfo(material))
      setShowLoader(false)
   }

   async function onSearchChange(value:string){
      if(value.trim().length){
         setSearchText(value)
         setShowLoader(true)
         await dispatch(searchMaterialWithName(value))
         setShowLoader(false)
      }
   }

   function onChangePage(page: number, pageSize: number){
      startPagination()
   }
}

function DetailPanel(props:{
   material: Material,
   onChangeMaterial: (material:Material) => void
}){

   const [material, setMaterial] = useState(lodash.cloneDeep(props.material))
   const [editing, setEditing] = useState(false)
   const [matchingBarcodes, setMatchingBarcodes] = useState<any>([])

   return (
      <div style={{
         display: 'flex',
         alignItems: 'flex-start',
         flexDirection: 'column',         
         padding: 15
      }}>
         <IconButton
            onClick={editing == false ? onClickEdit : onClickSave}>
            {editing == false ? <EditOutlined /> : <SaveOutlined />}
         </IconButton>
         {editing == true && <IconButton
            onClick={onClickClose}>
            <CloseOutlined />
         </IconButton>}
         <div style={{
            marginTop: 10
         }}>
            <form onSubmit={onSubmitBarcode} >
               <TextField
                  disabled={!editing}
                  label='Barcode'
                  onChange={(event) => {
                     onChangeValue('barcode', event.target.value.trim())
                  }}
                  style={{
                     marginBottom: 5
                  }}
                  placeholder='Enter Barcode (EAN, UPC etc.)'
                  variant='outlined'
                  value={material.barcode}
               />
            </form>
            {renderChips(material.barcodes ?? [],(index) => onDeleteBarcode(index))}
            {matchingBarcodes.length > 0 && <div>
               <b>You have either added this barcode twice or you have a different gin with the same barcode.</b>
               {matchingBarcodes.map((m) => <div>
                  <Typography>{m.name}</Typography>
               </div>)}
            </div>}
         </div>
      </div>
   )

   function onClickClose(){
      setMaterial(lodash.cloneDeep(props.material))
      setEditing(false)
   }

   function onClickEdit(){
      setEditing(true)
   }

   function onClickSave(){
      const ___material = { ...material } as Material
      ___material.barcode = ''
      props.onChangeMaterial(___material)
      setEditing(false)
   }

   function onDeleteBarcode(index) {

      const ___material = { ...material } as Material
      const barcodes = ___material.barcodes ?? []
      barcodes.splice(index,1)
      ___material.barcodes = barcodes
      setMaterial(___material)
   }

   function onSubmitBarcode(event) {
      event.preventDefault();

      const ___material = { ...material } as Material

      checkMatchingBarcodes(___material.barcode!)

      const barcodes = ___material.barcodes ?? []
      if (___material.barcode != null &&
         barcodes.find(b => b == ___material.barcode) == null) {
         barcodes.push(___material.barcode)
         ___material.barcodes = barcodes
      }
      ___material.barcode = ''
      setMaterial(___material)
   }

   async function checkMatchingBarcodes(barcode:string){
      const response = await getAllMaterials({
         limit: 100,
         where: [
            {
               fieldPath: 'barcodes',
               opStr: 'array-contains',
               value: barcode
            }
         ]
      })
      const matches = response.data
      setMatchingBarcodes(matches)
   }

   function onChangeValue(path: string, value: any){
      const ___material = {...material}
      lodash.setWith(___material,path,value)
      setMaterial(___material)
   }
}

export function renderChips(
   list: (string | undefined)[],
   onPressDelete?: (index) => void,
   lookUp?: any
) {
   return list?.filter(i => (i != null)).map((i, index) => (
      <Chip
         onDelete={onPressDelete ? () => onPressDelete(index) : undefined}
         style={{
            marginRight: 5,
            marginBottom: 5,
         }} 
         size="medium" 
         label={((lookUp ?? {})[i!]) ?? i ?? '--'} 
      />)
   )
}
