import {
   addToCabinet,
   removeFromCabinet,
   getCabinet
} from 'gf-connect/lib/User'
import { Material } from 'gf-connect/lib/Material'
import firebase from 'firebase/compat/app'
import { Change, AppState } from '.'

const initialState : Material[] = []

export default function (state = initialState, action: Change) : Material[] {
   switch (action.type) {  
      case 'SYNC_CABINET':{
         let currentList = [...state]            
         const newCategories = [...action.payload as Material[]]
         newCategories.forEach( (p:Material) => {
               const findIndex = currentList.findIndex( (c) => c.id ===  p.id)                  
               if(findIndex >= 0){
                  currentList[findIndex] = p
               }else{
                  currentList.push(p)
               }
            }
         )   
         return currentList
      } 
      case 'DELETE_FROM_CABINET': {
         let currentList = [...state]
         const categoryToBeDeleted = action.payload as Material
         const findIndex = currentList.findIndex((c) => c.id === categoryToBeDeleted.id)
         currentList.splice(findIndex, 1)
         return currentList
      }
      case 'SIGN_OUT' : {
         return []
      }
      default:
         return state
   }
}

export function getSavedCabinet(){
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise(async (resolve, reject) => {
         const userId = firebase.auth().currentUser?.uid!
         getCabinet(userId,{
            limit: 1000
         })
         .then(
            (response) => {
               dispatch({
                  payload: response.data,
                  type: 'SYNC_CABINET'
               })
               resolve(response)
            }
         )
         .catch((error) => reject(error))
      })
   }
}

export function addMaterialToCabinet(material:Material) : any {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<Material|undefined>(async (resolve, reject) => {
         const userId = firebase.auth().currentUser?.uid!
         const findResult = getState().cabinet.find(c => c.id == material.id)
         console.log('Category',material)
         console.log('Find Result',findResult)
         if(findResult){
            try {
               await removeFromCabinet(userId,material.id)            
               dispatch({
                  type: 'DELETE_FROM_CABINET',
                  payload: material
               })
               resolve(undefined)
            } catch (error) { reject(error) }
         }else{
            try {
               const result = await addToCabinet(userId, material)
               dispatch({
                  type: 'SYNC_CABINET',
                  payload: [result]
               })
               resolve(result)
            } catch (error) { reject(error) }
         }
      })
   }
}